import { api_gtw } from "../../apis";

export const getOrdersRetur = async (search) => {
  const { data } = await api_gtw.get(`/retur/all${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const getValidateRetur = async (search) => {
  const { data } = await api_gtw.get(`/retur/validate/${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const scanRetur = async ({ noawb_order, seller }) => {
  const { data } = await api_gtw.patch(
    `/retur/checked`,
    { noawb_order, seller },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const getCheckedRetur = async () => {
  const { data } = await api_gtw.get(`/retur/allChecked`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const updateRetur = async ({ payload, id }) => {
  const { data } = await api_gtw.put(`/retur/update/${id}`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const uploadRetur = async (data, ShopId) => {
  const { data: _data } = await api_gtw.post(
    `/retur/upload`,
    { data, ShopId },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return _data;
};
