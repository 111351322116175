import { api_gtw } from "../../apis";

export const addLog = async (access) => {
  const { data } = await api_gtw.post(
    "/log",
    { access },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  return data;
};
