import React from "react";
// import { LoadingButton } from '@material-ui/core';
import LoadingButton from "@mui/lab/LoadingButton";

export default function Loading() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ flexDirection: "row", height: "600px" }}
    >
      <div
        className="spinner-border"
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="sr-only">.</span>
      </div>
    </div>
  );
}

export function LoadingInButton() {
  return (
    // <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: 'row', height: '25px'}}>
    //   <div className="spinner-border" role="status">
    //     <span className="sr-only"></span>
    //   </div>
    // </div>
    <LoadingButton
      loading
      variant="contained"
      color="secondary"
      sx={{ lexDirection: "row", width: "100%" }}
    >
      Submit
    </LoadingButton>
  );
}
