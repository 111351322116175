import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//kebutuhan card
import {
  CardContent,
  Grid,
  Avatar,
  Chip,
  Box,
  Card,
  CardHeader,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Male, Female } from "@mui/icons-material";

//-------||IMPORT DATA||---------//
import {
  getKOLContentQuantity,
  updateStatusSample,
} from "../../store/actions/actionDisplay";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../store/actions/actionAwb";
import { handleError } from "../../helper/handleError";
import { convertMonth } from "../../helper/convertDate";
import MainCard from "../MainCard.js";

export default function CardSampleKol({ sampleList, i }) {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(false),
    [values, setValues] = useState({ expedisi: "", resi: "" }),
    [status, setStatus] = useState(""),
    [categories, setCategories] = useState([]),
    [contentCount, setContentCount] = useState({});

  function rank(jenis) {
    let color = "#a66c48";

    if (jenis === "Pejuang") {
      color = "#56A472";
    }
    if (jenis === "Kapten") {
      color = "#B04B87";
    }
    if (jenis === "Jendral") {
      color = "#00A2E9";
    }
    if (jenis === "Pahlawan") {
      color = "#BD454F";
    }

    return (
      <Box
        sx={{
          height: "100%",
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ color: color, fontWeight: 700 }}>
            {jenis.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    );
  }

  function AvatarGender(gender, id) {
    const man1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-1.png";
    const man2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-2.png";
    const woman1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-3.png";
    const woman2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-4.png";
    if (gender === "pria") {
      if (id % 2 == 0) {
        return man1;
      } else {
        return man2;
      }
    } else if (gender === "wanita") {
      if (id % 2 == 0) {
        return woman1;
      } else {
        return woman2;
      }
    }
  }

  function handleUpdateStatus(e) {
    e.preventDefault();
    setLoading(true);
    const payload = {
      ...values,
      status: "delivering",
      sample_id: sampleList.id,
      kol_info: sampleList,
    };
    dispatch(updateStatusSample(payload))
      .then(() => {
        dispatch(setErr(false));
        dispatch(setMessage("Berhasil mengirim sample ke kreator."));
        dispatch(setOpenSnack(true));
        setValues({ expedisi: "", resi: "" });
        setStatus("delivering");
      })
      .catch((e) => {
        const message = handleError(e);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleTolak(e) {
    e.preventDefault();
    setLoading(true);
    const payload = {
      status: "rejected",
      sample_id: sampleList.id,
      kol_info: sampleList,
    };
    dispatch(updateStatusSample(payload))
      .then(() => {
        dispatch(setErr(false));
        dispatch(setMessage("Menolak Kirim Sample ke Kreator"));
        dispatch(setOpenSnack(true));
        setValues({ expedisi: "", resi: "" });
        setStatus("rejected");
      })
      .catch((e) => {
        const message = handleError(e);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    let arr = [];
    if (sampleList.kol_info?.konsep_video.ads) arr.push("ADS");
    if (sampleList.kol_info?.konsep_video.duet) arr.push("Duet");
    if (sampleList.kol_info?.konsep_video.freestyle) arr.push("Freestyle");
    if (sampleList.kol_info?.konsep_video.slideShow) arr.push("slide Show");
    if (sampleList.kol_info?.konsep_video.storyTelling)
      arr.push("Story Telling");

    setCategories(arr);
    setStatus(sampleList.sample_tracking?.status);

    async function getKOLContent() {
      const { data } = await dispatch(
        getKOLContentQuantity(sampleList.kol_info.kol_id)
      );

      setContentCount(data.data.count);
    }
    getKOLContent();

    convertMonth(sampleList.createdAt);
  }, []);

  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
        <MainCard
          content={false}
          sx={{
            color: "#000000",
            paddingRight: "10px",
            width: "347px",
            borderRadius: 0,
          }}
          tabIndex={-1}
          disableRipple
        >
          <CardContent sx={{ p: 1 }}>
            <Grid mr={1}>
              <Typography variant="h4" sx={{ textAlignLast: "right" }}>
                ETALASE {convertMonth(sampleList.createdAt)} No.{" "}
                {sampleList.Display?.display_number}
              </Typography>
              <Typography
                variant="h4"
                sx={{ textAlignLast: "right" }}
              ></Typography>
            </Grid>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="recipe"
                  src={AvatarGender(
                    sampleList.kol_info?.gender,
                    sampleList.kol_info?.kol_id
                  )}
                  alt="ava"
                />
              }
              title={
                <Typography variant="h3">
                  {sampleList.kol_info?.full_name}
                </Typography>
              }
              subheader={"@" + sampleList.kol_info?.sosmed}
            />
            <CardContent sx={{ paddingTop: 0, backgrounColor: "#000000" }}>
              <Grid
                sx={{ display: "flex", justifyContent: "space-between" }}
                spacing={2}
              >
                {rank(sampleList.kol_info?.level)}
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlignLast: "center" }}
                  >
                    Followers
                  </Typography>
                  <Typography variant="h6">
                    <Male
                      style={{
                        color: `#13AEC0`,
                        height: "18px",
                        width: "18px",
                      }}
                    />{" "}
                    {sampleList.kol_info?.followers?.man}%
                    <Female
                      style={{
                        color: `#FC611F`,
                        height: "18px",
                        width: "18px",
                      }}
                    />{" "}
                    {sampleList.kol_info?.followers?.woman}%
                  </Typography>
                </Box>
              </Grid>

              <Grid spacing={2} mt={2}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlignLast: "start" }}
                  >
                    Nama Barang:
                  </Typography>
                  <Typography variant="h6">
                    {sampleList.Display?.item_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid spacing={2} mt={2}>
                <Typography variant="subtitle2" sx={{ textAlignLast: "start" }}>
                  Link Produk:
                </Typography>
                <Typography variant="h6">
                  <a
                    href={
                      /http/i.test(sampleList.Display?.display_link_product)
                        ? sampleList.Display?.display_link_product
                        : `https://${sampleList.Display?.display_link_product}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {sampleList.Display?.display_link_product?.length > 40
                      ? sampleList.Display?.display_link_product.substring(
                          0,
                          40
                        ) + "..."
                      : sampleList.Display?.display_link_product}
                  </a>
                </Typography>
              </Grid>
              <Grid spacing={2} mt={2}>
                <Typography variant="subtitle2" sx={{ textAlignLast: "start" }}>
                  Konsep Video:
                </Typography>
                <Typography variant="h6">
                  {categories.length ? categories.map((e) => e + ", ") : "-"}
                </Typography>
              </Grid>

              <Grid
                spacing={2}
                mt={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlignLast: "start" }}
                  >
                    Konten Selesai:
                  </Typography>
                  <Typography variant="h6">
                    {contentCount?.done ? contentCount?.done : 0} Konten
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlignLast: "start" }}
                  >
                    Konten Berlangsung:
                  </Typography>
                  <Typography variant="h6">
                    {contentCount?.accepted ? contentCount?.accepted : 0} Konten
                  </Typography>
                </Box>
              </Grid>

              <Box
                sx={{ width: "100%", bgcolor: "#EEEEEF", paddingX: 1 }}
                mt={2}
              >
                <Grid
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  spacing={2}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlignLast: "start" }}
                    >
                      Nomor HP:
                    </Typography>
                    <Typography variant="h6">
                      +{sampleList.kol_info?.phone_number}
                    </Typography>
                  </Box>
                </Grid>
                <Grid spacing={2} mt={2}>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlignLast: "start" }}
                  >
                    Alamat:
                  </Typography>
                  <Typography variant="h6">
                    {sampleList.kol_info?.kol_address}
                  </Typography>
                </Grid>
              </Box>

              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "10px",
                }}
                mt={2}
              >
                <TextField
                  id="outlined-name"
                  label="Resi"
                  size="small"
                  placeholder="021020074257518"
                  sx={{ width: "63%" }}
                  name="resi"
                  disabled={status !== "requesting" ? true : false}
                  values={values.resi}
                  onChange={(e) =>
                    setValues({ ...values, resi: e.target.value })
                  }
                  defaultValue={
                    sampleList.sample_tracking?.resi
                      ? sampleList.sample_tracking?.resi
                      : null
                  }
                />
                <TextField
                  id="outlined-uncontrolled"
                  label="Expedisi"
                  size="small"
                  placeholder="JNE"
                  sx={{ width: "35%" }}
                  name="expedisi"
                  disabled={status !== "requesting" ? true : false}
                  values={values.expedisi}
                  onChange={(e) =>
                    setValues({ ...values, expedisi: e.target.value })
                  }
                  defaultValue={
                    sampleList.sample_tracking?.ekspedisi
                      ? sampleList.sample_tracking?.ekspedisi
                      : null
                  }
                />
              </Box>
              {status !== "requesting" ? (
                <Box>
                  <Box
                    sx={{
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      color={
                        status === "delivering"
                          ? "primary"
                          : status === "rejected"
                          ? "error"
                          : "success"
                      }
                      label={status.toUpperCase().replace("_", " ")}
                      variant="outlined"
                    />
                  </Box>
                  {status === "content_making" ? (
                    <Box textAlign="center">
                      <Typography variant="caption">
                        Silahkan Cek Menu "Progres Konten" untuk melihat
                        kemajuan Konten anda{" "}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <Box sx={{ height: "60px" }}>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      backgroundColor: "#bd454f",
                      color: "white",
                      marginTop: "15px",
                      width: "100%",
                    }}
                    type="submit"
                    onClick={(e) => handleUpdateStatus(e)}
                  >
                    Kirim Sampel
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    style={{
                      color: "black",
                      marginTop: "10px",
                      width: "100%",
                    }}
                    type="submit"
                    onClick={(e) => handleTolak(e)}
                  >
                    Tolak
                  </Button>
                </Box>
              )}
            </CardContent>
            {/* <Card sx={{ width: "100%" }}></Card> */}
          </CardContent>
        </MainCard>
      </Grid>
    </>
  );
}
