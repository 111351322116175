import { api_gtw } from "../../apis";

export const getResi = async () => {
  const { data } = await api_gtw.get(`/dropper/getResi`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const getDestinationCode = async () => {
  const payload = "cekDestinationCode=true";
  const { data } = await api_gtw.post(
    `/dropper/getDestination`,
    { payload },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const getPrice = async (payload) => {
  const { data } = await api_gtw.post(`/dropper/getPrice`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const generateResi = async (payload) => {
  const { data } = await api_gtw.post(`/dropper/generateResi`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

// export const registerDropper = async (payload) => {
//   const { data } = await api_gtw.post(`/seller/register/dropper`, payload);
//   return data;
// };
