import { jk_gtw } from "../../apis";
import axios from "axios";

export function editProfile(payload) {
  return function () {
    return jk_gtw.put("/update-profile", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getDisplays() {
  return function () {
    return jk_gtw.get("/display/getDisplays", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function postEtalase(payload) {
  return function () {
    return jk_gtw.post("/display", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function putEtalase(payload) {
  return function () {
    return jk_gtw.put("/display/edit", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getRequestSample() {
  return function () {
    return jk_gtw.get("/display/sample", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function updateStatusSample(payload) {
  return function () {
    return jk_gtw.patch("/display/status-sample", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getKOLContentQuantity(kol_id) {
  return function () {
    return jk_gtw.get(`/display/getKOLContentQuantity?kol_id=${kol_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}
