import { api_gtw } from "../../apis";
//report
export const downloadReport = async ({ search, payload }) => {
  const { data } = await api_gtw.post(
    `/sync/download/report${search ? search : ""}`,
    payload,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const getTotalDownloadReport = async (payload) => {
  const { data } = await api_gtw.post(`/sync/download/total`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.count;
};

export const getReport = async ({ search, selectedStore }) => {
  const { data } = await api_gtw.get(
    `/sync/report${search}&userId=${selectedStore}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const getThreePlTotal = async ({ search, selectedStore }) => {
  const { data } = await api_gtw.get(
    `/sync/report/total${search}&userId=${selectedStore}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const getAllSuratJalan = async ({ date, selectedStore }) => {
  const { data } = await api_gtw.get(
    `/sync/suratjalan?date=${date}&userId=${selectedStore}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const addSuratJalan = async ({
  payload,
  date,
  selectedStoreSuratJalan,
}) => {
  const { data } = await api_gtw.post(
    `/sync/add/suratjalan?userId=${selectedStoreSuratJalan}`,
    { payload, date },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

//report

//ticket
export const getTransactionsTiketing = async ({ search, selectedStore }) => {
  const { data } = await api_gtw.get(
    `/sync/get/tiketing${search}&userId=${selectedStore}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const addTransactionsTicketing = async ({
  dataTicket,
  selectedStore,
}) => {
  let payload = dataTicket;
  const { data } = await api_gtw.post(
    `/sync/add/ticket?userId=${selectedStore}`,
    payload,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const deleteTicket = async (payload) => {
  const { data } = await api_gtw.post(`/sync/ticket`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};
//ticket

//dashboard

export const getDashboardWeekly = async (selectedStore) => {
  const { data } = await api_gtw.get(
    `/sync/dashboard/weekly?userId=${selectedStore}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

//dashboard

export const getRewardSeller = async (query) => {
  const { data } = await api_gtw.get(
    `/sync/finance?date=${query.date}&seller=${query.seller}`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};
