import React from "react";
import { Modal, Box } from "@mui/material";

export default function Mutasi({ open, setOpen }) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              // border: '2px solid #000',
              boxShadow: 24,
              p: 6,
              borderRadius: "10px",
            }}
          >
            <h1 style={{ textAlignLast: "center" }}> MUTASI COMMING SOON!</h1>
          </Box>
        </div>
      </Modal>
    </>
  );
}
