import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { ArrowDropDown } from "@mui/icons-material";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../store/actions/actionAwb";
import { bayar } from "../../store/actions/actionKol";
import { handleError } from "../../helper/handleError";
import { LoadingInButton } from "../Loading";
import { convertDate } from "../../helper/convertDate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  maxHeight: "80vh",
  overflowY: "auto",
};

export default function BayarContent({
  open,
  setOpen,
  data,
  setData,
  dataList,
}) {
  const dispatch = useDispatch(),
    [loading, setLoading] = useState(false),
    [expanded, setExpanded] = useState("bca"),
    [image, setImage] = useState(""),
    [formImage, setFormImage] = useState(null),
    [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value),
      newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fileHandler = (e) => {
    e.preventDefault();
    let form = new FormData();
    form.append("image", e.target.files[0]);
    setFormImage(form);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (checked.length === 0) {
      dispatch(setErr(true));
      dispatch(setMessage("Mohon pilih content yang ingin dibayar"));
      dispatch(setOpenSnack(true));
    } else {
      if (!image) {
        dispatch(setErr(true));
        dispatch(setMessage("Mohon masukkan foto bukti transfer"));
        dispatch(setOpenSnack(true));
      } else {
        setLoading(true);
        dispatch(bayar(formImage, checked, setLoading))
          .then(({ data }) => {
            dispatch(setErr(false));
            dispatch(setMessage(data.message));
            dispatch(setOpenSnack(true));
            setOpen(false);
            setImage(null);
            setFormImage(null);
            window.location.reload();
          })
          .catch((e) => {
            const message = handleError(e);
            dispatch(setErr(true));
            dispatch(setMessage(message));
            dispatch(setOpenSnack(true));
          })
          .finally(() => setLoading(false));
      }
    }
  };

  useEffect(() => {
    if (data) setChecked([...checked, data]);
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      {data ? (
        <>
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
              setImage(null);
              setChecked([]);
              setData(null);
            }}
          >
            <Box
              sx={{
                ...style,
                height: "auto",
                maxWidth: { xs: "95%", md: "50%" },
              }}
            >
              <p
                className="d-flex align-items-center m-0 p-0"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Belum Terbayar
              </p>
              <Grid sx={{ maxHeight: "350px", minWidth: "100%" }}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    maxHeight: 300,
                    overflowY: "scroll",
                  }}
                >
                  {dataList.map((value, i) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <ListItem sx={{ width: "100%" }} key={i} disablePadding>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(value)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(value) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${value.kol_name} - ${convertDate(
                              value.createdAt
                            )}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
                <Grid rowSpacing={2}>
                  <Button>
                    <Box
                      onClick={() => {
                        setChecked(dataList);
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      Pilih Semua
                    </Box>
                  </Button>
                  <Grid>
                    <p className="mt-2 p-0">
                      Total : {checked.length} x 50.000
                    </p>
                    <p className="m-0 p-0">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      Rp.{" "}
                      {Number(checked.length * 50000).toLocaleString("id-ID")},-
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Metode Pembayaran */}
              <Grid
                direction="column"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  justifyContent: "center",
                  mt: 7,
                }}
              >
                <Grid>
                  <Typography
                    align="center"
                    className="d-flex align-items-center m-0 p-0"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    Informasi Metode Pembayaran
                  </Typography>
                </Grid>
                <Accordion
                  expanded={expanded === "bca"}
                  onChange={handleChange("bca")}
                  className="mb-3 text-center"
                  align="center"
                  sx={{ width: "80%" }}
                >
                  <AccordionSummary expandIcon={<ArrowDropDown />}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Transfer BCA
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      backgroundColor: "#D5C59A",
                      /*color: 'black',*/ fontWeight: "bold",
                    }}
                  >
                    <p className="m-0 p-0">PT. Gapura Dunia Informatika</p>
                    <p className="m-0 p-0">710-089-8971</p>
                  </AccordionDetails>
                </Accordion>
                <form onSubmit={handleUpload}>
                  <Typography align="center">
                    <Button
                      variant="contained"
                      component="label"
                      alignItems="center"
                      className="d-flex justify-content-center align-items-center"
                      sx={{
                        backgroundColor: "#98777B",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      Upload Bukti Transfer
                      <input
                        type="file"
                        accept="image/*"
                        onChange={fileHandler}
                        hidden
                      />
                    </Button>
                  </Typography>
                  {image ? (
                    <Grid
                      className="d-flex justify-content-center align-items-center text-center"
                      sx={{
                        marginTop: "15px",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={image}
                        sx={{ borderRadius: "10px", mt: 5, mb: 5 }}
                        width="50%"
                        height="50%"
                        alt="bukti-trf"
                      />
                    </Grid>
                  ) : (
                    <Grid className="p-3 d-flex justify-content-center align-items-center">
                      <Typography
                        className="m-0 p-0"
                        align="center"
                        sx={{ mt: 5, mb: 5 }}
                      >
                        Belum Ada Gambar
                      </Typography>
                    </Grid>
                  )}
                  {loading ? (
                    <LoadingInButton />
                  ) : (
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "#ab8c36",
                        color: "white",
                        marginTop: "15px",
                        width: "100%",
                        borderRadius: 0,
                        "&:hover": {
                          backgroundColor: "#333333",
                          borderColor: "#333333",
                        },
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  )}
                </form>
              </Grid>
            </Box>
          </Modal>
        </>
      ) : null}
    </>
  );
}
