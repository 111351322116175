import { api_gtw } from "../../apis";

export const getAllDataReturn = async (search) => {
  const { data } = await api_gtw.get(`/scan/return${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const getAllData = async (search) => {
  const { data } = await api_gtw.get(`/scan/store${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const getAllDataBagging = async (search) => {
  const { data } = await api_gtw.get(`/scan/bagging${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const cancelDataBagging = async (seal) => {
  const { payload, id } = seal;
  const { data } = await api_gtw.post(`/scan/bagging/${id}`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

  return data.data;
};

export const createDataSeal = async (payload) => {
  const { data } = await api_gtw.post("/scan/bagging", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

  return data;
};

export const validateDataScan = async (awb) => {
  // console.log(awb);
  const { data } = await api_gtw.patch(
    `/scan/validate`,
    { awb },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const editDataScan = async (payload) => {
  // console.log(awb);
  const { data } = await api_gtw.put(`/scan`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const deleteDataScan = async (id) => {
  const { data } = await api_gtw.delete(`/scan/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const uploadData = async (data) => {
  const { data: _data } = await api_gtw.post(`/scan/upload`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return _data;
};

export const scanBulkData = async (data) => {
  const { data: _data } = await api_gtw.post(`/scan/bulk`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "X-Request-ID": "_" + Math.random().toString(36).substr(2, 9),
    },
  });
  return _data;
};

export const scanOneData = async (data) => {
  const { data: _data } = await api_gtw.patch(`/scan`, data, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return _data;
};

export const RegisterEasyDrop = async (payload) => {
  const { data } = await api_gtw.post("/seller/register/easy-drop", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const PushNotificationWA = async (payload) => {
  await api_gtw.post("/scan/push-wa", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
};

export const downloadReportBigPos = async ({ search, payload }) => {
  const { data } = await api_gtw.post(
    `/scan/download/report${search ? search : ""}`,
    payload,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const downloadTotalReport = async (payload) => {
  const { data } = await api_gtw.post(`/scan/download/total`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.count;
};
