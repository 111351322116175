export function getQueryUrl(query) {
  try {
    if (/[&?=]/.test(String(query).toLowerCase())) {
      let result = {};
      query
        .split("?")[1]
        .split("&")
        .forEach((el, i) => (result[el.split("=")[0]] = el.split("=")[1]));
      return result;
    }
  } catch (e) {}
  return false;
}

export const convertDate = (date) => {
  const _date = new Date(typeof date === "number" ? date * 1000 : date),
    options = { timeZone: "Asia/Jakarta" };
  return {
    date: _date.toLocaleDateString("en-US", options),
    time: _date.toLocaleTimeString("en-US", options),
  };
};

export const convertPrice = (price, point = false) => {
  const number_string = price.toString(),
    remainder = number_string.length % 3;
  let money = number_string.substr(0, remainder);
  const thousand = number_string.substr(remainder).match(/\d{3}/g);
  if (thousand) {
    const separator = remainder ? "." : "";
    money += separator + thousand.join(".");
  }
  return point ? money : `Rp. ${money}`;
};

export const convertDataToQuery = (data) => {
  if (data) {
    const obj = Object.keys(data);
    let query = ``;
    for (let i = 0; i < obj.length; i++) {
      if (query.length === 0) {
        query += `?${obj[i]}=${data[obj[i]]}`;
        continue;
      }
      query += `&${obj[i]}=${data[obj[i]]}`;
    }

    return query;
  }
  return "";
};

export const convertCurrency = (value) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(value);
};

export const ThisMonth = () => {
  let today = new Date();

  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  //day -1
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  let dateNow = yesterday.getDate();
  let pastmonth = yesterday.getMonth() + 1;

  // if(today.getDate())
  let lastdate = new Date(year, month, 0).getDate();
  let obj = {
    startDate: `${month}-01-${year}`,
    endDate: `${month}-${lastdate}-${year}`,
    thisDate: `${pastmonth}-${dateNow}-${year}`,
  };
  return obj;
};

export const formatDate = (date, status) => {
  if (typeof date === "string") {
    let newDate = date.split("-");
    if (!status) {
      const tempDate = newDate[1];
      const tempMonth = newDate[0];
      newDate[0] = tempDate;
      newDate[1] = tempMonth;
    }
    newDate = newDate.join("/");
    if (newDate[0] === 0) newDate = newDate.slice(1, newDate.length);
    return newDate;
  } else {
    let newDate = date.getDate();

    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    //day -1
    let newTanggal = `${newDate}/${month}/${year}`;
    return newTanggal;
  }
};

export function descriptionAWB(awb) {
  let description = "";

  switch (awb.trim().substring(0, 2)) {
    case "JX":
      description = "Tiktok";
      break;
    case "JP":
      description = "Shopee";
      break;
    case "JD":
      description = "Manual";
      break;
    case "JB":
      description = "Bukalapak";
      break;
    case "JT":
      description = "JNE";
      break;
    case "TK":
      description = "JNE";
      break;
    case "JL":
      description = "JNE";
      break;
    case "TL":
      description = "JNE";
      break;
    case "TJ":
      description = "JNE";
      break;
    case "CM":
      description = "JNE";
      break;
    case "JZ":
      description = "Lazada";
      break;

    default:
      description = "Other";
      break;
  }

  return description;
}
