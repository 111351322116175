import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
// import {
//   Close,
//   DeleteOutlined,
//   EditOutlined,
//   CancelOutlined,
//   DoneOutlined,
// } from "@mui/icons-material";
import { forwardRef } from "react";

// import { useReactToPrint } from "react-to-print";
import jwt_decode from "jwt-decode";

export const ComponentToPrint = forwardRef((props, ref) => {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: "Surat Jalan Nich Boss q",
  //   onAfterPrint: () => alert("Print succcess"),
  // });
  //for decode token
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  // const accessStatus = decode.accessStatus;
  const shopName = decode.shopName;
  //for decode token

  return (
    <Box sx={{ height: "50vh", width: "100vw" }} ref={ref}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignitems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "15%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography variant="h1" sx={{ mt: 2 }}>
              {shopName ? shopName.toUpperCase() : ""}
            </Typography>
            <Typography variant="h3" sx={{ mb: 2 }}>
              SURAT JALAN
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                border: 1,
                my: 2,
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                p: 1,
              }}
            >
              <Typography variant="h4">{`TANGGAL : ${
                props.date
                  ? new Date(props.date).toLocaleDateString("id-ID", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : new Date().toLocaleDateString("id-ID", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
              }`}</Typography>
              <Typography variant="h4">PLAT KENDARAAN :</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "70%",
            border: 2,
            borderColor: "#333333",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ekspedisi</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell align="center">Jumlah Paket</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.map((e, i) => (
                  <TableRow key={i}>
                    <TableCell>{e.expedition.toUpperCase()}</TableCell>
                    <TableCell>{e.service.toUpperCase()}</TableCell>
                    <TableCell align="center">
                      {e.total_expedition.toUpperCase()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "15%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80%",
              flexDirection: "column",
              ml: 2,
            }}
          >
            <Typography>Tanda Terima</Typography>
            <Typography>
              {"(................................................)"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80%",
              flexDirection: "column",
              mr: 2,
            }}
          >
            <Typography>Hormat Kami</Typography>
            <Typography>
              {"(................................................)"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
