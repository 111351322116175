import { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getDashboardWeekly } from "../../store/actions/syncAction";
import { handleError } from "../../helper/helpers";
import jwt_decode from "jwt-decode";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top",
//     },
//     title: {
//       display: true,
//       text: "Total Ekspedisi",
//     },
//   },
// };

// const backgroundColor: [
//   "#e27c7c",
//   "#a86464",
//   "#6d4b4b",
//   "#503f3f",
//   "#333333",
//   "#3c4e4b",
//   "#466964",
// ],

function DashboardPremiumPage() {
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  let userInternal = decode.userInternalId;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [seller, setSeller] = useState(0);
  const [selectedStore, setSelectedStore] = useState(
    // decode?.userInternalId[0].id
    0
  );

  const [data2, setData2] = useState({
    labels: ["", "", "", "", "", "", ""],
    datasets: [
      {
        label: "Total Paket",
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#e27c7c",
      },
    ],
  });
  const [options2, setOptions2] = useState({
    responsive: true,
    maintainAspectRatio: matchesMobile ? false : true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: `Total Harian Paket`,
      },
    },
  });

  const getDashboardWeeklyAction = async () => {
    try {
      const data = await getDashboardWeekly(selectedStore);
      setData2({
        ...data2,
        labels: data.tanggal,
        datasets: [
          {
            label: "Total Paket",
            data: data.total,
            backgroundColor: "#e27c7c",
          },
        ],
      });
    } catch (error) {
      const message = handleError(error);
    }
  };

  useEffect(() => {
    getDashboardWeeklyAction();

    if (decode.role) {
      setSeller(decode.userInternalId[0].id);
      setSelectedStore(decode?.userInternalId[seller]?.userInternalId[0]?.id);
    }
    if (!decode.role) {
      setSelectedStore(decode?.userInternalId[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#ffffff",
          height: "90%",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography align="center" sx={{ fontSize: 28, fontWeight: "bold" }}>
          DASHBOARD
        </Typography>

        <Box sx={{ mr: 2 }}>
          {decode.users?.length ? (
            <>
              <FormControl sx={{ mr: 2, width: "100px" }}>
                <InputLabel>Pilih Seller</InputLabel>
                <Select
                  label="Pilih Seller"
                  sx={{ borderRadius: 0 }}
                  value={seller}
                  onChange={(e) => {
                    setSeller(e.target.value);
                    // push({ ...query, status: "proces", limit: 10, page: 1 })
                  }}
                >
                  {userInternal.map((e, i) => (
                    <MenuItem key={i} value={e.id}>
                      {e.username}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {seller ? (
                <FormControl sx={{ width: "100px" }}>
                  <InputLabel>Pilih Toko</InputLabel>
                  <Select
                    label="Pilih Toko"
                    sx={{ borderRadius: 0 }}
                    value={selectedStore}
                    onChange={(e) => {
                      setSelectedStore(e.target.value);
                      // push({ ...query, status: "proces", limit: 10, page: 1 })
                    }}
                  >
                    {userInternal.map((e) => {
                      if (e.id === seller) {
                        return e.userInternalId.map((res, i) => (
                          <MenuItem key={i} value={res.id}>
                            {res.username}
                          </MenuItem>
                        ));
                      }
                    })}
                  </Select>
                </FormControl>
              ) : null}
            </>
          ) : null}

          {!decode.role ? (
            <FormControl sx={{ width: "100px" }}>
              <InputLabel>Pilih Toko</InputLabel>
              <Select
                label="Pilih Toko"
                sx={{ borderRadius: 0 }}
                value={selectedStore}
                onChange={(e) => {
                  setSelectedStore(e.target.value);
                  // push({ ...query, status: "proces", limit: 10, page: 1 })
                }}
              >
                {userInternal.map((e, i) => (
                  <MenuItem key={i} value={e.id}>
                    {e.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Box>

        <Box
          sx={{
            width: "80%",
            height: { lg: "auto", md: "auto", sm: "100%", xs: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Bar data={data2} options={options2} />
        </Box>
      </Box>
    </Container>
  );
}

export default DashboardPremiumPage;
