export const categories = [
  'Perlengkapan Rumah',
  'Perlengkapan Dapur',
  'Tekstil & Soft Furnishing',
  'Peralatan Rumah Tangga',
  'Pakaian & Pakaian Dalam Wanita',
  'Fashion Muslim',
  'Fashion Anak',
  'Pakaian & Pakaian Dalam Pria',
  'Sepatu',
  'Aksesoris Fashion',
  'Perawatan & Kecantikan',
  'Telepon & Elektronik',
  'Komputer & Perlengkapan Kantor',
  'Perlengkapan Hewan Peliharaan',
  'Bayi & Ibu Hamil',
  'Olahraga & Outdoor',
  'Mainan & Alat Musik',
  'Alat & Perangkat Keras',
  'Perbaikan Rumah',
  'Perlengkapan Kendaraan',
  'Makanan & Minuman',
  'Koper & Tas',
  'Kesehatan',
  'Buku, Majalah & Audio',
  'Koleksi'
];

export const konsepVideo = ['ADS', 'Story Telling', 'Duet', 'Slide Show', 'Freestyle'];
