import { useEffect, useMemo, useState } from "react";
import { DateRangeModal } from "../../components/DateRangeModal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  convertDataToQuery,
  getQueryUrl,
  getOfDateNow,
} from "../../helper/convertDate";
import { exportFileExcelNew } from "../../helper/helpers";
import { downloadTotalReport } from "../../store/actions/scanAction";
import moment from "moment";

export default function ReportPage() {
  const [dateFilter, setShowDateFilter] = useState(false);
  const [downloadReady, setDownloadReady] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [countPkg, setCountPkg] = useState(0);
  const navigate = useNavigate();
  const { search } = useLocation();

  const push = (payload) =>
    navigate(`/easy-drop/report${convertDataToQuery(payload)}`);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const payload = {
    state: {
      startDate: query.minDate,
      endDate: query.maxDate,
    },
  };

  const fetching = async () => {
    let time = moment.duration("08:00:00");
    let date = moment();
    date.subtract(time);
    const res = await downloadTotalReport({
      state: { startDate: date, endDate: new Date() },
    });

    setCountPkg(res);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    await exportFileExcelNew(payload);
    setIsDownloading(false);
  };

  useEffect(() => {
    fetching();
  }, []);

  return (
    <article className="w-full p-4">
      {/* tanggal report */}
      <section className="lg:text-4xl text-2xl font-bold">
        <h1>{getOfDateNow()}</h1>
      </section>
      <section className="mt-10 max-h-max grid lg:grid-cols-2 grid-cols-1 gap-4">
        <div className="flex justify-start">
          <div className="grid grid-cols-2 bg-[#ab8c36] shadow-lg lg:max-w-sm w-full">
            <h4 className="text-xl border font-bold px-4 min-h-[4rem] flex items-center">
              Total pengiriman
              <br />
              /hari ini
            </h4>
            <div className="bg-slate-100">
              <div className="flex items-center justify-center h-full">
                <h3 className="text-3xl font-bold">{countPkg}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:justify-end justify-normal">
          <div className="grid grid-cols-2 bg-[#ab8c36] shadow-lg lg:max-w-sm w-full">
            <h4 className="text-xl font-bold px-4 min-h-[4rem] flex border items-center">
              Checkpoint
            </h4>
            <div className="bg-slate-100">
              <div className="flex items-center justify-center h-full">
                <h3 className="text-3xl font-bold text-green-700">2000</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h1 className="text-2xl text-center py-8 font-semibold">
          Download report berdasarkan range tanggal
        </h1>
        <div className="flex justify-center gap-3">
          <button
            type="button"
            className="bg-white py-2 px-4 shadow-lg border-2 border-[#ab8c36]"
            onClick={() => setShowDateFilter(true)}
          >
            Choose Date Range
          </button>
          <button
            type="button"
            onClick={handleDownload}
            className="py-2 px-4 bg-green-700 text-white shadow-lg disabled:bg-green-700/70 disabled:cursor-not-allowed"
            disabled={isDownloading || downloadReady}
          >
            {isDownloading
              ? "Download sedang berlangsung..."
              : "Download Report"}
          </button>
        </div>
      </section>
      {dateFilter ? (
        <DateRangeModal
          onFilter={({ startDate, endDate }) =>
            (_) => {
              console.log(startDate, endDate);
              push({ ...query, minDate: startDate, maxDate: endDate });
              setDownloadReady(false);
              setShowDateFilter(false);
            }}
          open
          close={setShowDateFilter}
        />
      ) : null}
    </article>
  );
}
