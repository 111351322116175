import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Zoom,
  Fade,
  TablePagination,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Tooltip,
  Snackbar,
  Slide,
  InputLabel,
  Select,
} from "@mui/material";
import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/wrong.mp3";
import { dateFormat, timeFormat } from "../../helper/DateFormat";
import { ThisMonth } from "../../helper/custom";
import { Waypoint } from "react-waypoint";

import {
  Close,
  EditOutlined,
  CancelOutlined,
  DoneOutlined,
  Search,
  InfoOutlined,
} from "@mui/icons-material";
import { useState, useMemo, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";

import { read, utils, writeFile } from "xlsx";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import {
  convertDataToQuery,
  getQueryUrl,
  formatDate,
} from "../../helper/custom";
import { compress } from "compress-json";

import {
  getCheckedRetur,
  getValidateRetur,
  scanRetur,
  getOrdersRetur,
  updateRetur,
  uploadRetur,
} from "../../store/actions/returAction";
import jwt_decode from "jwt-decode";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";

function ReturPage() {
  //for decode token
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const accessStatus = decode.accessStatus;

  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };

  let inputScanAwb = useRef();

  const upper = useRef(null);
  const scrollToUpper = () => {
    if (!loading) {
      if (upper.current) upper.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  //modal
  const [text, setText] = useState("");
  // const [openModalPrint, setOpenModalPrint] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openMenuScan, setOpenMenuScan] = useState(false);
  const [openModalErrorChecked, setOpenModalErrorChecked] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalDetailValidation, setOpenModalDetailValidation] =
    useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  //modal

  //=================

  //loading
  const [loading, setLoading] = useState(true);
  const [loadingValidate, setLoadingValidate] = useState(true);
  const [loadingScan, setLoadingScan] = useState(false);
  const [loadingChecked, setLoadingChecked] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(true);

  //loading

  //=================

  //for show and hidden
  const [scanManual, setScanManual] = useState(false);
  const [isEdit, setIsEdit] = useState(-1);
  const [typeUpload, setTypeUpload] = useState("");

  //for show and hidden

  //=================

  //audio
  const [audioCorrect] = useState(new Audio(correct));
  const [audioWrong] = useState(new Audio(wrong));
  //audio

  //=================

  //for containin data
  const [dataChecked, setDataChecked] = useState([]);
  const [dataCheckedTotal, setDataCheckedTotal] = useState([]);

  const [dataValidate, setDataValidate] = useState({});
  const [count, setCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [detailValidate, setDetailValidate] = useState([]);
  const [totalDetailValidate, setTotalDetailValidate] = useState([]);
  // const [res, setRes] = useState({ status: "success", message: "" });
  const [previousService, setPreviousService] = useState("");
  const [previousExpedition, setPreviousExpedition] = useState("");
  const [checked, setChecked] = useState("");
  const [editDate, setEditDate] = useState("");
  // const [filterDetail, setFilterDetail] = useState("notChecked");

  const isAppleProduct =
    navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;

  //for containin data

  //=================

  //for change state
  const [idEdit, setIdEdit] = useState(0);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  //for get query
  let [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  //for get query

  //for change state
  const [editData, setEditData] = useState({});
  const [error, setError] = useState("");
  const [errorScan, setErrorScan] = useState("");
  const [errorUploadChecked, setErrorUploadChecked] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState(params.search || "");
  const [seller, setSeller] = useState(
    decode.role ? decode.users[0]?.ShopId : 0
  );

  //for change state

  //=================

  //=================

  //for navigate
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const push = (payload) => {
    navigate(`/retur${convertDataToQuery(payload)}`);
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //for navigate

  //=================

  //for styled
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  //for styled

  //=================
  //for decode token

  //=================

  // function getNetworkConnection() {
  //   return (
  //     navigator.connection ||
  //     navigator.mozConnection ||
  //     navigator.webkitConnection ||
  //     null
  //   );
  // }

  // function getNetworkConnectionInfo() {
  //   const connection = getNetworkConnection();
  //   if (!connection) {
  //     return {};
  //   }

  //   return {
  //     rtt: connection.rtt,
  //     type: connection.type,
  //     saveData: connection.saveData,
  //     downLink: connection.downLink,
  //     downLinkMax: connection.downLinkMax,
  //     effectiveType: connection.effectiveType,
  //   };
  // }

  // getNetworkConnectionInfo();

  //edit transaction
  const editSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = await updateRetur({
        payload: editData,
        id: idEdit,
      });
      let newArr = [...dataList];

      newArr[isEdit] = {
        ...newArr[isEdit],
        awb: data.awb,
        order_id: data.order_id,
        expedition: data.expedition,
        service: data.service,
      };
      setDataList(newArr);
      setIsEdit(-1);
      let temp = { ...dataValidate };
      let previous = false;
      let next = false;
      let temp2 = { ...data };
      temp2.returDate = "";
      temp2.returDate = editDate;

      if (checked) {
        temp2.checkedDate = new Date();
      }
      Object.keys(dataValidate).forEach((e) => {
        if (e !== "total") {
          if (
            `${data.expedition.toLowerCase()} ${data.service.toLowerCase()}` ===
            e.toLowerCase()
          ) {
            temp[e].totalNotChecked += 1;
            next = true;
          } else {
            next = false;
          }
          if (
            `${previousExpedition.toLowerCase()} ${previousService.toLowerCase()}` ===
            e.toLowerCase()
          ) {
            if (
              `${previousExpedition.toLowerCase()} ${previousService.toLowerCase()}` !==
              `${data.expedition.toLowerCase()} ${data.service.toLowerCase()}`
            ) {
              if (temp[e].totalNotChecked === 1) {
                delete temp[e];
              } else {
                temp[e].totalNotChecked -= 1;
                if (checked) {
                  temp[e].totalChecked -= 1;
                }
                let index = temp[e].data.findIndex((e) => e.awb === data.awb);
                if (index > -1) {
                  temp[e].data.splice(index, 1);
                }
              }
              previous = true;
            }
          } else {
            previous = false;
          }
        }
      });
      setPreviousService("");
      setPreviousExpedition("");
      if (!next || !previous) {
        if (!temp[`${data.expedition} ${data.service}`]) {
          temp[`${data.expedition} ${data.service}`] = {};
        }
        if (!temp[`${data.expedition} ${data.service}`].totalNotChecked) {
          temp[`${data.expedition} ${data.service}`].totalNotChecked = 1;
          temp[`${data.expedition} ${data.service}`].totalChecked = 0;

          if (checked) {
            temp[`${data.expedition} ${data.service}`].totalChecked += 1;
          }
        } else {
          if (checked) {
            temp[`${data.expedition} ${data.service}`].totalChecked += 1;
            setChecked("");
          }
        }
        if (!temp[`${data.expedition} ${data.service}`].data)
          temp[`${data.expedition} ${data.service}`].data = [];
        temp[`${data.expedition} ${data.service}`].data.push(temp2);
      }
      const sorted = Object.keys(temp)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = temp[key];

          return accumulator;
        }, {});
      setDataValidate(sorted);
    } catch (error) {
      const message = await handleError(error);
      setError(message);
    }
  };
  //edit transaction

  //=================

  //for upload file
  const fileHandler = async (e) => {
    try {
      setText("Upload sedang berlangsung");
      setOpenModalUpload(false);
      setLoadingUpload(true);

      setError("");
      setErrorUploadChecked([]);
      e.preventDefault();
      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();
      if (!/xls/i.test(getFiles.name)) {
        setErrorUploadChecked([{ status: "Harap format excel bertipe xlxs" }]);
        setLoadingUpload(false);
        setOpenModalErrorChecked(true);
        // setOpenModalUpload(false);
        return;
      }
      const f = await getFiles.arrayBuffer();
      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
            // else {
            //   ws[s].w = ws[s].w.split("/");

            //   ws[s].w[2] = `20${ws[s].w[2]}`;
            //   ws[s].w = ws[s].w.join("/");
            // }
          }
        }
      });
      const data = utils.sheet_to_json(ws, { raw: false });
      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;

      if (typeUpload === "checker") {
        if (excel[0][0] !== "No.awb Retur") {
          setErrorUploadChecked([
            { status: "Format table salah harap menggunakan tamplate terbaru" },
          ]);
          statusError = true;
        } else if (data.length === 1 && !data[0]["No.awb Retur"]) {
          statusError = true;

          setErrorUploadChecked([{ status: "Data tidak Ada" }]);
        } else {
          setLoadingDetail(true);
          const _data = await scanRetur({ noawb_order: data, seller });
          if (_data.update.length) {
            let temp1 = [...dataChecked];

            Object.keys(dataValidate).forEach((e, i) => {
              if (e !== "total") {
                let find = dataValidate[e].data.filter((e) =>
                  _data.update.find(
                    (e1) =>
                      e1.awb.toLowerCase() === e.awb.toLowerCase() &&
                      e1.order_id.toLowerCase() === e.order_id.toLowerCase()
                  )
                );
                if (find.length) {
                  let temp = dataValidate[e];

                  find.forEach((e1, i) => {
                    e1.checkedDate = _data.update[i].checkedDate;
                    temp1.push(e1);
                    temp.totalChecked += 1;
                  });
                  // temp.data.sort((a, b) => b.checkedDate - a.checkedDate);
                  setDataValidate({
                    ...dataValidate,
                    [e]: temp,
                  });
                  setLoadingDetail(false);

                  setDataChecked(temp1);
                }
              }
            });

            let temp = [...dataList];
            dataList.forEach((e, i) => {
              let find = _data.update.find(
                (e1) =>
                  `${e1.awb}`.toLowerCase() === `${e.awb}`.toLowerCase() &&
                  `${e1.order_id}`.toLowerCase() ===
                    `${e.order_id}`.toLowerCase()
              );
              if (find) {
                temp[i].checkedDate = find.checkedDate;
              }
            });
            setDataList(temp);
          }
        }
      } else {
        if (
          excel[0][0] !== "Nomor Order Retur (optional)" ||
          excel[0][1] !== "*Nomor Resi Retur" ||
          excel[0][2] !== "*Tanggal Order (DD/MM/YYYY)" ||
          excel[0][3] !== "*Ekspedisi" ||
          excel[0][4] !== "Service (optional)"
        ) {
          setErrorUploadChecked([
            { status: "Format table salah harap menggunakan tamplate terbaru" },
          ]);
          statusError = true;
        } else if (
          data.length === 1 &&
          !data[0]["Nomor Order Retur (optional)"] &&
          !data[0]["*Nomor Resi Retur"] &&
          !data[0]["*Tanggal Order (DD/MM/YYYY)"] &&
          !data[0]["*Ekspedisi"] &&
          !data[0]["Service (optional)"]
        ) {
          statusError = true;

          setErrorUploadChecked([{ status: "Data tidak Ada" }]);
        } else {
          let last = false;

          let _newData = { errors: [], totalCreate: 0, totalUpdate: 0 };
          while (!last) {
            let newData = data.splice(0, 10000);

            last = data.length === 0;
            console.log(last);
            let compressed = compress(newData);

            const _data = await uploadRetur(compressed, seller);
            _newData.errors = _newData.errors.concat(_data.errors);
            _newData.totalCreate += _data.totalCreate;
            _newData.totalUpdate += _data.totalUpdate;
          }
          if (_newData.errors.length) {
            setErrorUploadChecked(_newData.errors);
            statusError = true;
          }
          if (_newData.totalCreate || _newData.totalUpdate) {
            await getReturOrder();

            await getReturValidate();
          }
        }
      }

      setLoadingUpload(false);
      // setOpenModalUpload(false);
      if (statusError) {
        setOpenModalErrorChecked(true);
        return;
      }

      // setFileName(getFiles.name);
    } catch (err) {
      const message = await handleError(err);
      if (Array.isArray(message)) {
        if (message.length) {
          setErrorUploadChecked(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setErrorUploadChecked([{ status: message }]);
      } else {
        setErrorUploadChecked([message]);
      }

      setLoadingUpload(false);
      // setOpenModalUpload(false);
      setOpenModalErrorChecked(true);
    }
    e.target.value = null;
  };

  const downloadTemplate = async () => {
    try {
      let rows;
      let wscols;

      if (typeUpload === "checker") {
        rows = [
          {
            "No.awb Retur": "",
          },
        ];

        wscols = [{ width: 20 }];
      } else {
        rows = [
          {
            "Nomor Order Retur (optional)": "",
            "*Nomor Resi Retur": "",
            "*Tanggal Order (DD/MM/YYYY)": "",
            "*Ekspedisi": "",
            "Service (optional)": "",
          },
        ];

        // wscols = [
        //   { width: rows[0]["Nomor Order"].length },
        //   { width: rows[0]["Nomor Resi"].length },
        //   { width: rows[0]["Tanggal Order"].length },
        //   { width: rows[0]["Ekspedisi"].length },
        //   { width: rows[0]["Service"].length },
        // ];

        wscols = [
          { width: 26 },
          { width: 20 },
          { width: 40 },
          { width: 20 },
          { width: 20 },
        ];
      }

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      if (typeUpload === "checker") writeFile(wb, "Hasil_Scan_Retur.xlsx");
      else writeFile(wb, "Upload_Retur.xlsx");
    } catch (error) {
      await handleError(error);
    }
  };
  //for upload file

  //=================

  //for scan manual
  const scanSubmit = async (e) => {
    try {
      setError("");
      setErrorScan("");
      e.preventDefault();
      setLoadingScan(true);
      setLoadingDetail(true);

      const data = await scanRetur({
        noawb_order: inputScanAwb.current.value.toLowerCase(),
        seller,
      });

      let result;
      let findResult;
      Object.keys(dataValidate).forEach((e, i) => {
        if (e !== "total") {
          let find = dataValidate[e].data.find((e) => {
            return (
              e.awb.toLowerCase().trim() ===
                data.update.awb.toLowerCase().trim() &&
              e.order_id.toLowerCase().trim() ===
                data.update.order_id.toLowerCase().trim()
            );
          });
          if (find) {
            result = e;
            findResult = find;
            return;
          }
        }
      });

      if (result && findResult) {
        findResult.checkedDate = data.update.checkedDate;

        let temp = dataValidate[result];
        temp.totalChecked += 1;
        // temp.data.sort((a, b) => b.checkedDate - a.checkedDate);
        setDataValidate({
          ...dataValidate,
          [result]: temp,
        });
        setLoadingDetail(false);

        const findIndex = dataList.findIndex(
          (e) =>
            e.awb.toLowerCase().trim() ===
              inputScanAwb.current.value.toLowerCase().trim() ||
            e.order_id.toLowerCase().trim() ===
              inputScanAwb.current.value.toLowerCase().trim()
        );

        if (findIndex > -1) {
          let temp = [...dataList];
          temp[findIndex].checkedDate = data.update.checkedDate;
          setDataList(temp);
        }

        let temp2 = [...dataChecked];
        temp2.unshift(findResult);
        setDataChecked(temp2);
      }
      scrollToUpper();

      setLoadingScan(false);

      audioCorrect.play();
      e.target.reset();
    } catch (error) {
      setLoadingScan(false);

      const message = await handleError(error);
      setErrorScan(message);
      if (message === "Tidak ada koneksi") {
        setError(message);
      }
      audioWrong.play();
      e.target.reset();
    }
  };
  //for scan manual

  //=================

  // get all transaction data
  const getReturOrder = async () => {
    try {
      setError("");

      setLoading(true);
      let temp = [...dataList];
      temp = [];
      setDataList(temp);
      // setCount(0);
      const data = await getOrdersRetur(search);
      setCount(data.count);
      setDataList(data.rows);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const message = await handleError(error);
      setError(message);
    }
  };
  //

  //=================

  // get all scanned total data
  const getReturValidate = async () => {
    try {
      setLoadingValidate(true);
      setError("");
      setDataValidate({});

      const data = await getValidateRetur(search);
      setDataValidate(data.data);
      setLoadingValidate(false);
    } catch (error) {
      const message = await handleError(error);
      setLoadingValidate(false);
      setError(message);
    }
  };
  //

  //=================
  // get all scanned  data (manual)
  const getChecked = async () => {
    try {
      setError("");

      setLoadingChecked(true);
      const data = await getCheckedRetur();
      setDataCheckedTotal(data);

      // setDataValidate(data.data);
      setLoadingChecked(false);
    } catch (error) {
      setLoadingChecked(false);

      const message = await handleError(error);

      setError(message);
    }
  };

  //=================

  //waypoint detial
  const scrollDetail = async (i) => {
    setLoadingDetail(true);

    let temp1 = [...detailValidate];
    let temp2 = totalDetailValidate;
    temp2 = totalDetailValidate.slice(i + 1, i + 1 + 15);
    temp1 = temp1.concat(temp2);

    setDetailValidate(temp1);
    setLoadingDetail(false);
  };
  //waypoint detial
  //=================

  //waypoint scan
  const scrollScan = async (i) => {
    console.log(i);
    setLoadingScan(true);
    let temp1 = [...dataChecked];
    let temp2 = dataCheckedTotal;
    temp2 = dataCheckedTotal.slice(i + 1, i + 1 + 11);

    temp1 = temp1.concat(temp2);
    setDataChecked(temp1);
    setLoadingScan(false);
  };
  //waypoint scan

  useEffect(() => {
    if ((params.minDate && params.maxDate) || params.search) {
      getReturValidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.minDate, params.maxDate, params.search]);

  useEffect(() => {
    getReturOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    // getReturOrder();
    if (decode.role) {
      const searchParams = new URLSearchParams(search);
      searchParams.set("ShopId", seller);
      const newUrl = `${pathname}?${searchParams.toString()}`;
      navigate(newUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);

  // useEffect(() => {
  //   scrollToUpper();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataChecked]);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        maxHeight: {
          lg: "100vh",
          md: "100vh",
          sm: "100vh",
          xs: "100vh",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
        // mt: { lg: 0, md: 0, sm: 5, xs: 5 },
      }}
    >
      <Snackbar
        open={openModalErrorChecked}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Upload Gagal
        </Alert>
      </Snackbar>

      {/* modal loading */}
      <ModalLoading loadingUpload={loadingUpload} text={text} />
      {/* modal tanggal */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              width: matchesMobile ? "100%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => setState(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={matchesMobile ? 1 : 2}
              ranges={[state]}
              direction={matchesMobile ? "vertical" : "horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={matchesMobile ? [] : undefined}
              staticRanges={matchesMobile ? [] : undefined}
              // inputRanges={[]}
              // staticRanges={[]}
              className={
                matchesMobile
                  ? {
                      rdrStaticRanges: {
                        width: "0px",
                      },
                      rdrDateRangePickerWrapper: {
                        display: "flex !important",
                        justifyContent: "center !important",
                        alignItems: "center !important",
                        width: "100% !important",
                        position: "relative !important",
                      },
                    }
                  : null
              }
            />

            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => setOpenModal(false)}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  if (isAppleProduct) {
                    let newMinDate = new Date(
                      state.startDate
                    ).toLocaleDateString("en-US", options);

                    let newMaxDate = new Date(state.endDate).toLocaleDateString(
                      "en-US",
                      options
                    );

                    newMinDate = newMinDate.split("/");
                    newMaxDate = newMaxDate.split("/");

                    const tempDateMin = newMinDate[1];
                    const tempMonthMin = newMinDate[0];

                    const tempDateMax = newMaxDate[1];
                    const tempMonthMax = newMaxDate[0];
                    newMinDate[0] = tempDateMin;
                    newMinDate[1] = tempMonthMin;

                    newMaxDate[0] = tempDateMax;
                    newMaxDate[1] = tempMonthMax;
                    newMinDate = newMinDate.join("-");
                    newMaxDate = newMaxDate.join("-");

                    push({
                      ...query,
                      minDate: newMinDate,
                      maxDate: newMaxDate,
                    });
                  } else {
                    push({
                      ...query,
                      minDate: state.startDate
                        .toLocaleDateString("en-US", options)
                        .replaceAll("/", "-"),
                      maxDate: state.endDate
                        .toLocaleDateString("en-US", options)
                        .replaceAll("/", "-"),
                    });
                  }
                  getReturValidate();
                  setOpenModal(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>

      {/* modal upload */}
      <Modal
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalUpload}>
          <Box
            sx={{
              width: matchesMobile ? "80%" : "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
                marginY: 1,
              }}
            >
              <Typography variant="h4" sx={{ ml: 2 }}>
                {typeUpload === "checker"
                  ? "Upload Retur Checker"
                  : "Upload Retur Marketplace"}
              </Typography>
              <Button
                size="small"
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  setOpenModalUpload(false);
                }}
              >
                <Close
                  sx={{
                    color: "#bababa",
                    "&:hover": { color: "#333333" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                height: "90%",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // paddingX: 4,
                // pb: 2,
              }}
            >
              <Box
                component="label"
                sx={{
                  width: "100%",
                  height: "80%",
                  border: 2,
                  borderRadius: 2,
                  borderColor: "#bababa",
                  borderStyle: "dashed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "#bababa",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#333333",
                    borderColor: "#333333",
                  },
                }}
              >
                <input type="file" hidden onChange={fileHandler} />
                <Typography sx={{ fontSize: 15 }}>Klik Disini</Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => downloadTemplate()}
                  variant="outlined"
                  sx={{
                    borderColor: "#333333",
                    color: "#333333",
                    "&:hover": {
                      borderColor: "#333333",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                >
                  Download Template
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal error */}
      <Modal
        open={openModalErrorChecked}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalErrorChecked}>
          <Box
            sx={{
              width: "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "10%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalErrorChecked(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorUploadChecked.length
                      ? errorUploadChecked.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {`${e.awb ? e.awb : ""} ${
                                e.status ? e.status : ""
                              }`}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal detail validasi order */}
      <Modal
        open={openModalDetailValidation}
        onClose={() => setOpenModalDetailValidation(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalDetailValidation}>
          <Box
            sx={{
              width: matchesMobile ? "85%" : "70%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
              }}
            >
              <Typography variant="h4" sx={{ ml: 2 }}>
                Detail
              </Typography>
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setDetailValidate([]);
                  setOpenModalDetailValidation(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>No. Order</TableCell>
                      <TableCell>No. Resi</TableCell>
                      <TableCell>Logistik</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell align="center">Status Scan</TableCell>
                      <TableCell>Tanggal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailValidate.length
                      ? detailValidate.map((e, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              backgroundColor: e.checkedDate
                                ? "#b5eecd30"
                                : "#f8c1ba30",
                            }}
                          >
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {isNaN(e.order_id)
                                ? e.order_id.toUpperCase()
                                : e.order_id}
                            </TableCell>
                            <TableCell>
                              {isNaN(e.awb) ? e.awb.toUpperCase() : e.awb}
                            </TableCell>
                            <TableCell>{e.expedition}</TableCell>
                            <TableCell>{e.service}</TableCell>
                            <TableCell align="center">
                              {e.checkedDate ? (
                                <DoneOutlined sx={{ color: "#a8a8a8" }} />
                              ) : null}
                            </TableCell>
                            <TableCell>
                              {new Date(e.returDate).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </TableCell>
                            <TableCell>
                              {i === detailValidate.length - 1 ? (
                                <Waypoint onEnter={() => scrollDetail(i)} />
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
                {!dataValidate.length && openModalDetailValidation ? (
                  <Waypoint
                    onEnter={() => {
                      let temp;
                      setLoadingDetail(true);

                      if (totalDetailValidate.length > 15) {
                        temp = totalDetailValidate.slice(0, 15);
                      } else {
                        temp = totalDetailValidate;
                      }
                      if (typeof detailValidate === "object")
                        setDetailValidate(temp);
                      else setDetailValidate(...detailValidate, temp);
                      // setDetailValidate(...detailValidate, temp);
                      setLoadingDetail(false);
                    }}
                  />
                ) : null}
                {loadingDetail ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={25} sx={{ color: "#ffffff" }} />
                  </Box>
                ) : null}
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "75%" : "100%",
            width: matchesMobile ? "100%" : "75%",
            mr: matchesMobile ? 0 : 2,
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {decode.role ? (
                  <FormControl
                    sx={{
                      mr: 2,
                      backgroundColor: "#ffffff",
                      width: "100%",
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    }}
                    variant="filled"
                  >
                    <InputLabel>Pilih Seller</InputLabel>
                    <Select
                      label="Pilih Seller"
                      sx={{ borderRadius: 0 }}
                      value={seller}
                      onChange={(e) => {
                        setSeller(e.target.value);
                      }}
                      get
                    >
                      {decode.users?.map((e, i) => (
                        <MenuItem key={i} value={e.ShopId}>
                          {e.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : null}

                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size={matchesMobile ? "small" : "medium"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Nomor Resi / Nomor Order"
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              setScanManual(false);
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              push(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;

                        push(temp);
                        // push({ ...query, search: searchValue });
                        setScanManual(false);
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        push(temp);
                        setScanManual(false);
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: "30%",
                  width: matchesMobile ? "70%" : "50%",
                }}
              />
            </Box>

            {scanManual ? null : (
              <Box
                sx={{
                  height: "30%",
                  width: matchesMobile ? "30%" : "50%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    width: matchesMobile ? "80%" : "50%",
                    height: "100%",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    fontSize: matchesMobile ? "8px" : "14px",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                  onClick={() => setOpenModal(true)}
                >
                  {matchesMobile || !params.minDate
                    ? "Pilih Tanggal"
                    : `${formatDate(params.minDate)} - ${formatDate(
                        params.maxDate
                      )}`}
                </Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            {scanManual ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Zoom
                  in={scanManual}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#333333",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      backgroundColor: "#333333",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "98%",
                        height: "10%",
                      }}
                    >
                      <Typography
                        sx={{ color: "#ffffff", fontWeight: "bold", mb: 0.5 }}
                      >
                        Scan Manual
                      </Typography>
                      <IconButton
                        sx={{
                          color: "#ffffff",
                          height: "fit-content",
                          "&:hover": {
                            backgroundColor: "#ffffff20",
                          },
                        }}
                        onClick={async () => {
                          setError("");
                          getReturOrder();
                          setScanManual(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                    <Box
                      component="form"
                      onSubmit={scanSubmit}
                      sx={{
                        height: "50%",
                        width: "90%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#ffffff", mb: 2 }} variant="h4">
                        Data yang di Scan hari ini
                      </Typography>
                      <TableContainer
                        sx={{
                          border: 1,
                          backgroundColor: "#ffffff",
                          maxHeight: "100%",
                        }}
                      >
                        <Grid ref={upper}></Grid>

                        <Table
                          sx={{
                            backgroundColor: "#ffffff",
                          }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">NO.ORDER</TableCell>
                              <TableCell align="center">NO.RESI</TableCell>
                              <TableCell align="center">Tanggal Scan</TableCell>
                              <TableCell align="center">Waktu</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataChecked.length
                              ? dataChecked.map((retur, i) => (
                                  <TableRow key={i}>
                                    <TableCell>
                                      {isNaN(retur.order_id)
                                        ? retur.order_id.toUpperCase()
                                        : retur.order_id}
                                    </TableCell>
                                    <TableCell>
                                      {isNaN(retur.awb)
                                        ? retur.awb.toUpperCase()
                                        : retur.awb}
                                    </TableCell>
                                    <TableCell align="center">
                                      {dateFormat(retur.checkedDate)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {timeFormat(retur.checkedDate)}
                                    </TableCell>
                                    <TableCell>
                                      {i === dataChecked.length - 1 ? (
                                        <Waypoint
                                          onEnter={() => scrollScan(i)}
                                        />
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : null}
                          </TableBody>
                        </Table>
                        {!dataChecked.length && scanManual ? (
                          <Waypoint
                            onEnter={() => {
                              let temp;

                              if (dataCheckedTotal.length > 15) {
                                temp = dataCheckedTotal.slice(0, 15);
                              } else {
                                temp = dataCheckedTotal;
                              }

                              if (typeof dataCheckedTotal === "object")
                                setDataChecked(temp);
                              else setDataChecked(...dataChecked, temp);
                            }}
                          />
                        ) : null}
                        {loadingChecked ? (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              size={25}
                              sx={{ color: "#333333" }}
                            />
                          </Box>
                        ) : error ? (
                          <Alert variant="standard" severity="error">
                            {error}
                          </Alert>
                        ) : dataChecked.length === 0 ? (
                          <Alert
                            variant="standard"
                            severity="warning"
                            sx={{ borderRadius: 0 }}
                          >
                            Tidak ada data
                          </Alert>
                        ) : null}
                      </TableContainer>
                      {errorScan ? (
                        <Alert
                          variant="standard"
                          severity="error"
                          sx={{ position: "absolute" }}
                        >
                          {errorScan}
                        </Alert>
                      ) : null}
                    </Box>
                    <Box
                      component="form"
                      onSubmit={(e) => {
                        if (!loadingScan) scanSubmit(e);
                      }}
                      sx={{
                        height: "40%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        autoComplete="off"
                        sx={{
                          width: "50%",
                          mb: 2,
                        }}
                        InputProps={{
                          sx: {
                            borderRadius: 0,
                            backgroundColor: "#ffffff",
                            "& input": {
                              textAlign: "center",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            },
                          },
                        }}
                        // onChange={(e) => setScanAwb(e.target.value)}
                        inputRef={inputScanAwb}
                        // onKeyPress={(ev) => {
                        //   console.log(`Pressed keyCode ${ev.key}`);
                        //   if (ev.key === "Enter") {
                        //     scanSubmit(ev);
                        //   }
                        // }}
                        autoFocus
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#ffffff",
                          color: "#333333",
                          boxShadow: loadingScan
                            ? null
                            : "0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important",
                          "&:hover": {
                            backgroundColor: "#333333",
                            color: "#ffffff",
                          },
                        }}
                        disabled={loadingScan}
                      >
                        {loadingScan ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#ffffff" }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Zoom>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "90%",
                    display: "flex",
                  }}
                >
                  <TableContainer
                    sx={{
                      maxHeight: "100%",
                      "&::-webkit-scrollbar": { width: 10, height: 10 },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#33333350",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <Table size="large" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>No. Order</StyledTableCell>
                          <StyledTableCell>No. Resi</StyledTableCell>
                          <StyledTableCell>Logistik</StyledTableCell>
                          <StyledTableCell>Service</StyledTableCell>
                          <StyledTableCell align="center">
                            Status Scan
                          </StyledTableCell>
                          <StyledTableCell>Tanggal</StyledTableCell>
                          <StyledTableCell align="center">
                            Kode Unik
                            <Tooltip
                              title={
                                <div>
                                  Kode Unik untuk pengundian, Semoga Anda salah
                                  satunya
                                  <br />
                                  <br />
                                  Informasi lebih lanjut hubungi Big Delivery
                                </div>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#333333",
                                    color: "#ffffff",
                                  },
                                },
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: 17 }} />
                            </Tooltip>
                          </StyledTableCell>
                          {accessStatus !== "checker" ? (
                            <StyledTableCell align="center">
                              Action
                            </StyledTableCell>
                          ) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataList.length
                          ? dataList.map((e, i) =>
                              i === isEdit ? (
                                <TableRow
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor: "#33333330",
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    <TextField
                                      value={
                                        isNaN(editData.order_id)
                                          ? editData.order_id.toUpperCase()
                                          : editData.order_id
                                      }
                                      label="no. order"
                                      variant="standard"
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          order_id:
                                            e.target.value.toLowerCase(),
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={
                                        isNaN(editData.awb)
                                          ? editData.awb.toUpperCase()
                                          : editData.awb
                                      }
                                      label="no.ri"
                                      variant="standard"
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          awb: e.target.value.toLowerCase(),
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={editData.expedition.toUpperCase()}
                                      label="logistik"
                                      variant="standard"
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          expedition:
                                            e.target.value.toLowerCase(),
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={
                                        editData.service
                                          ? editData.service.toUpperCase()
                                          : ""
                                      }
                                      label="service"
                                      variant="standard"
                                      onChange={(e) =>
                                        setEditData({
                                          ...editData,
                                          service: e.target.value,
                                        })
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    {e.checkedDate ? (
                                      <DoneOutlined sx={{ color: "#a8a8a8" }} />
                                    ) : null}
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      value={new Date(
                                        e.returDate
                                      ).toLocaleDateString("en-US", options)}
                                      label="Tanggal"
                                      variant="standard"
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <TextField
                                      value={e.kodeUnique ? e.kodeUnique : "-"}
                                      label="Tanggal"
                                      variant="standard"
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      sx={{ color: "#000000", borderRight: 1 }}
                                      onClick={(e) => {
                                        setIsEdit(-1);
                                      }}
                                    >
                                      <CancelOutlined />
                                    </IconButton>
                                    <IconButton
                                      sx={{ color: "#000000", borderRight: 1 }}
                                      onClick={(e) => editSubmit(e)}
                                    >
                                      <DoneOutlined />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow key={i}>
                                  <StyledTableCell>
                                    {isNaN(e.order_id)
                                      ? e.order_id.toUpperCase()
                                      : e.order_id}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {isNaN(e.awb) ? e.awb.toUpperCase() : e.awb}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {e.expedition.toUpperCase()}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {e.service.toUpperCase()}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {e.checkedDate ? (
                                      <Tooltip
                                        title={`discan pada tanggal ${new Date(
                                          e.checkedDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          options
                                        )}, di jam ${new Date(
                                          e.checkedDate
                                        ).toLocaleTimeString(
                                          "en-US",
                                          options
                                        )}`}
                                      >
                                        <DoneOutlined
                                          sx={{ color: "#a8a8a8" }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {new Date(e.returDate).toLocaleDateString(
                                      "en-US",
                                      options
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {e.kodeUnique ? e.kodeUnique : "-"}
                                  </StyledTableCell>
                                  {accessStatus !== "checker" ? (
                                    <StyledTableCell align="center">
                                      <IconButton
                                        sx={{ borderRight: 1 }}
                                        onClick={() => {
                                          setPreviousExpedition(e.expedition);
                                          setPreviousService(e.service);
                                          setChecked(e.checkedDate);
                                          setEditDate(e.returDate);

                                          setEditData({
                                            ...editData,
                                            order_id: e.order_id,
                                            awb: e.awb,
                                            expedition: e.expedition,
                                            service: e.service,
                                          });
                                          setIdEdit(e.id);
                                          setIsEdit(i);
                                        }}
                                      >
                                        <EditOutlined />
                                      </IconButton>
                                    </StyledTableCell>
                                  ) : null}
                                </TableRow>
                              )
                            )
                          : null}
                      </TableBody>
                    </Table>
                    {loading ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress size={25} sx={{ color: "#333333" }} />
                      </Box>
                    ) : error ? (
                      <Alert variant="standard" severity="error">
                        {error}
                      </Alert>
                    ) : dataList.length === 0 ? (
                      <Alert
                        variant="standard"
                        severity="warning"
                        sx={{ borderRadius: 0 }}
                      >
                        Tidak ada data
                      </Alert>
                    ) : null}
                  </TableContainer>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    ml: matchesMobile ? 0 : 2,
                    height: "10%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 100, 200, 500]}
                    component="div"
                    // count={data.count}
                    count={count}
                    rowsPerPage={+params.limit}
                    page={+params.page - 1}
                    onPageChange={(_, newPage) =>
                      push({ ...query, page: newPage + 1 })
                    }
                    onRowsPerPageChange={({ target: { value = "10" } }) =>
                      push({ ...query, limit: value, page: 1 })
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            height: matchesMobile ? "25%" : "100%",
            width: matchesMobile ? "100%" : "25%",
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              height: matchesMobile ? "20%" : "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              flexDirection: "column",
              mt: matchesMobile ? 1 : 0,
            }}
          >
            {accessStatus !== "checker" ? (
              <Tooltip title="Upload data retur sesuai dengan template">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    height: matchesMobile ? "100%" : "30%",
                    width: "100%",
                    mb: matchesMobile ? 1 : 2,
                    backgroundColor: "#ab8c36",
                    boxShadow: matchesMobile
                      ? null
                      : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                    },
                  }}
                  onClick={() => {
                    setTypeUpload("orders");
                    setOpenModalUpload(true);
                  }}
                >
                  Upload
                </Button>
              </Tooltip>
            ) : null}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: matchesMobile ? "80%" : "80%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#333333",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              {scanManual ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#ffffff20",
                      },
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    {`${formatDate(params.minDate)} - ${formatDate(
                      params.maxDate
                    )}`}
                  </Button>
                </Box>
              ) : null}
              <Box
                sx={{
                  width: "100%",
                  height: matchesMobile ? "50%" : scanManual ? "80%" : "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ffffff50",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <Typography variant="h3" sx={{ color: "#ffffff" }}>
                            Total Retur
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <Typography
                            variant="h3"
                            sx={{ color: "#ffffff" }}
                            align={matchesMobile ? "right" : "left"}
                          >
                            {dataValidate.total ? dataValidate.total : 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {matchesMobile ? null : (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{ fontWeight: "bold", borderBottom: 0 }}
                            >
                              <Typography
                                variant="h4"
                                sx={{ color: "#ffffff" }}
                              >
                                List Logistik
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {Object.keys(dataValidate).length
                            ? Object.keys(dataValidate).map((e, i) =>
                                e !== "total" ? (
                                  <TableRow
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "#ffffff20",
                                        cursor: "pointer",
                                      },
                                    }}
                                    key={i}
                                    onClick={() => {
                                      setOpenModalDetailValidation(true);

                                      setTotalDetailValidate(
                                        dataValidate[e].data
                                      );
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        fontWeight: "bold",
                                        color:
                                          dataValidate[e].totalChecked ===
                                          dataValidate[e].totalNotChecked
                                            ? "#b5eecd"
                                            : "#f8c1ba",
                                      }}
                                    >
                                      {e.toUpperCase()}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: "bold",
                                        color:
                                          dataValidate[e].totalChecked ===
                                          dataValidate[e].totalNotChecked
                                            ? "#b5eecd"
                                            : "#f8c1ba",
                                      }}
                                    >
                                      {`${dataValidate[e].totalChecked} / ${dataValidate[e].totalNotChecked}`}
                                    </TableCell>
                                  </TableRow>
                                ) : null
                              )
                            : null}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {loadingValidate ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={25} sx={{ color: "#ffffff" }} />
                    </Box>
                  ) : error ? (
                    <Alert
                      variant="outlined"
                      severity="error"
                      sx={{ color: "#ffffff", border: 0 }}
                    >
                      {error}
                    </Alert>
                  ) : !dataValidate.total ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: matchesMobile ? "50%" : "10%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {/* <Button
                  variant="contained"
                  // onClick={() => setOpenModalPrint(true)}
                  sx={{
                    width: "40%",
                    backgroundColor: "#ab8c36",
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      color: "#333333",
                    },
                  }}
                >
                  Print
                </Button> */}

                <Button
                  variant="contained"
                  id="basic-button"
                  aria-controls={openMenuScan ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuScan ? "true" : undefined}
                  sx={{
                    backgroundColor: "#6c6d70",
                    width: "40%",
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      color: "#333333",
                    },
                  }}
                  onClick={(e) => {
                    setOpenMenuScan(true);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Scan
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenuScan}
                  onClose={() => setOpenMenuScan(false)}
                  TransitionComponent={Fade}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={async () => {
                      if (!dataChecked.length) {
                        await getChecked();
                      } else {
                        setLoadingChecked(false);
                      }
                      setScanManual(true);
                      setOpenMenuScan(false);
                      setAnchorEl(null);
                    }}
                  >
                    Manual
                  </MenuItem>
                  <MenuItem
                    component="label"
                    onClick={() => {
                      setOpenMenuScan(false);
                      setAnchorEl(null);
                      setTypeUpload("checker");

                      setOpenModalUpload(true);
                    }}
                  >
                    Upload
                    {/* <input type="file" hidden onChange={fileHandlerScan} /> */}
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ReturPage;
