import { jk_gtw } from "../../apis";
import { handleError } from "../../helper/handleError";
import {
  SET_DATAAWB,
  SET_DATAJALAN,
  SET_DATAONGKIRAWB,
  SET_DATAREADYPICKUP,
  SET_ERR,
  SET_LOADING,
  SET_MESSAGE,
  SET_OPENSNACK,
  SET_PROGRESSFAILED,
} from "../keys";
import axios from "axios";

export function setOngkirAwb(payload) {
  return { type: SET_DATAONGKIRAWB, payload };
}

export function uploadDataOngkir(payload) {
  return async function (dispatch, getState) {
    try {
      const { ongkirAwb } = getState().AwbReducer,
        time = new Date(),
        no_inv = `${time.getDate()}${
          time.getMonth() + 1
        }${time.getFullYear()}${time.getHours()}${time.getMinutes()}${time.getSeconds()}`;

      localStorage.setItem("current_invoice", `${no_inv} ${payload.length}`);
      // if (payload.length > 250) {
      //   let result = { success: [], failed: [] };
      //   for (let i = 0; i < payload.length; i += 250) {
      //     const awb = payload.slice(i, i + 250),
      //       { data } = await jk_gtw.post(`/awb/generate-ongkir/jne`, { data: awb, no_inv }, {
      //         headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` }
      //       })

      //     if (data) {
      //       result.failed.push([...data.failed]);
      //       data.data.success.forEach(e => {
      //         e.id = e.order_id
      //         e.hargaOngkir = e.ongkir.price.toLocaleString('id', 'ID')
      //       });
      //       result.success.push([...data.data.success]);
      //       if (i === payload.length - 1) {
      //         dispatch(setProgressFailed(result.failed))
      //         dispatch(setOngkirAwb({ count: data.count, data: [...result.success, ...ongkirAwb.data] }))
      //         localStorage.removeItem("excel_data")
      //       }
      //     }
      //   }
      // } else {
      const { data } = await jk_gtw.post(
        `/awb/generate-ongkir/jne`,
        { data: payload, no_inv },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (data) {
        dispatch(setProgressFailed(data.data.failed));
        data.data.success.forEach((e) => {
          e.id = e.order_id;
          e.hargaOngkir = e.ongkir.price.toLocaleString("id", "ID");
        });
        dispatch(
          setOngkirAwb({
            count: data.count,
            data: [...data.data.success, ...ongkirAwb.data],
          })
        );
        localStorage.removeItem("excel_data");
      }
      // }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
      localStorage.removeItem("current_invoice");
      localStorage.removeItem("excel_data");
    }
  };
}

export function uploadSingleData(payload, handleClose) {
  return async function (dispatch, getState) {
    try {
      const { ongkirAwb } = getState().AwbReducer,
        { data } = await jk_gtw.post(
          "/awb/generate-ongkir/jne",
          { data: [payload] },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

      if (data) {
        dispatch(setOngkirAwb([...data.data, ...ongkirAwb.data]));
        localStorage.removeItem("excel_data");
        handleClose();
      }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function getDataOngkir(currPage, perPage, search) {
  return async function (dispatch, getState) {
    try {
      let { ongkirAwb } = getState().AwbReducer;
      if (!currPage) currPage = 0;
      if (!perPage) perPage = 10;
      if (!search) search = "";

      const { data } = await jk_gtw.get(
        `/awb/data-ongkir?perPage=${perPage}&currPage=${currPage}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      let result = [...ongkirAwb.data];
      data.data.forEach((e) => {
        e.id = e.order_id;
        e.hargaOngkir = e.ongkir.price.toLocaleString("id", "ID");
        const index = ongkirAwb.data.findIndex(
          (x) => x.order_id === e.order_id
        );
        if (index === -1) {
          result.push(e);
        }
      });

      dispatch(setOngkirAwb({ data: result, count: data.count }));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function searchDataOngkir(search) {
  return async function (dispatch) {
    try {
      if (!search) search = "";

      const { data } = await jk_gtw.get(`/awb/data-ongkir?search=${search}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      data.data.forEach((e) => {
        e.id = e.order_id;
        e.hargaOngkir = e.ongkir.price.toLocaleString("id", "ID");
      });
      dispatch(setOngkirAwb({ data: data.data, count: data.count }));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function deleteDataOngkir(order) {
  return async function (dispatch, getState) {
    try {
      let { ongkirAwb } = getState().AwbReducer,
        Authorization = `Bearer ${localStorage.getItem("access_token")}`;

      for (let i = 0; i < order.length; i++) {
        const currOrder = order[i];
        ongkirAwb.data = ongkirAwb.data.filter(
          (e) => e.order_id !== currOrder.order_id
        );

        await jk_gtw.delete(
          `/awb/delete/${currOrder.no_inv}/${currOrder.order_id}`,
          { headers: { Authorization } }
        );
      }

      dispatch(setOngkirAwb(ongkirAwb));
      dispatch(setErr(false));
      dispatch(setMessage("Data berhasil dihapus"));
      dispatch(setOpenSnack("true"));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function setDataAwb(payload) {
  return { type: SET_DATAAWB, payload };
}

export function getDataAwb(currPage, perPage) {
  return async function (dispatch, getState) {
    try {
      const { dataAwb } = getState().AwbReducer;
      if (!currPage) currPage = 0;
      if (!perPage) perPage = 10;

      const { data } = await jk_gtw.get(
        `/awb/data-pickup?currPage=${currPage}&perPage=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      let result = [...dataAwb.data];
      data.data.forEach((e) => {
        e.id = e.order_id;
        e.awb = JSON.parse(e.awb).cnote_no;
        const index = dataAwb.data.findIndex((x) => x.order_id === e.order_id);
        if (index === -1) {
          result.push(e);
        }
      });
      dispatch(setDataAwb({ data: result, count: data.count }));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function setErr(payload) {
  return { type: SET_ERR, payload };
}

export function setMessage(payload) {
  return { type: SET_MESSAGE, payload };
}

export function setOpenSnack(payload) {
  return { type: SET_OPENSNACK, payload };
}

export function setLoading(payload) {
  return { type: SET_LOADING, payload };
}

export function requestPickup(payload, totalPrice) {
  return async function (dispatch, getState) {
    try {
      let { ongkirAwb } = getState().AwbReducer;
      // const { data: dataBalance } = await jk_gtw.get('/wallet', { headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` } }),
      //   main = dataBalance.filter(e => e.wallet_type === 'main');

      // if (main[0].balance >= totalPrice) {
      const { data } = await jk_gtw.post(
        "/awb/pickup",
        { ...payload, totalPrice },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (data) {
        dispatch(setMessage("Berhasil mengajukan request pickup"));
        dispatch(setOpenSnack(true));
        for (let i = 0; i < payload.order.length; i++) {
          const order = payload.order[i];
          ongkirAwb.data = ongkirAwb.data.filter(
            (e) => e.order_id !== order.order_id
          );
        }
        dispatch(setOngkirAwb(ongkirAwb));
      }
      // } else {
      //   console.log('saldo tidak cukup')
      // dispatch(setErr(true));
      // dispatch(setMessage('Saldo tidak cukup'));
      // dispatch(setOpenSnack(true));
      // }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function setDataReadyPickup(payload) {
  return { type: SET_DATAREADYPICKUP, payload };
}

export function getDataReadyPickup(currPage, perPage) {
  return async function (dispatch, getState) {
    try {
      const { dataReadyPickup } = getState().AwbReducer;
      if (!currPage) currPage = 0;
      if (!perPage) perPage = 10;

      const { data } = await jk_gtw.get(
        `/awb/data-label?currPage=${currPage}&perPage=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      let result = [...dataReadyPickup.data];
      data.data.forEach((e) => {
        e.id = e.order_id;
        e.awb = JSON.parse(e.awb).cnote_no;
        const index = dataReadyPickup.data.findIndex(
          (x) => x.order_id === e.order_id
        );
        if (index === -1) {
          result.push(e);
        }
      });
      dispatch(setDataReadyPickup({ data: result, count: data.count }));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function shippingLabeled(payload) {
  return async function (dispatch, getState) {
    try {
      let { dataReadyPickup } = getState().AwbReducer;
      dispatch(setDataAwb({ count: 0, data: [] }));
      const { data } = await jk_gtw.post(
        "/awb/data-label",
        { order: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (data) {
        // let res = { count: dataAwb.count, data: []}
        dispatch(
          setDataReadyPickup({
            count: dataReadyPickup.count,
            data: [...dataReadyPickup.data, ...payload],
          })
        );
        // for (let i = 0; i < payload.length; i++) {
        //   const currOrder = payload[i];
        //   res.data = dataAwb.data.filter(e => e.order_id !== currOrder.order_id)
        // }
        // dispatch(setDataAwb(res))
      }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function printSuratJalan(payload) {
  return async function (dispatch, getState) {
    try {
      dispatch(setDataReadyPickup({ count: 0, data: [] }));
      await jk_gtw.post(
        "/awb/surat-jalan",
        { order: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // if (data) {
      // let res = { count: dataReadyPickup.count, data: [] }
      // for (let i = 0; i < payload.length; i++) {
      //   const currOrder = payload[i];
      //   res.data = dataReadyPickup.data.filter(e => e.order_id !== currOrder.order_id)
      // }
      // dispatch(setDataReadyPickup(res))
      // }
    } catch (e) {
      console.log(e);
    }
  };
}

export function setDataJalan(payload) {
  return { type: SET_DATAJALAN, payload };
}

export function getDataJalan() {
  return async function (dispatch) {
    try {
      return await jk_gtw.get("/awb/surat-jalan", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      // console.log(data)
      // data.data.forEach(e => {
      //   e.id = e.order_id
      // });

      // dispatch(setDataJalan(data.data))
    } catch (e) {
      console.log(e);
    }
  };
}

export function deleteAwb(data) {
  return async function (dispatch, getState) {
    try {
      let { dataAwb, dataReadyPickup } = getState().AwbReducer,
        Authorization = `Bearer ${localStorage.getItem("access_token")}`;

      for (let i = 0; i < data.length; i++) {
        const currOrder = data[i];
        dataAwb.data = dataAwb.data.filter(
          (e) =>
            e.order_id !== currOrder.order_id && e.no_inv !== currOrder.no_inv
        );
        dataReadyPickup.data = dataReadyPickup.data.filter(
          (e) =>
            e.order_id !== currOrder.order_id && e.no_inv !== currOrder.no_inv
        );

        await jk_gtw.delete(
          `/awb/delete/${currOrder.no_inv}/${currOrder.order_id}`,
          { headers: { Authorization } }
        );
      }

      dispatch(setDataAwb(dataAwb));
      dispatch(setDataReadyPickup(dataReadyPickup));
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function setProgressFailed(payload) {
  return { type: SET_PROGRESSFAILED, payload };
}

export function getFailPickup(currPage) {
  return function () {
    return jk_gtw.get(`/awb/pickup-fail?currPage=${currPage}&perPage=50`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function updateOrder(payload, no_inv, order_id) {
  return function () {
    return jk_gtw.put(`/awb/update/${no_inv}/${order_id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function searchGenerated(search) {
  return async function (dispatch) {
    try {
      if (!search) search = "";

      const { data: dataAwb } = await jk_gtw.get(
          `/awb/data-pickup?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        ),
        { data: dataLabel } = await jk_gtw.get(
          `/awb/data-label?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

      if (dataAwb && dataLabel) {
        dataAwb.data.forEach((e) => {
          e.id = e.order_id;
          e.awb = JSON.parse(e.awb).cnote_no;
        });
        dispatch(setDataAwb({ data: dataAwb.data, count: dataAwb.count }));

        dataLabel.data.forEach((e) => {
          e.id = e.order_id;
          e.awb = JSON.parse(e.awb).cnote_no;
        });
        dispatch(
          setDataReadyPickup({ data: dataLabel.data, count: dataLabel.count })
        );
      }
    } catch (e) {
      const message = handleError(e);
      console.log(message);
    }
  };
}
