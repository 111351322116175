import { combineReducers } from "redux";
import sellerReducer from "./SellerReducers";
import UniversalReducer from "./UniversalReducer";

const rootReducer = combineReducers({
  seller: sellerReducer,
  UniversalReducer,
});

export default rootReducer;
