import { decompress } from "compress-json";
import { Navigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import {
  downloadReportBigPos,
  downloadTotalReport,
} from "../store/actions/scanAction";
import { addLog } from "../store/actions/actionLog";

export function handleError(err) {
  let messages = err.message || "server sedang sibuk";
  if (err.response !== undefined || err.response) {
    let message = err.response.data.message;
    if (message || message?.length) {
      messages = message;
    }
  }
  if (/Network/i.test(messages)) messages = "Tidak ada koneksi";
  if (
    messages === "sesi anda sudah habis, harap logout dan login kembali" ||
    messages === "invalid sesi, harap logout jika halaman tidak otomatis reload"
  ) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }
  return messages;
}

export function capitalizeWords(str) {
  let words = str.split(" ");

  words = words.map((word) => {
    return word[0].toUpperCase() + word.substring(1);
  });

  return words.join(" ");
}

export const exportFileExcelNew = async (payload = []) => {
  let data = [];
  let newTotalData = await downloadTotalReport(payload);
  let limitDownload = 2000;
  let page = 0;
  if (newTotalData > limitDownload) {
    let loop = Math.ceil(+newTotalData / limitDownload);
    let _data = [];
    for (let index = 0; index < loop; index++) {
      if (index === loop - 1) {
        _data = await downloadReportBigPos({
          search: `?limitDownload=${newTotalData}&pageDownload=${
            page * limitDownload
          }`,
          payload,
        });
      } else {
        _data = await downloadReportBigPos({
          search: `?limitDownload=${limitDownload}&pageDownload=${
            page * limitDownload
          }`,
          payload,
        });
      }
      page += 1;
      newTotalData -= limitDownload;
      data = data.concat(decompress(_data));
    }
  } else {
    data = await downloadReportBigPos({ payload });
    data = decompress(data);
  }

  let rows = data.map((e) => {
    return {
      "TANGGAL MASUK": e.order_date,
      "NAMA BARANG": e.goods_name,
      "NAMA PENGIRIM": e.shipper,
      "NAMA PENERIMA": e.receiver,
      "NO. RESI": e.awb,
      SUMBER: e.expedition,
      PEMBAYARAN: e.payment_method,
      "JENIS LAYANAN": e.service,
      BERAT: e.weight,
      ONGKIR: e.cost,
    };
  });

  let wscols = [
    { width: 26 },
    { width: 40 },
    { width: 30 },
    { width: 30 },
    { width: 20 },
    { width: 15 },
    { width: 17 },
    { width: 18 },
    { width: 12 },
    { width: 12 },
  ];

  await addLog("BIGPOS/Download-Report-Cashless");

  const ws = utils.json_to_sheet(rows);
  ws["!cols"] = wscols;
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Data");
  return writeFile(wb, "Report-BigPOS.xlsx");
};
