import { CancelOutlined, Close, Search } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Zoom,
  tableCellClasses,
  tableRowClasses,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ThisMonth,
  convertDataToQuery,
  formatDate,
  getQueryUrl,
} from "../../helper/custom";
import { getAllDataReturn, scanOneData } from "../../store/actions/scanAction";
import { handleError } from "../../helper/helpers";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function ReturnPackagePage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [error, setError] = useState("");
  let inputScanAwb = useRef();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const push = (payload) => {
    navigate(`/easy-drop/return${convertDataToQuery(payload)}`);
  };
  const [searchValue, setSearchValue] = useState(params.search || "");
  const [allData, setAllData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  const [openModalDateRange, setOpenModalDateRange] = useState(false);
  const [scanManual, setScanManual] = useState(false);
  const [showHighlight, setShowHighlight] = useState("");
  const [errorScan, setErrorScan] = useState("");
  const StyledTableRow = styled(TableRow)(() => ({
    [`&.${tableRowClasses.selected}`]: {
      backgroundColor: "rgba(148, 252, 171, 0.4)",
      color: "#333333",
      "&:hover": {
        backgroundColor: "rgb(148, 252, 171)",
        color: "#333333",
      },
    },
  }));

  const getData = async () => {
    try {
      setError("");
      setLoading(true);
      const data = await getAllDataReturn(search);
      setCount(data.count);
      setAllData(data.rows);
      setLoading(false);
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
      setError(message);
    }
  };

  const scanSubmit = async (e) => {
    try {
      e.preventDefault();
      setErrorScan("");
      if (!inputScanAwb.current?.value) throw new Error("Please Input Resi");
      let number = currentData.length + 1;
      if (number < 10) number = `00${number}`;
      else if (number < 100) number = `0${number}`;
      const updateData = {
        action: "update",
        order_id: number,
        awb: inputScanAwb.current.value.toLowerCase().trim(),
        expedition: "jnt",
        service: "regular",
      };
      await scanOneData(updateData);
      setCurrentData([...currentData, updateData]);
      setShowHighlight(inputScanAwb.current.value.toLowerCase().trim());
      e.target.reset();
    } catch (error) {
      e.target.reset();
      const message = await handleError(error);
      setErrorScan(message);
    }
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Container
      sx={{
        minHeight: "100vh",
        maxHeight: {
          lg: "100vh",
          md: "100vh",
          sm: "100vh",
          xs: "auto",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
      }}
    >
      {/* modal open daterange */}
      <Modal
        open={openModalDateRange}
        onClose={() => setOpenModalDateRange(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalDateRange}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => setState(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={matchesMobile ? 1 : 2}
              ranges={[state]}
              direction={matchesMobile ? "vertical" : "horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={matchesMobile ? [] : undefined}
              staticRanges={matchesMobile ? [] : undefined}
              // inputRanges={[]}
              // staticRanges={[]}
              className={
                matchesMobile
                  ? {
                      rdrStaticRanges: {
                        width: "0px",
                      },
                      rdrDateRangePickerWrapper: {
                        display: "flex !important",
                        justifyContent: "center !important",
                        alignItems: "center !important",
                        width: "100% !important",
                        position: "relative !important",
                      },
                    }
                  : null
              }
            />
            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => setOpenModalDateRange(false)}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  push({
                    ...query,
                    minDate: moment(state.startDate).format("MM-DD-YYYY"),
                    maxDate: moment(state.endDate).format("MM-DD-YYYY"),
                  });
                  setOpenModalDateRange(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>

      {/* modal open scan update */}
      <Modal
        open={scanManual}
        onClose={() => {
          setScanManual(false);
          window.location.reload();
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={scanManual}>
          <Box
            sx={{
              width: "90%",
              height: "auto",
              backgroundColor: "white",
              // borderRadius: 1,
              boxShadow: 24,
              p: 0.8,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                backgroundColor: "#333333",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontWeight: "bold", mb: 0.5 }}
                >
                  Scan Manual
                </Typography>
                <IconButton
                  sx={{
                    color: "#ffffff",
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#ffffff20",
                    },
                  }}
                  onClick={() => {
                    setScanManual(false);
                    window.location.reload();
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{
                  height: "50%",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "#ffffff", mb: 2 }} variant="h4">
                  Update data return
                </Typography>
                <TableContainer
                  sx={{
                    maxHeight: "40dvh",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">NO.ORDER</TableCell>
                        <TableCell align="center">NO.RESI</TableCell>
                        <TableCell align="center">Tanggal Scan</TableCell>
                        <TableCell align="center">Waktu</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {currentData.length > 0 &&
                        currentData.map((item, index) => (
                          <StyledTableRow
                            key={index}
                            selected={showHighlight === item.awb}
                          >
                            <TableCell align="center">
                              {item.order_id}
                            </TableCell>
                            <TableCell align="center">{item.awb}</TableCell>
                            <TableCell align="center">
                              {moment(item.order_date).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell align="center">
                              {moment(item.order_date).format("HH:mm:ss")}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {currentData.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
                {errorScan ? (
                  <Alert
                    variant="standard"
                    severity="error"
                    sx={{ position: "absolute", top: 80 }}
                  >
                    {errorScan}
                  </Alert>
                ) : null}
              </Box>
              <Box
                component="form"
                onSubmit={scanSubmit}
                sx={{
                  my: 3,
                  height: "40%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    width: "50%",
                    mb: 2,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: 0,
                      backgroundColor: "#ffffff",
                      "& input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      },
                    },
                  }}
                  inputRef={inputScanAwb}
                  autoFocus
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#333333",
                    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                  // disabled={showHighlight ? true : false}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "75%" : "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                // alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size={matchesMobile ? "small" : "medium"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Cari Nomor Resi"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              // setScanManual(false);
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              push(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;
                        push(temp);
                        // setScanManual(false);
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        push(temp);
                        // setScanManual(false);
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                height: "30%",
                width: matchesMobile ? "30%" : "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: matchesMobile ? "80%" : "50%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: matchesMobile ? "8px" : "14px",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    color: "#ffffff",
                  },
                }}
                onClick={() => setOpenModalDateRange(true)}
              >
                {matchesMobile || !params.minDate
                  ? "Pilih Tanggal"
                  : `${formatDate(params.minDate)} - ${formatDate(
                      params.maxDate
                    )}`}
              </Button>
              <Button
                variant="contained"
                onClick={() => setScanManual(true)}
                sx={{
                  borderRadius: 0,
                  width: matchesMobile ? "80%" : "50%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  textTransform: "uppercase",
                  fontSize: matchesMobile ? "8px" : "14px",
                  "&:hover": {
                    backgroundColor: "#ab8c36",
                    color: "#ffffff",
                  },
                }}
              >
                Scan Update Return
              </Button>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          No. Resi
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 80 }}>
                          Logistik
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Service
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }} align="center">
                          Pengirim
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }} align="center">
                          Penerima
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Scan Type
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ width: 50 }}>
                          Tanggal
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }} align="center">
                          Status Paket
                        </StyledTableCell>

                        {/* <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                          Action
                        </StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress
                                size={25}
                                sx={{ color: "#333333" }}
                              />
                            </Box>
                          </StyledTableCell>
                        </TableRow>
                      ) : error ? (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            <Alert
                              variant="standard"
                              severity="error"
                              sx={{ borderRadius: 0 }}
                            >
                              {error}
                            </Alert>
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        allData.length > 0 &&
                        allData.map((item, i) => (
                          <TableRow key={i}>
                            {/* <StyledTableCell>
                                {item.order_id.toUpperCase()}
                              </StyledTableCell> */}
                            <StyledTableCell>
                              {item.awb.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell
                              defaultValue={item.expedition}
                              // ref={myRef}
                            >
                              {item.expedition.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.service.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item?.shipper?.toUpperCase() || "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item?.receiver?.toUpperCase() || "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Chip
                                label={item.type_scan}
                                color={
                                  item.type_scan === "uploaded"
                                    ? "warning"
                                    : "success"
                                }
                                clickable
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {moment(item.order_date).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Chip
                                size="small"
                                label={`${item.status}`}
                                color="error"
                                variant="outlined"
                                clickable
                              />
                            </StyledTableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  {allData.length === 0 && !loading ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  ml: matchesMobile ? 0 : 2,
                  height: "10%",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={+params.limit}
                  page={count <= 0 ? 0 : +params.page - 1}
                  onPageChange={(_, newPage) =>
                    push({ ...query, page: newPage + 1 })
                  }
                  onRowsPerPageChange={({ target: { value = "10" } }) =>
                    push({ ...query, limit: value, page: 1 })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
