import React, { useEffect } from "react";

// material-ui
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  Modal,
  Typography,
} from "@mui/material";

// project imports
// import Typography from "@material-ui/core/Typography";

//-----------------------|| WORKING SPACE ||-----------------------//

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: "10px",
};

export default function EtalaseCardKolListModal({
  openModalListKol,
  setOpenModalListKol,
  etalaseKolList,
}) {
  function AvatarGender(gender, id) {
    const man1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-1.png";
    const man2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-2.png";
    const woman1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-3.png";
    const woman2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-4.png";
    if (gender === "pria") {
      if (id % 2 == 0) {
        return man1;
      } else {
        return man2;
      }
    } else if (gender === "wanita") {
      if (id % 2 == 0) {
        return woman1;
      } else {
        return woman2;
      }
    }
  }
  return (
    <Modal
      open={openModalListKol}
      onClose={() => {
        setOpenModalListKol(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          height: "50%",
          width: { xs: "90vw", sm: "50vw", md: "25%" },
          paddingTop: "28px",
        }}
      >
        <Typography variant="h4" textAlign="center" mb={1.5}>
          Kreator
        </Typography>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            height: "100%",
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {etalaseKolList?.length ? (
            etalaseKolList.map((kol) => (
              <Card
                sx={{
                  borderRadius: 0,
                  width: "98%",
                  bgcolor: "#EEEEEF",
                  padding: 0,
                  mb: 1,
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      alt={kol.Content?.kol_name}
                      src={AvatarGender(
                        kol.Content?.Sample
                          ? kol.Content?.Sample?.kol_info.gender
                          : "",
                        kol.Content.kol_id
                      )}
                    />
                  }
                  title={
                    <Typography variant="h4">{kol.Content.kol_name}</Typography>
                  }
                  subheader={
                    <Typography variant="caption">
                      {kol.Content?.Sample?.kol_info.sosmed}
                    </Typography>
                  }
                  sx={{ textAlign: "left" }}
                />
                {kol.Content?.Sample ? (
                  <CardContent sx={{ paddingTop: 0 }}>
                    <Typography variant="body2" color="text.secondary">
                      Resi {kol.Content?.Sample?.sample_tracking.ekspedisi}:
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {kol.Content?.Sample?.sample_tracking.resi}
                    </Typography>
                  </CardContent>
                ) : null}
              </Card>
            ))
          ) : (
            <Card sx={{ width: "98%", bgcolor: "#EEEEEF", padding: 0, mb: 1 }}>
              <CardHeader
                title={
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    Belum ada KOL
                  </Typography>
                }
              />
            </Card>
          )}
        </List>
      </Box>
    </Modal>
  );
}
