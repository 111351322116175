import { api_gtw } from "../../apis";
// import jwt_decode from "jwt-decode";

export const login = async (payload) => {
  const { data } = await api_gtw.post("/seller/login", payload);
  //create data scan in local storage
  // localStorage.setItem("dataScan", JSON.stringify([]));
  localStorage.setItem("access_token", data.access_token);
  return data;
};
// export const logout = async () => {
//   const token = localStorage.getItem("access_token");
//   const data = jwt_decode(token);
//   const { data: _data } = await api_gtw.patch("/seller/logout", data);
//   return _data;
// };
export const forgetPassword = async (payload) => {
  const { data } = await api_gtw.post("/seller/forgot/password", payload);

  return data;
};

export const checkVerification = async (payload) => {
  const { data } = await api_gtw.post("/seller/verify/password", payload);

  return data;
};

export const changePassword = async (payload) => {
  const { data } = await api_gtw.post("/seller/change/password", payload);

  return data;
};

export const allStaff = async () => {
  const { data } = await api_gtw.get("/seller/allStaff", {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const RegisterShop = async (payload) => {
  const { data } = await api_gtw.post("/seller/register/shop", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const RegisterAdmin = async (payload) => {
  const { data } = await api_gtw.post("/seller/register/admin", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const AddAdminEasyDrop = async (payload) => {
  const { data } = await api_gtw.post("/dropper/admin/easydrop", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const RegisterStaff = async (payload) => {
  const { data } = await api_gtw.post("/seller/register/staff", payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const editSeller = async ({ payload, id }) => {
  const { data } = await api_gtw.patch(
    "/seller/edit",
    { payload, id },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const deleteStaff = async (id) => {
  const { data } = await api_gtw.delete(`/seller/deleteStaff/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const updateProfile = async (payload) => {
  const { data } = await api_gtw.patch(`/seller/update/user`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  localStorage.setItem("access_token", data);
  return data;
};

export const getProfile = async () => {
  const { data } = await api_gtw.get(`/seller/profile/user`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const checkUser = async () => {
  const { data } = await api_gtw.get(`/seller/user/byid`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.access_token;
};
