import React, { useState } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import FormPengajuanTopup from "./FormPengajuanTopup";
// import { topUpBalance } from "../../store/actions/actionWallet";
// import FormPengajuanTopup from "./FormPengajuanTopup";
// import {
//   setErr,
//   setMessage,
//   setOpenSnack,
// } from "../../store/actions/actionAwb";
// import { handleError } from "../../helpers/errHandler";

export default function Topup({ open, setOpen }) {
  const dispatch = useDispatch();

  // const [openTopup, setOpenTopup] = useState(false);
  const [amount, setAmount] = useState("");
  const [openFormPengajuan, setOpenFormPengajuan] = useState(false);
  const [infoTrf, setInfoTrf] = useState({
    show: false,
    amount: 0,
    no_rek: 9998887771,
    nama: "Acong Ardiansyah",
    bank: "BCA",
  });

  function handleTopup(e) {
    e.preventDefault();
    const orderNumber = Date.now(),
      payload = {
        total: +amount,
        orderNumber: orderNumber.toString(),
      };

    setInfoTrf({
      ...infoTrf,
      show: true,
      amount,
    });

    // dispatch(topUpBalance(payload))
    //   .then(({ data }) => {
    //     setInfoTrf({
    //       ...infoTrf,
    //       show: true,
    //       amount: data.amount,
    //     });
    //   })
    //   .catch((err) => {
    //     const message = handleError(err);
    //     dispatch(setErr(true));
    //     dispatch(setMessage(message));
    //     dispatch(setOpenSnack(true));
    //   })
    //   .finally(() => setAmount(""));
  }

  return (
    <>
      {infoTrf.show ? (
        <Modal
          open={infoTrf.show}
          onClose={() => {
            setInfoTrf({ ...infoTrf, show: false });
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              // border: '2px solid #000',
              boxShadow: 24,
              p: 6,
              borderRadius: "10px",
            }}
          >
            <small style={{ color: "red" }}>
              Note: Mohon transfer dengan nominal yang sesuai dengan yang ada
              dibawah ini.
            </small>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>JUMLAH</strong>
                  </td>
                  <td>:</td>
                  <td>
                    <pre className="mt-3">
                      Rp. {infoTrf.amount.toLocaleString("id", "ID")}
                    </pre>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>No. rek</strong>
                  </td>
                  <td>:</td>
                  <td>
                    {/* <pre className="mt-3">7100068669</pre> */}
                    <pre className="mt-3">5810179742</pre>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>A/N</strong>
                  </td>
                  <td>:</td>
                  <td>
                    {/* <pre className="mt-3">GAPURA DUNIA INFORMATIKA</pre> */}
                    <pre className="mt-3">Michael</pre>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Bank</strong>
                  </td>
                  <td>:</td>
                  <td>
                    <pre className="mt-3">BCA</pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Modal>
      ) : null}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setAmount("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              // border: '2px solid #000',
              boxShadow: 24,
              p: 6,
              borderRadius: "10px",
            }}
          >
            <Typography variant="h3">Top Up Balance</Typography>
            <Typography
              variant="caption"
              fontSize="small"
              sx={{ color: "red" }}
            >
              Note: Jika transfer sesuai dengan kode unik yang tertera, harap
              tunggu 1 s/d 30 menit, jika belum silahkan isi form{" "}
              <NavLink onClick={() => setOpenFormPengajuan(true)}>
                disini.
              </NavLink>
            </Typography>
            {/* <small style={{ color: "red" }}></small> */}
            <form style={{ marginTop: "10px" }}>
              <TextField
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                fullWidth
                type="number"
                size="small"
                label="Amount"
                variant="outlined"
                sx={{ width: "100%" }}
              />
              <Button
                onClick={handleTopup}
                variant="contained"
                size="medium"
                style={{
                  backgroundColor: "#ab8c36",
                  color: "#ffffff",
                  marginTop: "15px",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                type="submit"
              >
                Top Up
              </Button>
            </form>
          </Box>
          <FormPengajuanTopup
            open={openFormPengajuan}
            setOpen={setOpenFormPengajuan}
          />
        </div>
      </Modal>
    </>
  );
}
