// import { useEffect, Fragment } from "react";
import { Modal, Grid, Box, Typography, Snackbar, Alert } from "@mui/material";

export const BasicModal = ({ open, onClose, title, children }) => (
  <Modal
    open={open}
    onClose={() => onClose(false)}
    aria-labelledby="basic-title"
    aria-describedby="basic-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 400,
        maxWidth: "95%",
        backgroundColor: "white",
        borderRadius: 1,
        boxShadow: 24,
        p: 2,
      }}
    >
      <Typography id="basic-title" variant="h6" component="h6">
        {title}
      </Typography>
      <Grid container>{children}</Grid>
    </Box>
  </Modal>
);

export const UIAlert = ({
  open,
  onClose,
  res = { status: "", message: "" },
}) => (
  <Snackbar
    anchorOrigin={{ horizontal: "center", vertical: "top" }}
    open={open}
    onClose={() => onClose(false)}
  >
    <Alert severity={res.status === "ok" ? "success" : "error"}>
      {res.message}
    </Alert>
  </Snackbar>
);
