import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Typography,
  Box,
  Button,
  Stack,
  FormControl,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  IconButton,
  TablePagination,
  Zoom,
  Modal,
  Grid,
  Alert,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { handleError } from "../../../../helper/helpers";
import { styled } from "@mui/material/styles";
import { ReportProblemOutlined } from "@mui/icons-material";
import { useState, useMemo, Fragment, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { utils, writeFile } from "xlsx";
import { TablePaginationActions } from "../../../../components/Reuse/pagination/pagination";
import { getQueryUrl, formatDate } from "../../../../helper/custom";
import {
  downloadReport,
  addTransactionsTicketing,
  getTotalDownloadReport,
  getReport,
} from "../../../../store/actions/syncAction";
import { addLog } from "../../../../store/actions/actionLog";

import { decompress } from "compress-json";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jwt_decode from "jwt-decode";

export default function DetailReportDesktopPage({
  selectedStore,
  // dataList,
  loading2,
  setRes,
}) {
  const isAppleProduct =
    navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;

  //format Date
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };

  // const [newSelected, setNewSelected] = useState("");

  //localstorage
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  let userInternal = decode.userInternalId;
  //localstorage

  //query
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { search } = useLocation();
  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //query

  //=======================

  //theme
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  //theme

  //=======================
  //value
  const [seller, setSeller] = useState(decode?.userInternalId[0]?.id);
  const [dataTicket, setDataTicket] = useState({
    awb: "",
    note: "",
    idInternal: decode.userInternalId,
  });

  const [dataDownload, setDataDownload] = useState({
    // minDate: "",
    // maxDate: "",
    id: decode.role
      ? decode?.userInternalId[seller]?.userInternalId[0]?.id
      : decode.userInternalId[0].id,
    date: "",
    threePl: "",
    state: { startDate: new Date(), endDate: new Date(), key: "selection" },
  });
  const [notif, setNotif] = useState({
    type: "",
    status: "success",
    message: "",
  });
  const [state, setState] = useState({
    startDate: params.minDate
      ? new Date(formatDate(params.minDate, true))
      : new Date(),
    endDate: params.maxDate
      ? new Date(formatDate(params.maxDate, true))
      : new Date(),
    // startDate: new Date(),
    // endDate: new Date(),
    key: "selection",
  });
  const [isManual, setIsManual] = useState(false);
  //value

  //=======================

  //modal
  const [openModalDate, setOpenModalDate] = useState(false);
  const [openModalDateDownload, setOpenModalDateDownload] = useState(false);

  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalTicket, setOpenModalTicket] = useState(false);
  const [dataList, setDataList] = useState({});
  //modal

  //=======================

  //loading
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  //loading

  //=======================
  useEffect(() => {
    getReportAction();
  }, [selectedStore]);

  const getReportAction = async () => {
    try {
      setDataList({ data: [], count: 0 });
      // setLoading2(true);
      const data = await getReport({ search, selectedStore });
      setDataList({ ...dataList, data: data.allData, count: data.count });
      // setLoading2(false);
    } catch (error) {
      // setLoading2(false);
      const message = await handleError(error);
      console.log(message);
      // setError(message);
    }
  };

  //function
  const downloadReportAction = async () => {
    try {
      setRes({ status: "", message: "" });
      // if (!dataDownload.state.minDate||!dataDownload.state.minDate) {
      //   setNotif({
      //     ...notif,
      //     type: "date",
      //     status: "error",
      //     message: "tanggal tidak boleh kosong",
      //   });
      //   return;
      // } else
      if (!dataDownload.threePl) {
        setNotif({
          ...notif,
          type: "threepl",
          status: "error",
          message: "tanggal tidak boleh kosong",
        });
        return;
      } else if (!dataDownload.id) {
        setNotif({
          ...notif,
          type: "seller",
          status: "error",
          message: "seller tidak boleh kosong",
        });
        return;
      }
      setLoadingDownload(true);

      // let dateRange =
      //   Math.abs(state.startDate.getTime() - state.endDate.getTime()) /
      //   (24 * 60 * 60 * 1000);

      // if (dateRange > 90) {
      //   console.log(
      //     "data hanya bisa didownload dalam jangka waktu maksimal 90 hari",
      //     dateRange,
      //     "----"
      //   );
      // } else {
      let data = [];
      let newTotalData = await getTotalDownloadReport(dataDownload);
      if (!newTotalData) {
        setRes({ status: "error", message: "tidak ada data" });
        setLoadingDownload(false);

        return;
      }
      let limitDownload = 2000;
      let page = 0;
      if (newTotalData > limitDownload) {
        let loop = Math.ceil(+newTotalData / limitDownload);
        let _data = [];
        for (let index = 0; index < loop; index++) {
          if (index === loop - 1) {
            _data = await downloadReport({
              search: `?limitDownload=${newTotalData}&pageDownload=${
                page * limitDownload
              }`,
              payload: dataDownload,
            });
          } else {
            _data = await downloadReport({
              search: `?limitDownload=${limitDownload}&pageDownload=${
                page * limitDownload
              }`,
              payload: dataDownload,
            });
          }
          page += 1;
          newTotalData -= limitDownload;
          data = data.concat(decompress(_data));
        }
      } else {
        data = await downloadReport({ payload: dataDownload });
        data = decompress(data);
      }
      if (!decode.role) await addLog();

      let rows = data.map((e) => {
        return {
          "TANGGAL PENGIRIMAN": e.tanggal_pengiriman,
          "NAMA TOKO": e.nama_toko,
          "NAMA PENERIMA": e.nama_penerima,
          "NAMA BARANG": e.nama_barang,
          "NO. RESI": e.awb,
          LOGISTIK: e.three_PL,
          SERVICE: e.service,
          ONGKIR: e.ongkir,
          BERAT: e.berat,
        };
      });
      let wscols = [
        { width: 26 },
        { width: 20 },
        { width: 40 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "Report.xlsx");

      setLoadingDownload(false);
      setNotif({
        ...notif,
        type: "",
        status: "success",
        message: "",
      });
    } catch (error) {
      setLoadingDownload(false);
      const message = await handleError(error);
      console.log(message);
      // setError(message);
    }
  };

  const reportTicketing = async () => {
    try {
      if (!dataTicket.note) {
        setNotif({
          ...notif,
          type: "note",
          status: "error",
          message: "note tidak boleh kosong",
        });
        return;
      }

      await addTransactionsTicketing({ dataTicket, selectedStore });
      setDataTicket({
        ...dataTicket,
        awb: "",
        note: "",
      });
      setRes({ status: "success", message: "add ticket berhasil" });
      setOpenModalTicket(false);
      setIsDisabled(false);
    } catch (error) {
      const message = await handleError(error);
      setRes({ status: "error", message });
      setDataTicket({
        ...dataTicket,
        awb: "",
        note: "",
      });
      setOpenModalTicket(false);
      setIsDisabled(false);
      // setError(message);
    }
  };
  //function

  //=======================

  return (
    <Box
      sx={{
        height: "80%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Modal
        open={openModalTicket}
        onClose={() => {
          setDataTicket({
            ...dataTicket,
            awb: "",
            note: "",
          });
          setOpenModalTicket(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalTicket}>
          <Box
            sx={{
              width: { lg: "30%", md: "30%", sm: "50%", xs: "80%" },
              backgroundColor: "white",
              paddingY: 5,
              borderRadius: 1,
            }}
          >
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
                {isManual ? "Laporkan Paket Manual" : "Laporkan Paket"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <TextField
                  disabled={dataTicket.awb ? true : false}
                  label="awb"
                  defaultValue={dataTicket.awb}
                  onChange={(e) => {
                    setNotif({
                      ...notif,
                      type: "",
                      status: "success",
                      message: "",
                    });
                    setDataTicket({ ...dataTicket, awb: e.target.value });
                  }}
                  sx={{ width: "80%" }}
                />

                <FormControl sx={{ m: 1, minWidth: "80%", marginTop: 5 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Note"
                    multiline
                    rows={6}
                    defaultValue={dataTicket.note}
                    inputProps={{ maxLength: 250 }}
                    onChange={(e) => {
                      setNotif({
                        ...notif,
                        type: "",
                        status: "success",
                        message: "",
                      });
                      setDataTicket({ ...dataTicket, note: e.target.value });
                    }}
                  />
                </FormControl>
                {notif.type === "note" ? (
                  <Grid>
                    <Typography sx={{ color: "red" }}>
                      {notif.message}
                    </Typography>
                  </Grid>
                ) : null}
              </Box>

              <Fragment>
                <Grid
                  container
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingX={5}
                  marginTop={5}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setDataTicket({
                        ...dataTicket,
                        awb: "",
                        note: "",
                      });
                      setOpenModalTicket(false);
                    }}
                    sx={{
                      borderColor: "#ab8c36",
                      color: "#ab8c36",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        borderColor: "#ab8c36",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                  >
                    Batal
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => {
                      reportTicketing();
                      setIsDisabled(true);
                    }}
                    sx={{
                      backgroundColor: "#333333",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#333333",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                    disabled={isDisabled}
                  >
                    Simpan
                  </Button>
                </Grid>
              </Fragment>
              <Grid item />
            </Grid>
          </Box>
        </Zoom>
      </Modal>
      <Modal
        open={openModalDate || openModalDateDownload}
        onClose={() => {
          setOpenModalDate(false);
          setOpenModalDateDownload(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalDate || openModalDateDownload}>
          <Box
            sx={{
              width: "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => {
                if (openModalDate) setState(item.selection);
                else if (openModalDateDownload)
                  setDataDownload({ ...dataDownload, state: item.selection });
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={openModalDateDownload ? [dataDownload.state] : [state]}
              direction={"horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={undefined}
              staticRanges={undefined}
            />

            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => {
                  setOpenModalDate(false);
                  setOpenModalDateDownload(false);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  if (!openModalDateDownload) {
                    if (isAppleProduct) {
                      let newMinDate = new Date(
                        state.startDate
                      ).toLocaleDateString("en-US", options);

                      let newMaxDate = new Date(
                        state.endDate
                      ).toLocaleDateString("en-US", options);

                      newMinDate = newMinDate.split("/");
                      newMaxDate = newMaxDate.split("/");

                      const tempDateMin = newMinDate[1];
                      const tempMonthMin = newMinDate[0];

                      const tempDateMax = newMaxDate[1];
                      const tempMonthMax = newMaxDate[0];
                      newMinDate[0] = tempDateMin;
                      newMinDate[1] = tempMonthMin;

                      newMaxDate[0] = tempDateMax;
                      newMaxDate[1] = tempMonthMax;
                      newMinDate = newMinDate.join("-");
                      newMaxDate = newMaxDate.join("-");

                      setSearchParams({
                        ...query,
                        page: 1,
                        limit: 10,
                        minDate: newMinDate,
                        maxDate: newMaxDate,
                      });
                    } else {
                      setSearchParams({
                        ...query,
                        page: 1,
                        limit: 10,
                        minDate: state.startDate
                          .toLocaleDateString("en-US", options)
                          .replaceAll("/", "-"),
                        maxDate: state.endDate
                          .toLocaleDateString("en-US", options)
                          .replaceAll("/", "-"),
                      });
                    }
                  }

                  // getOrderValidate();
                  setOpenModalDate(false);
                  setOpenModalDateDownload(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>
      <Modal
        open={openModalReport}
        onClose={() => setOpenModalReport(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalReport}>
          <Box
            sx={{
              // width: { lg: "30%", md: "30%", sm: "80%", xs: "80%" },
              backgroundColor: "white",
              paddingY: 5,
              borderRadius: 1,
              minWidth: 350,
              maxWidth: "80%",
              borderRadius: 0,
            }}
          >
            <Grid
              container
              display="flex"
              width="100%"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
                Download Report
              </Typography>
              <Fragment>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Choose Date"
                    inputFormat="dd/MM/yyyy"
                    value={dataDownload.date}
                    onChange={(e) => {
                      setDataDownload({ ...dataDownload, date: e });
                    }}
                    sx={{ borderRadius: 0 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider> */}
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    width: "auto",
                    height: "50px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    // fontSize: matchesMobile ? "8px" : "14px",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                    fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
                  }}
                  onClick={() => setOpenModalDateDownload(true)}
                >
                  {!dataDownload.state.startDate
                    ? "Pilih Tanggal"
                    : formatDate(dataDownload.state.startDate) ===
                      formatDate(dataDownload.state.endDate)
                    ? `${formatDate(dataDownload.state.startDate)}`
                    : `${formatDate(
                        dataDownload.state.startDate
                      )} - ${formatDate(dataDownload.state.endDate)}`}
                </Button>
                {notif.type === "date" ? (
                  <Grid>
                    <Alert
                      severity={notif.status === "ok" ? "success" : "error"}
                    >
                      {notif.message}
                    </Alert>
                  </Grid>
                ) : null}
                <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Ekspedisi
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={dataDownload.threePl}
                    label="Ekspedisi"
                    sx={{ borderRadius: 0 }}
                    onChange={(e) => {
                      setDataDownload({
                        ...dataDownload,
                        threePl: e.target.value,
                      });
                      setNotif({
                        ...notif,
                        type: "",
                        status: "success",
                        message: "",
                      });
                    }}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"jnt"}>JNT</MenuItem>
                    <MenuItem value={"jne"}>JNE</MenuItem>
                    <MenuItem value={"ninja express"}>Ninja Express</MenuItem>
                    <MenuItem value={"anteraja"}>Anteraja</MenuItem>
                    <MenuItem value={"shopee"}>Shopee</MenuItem>
                    <MenuItem value={"lex"}>Lex</MenuItem>
                    <MenuItem value={"id express"}>Id Expres</MenuItem>
                    <MenuItem value={"sicepat"}>SiCepat</MenuItem>
                  </Select>
                </FormControl>
                {notif.type === "threepl" ? (
                  <Grid>
                    <Alert
                      severity={notif.status === "ok" ? "success" : "error"}
                    >
                      {notif.message}{" "}
                    </Alert>
                  </Grid>
                ) : null}

                {decode.role ? (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                      <InputLabel>Seller</InputLabel>
                      <Select
                        value={seller}
                        label="Seller"
                        sx={{ borderRadius: 0 }}
                        onChange={(e) => {
                          setSeller(e.target.value);
                        }}
                      >
                        {userInternal.length
                          ? userInternal.map((e, i) => (
                              <MenuItem key={i} value={e.id}>
                                {e.username}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>

                    {seller ? (
                      <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                        <InputLabel>Pilih Toko</InputLabel>
                        <Select
                          label="Pilih Toko"
                          sx={{ borderRadius: 0 }}
                          value={dataDownload.id}
                          onChange={(e) => {
                            setDataDownload({
                              ...dataDownload,
                              id: e.target.value,
                            });
                            setNotif({
                              ...notif,
                              type: "",
                              status: "success",
                              message: "",
                            });
                          }}
                        >
                          {userInternal.map((e) => {
                            if (e.id === seller) {
                              return e.userInternalId.map((res, i) => (
                                <MenuItem key={i} value={res.id}>
                                  {res.username}
                                </MenuItem>
                              ));
                            }
                          })}
                        </Select>
                      </FormControl>
                    ) : null}
                  </>
                ) : (
                  <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                    <InputLabel>Seller</InputLabel>
                    <Select
                      value={dataDownload.id}
                      label="Seller"
                      sx={{ borderRadius: 0 }}
                      onChange={(e) => {
                        setDataDownload({
                          ...dataDownload,
                          id: e.target.value,
                        });
                        setNotif({
                          ...notif,
                          type: "",
                          status: "success",
                          message: "",
                        });
                      }}
                    >
                      {userInternal.length
                        ? userInternal.map((e, i) => (
                            <MenuItem key={i} value={e.id}>
                              {e.username}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
                {notif.type === "seller" ? (
                  <Grid>
                    <Alert
                      severity={notif.status === "ok" ? "success" : "error"}
                    >
                      {notif.message}
                    </Alert>
                  </Grid>
                ) : null}
              </Fragment>

              <Fragment>
                <Grid
                  container
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingX={5}
                  marginTop={5}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setNotif({
                        ...notif,
                        type: "",
                        status: "success",
                        message: "",
                      });
                      setOpenModalReport(false);
                    }}
                    sx={{
                      borderColor: "#ab8c36",
                      color: "#ab8c36",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        borderColor: "#ab8c36",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                  >
                    Batal
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#333333",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#333333",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                    onClick={() => {
                      downloadReportAction();
                      // setIsDisabled(true);
                    }}
                    disabled={loadingDownload}
                  >
                    {loadingDownload ? (
                      <CircularProgress size={25} sx={{ color: "#333333" }} />
                    ) : (
                      "Simpan"
                    )}
                  </Button>
                </Grid>
              </Fragment>
              <Grid item />
            </Grid>
          </Box>
        </Zoom>
      </Modal>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          height: "10%",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setOpenModalTicket(true);
            setIsManual(true);
          }}
          sx={{
            borderRadius: 0,
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
            backgroundColor: "#ab8c36",
            "&:hover": {
              color: "#ffffff",
              backgroundColor: "#333333",
            },
            height: "50px",
            fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
          }}
        >
          Laporkan Paket Manual
        </Button>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            onClick={() => setOpenModalReport(true)}
            sx={{
              borderRadius: 0,
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              backgroundColor: "#ab8c36",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
              height: "50px",
              fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
            }}
          >
            Download Report
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              width: "auto",
              height: "50px",
              backgroundColor: "#ffffff",
              color: "#000000",
              // fontSize: matchesMobile ? "8px" : "14px",
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              "&:hover": {
                backgroundColor: "#333333",
                color: "#ffffff",
              },
              fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
            }}
            onClick={() => setOpenModalDate(true)}
          >
            {!params.minDate
              ? "Pilih Tanggal"
              : `${formatDate(params.minDate)} - ${formatDate(params.maxDate)}`}
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "90%",
          boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          // display: "flex",
          backgroundColor: "#ffffff",
          flexDirection: "column",
        }}
      >
        <TableContainer
          sx={{
            minHeight: "90%",
            maxHeight: "90%",
            "&::-webkit-scrollbar": { width: 10, height: 10 },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#33333350",
              borderRadius: 1,
            },
          }}
        >
          <Table size="large" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ minWidth: 50 }}>No.</StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>
                  Tanggal Pengiriman
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>
                  Nama Barang
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>
                  Nama Penerima
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>
                  No. Resi
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>
                  Logistik
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: 100 }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList.data?.length
                ? dataList.data.map((e, i) => (
                    <TableRow key={i}>
                      <StyledTableCell>{i + 1}</StyledTableCell>
                      <StyledTableCell>
                        {new Date(e.tanggal_pengiriman).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{e.nama_barang}</StyledTableCell>
                      <StyledTableCell>{e.nama_penerima}</StyledTableCell>
                      <StyledTableCell>{e.awb}</StyledTableCell>
                      <StyledTableCell>{e.three_PL}</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="Laporkan Paket">
                          <IconButton
                            sx={{ borderRight: 1 }}
                            onClick={() => {
                              setDataTicket({
                                ...dataTicket,
                                awb: e.awb,
                                note: "",
                              });
                              setOpenModalTicket(true);
                              setIsManual(false);
                            }}
                          >
                            <ReportProblemOutlined sx={{ color: "red" }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {loading2 ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={25} sx={{ color: "#333333" }} />
            </Box>
          ) : dataList.data?.length === 0 ? (
            <Alert
              variant="standard"
              severity="warning"
              sx={{ borderRadius: 0 }}
            >
              Tidak ada data
            </Alert>
          ) : null}
        </TableContainer>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            ml: 2,
            height: "10%",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={+dataList.count}
            rowsPerPage={+params.limit}
            page={dataList.count <= 0 ? 0 : +params.page - 1}
            onPageChange={(_, newPage) =>
              setSearchParams({ ...query, page: newPage + 1 })
            }
            onRowsPerPageChange={({ target: { value = "10" } }) =>
              setSearchParams({ ...query, limit: value })
            }
            ActionsComponent={TablePaginationActions}
          />
        </Box>
      </Box>
    </Box>
  );
}
