import { Routes, Route } from "react-router-dom";
import PrivateRoute, {
  AuthRoute,
  IdentifyRoute,
  AgentAuth,
} from "./auth/index";

import Login from "./views/LoginLayout/Login";
import ForgetPassword from "./views/LoginLayout/ForgetPassword";
import RegistrationPage from "./views/LoginLayout/Registration";

import DashboardPage from "./views/DashboardLayout/DashBoard";

import ProblematicOrderPage from "./views/PremiumTransactionsLayout/ProblematicOrder";
import BigdeliveryOrderPage from "./views/PremiumTransactionsLayout/BigdeliveryOrder";
// import ReportOrderPage from "./views/PremiumTransactionsLayout/ReportOrder";
import DashboardPremiumPage from "./views/PremiumTransactionsLayout/DashboardPremium";

// import OrderPage from "./views/TransactionsLayout/Order";
import OrderPage from "./views/TransactionsLayout/Order2";
import ReturPage from "./views/TransactionsLayout/Retur";

import SidebarMenu from "./views/SidebarLayout/index";
import ThemeProvider from "./theme/ThemeProvider";
import AddUser from "./views/SellerLayout/AddUser";

import SettingPage from "./views/ProfileLayout/Setting";
import IndexReport from "./views/PremiumTransactionsLayout/Report";
import AgencyPage from "./views/AgencyLayout/Agency";
// import ViewKolList from "./views/JualKirimLayout/KOLListLayout/ViewKolList";
import ViewProgresContent from "./views/JualKirimLayout/ProgressContentLayout/ViewProgresContent";
import ViewKontenSelesai from "./views/JualKirimLayout/ContentDoneLayout/ViewKontenSelesai";
import ListProducts from "./views/JualKirimLayout/ListProductLayout/ListProducts";
import PermintaanSample from "./views/JualKirimLayout/PermintaanSample";

import "./App.css";
import RatingComponent from "./views/JualKirimLayout/RatingLayout/ratingView";
import CreateResi from "./views/ResiLayout/CreateResi";
import Check from "./views/ResiLayout/CheckResi";
import ScanPage from "./views/TransactionsLayout/Scan";
import ReturnPackagePage from "./views/TransactionsLayout/ReturnPackage";
import ReportBigPosPage from "./views/BigPosLayout/report";
import RegistrationEasyDropPage from "./views/LoginLayout/RegistrationEasyDrop";
import CashbackPage from "./views/PremiumTransactionsLayout/CashbackPage";
import UserAdminPage from "./views/BigPosLayout/admin";
import BaggingPage from "./views/BigPosLayout/bagging";

function App() {
  return (
    <ThemeProvider>
      <Routes>
        <Route path="/" element={<IdentifyRoute />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<SidebarMenu />}>
            <Route path="/easy-drop">
              <Route path="scan" element={<ScanPage />} />
              <Route path="return" element={<ReturnPackagePage />} />
              <Route path="report" element={<ReportBigPosPage />} />
              <Route path="admin" element={<UserAdminPage />} />
              <Route path="bagging" element={<BaggingPage />} />
            </Route>
            <Route path="order" element={<OrderPage />} />
            <Route path="retur" element={<ReturPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="/premium">
              {/* <Route path="index" element={<IndexReport />} /> */}
              <Route path="dashboard" element={<DashboardPremiumPage />} />
              <Route path="bermasalah" element={<ProblematicOrderPage />} />
              <Route path="bigdelivery" element={<BigdeliveryOrderPage />} />
              <Route path="cashback" element={<CashbackPage />} />
              {/* <Route path="report" element={<ReportOrderPage />} /> */}
              <Route path="report" element={<IndexReport />} />
            </Route>

            <Route path="/kol">
              {/* <Route path="list" element={<ViewKolList />} /> */}
              <Route path="content" element={<ViewProgresContent />} />
              <Route path="rating" element={<RatingComponent />} />
              <Route path="posted" element={<ViewKontenSelesai />} />
            </Route>
            <Route path="/displays">
              <Route path="sample" element={<ListProducts />} />
              <Route path="request" element={<PermintaanSample />} />
            </Route>
            <Route path="/resi">
              <Route path="create" element={<CreateResi />} />
              <Route path="cekresi" element={<Check />} />
            </Route>

            <Route element={<AgentAuth />}>
              <Route path="user" element={<AddUser />} />
              <Route path="setting" element={<SettingPage />} />
            </Route>

            <Route path="agency" element={<AgencyPage />} />
          </Route>
        </Route>
        <Route element={<AuthRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route
            path="/registration-easydrop"
            element={<RegistrationEasyDropPage />}
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
