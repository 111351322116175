import React from "react";

// material-ui
import { CardContent, Grid, Link, Typography } from "@mui/material";

// project imports
import MainCard from "../MainCard";

export default function EtalaseCardAvailable({
  setOpenModalInputOrEditEtalase,
  index,
  setEvent,
  setIndex,
}) {
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12} mt={5}>
        <Typography variant="h4" mb={1}>
          Etalase {index + 1}
        </Typography>
        <MainCard
          content={false}
          sx={{
            borderRadius: 0,
            color: "#bfbfbf",
            boxShadow: 0,
            "&:hover": {
              boxShadow: "none",
              marginTop: "",
              marginBottom: "",
            },
          }}
          tabIndex={-1}
          disableRipple
        >
          <Link
            href="#"
            overlay
            underline="none"
            onClick={() => {
              setIndex(index);
              setEvent("input");
              setOpenModalInputOrEditEtalase(true);
            }}
          >
            <CardContent sx={{ p: 1, height: "130px", padding: "50px" }}>
              <div className="row" style={{ textAlign: "-webkit-center" }}>
                <div className="column">
                  <Grid container columns={16} justifyContent="center">
                    <Typography variant="h3">+ Tambahkan Produk</Typography>
                  </Grid>
                </div>
              </div>
            </CardContent>
          </Link>
        </MainCard>
      </Grid>
    </>
  );
}
