import React, { useEffect, useState } from "react";

import {
  ListSubheader,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Typography,
  Modal,
  Zoom,
  useMediaQuery,
  Grid,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ThisMonth, convertPrice } from "../../helper/custom";
import { useTheme } from "@mui/material/styles";
import {
  Repartition,
  TableChartTwoTone,
  Logout,
  Group,
  Settings,
  ReportGmailerrorred,
  ReceiptLong,
  Dashboard,
  NoteAdd,
  ClearAllOutlined,
  QrCodeScanner,
  Restore,
  DocumentScannerOutlined,
  AttachMoney,
  Archive,
  Close,
} from "@mui/icons-material";
import ModalCreateResi from "../../views/ResiLayout/ModalCreateResi";

import jwt_decode from "jwt-decode";

import Topup from "./ModalTopup/Topup";
import Mutasi from "./Mutasi";
import { getWallet } from "../../store/actions/actionWallet";
import popup from "../../image/popup-download-table.png";
// import popup2 from "../../image/popupbagging2.jpg";
// import { handleError } from "../../helper/helpers";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active ,
          &:hover {
            background: linear-gradient(to right, #ffffff, #333333);
            color: #333333;
            border-radius: 0;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          },
          
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openTopup, setOpenTopup] = useState(false);
  const [openMutasi, setOpenMutasi] = useState(false);
  const [saldoWallet, setSaldoWallet] = useState(0);
  const [openModalCreateResi, setOpenModalCreateResi] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  // const [openPopup2, setOpenPopup2] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSaldoWallet = async () => {
    try {
      // setError("");

      // setLoadingSaldo(true);
      const { data } = await getWallet();
      setSaldoWallet(+data.balance);

      // setLoadingSaldo(true);
    } catch (error) {
      // const message = await handleError(error);
    }
  };

  // useEffect(() => {
  //   if (/scan/i.test(pathname)) {
  //     setTimeout(() => {
  //       setOpenPopup(false);
  //     }, 2700);
  //   }
  // if (/bagging/i.test(pathname)) {
  //   setTimeout(() => {
  //     setOpenPopup2(true);
  //   }, 1200);
  // }
  // }, [pathname]);

  useEffect(() => {
    getSaldoWallet();
  }, []);

  return (
    <>
      <Topup open={openTopup} setOpen={setOpenTopup} />
      <Mutasi open={openMutasi} setOpen={setOpenMutasi} />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography variant="h4">{`Kamu yakin akan keluar ?`}</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModal(false);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={async (e) => {
                  // await logout();
                  localStorage.clear();
                  navigate("/login");
                }}
                variant="contained"
                sx={{
                  width: "10%",
                  backgroundColor: "#333333",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Ya
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal new feature 1 */}
      <Modal
        open={openPopup}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openPopup}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              borderRadius: 1,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ position: "absolute", right: -30, top: -25 }}>
              <IconButton
                sx={{
                  color: "#ffffff",
                  height: "fit-content",
                  "&:hover": {
                    backgroundColor: "#ffffff20",
                  },
                }}
                onClick={() => setOpenPopup(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <img
              src={popup}
              className="w-auto max-h-[75dvh] rounded-xl"
              alt="popup"
            />
          </Box>
        </Zoom>
      </Modal>

      {/* modal new feature 2 */}
      {/* <Modal
        open={openPopup2}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openPopup2}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              borderRadius: 1,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ position: "absolute", right: -30, top: -25 }}>
              <IconButton
                sx={{
                  color: "#ffffff",
                  height: "fit-content",
                  "&:hover": {
                    backgroundColor: "#ffffff20",
                  },
                }}
                onClick={() => setOpenPopup2(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <img
              src={popup2}
              className="w-auto max-h-[75dvh] rounded-xl"
              alt="popup"
            />
          </Box>
        </Zoom>
      </Modal> */}

      <MenuWrapper>
        {/* <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Dashboards
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              <ListItem
                component="div"
                sx={
                  /dashboard/i.test(pathname)
                    ? { backgroundColor: "#f0a835", borderRadius: 1 }
                    : null
                }
              >
                <Button disableRipple onClick={() => navigate("/dashboard")}>
                  <EventRepeatIcon
                    sx={
                      /dashboard/i.test(pathname)
                        ? { color: "black", fontWeight: 1000, mr: 1 }
                        : { fontWeight: 1000, mr: 1 }
                    }
                  />
                  <Typography
                    sx={
                      /dashboard/i.test(pathname)
                        ? { color: "black", fontWeight: 1000 }
                        : { fontWeight: 1000 }
                    }
                  >
                    Daily Update
                  </Typography>
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
        {/* bigpos */}
        {decode.accessStatus === "easydrop" &&
        !decode?.userInternalId?.length ? (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                BIG P.O.S
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem
                  component="div"
                  sx={
                    /scan/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/easy-drop/scan?limit=10&page=1&minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&ShopId=${
                          decode.role ? decode.users[0].ShopId : 0
                        }`
                      );
                    }}
                  >
                    <QrCodeScanner
                      sx={
                        /scan/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /scan/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Scan Pengiriman
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem
                  component="div"
                  sx={
                    /return/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/easy-drop/return?limit=10&page=1&minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&ShopId=${
                          decode.role ? decode.users[0].ShopId : 0
                        }`
                      );
                    }}
                  >
                    <Restore
                      sx={
                        /return/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /return/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Pengiriman Return
                    </Typography>
                  </Button>
                </ListItem>
                {!decode.isOwner ? null : (
                  <ListItem
                    component="div"
                    sx={
                      /cashback/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      onClick={() => {
                        const thisMonth = ThisMonth();
                        navigate(
                          `/premium/cashback?date=${thisMonth.endDate}&seller=${decode?.shopName}`
                        );
                      }}
                    >
                      <AttachMoney
                        sx={
                          /cashback/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000, mr: 1 }
                            : { fontWeight: 1000, mr: 1 }
                        }
                      />
                      <Typography
                        sx={
                          /cashback/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Cashback
                      </Typography>
                    </Button>
                  </ListItem>
                )}
                <ListItem
                  component="div"
                  sx={
                    /bagging/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/easy-drop/bagging?limit=10&page=1&minDate=${thisMonth.startDate}&maxDate=${thisMonth.endDate}&ShopId=${decode.ShopId}`
                      );
                    }}
                  >
                    <Archive
                      sx={
                        /bagging/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /bagging/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Scan Bagging
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem
                  component="div"
                  sx={
                    /report/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/easy-drop/report?minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&ShopId=${
                          decode.role ? decode.users[0].ShopId : 0
                        }`
                      );
                    }}
                  >
                    <DocumentScannerOutlined
                      sx={
                        /report/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /report/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Unduh Report
                    </Typography>
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ) : null}
        {/* SALDO BIGDEL */}
        {decode.role ? (
          <Grid container rowSpacing={1} sx={{ p: 2 }}>
            <Grid item xs={12} lg={12}>
              <Typography variant="h4">{decode.name.toUpperCase()}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                displey: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <Button sx={{ color: "white " }} onClick={handleClick}>
                <Typography variant="h4">
                  Saldo : {convertPrice(saldoWallet)}
                </Typography>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  key={"topup"}
                  onClick={() => {
                    setOpenTopup(true);
                    setAnchorEl(null);
                  }}
                >
                  Top Up
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenMutasi(true);
                    setAnchorEl(null);
                  }}
                >
                  Mutasi
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        ) : null}
        {decode.accessStatus !== "easydrop" &&
        !decode?.userInternalId?.length ? (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Order
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              {/* <List component="div">
              <ListItem
                component="div"
                sx={
                  /input/i.test(pathname)
                    ? { backgroundColor: "#f0a835", borderRadius: 1 }
                    : null
                }
              >
                <Button
                  disableRipple
                  onClick={() => navigate("/input")}
                  color="primary"
                >
                  <CropFreeIcon
                    sx={
                      /input/i.test(pathname)
                        ? { color: "black", fontWeight: 1000, mr: 1 }
                        : { fontWeight: 1000, mr: 1 }
                    }
                  />
                  <Typography
                    sx={
                      /input/i.test(pathname)
                        ? { color: "black", fontWeight: 1000 }
                        : { fontWeight: 1000 }
                    }
                  >
                    Input Manual
                  </Typography>
                </Button>
              </ListItem>
            </List> */}
              {/* <List component="div">
              <ListItem
                component="div"
                sx={
                  /upload/i.test(pathname)
                    ? { backgroundColor: "#f0a835", borderRadius: 1 }
                    : null
                }
              >
                <Button disableRipple onClick={() => navigate("/upload")}>
                  <UploadFileIcon
                    sx={
                      /upload/i.test(pathname)
                        ? { color: "black", fontWeight: 1000, mr: 1 }
                        : { fontWeight: 1000, mr: 1 }
                    }
                  />
                  <Typography
                    sx={
                      /upload/i.test(pathname)
                        ? { color: "black", fontWeight: 1000 }
                        : { fontWeight: 1000 }
                    }
                  >
                    Upload
                  </Typography>
                </Button>
              </ListItem>
            </List> */}
              <List component="div">
                <ListItem
                  component="div"
                  sx={
                    /order/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/order?status=all&limit=10&page=1&minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&ShopId=${
                          decode.role ? decode.users[0].ShopId : 0
                        }`
                      );
                    }}
                  >
                    <TableChartTwoTone
                      sx={
                        /order/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /order/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Atur Pengiriman
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem
                  component="div"
                  sx={
                    /retur/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/retur?limit=10&page=1&minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&ShopId=${
                          decode.role ? decode.users[0].ShopId : 0
                        }`
                      );
                    }}
                  >
                    <Repartition
                      sx={
                        /retur/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /retur/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Atur Retur
                    </Typography>
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ) : null}
        {decode?.userInternalId?.length ? (
          <>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  Big Delivery Premium
                </ListSubheader>
              }
            >
              <SubMenuWrapper>
                <List component="div">
                  <ListItem
                    component="div"
                    sx={
                      /dashboard/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      onClick={() => navigate("/premium/dashboard")}
                    >
                      <Dashboard
                        sx={
                          /dashboard/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000, mr: 1 }
                            : { fontWeight: 1000, mr: 1 }
                        }
                      />
                      <Typography
                        sx={
                          /dashboard/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Dashboard
                      </Typography>
                    </Button>
                  </ListItem>
                  <ListItem
                    component="div"
                    sx={
                      /report/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      onClick={() => {
                        const thisMonth = ThisMonth();
                        navigate(
                          `/premium/report?limit=${10}&page=${1}&minDate=${
                            thisMonth.startDate
                          }&maxDate=${thisMonth.endDate}&thisDate=${
                            thisMonth.thisDate
                          }`
                        );
                      }}
                    >
                      <ReceiptLong
                        sx={
                          /report/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000, mr: 1 }
                            : { fontWeight: 1000, mr: 1 }
                        }
                      />
                      <Typography
                        sx={
                          /report/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Report
                      </Typography>
                    </Button>
                  </ListItem>
                  {decode.accessStatus !== "root" ? null : (
                    <ListItem
                      component="div"
                      sx={
                        /cashback/i.test(pathname)
                          ? {
                              background:
                                "linear-gradient(to right, #ffffff, #333333)",
                            }
                          : null
                      }
                    >
                      <Button
                        disableRipple
                        onClick={() => {
                          const thisMonth = ThisMonth();
                          navigate(
                            `/premium/cashback?date=${thisMonth.endDate}&seller=${decode?.shopName}`
                          );
                        }}
                      >
                        <AttachMoney
                          sx={
                            /cashback/i.test(pathname)
                              ? { color: "#333333", fontWeight: 1000, mr: 1 }
                              : { fontWeight: 1000, mr: 1 }
                          }
                        />
                        <Typography
                          sx={
                            /cashback/i.test(pathname)
                              ? { color: "#333333", fontWeight: 1000 }
                              : { fontWeight: 1000 }
                          }
                        >
                          Cashback
                        </Typography>
                      </Button>
                    </ListItem>
                  )}

                  <ListItem
                    component="div"
                    sx={
                      /bermasalah/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      onClick={() => {
                        navigate(
                          `/premium/bermasalah?limit=10&page=1&status=all`
                        );
                      }}
                    >
                      <ReportGmailerrorred
                        sx={
                          /bermasalah/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000, mr: 1 }
                            : { fontWeight: 1000, mr: 1 }
                        }
                      />
                      <Typography
                        sx={
                          /bermasalah/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Order Bermasalah
                      </Typography>
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </List>
          </>
        ) : null}
        <ModalCreateResi
          open={openModalCreateResi}
          setOpen={setOpenModalCreateResi}
        />
        {/* GENERATE RESI */}
        {decode.role ? (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Resi
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                <ListItem
                  component="div"
                  sx={
                    /create/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                          height: "3rem",
                          // "&:hover": {
                          //   height: "3rem",
                          // },
                        }
                      : null
                  }
                >
                  <Button
                    sx={{ height: "3rem" }}
                    disableRipple
                    onClick={() => {
                      navigate(`/resi/create?limit=10&page=1&status=all`);
                    }}
                  >
                    {/* <Description
                      sx={
                        /resi/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: "5px" }
                          : { fontWeight: 1000, mr: "5px" }
                      }
                    /> */}

                    <IconButton
                      onClick={() => setOpenModalCreateResi(true)}
                      sx={
                        /create/i.test(pathname)
                          ? {
                              color: "#ab8c36",
                              ml: "-8px",
                            }
                          : {
                              color: "white",
                              ml: "-8px",
                              "&:hover": {
                                color: "black",
                              },
                            }
                      }
                    >
                      <NoteAdd />
                    </IconButton>
                    <Typography
                      sx={
                        /create/i.test(pathname)
                          ? {
                              color: "#333333",
                              fontWeight: 1000,
                            }
                          : { fontWeight: 1000 }
                      }
                    >
                      Pembuatan Resi
                    </Typography>
                  </Button>
                </ListItem>
                <ListItem
                  component="div"
                  sx={
                    /cekresi/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => {
                      const thisMonth = ThisMonth();
                      navigate(
                        `/resi/cekresi?limit=${10}&page=${1}&minDate=${
                          thisMonth.startDate
                        }&maxDate=${thisMonth.endDate}&thisDate=${
                          thisMonth.thisDate
                        }`
                      );
                    }}
                  >
                    <ClearAllOutlined
                      sx={
                        /cekresi/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000, mr: 1 }
                          : { fontWeight: 1000, mr: 1 }
                      }
                    />
                    <Typography
                      sx={
                        /cekresi/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Cek Resi
                    </Typography>
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        ) : null}

        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Accounts
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button disableRipple startIcon={<AccountCircleTwoToneIcon />}>
                  User Profile
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  startIcon={<DisplaySettingsTwoToneIcon />}
                >
                  Account Settings
                </Button>
              </ListItem> */}
              {decode.accessStatus === "root" ? (
                <>
                  <ListItem
                    component="div"
                    sx={
                      /setting/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      startIcon={
                        <Settings
                          sx={
                            /setting/i.test(pathname)
                              ? { color: "#333333", fontWeight: 1000 }
                              : { fontWeight: 1000 }
                          }
                        />
                      }
                      onClick={() => {
                        navigate("/setting");
                      }}
                    >
                      <Typography
                        sx={
                          /setting/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Setting
                      </Typography>
                    </Button>
                  </ListItem>

                  <ListItem
                    component="div"
                    sx={
                      /user/i.test(pathname)
                        ? {
                            background:
                              "linear-gradient(to right, #ffffff, #333333)",
                          }
                        : null
                    }
                  >
                    <Button
                      disableRipple
                      onClick={() => navigate("/user")}
                      startIcon={
                        <Group
                          sx={
                            /user/i.test(pathname)
                              ? { color: "#333333", fontWeight: 1000 }
                              : { fontWeight: 1000 }
                          }
                        />
                      }
                    >
                      <Typography
                        sx={
                          /user/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      >
                        Atur User
                      </Typography>
                    </Button>
                  </ListItem>
                </>
              ) : null}
              {!decode.isOwner ? null : (
                <ListItem
                  component="div"
                  sx={
                    /admin/i.test(pathname)
                      ? {
                          background:
                            "linear-gradient(to right, #ffffff, #333333)",
                        }
                      : null
                  }
                >
                  <Button
                    disableRipple
                    onClick={() => navigate("/easy-drop/admin")}
                    startIcon={
                      <Group
                        sx={
                          /admin/i.test(pathname)
                            ? { color: "#333333", fontWeight: 1000 }
                            : { fontWeight: 1000 }
                        }
                      />
                    }
                  >
                    <Typography
                      sx={
                        /admin/i.test(pathname)
                          ? { color: "#333333", fontWeight: 1000 }
                          : { fontWeight: 1000 }
                      }
                    >
                      Tambahkan Admin
                    </Typography>
                  </Button>
                </ListItem>
              )}
              <ListItem component="div">
                <Button
                  disableRipple
                  startIcon={<Logout />}
                  onClick={() => {
                    setOpenModal(true);
                    // localStorage.clear();
                    // navigate("/login");
                  }}
                >
                  Logout
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

// sidebar hidden
// {decode.accessStatus === "root" || decode.accessStatus === "admin" ? (
//   <>
//     <List
//       component="div"
//       subheader={
//         <ListSubheader component="div" disableSticky>
//           User
//         </ListSubheader>
//       }
//     >
//       <SubMenuWrapper>
//         <List component="div">
//           <ListItem
//             component="div"
//             sx={
//               /user/i.test(pathname)
//                 ? {
//                     background:
//                       "linear-gradient(to right, #ffffff, #333333)",
//                   }
//                 : null
//             }
//           >
//             <Button disableRipple onClick={() => navigate("/user")}>
//               <Group
//                 sx={
//                   /user/i.test(pathname)
//                     ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                     : { fontWeight: 1000, mr: 1 }
//                 }
//               />
//               <Typography
//                 sx={
//                   /user/i.test(pathname)
//                     ? { color: "#333333", fontWeight: 1000 }
//                     : { fontWeight: 1000 }
//                 }
//               >
//                 Atur User
//               </Typography>
//             </Button>
//           </ListItem>
//         </List>
//       </SubMenuWrapper>
//     </List>
//   </>
// ) : null}
// {!decode.role && decode.accessStatus !== "easydrop" ? (
//   <List
//     component="div"
//     subheader={
//       <ListSubheader component="div" disableSticky>
//         Kreator
//       </ListSubheader>
//     }
//   >
//     <SubMenuWrapper>
//       <List component="div">
//         <ListItem
//           component="div"
//           sx={
//             /list/i.test(pathname)
//               ? {
//                   background:
//                     "linear-gradient(to right, #ffffff, #333333)",
//                 }
//               : null
//           }
//         >
//           <Button
//             disableRipple
//             onClick={() => {
//               navigate(`/kol/list`);
//             }}
//           >
//             <SupervisorAccount
//               sx={
//                 /list/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                   : { fontWeight: 1000, mr: 1 }
//               }
//             />
//             <Typography
//               sx={
//                 /list/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000 }
//                   : { fontWeight: 1000 }
//               }
//             >
//               Kreator List
//             </Typography>
//           </Button>
//         </ListItem>
//         <ListItem
//           component="div"
//           sx={
//             /content/i.test(pathname)
//               ? {
//                   background:
//                     "linear-gradient(to right, #ffffff, #333333)",
//                 }
//               : null
//           }
//         >
//           <Button
//             disableRipple
//             onClick={() => {
//               navigate(`/kol/content`);
//             }}
//           >
//             <ManageAccounts
//               sx={
//                 /content/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                   : { fontWeight: 1000, mr: 1 }
//               }
//             />
//             <Typography
//               sx={
//                 /content/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000 }
//                   : { fontWeight: 1000 }
//               }
//             >
//               Progres Konten
//             </Typography>
//           </Button>
//         </ListItem>
//         {/* <ListItem
//         component="div"
//         sx={
//           /posted/i.test(pathname)
//             ? {
//                 background:
//                   "linear-gradient(to right, #ffffff, #333333)",
//               }
//             : null
//         }
//       >
//         <Button
//           disableRipple
//           onClick={() => {
//             navigate(`/kol/posted`);
//           }}
//         >
//           <VerifiedUser
//             sx={
//               /posted/i.test(pathname)
//                 ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                 : { fontWeight: 1000, mr: 1 }
//             }
//           />
//           <Typography
//             sx={
//               /posted/i.test(pathname)
//                 ? { color: "#333333", fontWeight: 1000 }
//                 : { fontWeight: 1000 }
//             }
//           >
//             Konten Selesai
//           </Typography>
//         </Button>
//       </ListItem> */}
//       </List>
//     </SubMenuWrapper>
//   </List>
// ) : null}
// {!decode.role && decode.accessStatus !== "easydrop" ? (
//   <List
//     component="div"
//     subheader={
//       <ListSubheader component="div" disableSticky>
//         Etalase
//       </ListSubheader>
//     }
//   >
//     <SubMenuWrapper>
//       <List component="div">
//         <ListItem
//           component="div"
//           sx={
//             /sample/i.test(pathname)
//               ? {
//                   background:
//                     "linear-gradient(to right, #ffffff, #333333)",
//                 }
//               : null
//           }
//         >
//           <Button
//             disableRipple
//             onClick={() => {
//               navigate(`/displays/sample`);
//             }}
//           >
//             <Storefront
//               sx={
//                 /sample/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                   : { fontWeight: 1000, mr: 1 }
//               }
//             />
//             <Typography
//               sx={
//                 /sample/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000 }
//                   : { fontWeight: 1000 }
//               }
//             >
//               List Product
//             </Typography>
//           </Button>
//         </ListItem>
//         <ListItem
//           component="div"
//           sx={
//             /request/i.test(pathname)
//               ? {
//                   background:
//                     "linear-gradient(to right, #ffffff, #333333)",
//                 }
//               : null
//           }
//         >
//           <Button
//             disableRipple
//             onClick={() => {
//               navigate(`/displays/request`);
//             }}
//           >
//             <MoveToInbox
//               sx={
//                 /request/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000, mr: 1 }
//                   : { fontWeight: 1000, mr: 1 }
//               }
//             />
//             <Typography
//               sx={
//                 /request/i.test(pathname)
//                   ? { color: "#333333", fontWeight: 1000 }
//                   : { fontWeight: 1000 }
//               }
//             >
//               Permintaan Sample
//             </Typography>
//           </Button>
//         </ListItem>
//       </List>
//     </SubMenuWrapper>
//   </List>
// ) : null}

export default SidebarMenu;
