import {
  Typography,
  Box,
  Modal,
  Zoom,
  useMediaQuery,
  LinearProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ModalLoading({ loadingUpload, text }) {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <>
      <Modal
        open={loadingUpload}
        // onClose={() => setLoadingUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={loadingUpload}>
          <Box
            sx={{
              width: matchesMobile ? "100%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              px: matchesMobile ? 2 : 10,
              py: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                pb: 4,
              }}
            >
              <Typography variant="h4">{text}</Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <LinearProgress />
            </Box>
          </Box>
        </Zoom>
      </Modal>
    </>
  );
}
