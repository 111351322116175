// const moment = require("moment");
const isAppleProduct =
  navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;

export function dateFormat(date) {
  let event = new Date();
  if (date) {
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      event = new Date(year, month, day);
    } else {
      console.error("Invalid date input");
    }
  }
  const dateNumber = event.getDate();
  const year = event.getFullYear();
  // Day name
  const day = event.getDay();
  const weekdays = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];
  const dayName = weekdays[day];
  // Month name
  const month = event.getMonth();
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];
  const monthName = months[month];

  return `${dayName}, ${dateNumber} ${monthName} ${year}`;
}

export function dateFormatReport(date) {
  const parts = date.split("-");
  const day = parseInt(parts[1]);
  const month = parseInt(parts[0]);
  const year = parseInt(parts[2]);

  const weekdays = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jumat",
    "Sabtu",
  ];
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const dayName = weekdays[new Date(year, month - 1, day).getDay()];
  const dateNumber = day;
  const monthName = months[month - 1];

  return `${dayName}, ${dateNumber} ${monthName} ${year}`;
}

export function timeFormat(date) {
  const event = new Date(date);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  return event.toLocaleTimeString("id-ID", options);
}
