import React from "react";
import { Alert, Snackbar, Stack } from "@mui/material";

const MuiAlert = React.forwardRef(function (props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snack({ open, setOpen, message, status = "success" }) {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(!open)}
      >
        <MuiAlert
          onClose={() => setOpen(!open)}
          severity={status}
          sx={{ width: "100%" }}
          size="small"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </Stack>
  );
}
