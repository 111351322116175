import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Tooltip,
  Zoom,
  Fade,
  TablePagination,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Snackbar,
  Slide,
  Divider,
  Link,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/wrong.mp3";
import { dateFormat, timeFormat } from "../../helper/DateFormat";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThisMonth } from "../../helper/custom";
import {
  Close,
  DeleteOutlined,
  EditOutlined,
  CancelOutlined,
  DoneOutlined,
  Search,
  RestoreFromTrashOutlined,
  InfoOutlined,
  EmojiEvents,
  RefreshOutlined,
  Sync,
} from "@mui/icons-material";
import { useState, useMemo, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { compress } from "compress-json";
import { read, utils, writeFile } from "xlsx";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { convertDataToQuery, getQueryUrl } from "../../helper/custom";
import {
  getOrders,
  getOrdersValidate,
  getDetailLottery,
  uploadOrder,
  deleteOrder,
  deleteOrderPermanent,
  updateOrder,
  getCheckedOrder,
  checkedOrder,
  getOrdersPrint,
  restoreOrder,
  updateStatusPackage,
  getManifest,
  updateToRetur,
} from "../../store/actions/transactionAction";
import jwt_decode from "jwt-decode";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ComponentToPrint } from "../../components/Print/Print";
// import { previousDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";
import { Waypoint } from "react-waypoint";
function BigdeliveryOrderPage() {
  const [allExpedition, setAllExpedition] = useState([
    "JNE",
    "JNT",
    "ANTER AJA",
    "NINJA",
    "ID",
    "LION",
    "SICEPAT",
    "SHOPEE",
    "LAZADA",
    "WAHANA",
    "OTHER",
  ]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingY: 2,
        height: { lg: "100vh", md: "100vh", sm: "100vh" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          backgroundColor: "#ffffff",
          mt: { lg: 0, md: 5, sm: 5, xs: 5 },
          height: "100%",
        }}
      >
        <Box
          sx={{
            // backgroundColor: "#333333",
            width: "100%",
            mb: 5,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderRadius: 0,
              mr: 2,
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              borderColor: "#333333",
              color: "#333333",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
            }}
          >
            Pilih Tanggal
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              backgroundColor: "#ab8c36",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
            }}
          >
            Download Report
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              mb: 2,
              width: "250px",
              height: "60px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "5px 0px 5px 0px rgba(0, 0, 0, 0.2) !important",
                backgroundColor: "#333333",
              }}
            >
              <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
                TOTAL
              </Typography>
            </Box>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: 2,
                borderLeft: 0,
                borderColor: "#333333",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 18,
                  fontFamily: "revert",
                }}
              >
                1100
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {allExpedition.map((e, i) => (
              <Box
                sx={{
                  backgroundColor: "#transparent",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  m: 0.5,
                  width: "200px",
                  height: "50px",
                  display: "flex",
                }}
                key={i}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "5px 0px 5px 0px rgba(0, 0, 0, 0.2) !important",
                    backgroundColor: "#333333",
                  }}
                >
                  <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
                    {e}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: 2,
                    borderLeft: 0,
                    borderColor: "#333333",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: "revert",
                    }}
                  >
                    100
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default BigdeliveryOrderPage;
