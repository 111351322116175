import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Zoom,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  FormControl,
  useMediaQuery,
  InputLabel,
  NativeSelect,
  Divider,
  Snackbar,
  Slide,
} from "@mui/material";
import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import {
  DeleteOutlined,
  EditOutlined,
  CancelOutlined,
  DoneOutlined,
  AddCircle,
  Password,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";

import {
  RegisterAdmin,
  allStaff,
  RegisterStaff,
  editSeller,
  deleteStaff,
} from "../../store/actions/sellerAction";

function AddUserPage() {
  const [addType, setAddType] = useState("");
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState("password");

  //==========

  //for decode token
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const userAccessStatus = decode.accessStatus;
  //for decode token

  //==========

  //for style
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  //for style

  //==========

  //for deleted line add
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    i: "",
    username: "",
  });
  //for deleted line add

  //==========

  //for modal
  const [openModal, setOpenModal] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  //for modal

  //==========

  //edit
  const [isEdit, setIsEdit] = useState(-1);
  const [editId, setEditId] = useState(-1);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [accessStatus, setAccessStatus] = useState("");
  const [password, setPassword] = useState("");
  //edit

  //========

  //for containing data
  const [sellerList, setSellerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorField, setErrorField] = useState([
    { email: "", username: "", password: "", wrongFormat: "" },
  ]);
  const [inputSeller, setInputSeller] = useState([
    { username: "", email: "", password: "" },
  ]);

  const [errorFieldEdit, setErrorFieldEdit] = useState({
    email: "",
    username: "",
    wrongFormat: "",
  });
  const [res, setRes] = useState({ status: "", message: "" });
  //for containing data

  //========

  //for styled
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  //for styled

  //========

  //func edit seller
  const editSubmit = async (e) => {
    try {
      e.preventDefault();
      setRes({
        ...res,
        status: "",
        message: "",
      });
      setErrorFieldEdit({
        ...errorFieldEdit,
        username: "",
        email: "",
      });
      await editSeller({
        payload: { username, email, accessStatus },
        id: editId,
      });
      let newArr = [...sellerList];
      newArr[isEdit] = { id: editId, username, email, accessStatus };
      setSellerList(newArr);
      setIsEdit(-1);
      setRes({
        ...res,
        status: "success",
        message: "Edit User Berhasil",
      });
      setShowResponse(true);
    } catch (error) {
      const message = handleError(error);
      setError(message);
      if (/username/i.test(message)) {
        setErrorFieldEdit({
          ...errorFieldEdit,
          username: message,
        });
      } else if (/email/i.test(message)) {
        setErrorFieldEdit({
          ...errorField,
          email: message,
        });
      } else {
        setErrorFieldEdit({
          ...errorField,
          email: "",
          username: "",
        });
      }
      setRes({
        ...res,
        status: "error",
        message: message === "Tidak ada koneksi" ? message : "Gagal Edit User",
      });
      setShowResponse(true);
    }
  };
  //func edit seller

  //========

  //func delete seller
  const deleteHandler = async ({ id, i }) => {
    try {
      setRes({
        ...res,
        status: "",
        message: "",
      });
      await deleteStaff(id);
      let newArr = [...sellerList];
      newArr.splice(i, 1);
      setSellerList(newArr);
      setOpenModalDelete(false);
      setSelectedUser({ id: "", i: "", username: "" });
      setRes({
        ...res,
        status: "success",
        message: "Hapus User Berhasil",
      });
      setShowResponse(true);
    } catch (error) {
      const message = handleError(error);
      setError(message);
      setRes({
        ...res,
        status: "error",
        message:
          message === "Tidak ada koneksi" ? message : "Gagal Menghapus User",
      });
      setShowResponse(true);
    }
  };
  //func delete seller

  //========

  // func add seller
  const addSellerSubmit = async (e) => {
    try {
      let temp = [];
      setRes({
        ...res,
        status: "",
        message: "",
      });
      inputSeller.forEach((e, index) => {
        // let obj = { email: "", username: "", password: "", wrongFormat: "" };
        temp.push({ email: "", username: "", password: "", wrongFormat: "" });
        if (!e.email) {
          temp[index].email = "please input field";
        } else {
          temp[index].email = "";
        }
        if (!e.username) {
          temp[index].username = "please input field";
        } else {
          temp[index].username = "";
        }
        if (!e.password) {
          temp[index].password = "please input field";
        } else {
          temp[index].password = "";
        }
        if (e.email || e.username) {
          let findIndexEmail = inputSeller.findIndex((e1) => {
            return e.email === e1.email;
          });
          let Username = inputSeller.findIndex((e1) => {
            return e.username === e1.username;
          });
          const findEmail = findIndexEmail !== index;
          const findUsername = Username !== index;
          // console.log(findEmail, index);
          // console.log(findUsername, index);
          // temp2.push(obj);

          if (findEmail) {
            temp[index].email = `duplicate email`;
          }
          if (findUsername) {
            temp[index].username = `duplicate username`;
          }
        }
      });
      if (
        temp.find((e) => {
          return e.email || e.username || e.password;
        })
      ) {
        setErrorField(temp);

        return;
      } else if (
        !temp.find((e) => {
          return e.email || e.username || e.password;
        })
      ) {
        let data;
        if (addType === "admin") data = await RegisterAdmin(inputSeller);
        if (addType === "checker") data = await RegisterStaff(inputSeller);
        data.forEach((inputSeller) => {
          setSellerList((previous) => [...previous, inputSeller]);
        });
        setOpenModal(false);
        setErrorField([
          { email: "", username: "", password: "", wrongFormat: "" },
        ]);

        setInputSeller([{ username: "", email: "", password: "" }]);
      }
      setRes({
        ...res,
        status: "success",
        message: "Tambah User Berhasil",
      });
      setShowResponse(true);
    } catch (error) {
      const message = handleError(error);
      setError(message);
      let temp = error.response.data.fieldError;

      setErrorField(temp);
      setRes({
        ...res,
        status: "error",
        message:
          message === "Tidak ada koneksi" ? message : "Gagal Menambahkan User",
      });
      setShowResponse(true);
    }
  };

  function addInput(e) {
    e.preventDefault();
    setErrorField((current) => [
      ...current,
      { email: "", username: "", password: "", wrongFormat: "" },
    ]);

    setInputSeller((current) => [
      ...current,
      { username: "", email: "", password: "" },
    ]);
  }
  // func add seller

  //========

  // func addding line in modal add
  function editInput(e, i, stat) {
    e.preventDefault();
    let tempInput = [...inputSeller];
    tempInput[i][stat] = e.target.value;
    setInputSeller(tempInput);
  }
  // func addding line in modal add

  //========

  //for get data staff
  const getStaff = async () => {
    const seller = await allStaff();
    setSellerList(seller);

    setLoading(false);
  };
  //for get data staff

  //========

  //for change passsword
  const changePassword = async (e) => {
    try {
      setRes({
        ...res,
        status: "",
        message: "",
      });
      if (!password) {
        setError("masukan password baru");
        return;
      }
      e.preventDefault();
      await editSeller({
        payload: { password },
        id: editId,
      });

      setOpenModalPassword(false);
      setError("");

      setPassword("");
      setRes({
        ...res,
        status: "success",
        message: "Ubah Password Berhasil",
      });
      setShowResponse(true);
    } catch (error) {
      const message = handleError(error);
      setError(message);
      setPassword("");
      setRes({
        ...res,
        status: "error",
        message:
          message === "Tidak ada koneksi" ? message : "Gagal Mengubah Password",
      });
      setShowResponse(true);
    }
  };
  //for change passsword

  //========

  useEffect(() => {
    getStaff();
  }, []);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [sellerList]);

  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        pb: 3,
      }}
    >
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              width: matchesMobile ? "95%" : "50%",
              height: "50%",
              backgroundColor: "white",
              paddingY: 5,
              borderRadius: 1,
            }}
          >
            <Box sx={{ height: "80%", paddingX: matchesMobile ? 0 : 4 }}>
              <TableContainer
                sx={{
                  maxHeight: "100%",
                  "&::-webkit-scrollbar": { width: 10 },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#333333",
                    borderRadius: 1,
                  },
                }}
              >
                <Table>
                  <TableBody>
                    {inputSeller.map((input, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TableCell>
                            <TextField
                              label="Username"
                              value={input.username}
                              variant="standard"
                              onChange={(e) => editInput(e, index, "username")}
                              error={errorField[index].username ? true : false}
                              helperText={
                                errorField[index].username
                                  ? errorField[index].username
                                  : ""
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="Email"
                              variant="standard"
                              type="email"
                              value={input.email}
                              onChange={(e) => editInput(e, index, "email")}
                              error={
                                errorField[index].wrongFormat ||
                                errorField[index].email
                                  ? true
                                  : false
                              }
                              helperText={
                                errorField[index].wrongFormat
                                  ? errorField[index].wrongFormat
                                  : errorField[index].email
                                  ? errorField[index].email
                                  : ""
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="password"
                              variant="standard"
                              value={input.password}
                              onChange={(e) => editInput(e, index, "password")}
                              error={errorField[index].password ? true : false}
                              helperText={
                                errorField[index].password
                                  ? errorField[index].password
                                  : ""
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                let temp = [...inputSeller];
                                if (temp.length === 1) {
                                  setOpenModal(false);

                                  setInputSeller([
                                    { username: "", email: "", password: "" },
                                  ]);
                                } else {
                                  temp.splice(index, 1);
                                  setInputSeller(temp);
                                }
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "10%",
                paddingX: 4,
              }}
            >
              <IconButton
                onClick={(e) => addInput(e)}
                sx={{
                  color: "#ab8c36",
                  backgroundColor: "transparent",
                  // border: 1,
                  // borderColor: "#ab8c36",
                  fontSize: 15,
                  "&:hover": {
                    color: "#333333",
                    // borderColor: "#333333",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AddCircle sx={{ mr: 1 }} />
                {addType === "admin" ? " Tambah Admin" : " Tambah Checker"}
              </IconButton>
              <Divider orientation="horizontal" />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                height: "10%",
                // marginY: 2,
                paddingX: 4,
                mt: 2,
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModal(false);
                  setErrorField([
                    { email: "", username: "", password: "", wrongFormat: "" },
                  ]);

                  setInputSeller([{ username: "", email: "", password: "" }]);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={(e) => addSellerSubmit(e)}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Modal
        open={openModalPassword}
        onClose={() => setOpenModalPassword(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalPassword}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h4"> Ganti Password </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
              <TextField
                id="input-with-icon-textfield"
                label="Password Baru"
                type={showPassword}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          if (showPassword === "password") {
                            setShowPassword("text");
                          } else {
                            setShowPassword("password");
                          }
                        }}
                        edge="end"
                        sx={{
                          color: "#bababa",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "#333333",
                          },
                        }}
                      >
                        {showPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              {error ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalPassword(false);
                  // setErrorField([
                  //   { email: "", username: "", password: "", wrongFormat: "" },
                  // ]);

                  // setInputSeller([{ username: "", email: "", password: "" }]);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={(e) => changePassword(e)}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Simpan
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Modal
        open={openModalDelete}
        onClose={() => setOpenModalDelete(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalDelete}>
          <Box
            sx={{
              width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography variant="h4">
                {`Apakah Kamu yakin menghapus User "${selectedUser.username}" ini ?`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalDelete(false);
                  setSelectedUser({ id: "", i: "", username: "" });
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={(e) => deleteHandler(selectedUser)}
                variant="contained"
                sx={{
                  width: "10%",
                  backgroundColor: "#bd454f",
                  "&:hover": {
                    backgroundColor: "#bd454f",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Hapus
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "20%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            flexDirection: "column",
            mt: matchesMobile ? 1 : 0,
          }}
        >
          <Box
            sx={{
              height: "30%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            {userAccessStatus === "root" ? (
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  mb: matchesMobile ? 1 : 2,
                  mr: 2,
                  backgroundColor: "#ab8c36",
                  boxShadow: matchesMobile
                    ? null
                    : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                }}
                onClick={() => {
                  setAddType("admin");
                  setOpenModal(true);
                }}
              >
                Tambah Admin
              </Button>
            ) : null}

            {userAccessStatus === "root" || userAccessStatus === "admin" ? (
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  mb: matchesMobile ? 1 : 2,
                  backgroundColor: "#ab8c36",
                  boxShadow: matchesMobile
                    ? null
                    : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                }}
                onClick={() => {
                  setAddType("checker");
                  setOpenModal(true);
                }}
              >
                Tambah Checker
              </Button>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#ffffff",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Username</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    {userAccessStatus !== "checker" ? (
                      <StyledTableCell align="center">Action</StyledTableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sellerList.length
                    ? sellerList.map((row, i) =>
                        i === isEdit ? (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor: "#33333330",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <TextField
                                value={username}
                                label="Username"
                                variant="standard"
                                onChange={(e) => setUsername(e.target.value)}
                                error={errorFieldEdit.username ? true : false}
                                helperText={
                                  errorFieldEdit.username
                                    ? errorFieldEdit.username
                                    : ""
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                value={email}
                                label="email"
                                variant="standard"
                                onChange={(e) => setEmail(e.target.value)}
                                error={errorFieldEdit.email ? true : false}
                                helperText={
                                  errorFieldEdit.email
                                    ? errorFieldEdit.email
                                    : ""
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {userAccessStatus === "root" ? (
                                <FormControl fullWidth>
                                  <InputLabel
                                    variant="standard"
                                    htmlFor="uncontrolled-native"
                                  >
                                    status
                                  </InputLabel>
                                  <NativeSelect
                                    value={accessStatus}
                                    inputProps={{
                                      name: "Status",
                                      id: "uncontrolled-native",
                                    }}
                                    onChange={(e) =>
                                      setAccessStatus(e.target.value)
                                    }
                                  >
                                    {/* {accessStatus === "root" ? (
                                    <option value={"admin"}>admin</option>
                                  ) : null} */}
                                    <option value={"admin"}>admin</option>
                                    <option value={"checker"}>checker</option>
                                  </NativeSelect>
                                </FormControl>
                              ) : (
                                accessStatus
                              )}
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                sx={{ color: "#000000", borderRight: 1 }}
                                onClick={(e) => {
                                  setIsEdit(-1);
                                }}
                              >
                                <CancelOutlined />
                              </IconButton>
                              <IconButton
                                sx={{ color: "#000000", borderRight: 1 }}
                                onClick={(e) => editSubmit(e)}
                              >
                                <DoneOutlined />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={i}>
                            <StyledTableCell>{row.username}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>
                            <StyledTableCell>
                              {row.accessStatus}
                            </StyledTableCell>
                            {userAccessStatus !== "checker" ? (
                              <StyledTableCell align="center">
                                <IconButton
                                  sx={{ borderRight: 1 }}
                                  onClick={() => {
                                    setEditId(row.id);

                                    setOpenModalPassword(true);
                                  }}
                                >
                                  <Password />
                                </IconButton>
                                <IconButton
                                  sx={{ borderRight: 1 }}
                                  onClick={() => {
                                    setErrorFieldEdit({
                                      email: "",
                                      username: "",
                                    });
                                    setIsEdit(i);
                                    setEditId(row.id);
                                    setAccessStatus(row.accessStatus);
                                    setUsername(row.username);
                                    setEmail(row.email);
                                  }}
                                >
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  sx={{ borderRight: 1 }}
                                  onClick={() => {
                                    setOpenModalDelete(true);
                                    setSelectedUser({
                                      id: row.id,
                                      i,
                                      username: row.username,
                                    });
                                  }}
                                >
                                  <DeleteOutlined />
                                </IconButton>
                              </StyledTableCell>
                            ) : null}
                          </TableRow>
                        )
                      )
                    : null}
                </TableBody>
              </Table>
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={25} sx={{ color: "#333333" }} />
                </Box>
              ) : sellerList.length === 0 ? (
                <Alert variant="standard" severity="error">
                  No Data
                </Alert>
              ) : null}
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default AddUserPage;
