import React from "react";

// material-ui
import { CardContent, Grid } from "@mui/material";

// project imports
import { Typography } from "@mui/material";
import MainCard from "../MainCard";

export default function EtalaseCardNotAvailable({ index }) {
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12} mt={5}>
        <Typography variant="h4" mb={1}>
          Etalase {index + 1}
        </Typography>
        <MainCard
          content={false}
          sx={{
            borderRadius: 0,
            color: "#bfbfbf",
            boxShadow: 0,
            "&:hover": {
              boxShadow: "none",
              marginTop: "",
              marginBottom: "",
            },
          }}
          tabIndex={-1}
          disableRipple
        >
          <CardContent
            sx={{
              p: 1,
              height: "130px",
              padding: "50px",
              backgroundColor: "#e5e5e5",
            }}
          >
            <div className="row" style={{ textAlign: "-webkit-center" }}>
              <div className="column">
                <Grid container columns={16}>
                  <Typography sx={{ color: "#959595" }}>
                    Selesaikan {(index + 1) * 31} Konten untuk membuka etalase
                  </Typography>
                </Grid>
              </div>
            </div>
          </CardContent>
        </MainCard>
      </Grid>
    </>
  );
}
