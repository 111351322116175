export const SET_SELLERS = "seller/setSeller";
export const SET_ERROR = "seller/error";

// KREATOR

export const SET_USERDATA = "userData/setUserData";
export const SET_VERIFIEDADDRESS = "verifiedAddress/setVerifiedAddress";

// Awb
export const SET_DATAONGKIRAWB = "dataOngkirAwb/setDataOngkirAwb";
export const SET_DATAAWB = "dataAwb/setDataAwb";
export const SET_DATAREADYPICKUP = "dataReadyPickup/setDataReadyPickup";
export const SET_DATAJALAN = "dataJalan/setDataJalan";

export const SET_UPLOADPROGRESS = "uploadProgress/setUploadProgress";
export const SET_PROGRESSFAILED = "progressFailed/setProgressFailed";

// Wallet
export const SET_MAINWALLET = "mainWallet/setMainWallet";
export const SET_SPAREWALLET = "spareWallet/setSpareWallet";

// universal
export const SET_ERR = "err/setErr";
export const SET_MESSAGE = "message/setMessage";
export const SET_OPENSNACK = "openSnack/setOpenSnack";
export const SET_LOADING = "loading/setLoading";
export const SET_NOTIFICATIONS = "notifications/setNotifications";

// filter and search
export const SET_FOLLOWERS = "followers/setFollowers";
export const SET_SEARCHKOL = "searchKOL/setSearchKOL";
export const SET_CATEGORY = "category/setCategory";

//auth

export const SET_VERSION = "version/set";
