import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Zoom,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  FormControl,
  useMediaQuery,
  InputLabel,
  NativeSelect,
  Divider,
  Snackbar,
  Slide,
  Chip,
  Grid,
  Badge,
  Stack,
  Rating,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getContent } from "../../../store/actions/actionKol";
import { handleError } from "../../../helper/helpers";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../../store/actions/actionAwb";
import { convertDate } from "../../../helper/convertDate";
import { ReceiptLong, ThumbsUpDown } from "@mui/icons-material";
import BayarContent from "../../../components/Modal/BayarContent";
import { useNavigate } from "react-router-dom";

function ViewProgresContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [type, setType] = useState("unpaid");
  const [loading, setLoading] = useState(true);
  const [showBayar, setShowBayar] = useState(false);
  const [dataBayar, setDataBayar] = useState(null);

  //--------------||BADGE COUNT||---------//
  const [countUnpaid, setCountUnpaid] = useState(0);
  const [countPaid, setCountPaid] = useState(0);
  const [countPending, setCountPending] = useState(0);
  const [countPromote, setCountPromote] = useState(0);

  //----------------||DECODE TOKEN||---------------//
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const userAccessStatus = decode.accessStatus;

  //---------------||STYLING||--------------//
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  //-----------||FUNCTION||-----------//
  function setCOD(proses) {
    if (proses.length) {
      return (
        <Chip
          color="success"
          label="aktif"
          size="small"
          sx={{
            backgroundColor: "#333333",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    } else {
      return (
        <Chip
          label="tidak aktif"
          size="small"
          sx={{
            backgroundColor: "#6c6d70",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
  }

  function setProses(process, status) {
    if (process === "pending") {
      return (
        <Chip
          label="Pending"
          variant="outlined"
          sx={{
            backgroundColor: "#195B66",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "accepted" && !status) {
      return (
        <Chip
          label="Menunggu Pembayaran"
          variant="outlined"
          sx={{
            backgroundColor: "#71883F",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "accepted" && status) {
      return (
        <Chip
          label="Menunggu Konten"
          variant="outlined"
          sx={{
            backgroundColor: "#c7a74d",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "etalase") {
      return (
        <Chip
          color="primary"
          label="Konten Etalase"
          variant="outlined"
          sx={{
            backgroundColor: "#AB8C36",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
  }

  useEffect(() => {
    dispatch(getContent(type, 0, 1000))
      .then(({ data }) => {
        setData(data.data);
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => setLoading(false));

    dispatch(getContent("unpaid", 1, 1))
      .then(({ data }) => {
        setCountUnpaid(data.count);
      })
      .catch((_) => {});

    dispatch(getContent("paid", 1, 1))
      .then(({ data }) => {
        setCountPaid(data.count);
      })
      .catch((_) => {});

    dispatch(getContent("etalase", 1, 1))
      .then(({ data }) => {
        setCountPromote(data.count);
      })
      .catch((_) => {});

    dispatch(getContent("pending", 1, 1))
      .then(({ data }) => {
        setCountPending(data.count);
      })
      .catch((_) => {});
  }, [type]);

  return (
    <Container
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        pb: 3,
      }}
    >
      <BayarContent
        open={showBayar}
        setOpen={setShowBayar}
        data={dataBayar}
        setData={setDataBayar}
        dataList={data}
      />
      <Box
        sx={{
          height: "90%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "20%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            flexDirection: "column",
            mt: matchesMobile ? 1 : 0,
          }}
        >
          <Box
            sx={{
              height: "30%",
              width: "100%",
              display: "flex",
              alignItems: "end",
              gap: 1,
            }}
          >
            <Badge
              max={9}
              badgeContent={countUnpaid}
              color="error"
              sx={{
                display: "flex",
                height: "100%",
                width: { xs: "20%", md: "auto" },
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  backgroundColor: type === "unpaid" ? "#ffffff" : "#ab8c36",
                  color: type === "unpaid" ? "#000000" : null,
                  "&:hover": {
                    backgroundColor: matchesMobile ? "#ffffff" : "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                  fontSize: { md: 13, xs: 10 },
                }}
                onClick={() => {
                  setType("unpaid");
                }}
              >
                Menunggu Pembayaran
              </Button>
            </Badge>

            <Badge
              max={9}
              badgeContent={countPaid}
              color="error"
              sx={{
                display: "flex",
                height: "100%",
                width: { xs: "20%", md: "auto" },
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  backgroundColor: type === "paid" ? "#ffffff" : "#ab8c36",
                  color: type === "paid" ? "#000000" : null,
                  "&:hover": {
                    backgroundColor: matchesMobile ? "#ffffff" : "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                  fontSize: { md: 13, xs: 10 },
                }}
                onClick={() => {
                  setType("paid");
                }}
              >
                Menunggu Konten
              </Button>
            </Badge>

            <Badge
              max={9}
              badgeContent={countPending}
              color="error"
              sx={{
                display: "flex",
                height: "100%",
                width: { xs: "20%", md: "auto" },
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  backgroundColor: type === "pending" ? "#ffffff" : "#ab8c36",
                  color: type === "pending" ? "#000000" : null,
                  "&:hover": {
                    backgroundColor: matchesMobile ? "#ffffff" : "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                  fontSize: { md: 13, xs: 10 },
                }}
                onClick={() => {
                  setType("pending");
                }}
              >
                Pending
              </Button>
            </Badge>

            <Badge
              max={9}
              badgeContent={countPromote}
              color="error"
              sx={{
                display: "flex",
                height: "100%",
                width: { xs: "20%", md: "auto" },
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 0,
                  height: "100%",
                  backgroundColor: type === "etalase" ? "#ffffff" : "#ab8c36",
                  color: type === "etalase" ? "#000000" : null,
                  "&:hover": {
                    backgroundColor: matchesMobile ? "#ffffff" : "#333333",
                    boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                  },
                  fontSize: { md: 13, xs: 10 },
                }}
                onClick={() => {
                  setType("etalase");
                }}
              >
                Konten Etalase
              </Button>
            </Badge>
            {/* <Badge
              max={9}
              badgeContent={countPromote}
              color="error"
              sx={{
                display: "flex",
                height: "100%",
                width: { xs: "20%", md: "auto" },
              }}
            > */}
            <Button
              size="small"
              variant="contained"
              sx={{
                borderRadius: 0,
                height: "100%",
                backgroundColor: type === "done" ? "#ffffff" : "#ab8c36",
                color: type === "done" ? "#000000" : null,
                "&:hover": {
                  backgroundColor: matchesMobile ? "#ffffff" : "#333333",
                  boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                },
                fontSize: { md: 13, xs: 10 },
              }}
              onClick={() => {
                setType("done");
              }}
            >
              Konten Selesai
            </Button>
            {/* </Badge> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#ffffff",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          {/* <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          > */}
          <TableContainer sx={{ maxHeight: "100%" }}>
            {type === "done" ? (
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tanggal Request</StyledTableCell>
                    <StyledTableCell>Kode Konten</StyledTableCell>
                    <StyledTableCell>Nama Kreator</StyledTableCell>
                    <StyledTableCell>Link Video</StyledTableCell>
                    <StyledTableCell>Rating</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell>{convertDate(row.createdAt)}</TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.kol_name}</TableCell>
                      <TableCell>
                        <a href={row.url_video} target="_blank">
                          {row.url_video?.length > 40
                            ? row.url_video.substring(0, 41) + "..."
                            : row.url_video}
                        </a>
                      </TableCell>
                      <TableCell>
                        {+row.rating === 0 ? (
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              width: "80%",
                              backgroundColor: "#E3A857",

                              color: "#000000",
                              borderRadius: 0,
                              "&:hover": {
                                backgroundColor: "#FFE135",
                                borderColor: "#333333",
                                color: "#000000",
                              },
                            }}
                            startIcon={<ThumbsUpDown />}
                            onClick={() => {
                              navigate(`/kol/rating?kontenId=${row.id}`);
                            }}
                          >
                            Berikan Rating
                          </Button>
                        ) : (
                          <Rating
                            name="read-only"
                            value={row.rating}
                            readOnly
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    {type === "unpaid" ? (
                      <StyledTableCell>Action</StyledTableCell>
                    ) : null}
                    <StyledTableCell>Tanggal Request</StyledTableCell>
                    <StyledTableCell>Kode Konten</StyledTableCell>
                    <StyledTableCell>Nama Kreator</StyledTableCell>
                    <StyledTableCell>COD (Cash on Delivery)</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Proses</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  background={
                    data?.length === 0 ? "rgb(255, 238, 241)" : "none"
                  }
                >
                  {data.map((row, i) => (
                    <TableRow key={i}>
                      {row.approval_status === "accepted" &&
                      !row.payment_status ? (
                        <TableCell>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              width: "100%",
                              backgroundColor: "#C0564A",

                              color: "#ffffff",
                              borderRadius: 0,
                              "&:hover": {
                                backgroundColor: "#333333",
                                borderColor: "#333333",
                                color: "#ffffff",
                              },
                            }}
                            endIcon={<ReceiptLong />}
                            onClick={() => {
                              setShowBayar(true);
                              setDataBayar(row);
                            }}
                          >
                            Bayar
                          </Button>
                        </TableCell>
                      ) : null}
                      <TableCell>{convertDate(row.createdAt)}</TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.kol_name}</TableCell>
                      <TableCell>
                        {setCOD(row?.Addon ? row.Addon.cod : "")}
                      </TableCell>
                      <TableCell>{row.Sample ? "Ada Sample" : ""}</TableCell>
                      <TableCell>
                        {setProses(row.approval_status, row.payment_status)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}

            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={25} sx={{ color: "#333333" }} />
              </Box>
            ) : data?.length === 0 ? (
              <Alert
                variant="standard"
                severity="error"
                sx={{ borderRadius: 0, justifyContent: "center" }}
              >
                No Data
              </Alert>
            ) : null}
          </TableContainer>
        </Box>
        {/* </Box> */}
      </Box>
    </Container>
  );
}

export default ViewProgresContent;
