import React, { useEffect } from "react";
import EtalaseCardAvailable from "./EtalaseCard-Available";
import EtalaseCardFilled from "./EtalaseCard-Filled";
import EtalaseCardNotAvailable from "./EtalaseCard-NotAvailable";

// project imports

export default function EtalaseCard({
  setOpenModalListKol,
  setOpenModalInputOrEditEtalase,
  index,
  setEvent,
  setIndex,
  thisMonthEtalase,
  totalContentUser,
}) {
  return (
    <>
      {thisMonthEtalase?.display_number === index + 1 &&
      totalContentUser >= (index + 1) * 31 ? (
        <EtalaseCardFilled
          setOpenModalListKol={setOpenModalListKol}
          setOpenModalInputOrEditEtalase={setOpenModalInputOrEditEtalase}
          index={index}
          setEvent={setEvent}
          setIndex={setIndex}
          thisMonthEtalase={thisMonthEtalase}
        />
      ) : null}
      {!thisMonthEtalase && totalContentUser >= (index + 1) * 31 ? (
        <EtalaseCardAvailable
          setOpenModalInputOrEditEtalase={setOpenModalInputOrEditEtalase}
          index={index}
          setEvent={setEvent}
          setIndex={setIndex}
        />
      ) : null}
      {!thisMonthEtalase?.length && totalContentUser < (index + 1) * 31 ? (
        <EtalaseCardNotAvailable index={index} />
      ) : null}
    </>
  );
}
