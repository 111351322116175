import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconButton,
  InputAdornment,
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { handleError } from "../../helper/helpers";
import { login } from "../../store/actions/sellerAction";
import { ThisMonth } from "../../helper/custom";
import logoBigPOS from "../../image/logo_bigpos.png";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { CheckAuth } from "../../auth";

export default function SignIn() {
  const navigate = useNavigate();
  const thisMonth = ThisMonth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showResponse, setShowResponse] = useState(false);
  const [res, setRes] = useState({ status: "", message: "" });
  const [role, setRole] = useState("easydrop");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      setLoading(true);
      let dataLogin = await login({
        username_email: data.get("email"),
        password: data.get("password"),
        role,
      });
      if (dataLogin) {
        await CheckAuth();
        const token = localStorage.getItem("access_token");
        const decode = jwt_decode(token);
        setRes({ status: "success", message: "Login Berhasil" });
        setShowResponse(true);
        setLoading(false);
        setError("");
        if (
          decode.accessStatus === "easydrop" &&
          !decode?.userInternalId?.length
        ) {
          return navigate(
            `/easy-drop/scan?limit=10&page=1&minDate=${thisMonth.startDate}&maxDate=${thisMonth.endDate}`
          );
        } else if (
          decode.accessStatus !== "easydrop" &&
          !decode?.userInternalId?.length
        ) {
          return navigate(
            `/order?status=all&limit=10&page=1&minDate=${
              thisMonth.startDate
            }&maxDate=${thisMonth.endDate}&ShopId=${
              decode.role ? decode.users[0].ShopId : 0
            }`
          );
        } else {
          return navigate("/premium/dashboard");
        }
      }
    } catch (err) {
      setRes({ status: "error", message: "Login Gagal" });
      setShowResponse(true);
      setLoading(false);
      const message = handleError(err);
      setError(message);
    }
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <Container
      sx={{ width: "100vw", height: "100vh", backgroundColor: "#333333" }}
      maxWidth={false}
    >
      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <CssBaseline />

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          component="img"
          sx={{
            height: "12%",
            width: "auto",
            mr: 1,
            // maxHeight: { xs: 233, md: 167 },
            // maxWidth: { xs: 350, md: 250 },
          }}
          src={logoBigPOS}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xl: "30%", lg: "30%", md: "30%", sm: "50%", xs: "80%" },
            color: "white",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: changeBackgroundColor }}>
            <LockOutlined sx={{ color: "#ffffff" }} />
          </Avatar> */}
          {/* <Typography component="h1" variant="h5">
            {role}
          </Typography> */}
          <Box
            sx={{
              // bgcolor: "#e8f0fe",
              mt: 2,
              width: "70%",
              margin: "1.2rem auto 0rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FormControl
              sx={{
                width: "18rem",
                bgcolor: "white",
                borderRadius: "10px",
              }}
              variant="filled"
            >
              <InputLabel>Login Sebagai</InputLabel>
              <Select
                label="Sebagai"
                // value={values.services}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value={"easydrop"}>Easy-Drop</MenuItem>
                <MenuItem value={"seller"}>Seller</MenuItem>
                <MenuItem value={"dropper"}>Mitra Bigpoint</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl sx={{ m: 1, color: "black" }}>
              <FormLabel>
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{ color: "black", mb: 1 }}
                >
                  Login Sebagai
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={role}
                onChange={(event) => {
                  setRole(event.target.value);
                }}
              >
                <FormControlLabel
                  value="seller"
                  control={<Radio color="warning" />}
                  label={
                    <>
                      <Typography component="h1" variant="h5">
                        Seller
                      </Typography>
                      <FormHelperText>
                        Login Sebagai Seller Big Delivery
                      </FormHelperText>
                    </>
                  }
                ></FormControlLabel>

                <FormControlLabel
                  value="dropper"
                  control={<Radio color="warning" />}
                  sx={{ mt: 2 }}
                  label={
                    <>
                      <Typography component="h1" variant="h5">
                        Mitra Big Point
                      </Typography>
                      <FormHelperText>
                        Login Sebagai Dropper Big Point, Jika ingin menjadi
                        Dropper silahkan Hubungi Admin Big Point
                      </FormHelperText>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl> */}
          </Box>
          {role === "seller" ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address / Username"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ bgcolor: "white" }}
                variant="filled"
                onChange={() => setError("")}
                disabled={loading}
              />
              {error === "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword}
                id="password"
                autoComplete="current-password"
                onChange={() => setError("")}
                sx={{
                  bgcolor: "white",
                }}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword === "password") {
                              setShowPassword("text");
                            } else {
                              setShowPassword("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
                disabled={loading}
              />
              {error !== "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Sign In"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/registration")}
                    component="button"
                    variant="body2"
                    sx={{ color: "white" }}
                  >
                    Registrasi
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    onClick={() => navigate("/forgetpassword")}
                    component="button"
                    variant="body2"
                    sx={{ color: "white" }}
                  >
                    Lupa Password ?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : role === "dropper" ? (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email/Username Dropper"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ bgcolor: "white" }}
                variant="filled"
                onChange={() => setError("")}
                disabled={loading}
              />
              {error === "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword}
                id="password"
                autoComplete="current-password"
                onChange={() => setError("")}
                sx={{
                  bgcolor: "white",
                }}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword === "password") {
                              setShowPassword("text");
                            } else {
                              setShowPassword("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
                disabled={loading}
              />
              {error !== "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Sign as Dropper"
                )}
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/register-dropper")}
                    component="button"
                    variant="body2"
                    sx={{ color: "white" }}
                  >
                    Registrasi
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email/Username Easy Drop"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ bgcolor: "white" }}
                variant="filled"
                onChange={() => setError("")}
                disabled={loading}
              />
              {error === "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword}
                id="password"
                autoComplete="current-password"
                onChange={() => setError("")}
                sx={{
                  bgcolor: "white",
                }}
                variant="filled"
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword === "password") {
                              setShowPassword("text");
                            } else {
                              setShowPassword("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
                disabled={loading}
              />
              {error !== "Tolong masukan Email/Username" ? (
                <Typography sx={{ color: "error.main" }}>{error}</Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Sign as Easy Drop"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/registration-easydrop")}
                    component="button"
                    variant="body2"
                    sx={{ color: "white" }}
                  >
                    Registrasi Easy Drop
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    onClick={() => navigate(`/forgetpassword?role=${role}`)}
                    component="button"
                    variant="body2"
                    sx={{ color: "white" }}
                  >
                    Lupa Password ?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
}
