import { Box, useTheme, Grid, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";

import Sidebar from "../../components/SideBar/index";
import Header from "../../components/Header/index";

import { useLocation, useSearchParams } from "react-router-dom";

const SidebarLayout = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const { pathname } = useLocation();

  const premium = /premium/i.test(pathname);
  const kol = /kol/i.test(pathname);
  const agency = /agency/i.test(pathname);
  const display = /display/i.test(pathname);
  const setting = /setting/i.test(pathname);

  return (
    <>
      <Box>
        <Box
          sx={{
            position: "absolute",
          }}
        >
          <Sidebar />
        </Box>
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            height:
              premium || kol || agency || display || setting ? "auto" : "100vh",
            // background: /kol/i.test(pathname)
            //   ? "#ffffff"
            //   : /displays/i.test(pathname)
            //   ? "#ffffff"
            //   : "#33333350",
            background: "#33333350",
            [theme.breakpoints.up("lg")]: {
              ml: `${theme.sidebar.width}`,
            },
            width: "auto",
          }}
        >
          {matches ? (
            <Box>
              <Header />
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              height: "auto",
              width: "100%",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
