import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
  Alert,
  Box,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

//global Error

//dispatcer to store
// import CardSampleKol from '../../components/CardSampleKol';
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../../store/actions/actionAwb";
import { getRequestSample } from "../../../store/actions/actionDisplay";
import { handleError } from "../../../helper/handleError";
import Snack from "../../../components/Snackbar/Snack";
import CardSampleKol from "../../../components/Etalase/CardSampleKol";

//-----------------------|| DEFAULT DASHBOARD ||-----------------------//

export default function PermintaanSample() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sampleList, setSampleList] = useState([]);

  // open snack error
  const { err, message, openSnack } = useSelector(
    (state) => state.UniversalReducer
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getRequestSample())
      .then(({ data }) => {
        setSampleList(data.data);
      })
      .catch((e) => {
        const message = handleError(e);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <Container sx={{ width: "100%", height: "100%", display: "flex", pb: 2 }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "100%", textAlignLast: "center" }}>
          <Typography variant="h1">ETALASE</Typography>
        </Box>
        <Grid container spacing={2}>
          <Snack
            open={openSnack}
            setOpen={() => dispatch(setOpenSnack(false))}
            message={message}
            status={err ? "error" : "success"}
          />
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2}>
              {loading ? (
                <Grid
                  container
                  justifyContent="center"
                  sx={{ width: "100%", mt: 2 }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={263}
                    height={144}
                    sx={{ mr: 1, mt: 2 }}
                  />
                </Grid>
              ) : sampleList.length > 0 ? (
                sampleList.map((e, i) => {
                  return <CardSampleKol key={i} i={i} sampleList={e} />;
                })
              ) : (
                <Grid container justifyContent="center" sx={{ width: "100%" }}>
                  <Typography sx={{ mt: 2 }}>
                    <Alert severity="error" sx={{ width: "100%" }}>
                      Belum Ada Permintaan Sample
                    </Alert>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
