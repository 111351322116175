import React, { useEffect, useState } from "react";

// material-ui
import { Box, Container, Grid, Typography } from "@mui/material";

// project imports
import { useDispatch, useSelector } from "react-redux";
import Snack from "../../../components/Snackbar/Snack";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../../store/actions/actionAwb";
import EtalaseCardInputOrEditProductModal from "../../../components/Etalase/EtalaseCardInputOrEditProductModal";
import EtalaseCardKolListModal from "../../../components/Etalase/EtalaseCardKolListModal";
import EtalaseCard from "../../../components/Etalase/EtalaseCard";
import { getDisplays } from "../../../store/actions/actionDisplay";
import { handleError } from "../../../helper/helpers";

//-----------------------|| WORKING SPACE ||-----------------------//

export default function ListProducts() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [triggerRefresh, setTriggerRefresh] = useState("");

  // state
  const [totalContentUser, setTotalContentUser] = useState(0);
  const [thisMonthEtalase, setThisMonthEtalase] = useState([]);
  const [openModalInputOrEditEtalase, setOpenModalInputOrEditEtalase] =
    useState(false);
  const [openModalListKol, setOpenModalListKol] = useState(false);
  const [index, setIndex] = useState(0);
  const [event, setEvent] = useState("");
  const etalaseQuantity = ["x", "x", "x", "x", "x"];
  const { err, message, openSnack } = useSelector(
    (state) => state.UniversalReducer
  );

  const SendData = (index) => {
    let dataEtalase = "";

    thisMonthEtalase.forEach((y) => {
      if (y.display_number === index + 1) {
        dataEtalase = y;
      }
    });

    return dataEtalase;
  };

  useEffect(() => {
    // const { data } = await dispatch(getDisplays());
    dispatch(getDisplays())
      .then(({ data }) => {
        setTotalContentUser(data.totalContentUser);
        setThisMonthEtalase(data.thisMonthEtalase);
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      });
  }, [triggerRefresh]);

  return (
    <Container sx={{ width: "100%", height: "100%", display: "flex", pb: 3 }}>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2}>
          <Snack
            open={openSnack}
            setOpen={() => dispatch(setOpenSnack(false))}
            message={message}
            status={err ? "error" : "success"}
          />
          <EtalaseCardInputOrEditProductModal
            openModalInputOrEditEtalase={openModalInputOrEditEtalase}
            setOpenModalInputOrEditEtalase={setOpenModalInputOrEditEtalase}
            index={index}
            event={event}
            thisMonthEtalase={SendData(index)}
            setTriggerRefresh={setTriggerRefresh}
          />
          <EtalaseCardKolListModal
            openModalListKol={openModalListKol}
            setOpenModalListKol={setOpenModalListKol}
            etalaseKolList={SendData(index)?.Addons}
          />
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          >
            <Typography variant="h1">ETALASE</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          >
            <Typography variant="h1">
              Konten bulan ini : {totalContentUser}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              {etalaseQuantity.map((x, i) => (
                <EtalaseCard
                  setOpenModalListKol={setOpenModalListKol}
                  setOpenModalInputOrEditEtalase={
                    setOpenModalInputOrEditEtalase
                  }
                  index={i}
                  setEvent={setEvent}
                  setIndex={setIndex}
                  thisMonthEtalase={SendData(i)}
                  totalContentUser={totalContentUser}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
