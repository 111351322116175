import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff, LockOutlined } from "@mui/icons-material";
import { handleError } from "../../helper/helpers";

import {
  checkVerification,
  forgetPassword,
  changePassword,
} from "../../store/actions/sellerAction";
import { getQueryUrl } from "../../helper/custom";

export default function ForgetPassword() {
  const { search } = useLocation();

  // changing view
  const [boxEmail, setBoxEmail] = useState(true);
  const [boxVerification, setBoxVerification] = useState(false);
  const [boxPassword, setBoxPassword] = useState(false);

  // show password
  const [showPassword1, setShowPassword1] = useState("password");
  const [showPassword2, setShowPassword2] = useState("password");

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [verificationCode, setverificationCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");
  const [checkError, setCheckError] = useState("");
  const [errorMessage, setCheckErrorMessage] = useState("");
  const [changeBackgroundColor, setChangeBackgroundColor] = useState("#333333");

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    try {
      e.preventDefault();
      setCheckError("");
      setLoading(true);
      const response = await forgetPassword({ email, ...query });

      if (response) {
        setLoading(false);
        setBoxVerification(true);
        setBoxEmail(false);
      }
    } catch (err) {
      setLoading(false);
      const message = handleError(err);
      setCheckError(message);
    }
  };

  const handleVerificationSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setCheckError("");
      const response = await checkVerification({ verificationCode });
      if (response.message !== "wrong verification code") {
        setLoading(false);
        setverificationCode(response.verificationCode);
        setEmail(response.email);
        setBoxVerification(false);
        setBoxPassword(true);
      }
    } catch (err) {
      setLoading(false);
      const message = handleError(err);
      if (message.cancel) return true;
      setCheckError(message);
    }
  };

  const handlePasswordSubmit = async (e) => {
    try {
      e.preventDefault();
      setCheckError("");

      if (!password) {
        setCheckErrorMessage("password tidak boleh kosong");

        return;
      } else {
        setCheckErrorMessage("");
      }
      setLoading(true);

      let response = await changePassword({
        newPassword: password,
        newRepassword: rePassword,
        verificationCode,
        email,
      });

      setCheckErrorMessage("");

      if (
        response.message !== "password tidak sama" &&
        response.mesage !== "password tidak boleh kosong"
      ) {
        setLoading(false);
        setBoxVerification(false);
        setBoxPassword(true);
        setCheckError("");
        navigate("/login");
      }
    } catch (err) {
      setLoading(false);
      const message = handleError(err);
      if (message.cancel) return true;
      setCheckError(message);
    }
  };

  return (
    <Container
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#333333",
      }}
      maxWidth={false}
    >
      <CssBaseline />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xl: "30%", lg: "30%", md: "50%", sm: "50%", xs: "80%" },
            color: "white",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: changeBackgroundColor }}>
            <LockOutlined sx={{ color: "#ffffff" }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forget Password
          </Typography>

          {/* show email box */}
          {boxEmail ? (
            <Box
              component="form"
              onSubmit={handleEmailSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                sx={{ backgroundColor: "#ffffff" }}
                variant="filled"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                disabled={loading}
              />
              {checkError ? (
                <Typography sx={{ color: "error.main" }}>
                  {checkError}
                </Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                onMouseOver={() => {
                  setChangeBackgroundColor("#ab8c36");
                }}
                onMouseOut={() => {
                  setChangeBackgroundColor("#333333");
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Send Verification Code"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/login")}
                    component="button"
                    variant="body2"
                    sx={{ color: "#ffffff" }}
                  >
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {/* show verification box */}
          {boxVerification ? (
            <Box
              component="form"
              onSubmit={handleVerificationSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                  Kami sudah mengirimkan kode verifikasi, Tolong periksa email
                  Anda
                </Typography>
              </Box>
              <TextField
                margin="normal"
                required
                fullWidth
                id="verificationCode"
                label="Verification Code"
                name="verificationCode"
                variant="filled"
                sx={{ backgroundColor: "#ffffff" }}
                inputProps={{
                  maxLength: 6,
                  style: { textAlign: "center", textTransform: "uppercase" },
                }}
                onChange={(e) =>
                  setverificationCode(e.target.value.toUpperCase())
                }
                autoFocus
                autoComplete="off"
                disabled={loading}
              />
              {checkError ? (
                <Typography sx={{ color: "error.main" }}>
                  {checkError}
                </Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                onMouseOver={() => {
                  setChangeBackgroundColor("#ab8c36");
                }}
                onMouseOut={() => {
                  setChangeBackgroundColor("#333333");
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Konfirmasi"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/login")}
                    component="button"
                    variant="body2"
                    sx={{ color: "#ffffff" }}
                  >
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {/* show password box */}
          {boxPassword ? (
            <Box
              component="form"
              onSubmit={handlePasswordSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="password1"
                label="Your New Password"
                type={showPassword1}
                name="password1"
                variant="filled"
                sx={{ backgroundColor: "#ffffff" }}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword1 === "password") {
                              setShowPassword1("text");
                            } else {
                              setShowPassword1("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword1 === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
                disabled={loading}
              />
              {errorMessage ? (
                <Typography sx={{ color: "error.main" }}>
                  {errorMessage}
                </Typography>
              ) : null}
              <TextField
                margin="normal"
                required
                fullWidth
                id="password2"
                label="Write Again Your New Password"
                type={showPassword2}
                name="password2"
                variant="filled"
                sx={{ backgroundColor: "#ffffff" }}
                onChange={(e) => setRepassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword2 === "password") {
                              setShowPassword2("text");
                            } else {
                              setShowPassword2("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword2 === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
                disabled={loading}
              />
              {checkError ? (
                <Typography sx={{ color: "error.main" }}>
                  {checkError}
                </Typography>
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  color: "#ffffff",
                  backgroundColor: "#ab8c36",
                  height: "1.3cm",
                  borderRadius: 0,
                  "&:hover": {
                    backgroundColor: "#333333",
                    border: 1,
                    borderColor: "#ffffff",
                  },
                }}
                onMouseOver={() => {
                  setChangeBackgroundColor("#ab8c36");
                }}
                onMouseOut={() => {
                  setChangeBackgroundColor("#333333");
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fed03d" }} />
                ) : (
                  "Konfirmasi"
                )}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => navigate("/login")}
                    component="button"
                    variant="body2"
                    sx={{ color: "#ffffff" }}
                  >
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
}
