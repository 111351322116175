import { Container, Typography, Box } from "@mui/material";

export default function DashboardPage() {
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        paddingY: 3,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography>Dashboard</Typography>
      </Box>
    </Container>
  );
}
