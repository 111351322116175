import { useContext, useState, useMemo } from "react";

import {
  Box,
  Stack,
  Divider,
  IconButton,
  Tooltip,
  styled,
  // FormControl,
  // TextField,
  // InputAdornment,
} from "@mui/material";
import {
  MenuTwoTone,
  // CancelOutlined,
  // Search,
  // CloseTwoTone,
} from "@mui/icons-material";
import { SidebarContext } from "../../contexts/SidebarContext";
// import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
// import { convertDataToQuery, getQueryUrl } from "../../helper/custom";
// import { ThisMonth } from "../../helper/custom";

// import HeaderMenu from "./Menu";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        z-index: 6;
        background-color: "white";
        position: fixed;
        right:0;
        justify-content: space-between;
        width: auto;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  // const [searchParams] = useSearchParams();
  // const params = Object.fromEntries([...searchParams]);
  // const [searchValue, setSearchValue] = useState(params.search || "");
  // const { search } = useLocation();
  // const navigate = useNavigate();
  // const push = (payload) => {
  //   navigate(`/premium/report${convertDataToQuery(payload)}`);
  // };
  // const query = useMemo(() => {
  //   if (!search) return null;
  //   return getQueryUrl(search);
  // }, [search]);
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        {/* <HeaderMenu /> */}
      </Stack>
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            // ml: 2,
            // display: { lg: "none", xs: "" },
          }}
        >
          <Tooltip arrow title="Buka Menu">
            <IconButton
              onClick={toggleSidebar}
              sx={{
                // background: "black",
                color: "black",
                "&:hover": {
                  // background: "black",
                  color: "black",
                },
              }}
            >
              {!sidebarToggle ? <MenuTwoTone fontSize="small" /> : null}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
