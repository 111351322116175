import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  Item,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import moment from "moment";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
// import exResi from "../../image/image.png";

export default function PopUpResi({ isOpen, setIsOpen, data }) {
  // console.log(data);

  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const DownloadResi = async (e) => {
    e.preventDefault();
    try {
      const dom = document.getElementById("print");
      // setLoading(true);
      const dataUrl = await toPng(dom);
      const img = new Image();
      img.crossOrigin = "annoymous";
      img.src = dataUrl;
      img.onload = () => {
        // Initialize the PDF.
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "in",
          format: [5.5, 6.2],
        });

        // Define reused data
        const imgProps = pdf.getImageProperties(img);
        const imageType = imgProps.fileType;
        const pdfWidth = pdf.internal.pageSize.getWidth();

        // Calculate the number of pages.
        const pxFullHeight = imgProps.height;
        const pxPageHeight = Math.floor((imgProps.width * 8.5) / 5.5);
        const nPages = Math.ceil(pxFullHeight / pxPageHeight);

        // Define pageHeight separately so it can be trimmed on the final page.
        let pageHeight = pdf.internal.pageSize.getHeight();

        // Create a one-page canvas to split up the full image.
        const pageCanvas = document.createElement("canvas");
        const pageCtx = pageCanvas.getContext("2d");
        pageCanvas.width = imgProps.width;
        pageCanvas.height = pxPageHeight;

        for (let page = 0; page < nPages; page++) {
          // Trim the final page to reduce file size.
          if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
            pageCanvas.height = pxFullHeight % pxPageHeight;
            pageHeight = (pageCanvas.height * pdfWidth) / pageCanvas.width;
          }
          // Display the page.
          const w = pageCanvas.width;
          const h = pageCanvas.height;
          pageCtx.fillStyle = "white";
          pageCtx.fillRect(0, 0, w, h);
          pageCtx.drawImage(img, 0, page * pxPageHeight, w, h, 0, 0, w, h);

          // Add the page to the PDF.
          if (page) pdf.addPage();

          const imgData = pageCanvas.toDataURL(`image/Rp{imageType}`, 1);
          pdf.addImage(imgData, imageType, 0, 0, pdfWidth, pageHeight);
        }
        // Output / Save
        pdf.save(`${data.awb}.pdf`);
      };
    } catch (e) {
      console.log(e);
    }
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      aria-labelledby="modal-popup"
      aria-describedby="modal-popup-description"
    >
      <div className="absolute rounded-xl inset-0 max-w-screen-sm mx-4 my-auto lg:mx-auto bg-white max-h-[75%] lg:max-h-[85%] outline-none p-2">
        <div className="w-full h-full border border-black relative" id="print">
          <h1 className="text-2xl lg:text-5xl p-2 border-b border-black text-center font-bold mx-3">
            {data.originCode?.slice(0, 3)} - {data.destinationCode?.slice(0, 3)}
          </h1>
          <div className="px-2 py-3 flex justify-center border-b border-black mx-3">
            <img
              src={`${data.qrPath}`}
              alt="qrCode"
              className="max-w-[80%] mx-auto"
            />
          </div>
          <div className="text-xs lg:text-base p-3">
            <h4>
              Pengirim : {data.shipperName}, {data.shipperPhone}
            </h4>
            <h4>
              {data.shipperCity}, {data.shipperAddress}
            </h4>
          </div>
          <div className="text-xs lg:text-base px-3 font-bold">
            <h4>
              Penerima : {data.receiverName}, {data.receiverPhone}
            </h4>
            <h4>
              {data.receiverCity}, {data.receiverAddress}
            </h4>
          </div>
          <div className="grid grid-cols-8 text-[10px] lg:text-sm text-center p-3">
            <div className="border border-r-0 border-black col-span-2 flex items-center justify-center font-semibold">
              NON COD
            </div>
            <div className="border border-r-0 border-black flex items-center justify-center">
              {data.weight} KG
            </div>
            <div className="border border-r-0 border-black flex items-center justify-center">
              Tunai
            </div>
            <div className="border border-r-0 border-black text-[11px] lg:text-sm px-[1px] col-span-2">
              Ship: {moment(data.createdAt).format("DD-MM-YYYY")}
            </div>
            <div className="border border-black text-[11px] lg:text-sm px-[1px] col-span-2">
              Exp: {moment(data.createdAt).add(3, "days").format("DD-MM-YYYY")}
            </div>
            <div className="text-sm lg:text-lg font-bold col-span-2 border border-black border-t-0 border-r-0 py-5">
              Total Biaya
              <br />
              IDR {Number(data.amount).toLocaleString().replaceAll(",", ".")}
            </div>
            <div className="col-span-6 border flex-col border-black border-t-0 row-span-2 flex px-1 py-1 text-[7px] lg:text-xs">
              <div className="text-left">
                Qty : {data.qty} pcs, Barang : {data.goodsName} <br />
                Note : {data.goodsDesc}
              </div>
              <p className="text-left mt-2">
                <b>
                  Syarat dan ketentuan pengiriman dapat dilihat pada website
                  www.jne.co.id
                </b>
                <br /> PT. GLOBAL BINTANG TIMUR EXPRESS. 80.7717756.006.000
                ALTRA BUSSINESS PARK LT.8 JL. YOS SUDARSO KAV. 85 BLOCK H NO.
                1245, SUMBER JAYA TANJUNG PRIOK. JAKARTA TIMUR - DKI JAKARTA
              </p>
            </div>
            <div className="bg-gray-800 col-span-2 text-white text-[7px] lg:text-xs flex items-center justify-center">
              Lembar Pengirim
            </div>
          </div>
          <div className="flex justify-end pb-2 border-b border-black mx-3">
            <img
              src={`${data.qrPath}`}
              alt="qrCode"
              className="max-w-[30%] lg:max-w-[45%]"
            />
          </div>
          <div className="text-[9px] px-3 pt-1 lg:text-lg lg:pt-3">
            <h4>
              Pengirim: {data.shipperName}, {data.shipperPhone}
            </h4>
            <h4>
              Penerima: {data.receiverName}, {data.receiverPhone}
            </h4>
            <h4>{data.receiverAddress}</h4>
          </div>
        </div>
        <Button
          onClick={DownloadResi}
          variant="contained"
          sx={{
            marginX: 1,
            borderRadius: 10,
            // height: "100%",
            maxWidth: "50%",
            width: "9rem",
            // maxHeight: "10%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            bottom: matchMobile ? "-12%" : "-7%",
            left: "50%",
            backgroundColor: "#ab8c36",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
            "&:hover": {
              boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
              backgroundColor: "#333333",
            },
          }}
        >
          Cetak Resi
        </Button>
      </div>
    </Modal>
  );
}
