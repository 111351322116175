import {
  Box,
  Tooltip,
  Badge,
  tooltipClasses,
  styled,
  useTheme,
} from "@mui/material";
import logo from "../../image/logo_bigpos.png";
import logoDropper from "../../image/newLogo(color).png";
import jwt_decode from "jwt-decode";

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Logo() {
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const theme = useTheme();

  return (
    <TooltipWrapper title="BIGPOS" arrow>
      <Badge
        sx={{
          ".MuiBadge-badge": {
            fontSize: theme.typography.pxToRem(11),
            right: -2,
            top: 8,
          },
        }}
        overlap="circular"
        color="success"
      >
        {decode.role ? (
          <LogoSignWrapper>
            <img className="min-w-[7rem]" src={logoDropper} alt="logoDropper" />
          </LogoSignWrapper>
        ) : (
          <LogoSignWrapper>
            <img className="min-w-[9rem]" src={logo} alt="logo" />
          </LogoSignWrapper>
        )}
      </Badge>
    </TooltipWrapper>
  );
}

export default Logo;
