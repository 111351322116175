import React, { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  useTheme,
  Typography,
  styled,
} from "@mui/material";

//Icon
import CloseIcon from "@mui/icons-material/Close";

// project imports
import { useDispatch } from "react-redux";
import { categories } from "../../helper/values";
import { postEtalase, putEtalase } from "../../store/actions/actionDisplay";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../store/actions/actionAwb";
import { handleError } from "../../helper/helpers";
import { LoadingInButton } from "../Loading";

//-----------------------|| WORKING SPACE ||-----------------------//

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: "10px",
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function EtalaseCardInputOrEditProductModal({
  openModalInputOrEditEtalase,
  setOpenModalInputOrEditEtalase,
  index,
  event,
  thisMonthEtalase,
  setTriggerRefresh,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // state
  const [values, setValues] = useState({ item_sample: false });

  function handleCreate(e) {
    e.preventDefault();
    setLoading(true);
    dispatch(postEtalase({ ...values, display_number: index + 1 }))
      .then(() => {
        setTriggerRefresh(`create etalase ${index + 1}`);
        dispatch(setErr(false));
        dispatch(
          setMessage(`Berhasil Menambahkan Produk ke Etalase ${index + 1}`)
        );
        dispatch(setOpenSnack(true));
        setOpenModalInputOrEditEtalase(false);
        setValues({ item_sample: false });
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleEdit(e) {
    e.preventDefault();
    setLoading(true);
    dispatch(
      putEtalase({
        ...values,
        item_sample: thisMonthEtalase?.item_sample
          ? thisMonthEtalase?.item_sample
          : values.item_sample,
        display_number: index + 1,
        id: thisMonthEtalase.id,
      })
    )
      .then(() => {
        setTriggerRefresh(`edit etalase ${index + 1}, ${new Date()}`);
        dispatch(setErr(false));
        dispatch(
          setMessage(`Berhasil Mengubah Produk ke Etalase ${index + 1}`)
        );
        dispatch(setOpenSnack(true));
        setOpenModalInputOrEditEtalase(false);
        setValues({ item_sample: false });
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // useEffect(() => console.log(thisMonthEtalase));

  return (
    <Modal open={openModalInputOrEditEtalase}>
      <Box
        sx={{
          ...style,
          height: "auto",
          width: { xs: "90vw", sm: "50vw", md: "35vw" },
          padding: "26px",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Data Produk Untuk Etalase {index + 1}
        </Typography>
        <form
          onSubmit={(e) => {
            if (event === "edit") {
              handleEdit(e);
            } else {
              handleCreate(e);
            }
          }}
        >
          <CardContent>
            <Box>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setValues({ item_sample: false });
                  setOpenModalInputOrEditEtalase(false);
                }}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              multiline
              sx={{ width: "100%", mb: 1 }}
              value={values?.item_name}
              onChange={(e) =>
                setValues({ ...values, item_name: e.target.value })
              }
              required
              label="Nama Produk"
              name="item_name"
              variant="outlined"
              size="small"
              placeholder="Masukan Nama Produk yang akan dijual"
              defaultValue={event === "edit" ? thisMonthEtalase?.item_name : ""}
            />
            <FormControl sx={{ width: "100%", mb: 1 }} size="small">
              <InputLabel id="demo-select-small">Pilih Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Pilih Category"
                onChange={(e) => {
                  setValues({ ...values, item_category: e.target.value });
                }}
                defaultValue={
                  event === "edit" ? thisMonthEtalase?.item_category : ""
                }
              >
                {categories.map((categoryList) => (
                  <MenuItem key={categoryList} value={categoryList}>
                    {categoryList}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              multiline
              disabled={event === "edit"}
              sx={{ width: "100%", mb: 1 }}
              value={values?.display_link_product}
              onChange={(e) =>
                setValues({ ...values, display_link_product: e.target.value })
              }
              required
              label="Link Produk"
              name="display_link_product"
              variant="outlined"
              size="small"
              placeholder="Masukan Link Produk"
              defaultValue={
                event === "edit" ? thisMonthEtalase?.display_link_product : ""
              }
            />
            <TextField
              multiline
              sx={{ width: "100%", mb: 1 }}
              value={values?.item_selling_point}
              onChange={(e) =>
                setValues({ ...values, item_selling_point: e.target.value })
              }
              required
              label="Kelebihan produk"
              name="url"
              variant="outlined"
              size="large"
              placeholder="Anti Air, Awet, Kapasitas Besar, UV Protection, dll"
              defaultValue={
                event === "edit" ? thisMonthEtalase?.item_selling_point : ""
              }
            />
            <TextField
              sx={{ width: "100%", mb: 1 }}
              value={values?.item_commission}
              onChange={(e) => {
                setValues({ ...values, item_commission: +e.target.value });
              }}
              onBlur={(e) => {
                if (e.target.value < 5) {
                  setValues({ ...values, item_commission: 5 });
                } else if (e.target.value > 50) {
                  setValues({ ...values, item_commission: 50 });
                } else {
                  setValues({ ...values, item_commission: +e.target.value });
                }
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              required
              variant="outlined"
              size="small"
              type="number"
              label="Komisi"
              placeholder="Min : 5, Max : 50"
              className="w-100 mt-3"
              defaultValue={
                event === "edit" ? thisMonthEtalase?.item_commission : null
              }
            />
            <TextField
              multiline
              sx={{ width: "100%", mb: 1 }}
              value={values?.item_notes}
              onChange={(e) =>
                setValues({ ...values, item_notes: e.target.value })
              }
              variant="outlined"
              size="large"
              label="Notes"
              className="w-100 mt-3"
              placeholder="Deskripsikan hastag, Nama Toko, dan informasi penjualan anda di TikTok"
              defaultValue={
                event === "edit" ? thisMonthEtalase?.item_notes : ""
              }
            />

            <FormLabel component="legend" sx={{ mb: 1 }}>
              COD (Cash on Delivery)
            </FormLabel>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Typography>Off</Typography>
              <AntSwitch
                className="item_cod"
                onChange={(e) =>
                  setValues({ ...values, item_cod: e.target.checked })
                }
                name="item_cod"
                inputProps={{ "aria-label": "ant design" }}
                defaultChecked={
                  event === "edit" ? thisMonthEtalase?.item_cod : false
                }
              />
              <Typography>On</Typography>
            </Stack>

            <FormLabel component="legend" sx={{ mb: 1 }}>
              Menyediakan Sample
            </FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Tidak</Typography>
              <AntSwitch
                disabled={thisMonthEtalase?.SisaSample?.length}
                onChange={(e) => {
                  if (!thisMonthEtalase?.item_sample) {
                    setValues({ ...values, item_sample: e.target.checked });
                  }
                }}
                name="item_sample"
                inputProps={{ "aria-label": "ant design" }}
                defaultChecked={
                  event === "edit" ? thisMonthEtalase?.item_sample : false
                }
              />
              <Typography>Iya</Typography>

              {(event === "input" && values?.item_sample) ||
              (event === "edit" && thisMonthEtalase?.item_sample) ||
              (event === "edit" &&
                !thisMonthEtalase?.item_sample &&
                values?.item_sample) ? (
                <TextField
                  sx={{ width: "20%", mb: 2 }}
                  value={values?.item_sample_quantity}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      item_sample_quantity: +e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    if (
                      e.target.value <
                      (1 || thisMonthEtalase?.SisaSample?.length)
                    ) {
                      setValues({
                        ...values,
                        item_sample_quantity: thisMonthEtalase?.SisaSample
                          ?.length
                          ? thisMonthEtalase?.SisaSample?.length
                          : 1,
                      });
                    } else if (e.target.value > 5) {
                      setValues({ ...values, item_sample_quantity: 5 });
                    } else {
                      setValues({
                        ...values,
                        item_sample_quantity: +e.target.value,
                      });
                    }
                  }}
                  variant="outlined"
                  size="small"
                  label="Jumlah"
                  className="w-100 mt-3"
                  type="number"
                  placeholder="Jumlah Sample yang dapat diberikan Min 1 Max 5"
                  defaultValue={
                    event === "edit"
                      ? thisMonthEtalase?.item_sample_quantity
                      : 1
                  }
                />
              ) : null}
            </Stack>

            <Button
              variant="contained"
              size="medium"
              sx={{
                backgroundColor: "#ab8c36",
                color: "white",
                marginTop: "15px",
                width: "100%",
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: "#333333",
                  borderColor: "#333333",
                },
              }}
              type="submit"
            >
              {loading ? (
                <LoadingInButton />
              ) : event === "input" ? (
                "Input ke Etalase"
              ) : (
                "Edit Etalase"
              )}
            </Button>
          </CardContent>
        </form>
      </Box>
    </Modal>
  );
}
