import { jk_gtw } from "../../apis";
import { handleError } from "./../../helper/handleError";
import { SET_NOTIFICATIONS } from "../keys";
import { setErr, setMessage, setOpenSnack } from "./actionAwb";
// import axios from "axios";

export function createContent(payload) {
  return function () {
    return jk_gtw.post(`/content`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getContent(type, page, perPage) {
  return function () {
    return jk_gtw.get(
      `/content?perPage=${perPage ? perPage : 20}&page=${page}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
  };
}

export function getContentById(id) {
  return function () {
    return jk_gtw.get(`/content/detail/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function createRequestVideo(payload) {
  return function () {
    return jk_gtw.post(`/content/request-video`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getReqeustVideo(type, page, perPage) {
  return function () {
    return jk_gtw.get(
      `/content/request-video?perPage=${
        perPage ? perPage : 20
      }&page=${page}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
  };
}

export function getKolList(
  page,
  perPage,
  category,
  search,
  followers,
  excludedgetlistkol
) {
  return function () {
    return jk_gtw.get(
      `/kol/list2/${page}/${perPage}?category=${
        category ? category.toLowerCase() : ""
      }&search=${search ? search : ""}&followers=${
        followers ? followers : ""
      }&excludedgetlistkol=${excludedgetlistkol ? excludedgetlistkol : ""}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
  };
}

export function getKolRanking(id) {
  return function () {
    return jk_gtw.get(`content/getRankingLKS/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getCountKol(id) {
  return function () {
    return jk_gtw.get(`/content/count/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function setNotification(payload) {
  return { type: SET_NOTIFICATIONS, payload };
}

export function getNotifications() {
  return function (dispatch) {
    jk_gtw
      .get(`/kol/logs/false/list/0/20`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(async ({ data }) => {
        const { data: unread } = await jk_gtw.get(`/kol/logs/false/un-read`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        dispatch(setNotification({ count: unread, data: data.data }));
      })
      .catch((e) => {
        const message = handleError(e);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      });
  };
}

export function bayar(file, content) {
  return async function (dispatch) {
    try {
      const { data: url } = await jk_gtw.post("/wallet/upload", file, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (url) {
        try {
          const payload = { url_payment: url };

          for (let i = 0; i < content.length; i++) {
            const data = content[i];
            if (i === content.length - 1) {
              return jk_gtw.post(`/content/payment/${data.id}`, payload, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              });
            } else {
              await jk_gtw.post(`/content/payment/${data.id}`, payload, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              });
            }
          }
        } catch (e) {
          const message = handleError(e);
          dispatch(setErr(true));
          dispatch(setMessage(message));
          dispatch(setOpenSnack(true));
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function bayarVideo(file, content) {
  return async function (dispatch) {
    try {
      const { data: url } = await jk_gtw.post("/wallet/upload", file, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (url) {
        try {
          const payload = { url_payment: url };
          const data = content[0];
          if (data === content.length - 1) {
            return jk_gtw.post(
              `/content/request-video/payment/${data.id}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );
          } else {
            await jk_gtw.post(
              `/content/request-video/payment/${data.id}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );
          }
        } catch (e) {
          const message = handleError(e);
          dispatch(setErr(true));
          dispatch(setMessage(message));
          dispatch(setOpenSnack(true));
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function updateApproval(id, data) {
  return async function () {
    jk_gtw.put(`/content/request-video/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function perpanjang(file, content) {
  return async function (dispatch) {
    try {
      const { data: url } = await jk_gtw.post("/wallet/upload", file, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (url) {
        try {
          const payload = { url_payment: url };

          for (let i = 0; i < content.length; i++) {
            const data = content[i];
            if (i === content.length - 1) {
              return jk_gtw.post(`/content/perpanjang/${data.id}`, payload, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              });
            } else {
              await jk_gtw.post(`/content/perpanjang/${data.id}`, payload, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              });
            }
          }
        } catch (e) {
          const message = handleError(e);
          dispatch(setErr(true));
          dispatch(setMessage(message));
          dispatch(setOpenSnack(true));
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch(setErr(true));
      dispatch(setMessage(message));
      dispatch(setOpenSnack(true));
    }
  };
}

export function createRandomContent(payload) {
  return function () {
    return jk_gtw.post(`/content/sample`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function bayarOngkir(id) {
  return function () {
    return jk_gtw.post(
      `/content/sample/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
  };
}

export function getPromotedContent(id) {
  return function () {
    return jk_gtw.get(`/content/promoted/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function getKolById(id) {
  return function () {
    return jk_gtw.get(`/kol/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function canceledPromoted(id) {
  return function () {
    return jk_gtw.patch(`/content/promoted/${id}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function acceptedPromoted(id, payload) {
  return function () {
    return jk_gtw.put(`/content/promoted/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function createBulkContent(payload) {
  return function () {
    return jk_gtw.post(`/content/bulkCreateContent`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function topKOL() {
  return function () {
    return jk_gtw.get(`/like/topTen`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function createRating(id, data) {
  return async function () {
    jk_gtw.put(`/content/rating/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}

export function avarageRating(id) {
  return function () {
    return jk_gtw.get(`/content/rating/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
  };
}
