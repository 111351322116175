import { api_gtw } from "../../apis";

export const getWallet = async () => {
  const { data } = await api_gtw.get("/dropper/wallet", {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};
