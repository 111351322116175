import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import IndexDesktop from "./DesktopView/index";
import IndexMobile from "./MobileView/index";
export default function IndexReport() {
  const theme = useTheme();

  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  if (matchesMobile) {
    return <IndexMobile />;
  } else {
    return <IndexDesktop />;
  }
}
