import { useState, useMemo, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Tooltip,
  Zoom,
  Fade,
  TablePagination,
  CircularProgress,
  Alert,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Snackbar,
  Slide,
  Divider,
  Link,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { useDispatch } from "react-redux";

import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/wrong.mp3";
import { dateFormat, timeFormat } from "../../helper/DateFormat";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { ThisMonth, formatDate } from "../../helper/custom";
import {
  Close,
  DeleteOutlined,
  EditOutlined,
  CancelOutlined,
  DoneOutlined,
  Search,
  RestoreFromTrashOutlined,
  InfoOutlined,
  EmojiEvents,
  RefreshOutlined,
  Sync,
  ReportProblemOutlined,
  Receipt,
  NoteAdd,
} from "@mui/icons-material";
import { DateRangePicker } from "react-date-range";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { compress } from "compress-json";
import { read, utils, writeFile } from "xlsx";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { convertDataToQuery, getQueryUrl } from "../../helper/custom";
import {
  getOrders,
  getOrdersValidate,
  getDetailLottery,
  uploadOrder,
  deleteOrder,
  deleteOrderPermanent,
  updateOrder,
  getCheckedOrder,
  checkedOrder,
  getOrdersPrint,
  restoreOrder,
  updateStatusPackage,
  getManifest,
  updateToRetur,
} from "../../store/actions/transactionAction";
import { getResi } from "../../store/actions/dropperAction";
import jwt_decode from "jwt-decode";
import axios from "axios";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ComponentToPrint } from "../../components/Print/Print";
// import { previousDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";
import { Waypoint } from "react-waypoint";
import ModalCreateResi from "./ModalCreateResi";
import PopUpResi from "./ModalPopUpResi";
import { api_gtw } from "../../apis";

// const url = "http://localhost:3000";

function CreateResi() {
  const dispatch = useDispatch();

  //for decode token
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const accessStatus = decode.accessStatus;

  //styled
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //for change state

  //=================

  //for get query
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const selectedStatus = params.status;
  //for get query

  const [searchValue, setSearchValue] = useState(params.search || "");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalPrint, setOpenModalPrint] = useState(false);
  const [resi, setResi] = useState({});
  //=================

  //for navigate
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const push = (payload) => {
    navigate(`/order${convertDataToQuery(payload)}`);
  };

  const getResiAction = async () => {
    try {
      const result = await getResi();
      // console.log(data);
      setDataList(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResiAction();
  }, []);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  const handleOpenModalPrint = (e, data) => {
    e.preventDefault();
    setResi(data);
    setOpenModalPrint(true);
  };

  function DetectError(e) {
    e.preventDefault();
    window.alert("data tidak ditemukan");
    return window.location.reload();
  }

  return (
    <Container
      sx={{
        minHeight: "100vh",
        maxHeight: {
          lg: "150vh",
          md: "150vh",
          sm: "150vh",
          xs: "auto",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
        // mt: { lg: 0, md: 0, sm: 5, xs: 5 },
      }}
    >
      <ModalCreateResi open={openModalCreate} setOpen={setOpenModalCreate} />
      <PopUpResi
        isOpen={openModalPrint}
        setIsOpen={setOpenModalPrint}
        data={resi}
      />
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "90%" : "100%",
            width: matchesMobile ? "100%" : "100%",
            mr: matchesMobile ? 0 : 2,
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                // alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size={matchesMobile ? "small" : "medium"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Nomor Resi / Nomor Order"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              push(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;
                        push(temp);
                        // push({ ...query, search: searchValue });
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        push(temp);
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: "32%",
                  width: "100%",
                  display: "flex",
                }}
              >
                <IconButton
                  variant="contained"
                  sx={{
                    marginX: 1,
                    borderRadius: 0,
                    height: "100%",
                    width: matchesMobile ? "70%" : "30%",
                    color: "white",
                    backgroundColor:
                      selectedStatus === "instant" ? "#333333" : "#ab8c36",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      backgroundColor: "#333333",
                      color: "white",
                    },
                  }}
                  onClick={() => setOpenModalCreate(true)}
                >
                  <NoteAdd />{" "}
                  <Typography paddingLeft="6px" fontWeight="bold">
                    Pembuatan Resi
                  </Typography>
                </IconButton>
                {/* <Button
                  variant="contained"
                  sx={{
                    marginX: 1,
                    borderRadius: 0,
                    height: "100%",
                    width: "30%",
                    backgroundColor:
                      selectedStatus === "instant" ? "#333333" : "#ab8c36",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      backgroundColor: "#333333",
                    },
                  }}
                  onClick={() => setOpenModalCreate(true)}
                >
                  Pembuatan Resi
                </Button> */}
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                backgroundColor: "#ffffff",
                fontSize: matchesMobile ? "12px" : "14px",
                color: "#333333",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
              }}
              onClick={(e) => setOpenDatePicker(true)}
            >
              {moment(date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                ? "Filter Tanggal"
                : `${matchesMobile ? "" : "Tanggal -"} ${formatDate(
                    date,
                    true
                  )} 
              `}
            </Button>
          </Box>
          <Modal
            open={openDatePicker}
            onClose={() => {
              // setDateSuratJalan(false);
              setOpenDatePicker(false);
            }}
            sx={{
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Zoom in={openDatePicker}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#ffffff",
                  padding: 2,
                }}
              >
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      openTo="day"
                      format="dd/MM/yyyy"
                      value={date}
                      sx={{ "& input": { color: "red" } }}
                      onChange={(e) => setDate(e)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    onClick={() => {
                      // setDateSuratJalan(false);
                      setOpenDatePicker(false);
                      // setDate2(new Date());
                    }}
                    style={{ marginRight: 15 }}
                    variant="outlined"
                    sx={{
                      borderColor: "#ab8c36",
                      color: "#ab8c36",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        borderColor: "#ab8c36",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                  >
                    Batal
                  </Button>
                  <Button
                    onClick={(e) => {
                      const newDataList = dataList.filter(
                        (data) =>
                          moment(data.createdAt).format("YYYY-MM-DD") ==
                          moment(date).format("YYYY-MM-DD")
                      );
                      if (!newDataList.length) DetectError(e);
                      setDataList(newDataList);
                      setOpenDatePicker(false);
                    }}
                    variant="contained"
                    sx={{
                      backgroundColor: "#333333",
                      width: "10%",
                      "&:hover": {
                        backgroundColor: "#333333",
                        transform: "scale3d(1.1, 1.2, 1)",
                      },
                    }}
                  >
                    Cari
                  </Button>
                </Box>
              </Box>
            </Zoom>
          </Modal>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Tanggal Pickup
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          No. AWB
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 150 }}>
                          Nama Barang
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Cost Pengiriman
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Berat Barang (Kg)
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Nama Pengirim
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 150 }}>
                          Alamat Pengirim
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Nomor Pengirim
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Nama Penerima
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 150 }}>
                          Alamat Penerima
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Nomor Penerima
                        </StyledTableCell>
                        {/* <StyledTableCell sx={{ minWidth: 300 }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            Status Paket */}
                        {/* <Tooltip
                              title={
                                <div>
                                  Paket yang bisa di cek adalah JNE, J&T,
                                  SiCepat, Ninja Express, Lion Parcel, ID
                                  Express, Anter Aja, Wahana
                                  <br />
                                  <br />
                                  Informasi lebih lanjut hubungi Big Delivery
                                </div>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#333333",
                                    color: "#ffffff",
                                  },
                                },
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: 17 }} />
                            </Tooltip> */}
                        {/* {loadingUpdateStatus === -1 ? (
                              errorUpdateStatus.length ? (
                                <IconButton>
                                  <Close
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setErrorUpdateStatus([]);
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <Tooltip
                                  title="update status pada data tabel di bawah"
                                  onClick={() => {
                                    updateStatus();
                                    // updateStatus({
                                    //   awb: e.awb,
                                    //   expedition: e.expedition,
                                    //   index: i,
                                    // });
                                  }}
                                >
                                  <IconButton sx={{ color: "#ab8c36" }}>
                                    <Sync />
                                  </IconButton>
                                </Tooltip>
                              )
                            ) : (
                              <CircularProgress
                                size={25}
                                sx={{ color: "#333333", ml: 2 }}
                              />
                            )} */}
                        {/* </Box>
                        </StyledTableCell> */}
                        <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                          Cetak Resi
                          <Tooltip
                            title={
                              <div>
                                Cetak resi anda segera agar barang dapat
                                dipickup!
                              </div>
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  backgroundColor: "#333333",
                                  color: "#ffffff",
                                },
                              },
                            }}
                          >
                            <InfoOutlined sx={{ fontSize: 17 }} />
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList?.length > 0
                        ? dataList.map((e, i) => (
                            <TableRow
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor: "#33333330",
                              }}
                            >
                              <TableCell>
                                <TextField
                                  value={moment(new Date(e.createdAt)).format(
                                    "DD/MM/YYYY"
                                  )}
                                  label="tanggal pickup"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.awb}
                                  label="no. awb"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.goodsName}
                                  label="Nama Barang"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={Number(e.amount)
                                    .toLocaleString()
                                    .replaceAll(",", ".")}
                                  label="Cost Pengiriman"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.weight}
                                  label="Berat Barang"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.shipperName}
                                  label="Nama Pengirim"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.shipperAddress}
                                  label="Alamat Pengirim"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.shipperPhone}
                                  label="Nomor Pengrim"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.receiverName}
                                  label="Nama Penerima"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.receiverAddress}
                                  label="Alamat Penerima"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  value={e.receiverPhone}
                                  label="Nomor Penerima"
                                  variant="standard"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  sx={{ color: "#000000", borderRight: 1 }}
                                  onClick={(x) => handleOpenModalPrint(x, e)}
                                >
                                  <Receipt />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                      {/* {dataList?.length
                        ? dataList.map((e, i) =>
                            i === isEdit ? (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  backgroundColor: "#33333330",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <TextField
                                    value={
                                      isNaN(editData.order_id)
                                        ? editData.order_id.toUpperCase()
                                        : editData.order_id
                                    }
                                    label="no. order"
                                    variant="standard"
                                    error={/order/i.test(error) ? true : false}
                                    helperText={
                                      /order/i.test(error)
                                        ? "sudah terdaftar"
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setEditData({
                                        ...editData,
                                        order_id: e.target.value.toLowerCase(),
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    value={
                                      isNaN(editData.awb)
                                        ? editData.awb.toUpperCase()
                                        : editData.awb
                                    }
                                    label="no.awb"
                                    variant="standard"
                                    error={/awb/i.test(error) ? true : false}
                                    helperText={
                                      /awb/i.test(error)
                                        ? "sudah terdaftar"
                                        : ""
                                    }
                                    onChange={(e) =>
                                      setEditData({
                                        ...editData,
                                        awb: e.target.value.toLowerCase(),
                                      })
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    value={editData.expedition.toUpperCase()}
                                    label="logistik"
                                    variant="standard"
                                    onChange={(e) => {
                                      setEditData({
                                        ...editData,
                                        expedition:
                                          e.target.value.toLowerCase(),
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    value={
                                      editData.service
                                        ? editData.service.toUpperCase()
                                        : editData.service
                                    }
                                    label="service"
                                    variant="standard"
                                    onChange={(e) => {
                                      setEditData({
                                        ...editData,
                                        service: e.target.value.toLowerCase(),
                                      });
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="center">
                                  {e.checkedDate ? (
                                    <DoneOutlined sx={{ color: "#a8a8a8" }} />
                                  ) : null}
                                </TableCell>

                                <TableCell>
                                  <TextField
                                    value={new Date(
                                      e.order_date
                                    ).toLocaleDateString("en-US", options)}
                                    label="Tanggal"
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>

                                <TableCell align="center">
                                  <TextField
                                    value={
                                      e.statusPackage ? e.statusPackage : "-"
                                    }
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>

                                <TableCell align="center">
                                  <IconButton
                                    sx={{ color: "#000000", borderRight: 1 }}
                                    onClick={(e) => {
                                      setIsEdit(-1);
                                    }}
                                  >
                                    <CancelOutlined />
                                  </IconButton>
                                  <IconButton
                                    sx={{ color: "#000000", borderRight: 1 }}
                                    onClick={(e) => editSubmit(e)}
                                  >
                                    <DoneOutlined />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow key={i}>
                                <StyledTableCell>
                                  {isNaN(e.order_id)
                                    ? e.order_id.toUpperCase()
                                    : e.order_id}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {isNaN(e.awb) ? e.awb.toUpperCase() : e.awb}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {e.expedition.toUpperCase()}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {e.service.toUpperCase()}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {e.checkedDate ? (
                                    <Tooltip
                                      title={`discan pada tanggal ${new Date(
                                        e.checkedDate
                                      ).toLocaleDateString(
                                        "en-US",
                                        options
                                      )}, di jam ${new Date(
                                        e.checkedDate
                                      ).toLocaleTimeString("en-US", options)}`}
                                    >
                                      <DoneOutlined sx={{ color: "#a8a8a8" }} />
                                    </Tooltip>
                                  ) : null}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {new Date(e.order_date).toLocaleDateString(
                                    "en-US",
                                    options
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {loadingUpdateStatus === i ? (
                                      <CircularProgress
                                        size={25}
                                        sx={{ color: "#333333", ml: 2 }}
                                      />
                                    ) : e.statusPackage ? (
                                      <Typography
                                        underline="hover"
                                        sx={{
                                          color:
                                            e.statusPackage === "DELIVERED"
                                              ? "#2e7d32"
                                              : e.statusPackage === "RETUR"
                                              ? "#9c27b0"
                                              : "#1976d2",
                                        }}
                                      >
                                        {e.statusPackage}
                                      </Typography>
                                    ) : (
                                      "-"
                                    )}
                                    {errorUpdateStatus.length ? (
                                      errorUpdateStatus[i] ? (
                                        <Tooltip
                                          title={errorUpdateStatus[i]}
                                          sx={{
                                            "&:hover": {
                                              backgroundColor: "transparent",
                                            },
                                            color: "#ff194390",
                                          }}
                                        >
                                          <InfoOutlined />
                                        </Tooltip>
                                      ) : null
                                    ) : null}
                                  </Box>
                                </StyledTableCell>
                                {accessStatus !== "checker" ? (
                                  <StyledTableCell align="center">
                                    {params.status === "delete" ? (
                                      <IconButton
                                        sx={{ borderRight: 1 }}
                                        onClick={() => {
                                          setOpenModalRestore(true);
                                          setSelectedData(e);
                                        }}
                                      >
                                        <RestoreFromTrashOutlined />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={{ borderRight: 1 }}
                                        onClick={() => {
                                          setPreviousExpedition(e.expedition);
                                          setPreviousService(e.service);
                                          setChecked(e.checkedDate);
                                          setEditDate(e.order_date);
                                          setEditData({
                                            ...editData,
                                            order_id: e.order_id,
                                            awb: e.awb,
                                            expedition: e.expedition,
                                            service: e.service,
                                          });
                                          setIdEdit(e.id);
                                          setIsEdit(i);
                                        }}
                                      >
                                        <EditOutlined />
                                      </IconButton>
                                    )}
                                    <IconButton
                                      sx={{ borderRight: 1 }}
                                      onClick={() => {
                                        setOpenModalDelete(true);
                                        setIdDelete(e.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </IconButton>
                                  </StyledTableCell>
                                ) : null}
                              </TableRow>
                            )
                          )
                        : null} */}
                    </TableBody>
                  </Table>
                  {loading ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={25} sx={{ color: "#333333" }} />
                    </Box>
                  ) : dataList?.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  ml: matchesMobile ? 1 : 2,
                  height: "10%",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  component="div"
                  // count={data.count}
                  count={count}
                  rowsPerPage={+params.limit}
                  page={count <= 0 ? 0 : +params.page - 1}
                  onPageChange={(_, newPage) =>
                    push({ ...query, page: newPage + 1 })
                  }
                  onRowsPerPageChange={({ target: { value = "10" } }) =>
                    push({ ...query, limit: value, page: 1 })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default CreateResi;
