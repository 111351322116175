import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LocalizationProvider, MonthPicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertCurrency,
  convertDataToQuery,
  getQueryUrl,
} from "../../helper/custom";
import { getRewardSeller } from "../../store/actions/syncAction";

export default function CashbackPage() {
  const [openMonth, setOpenMonth] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [listData, setListData] = useState([]);

  const navigate = useNavigate();
  const { search } = useLocation();

  const push = (payload) =>
    navigate(`/premium/cashback${convertDataToQuery(payload)}`);

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getListReward(query);
    }, 500);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getListReward = async (query) => {
    const results = await getRewardSeller(query);
    setListData(results.data);
  };

  return (
    <section className="flex flex-col items-center justify-center w-full p-6">
      <div className="justify-center space-y-4">
        {/* title */}
        <h1 className="text-3xl font-bold text-center underline uppercase underline-offset-4">
          Report Komisi
        </h1>
        {/* choose month */}
        <div className="inline-flex items-center gap-2">
          <h3 className="text-xl font-semibold">Choose a month :</h3>
          <Button
            component="label"
            variant="outlined"
            size="small"
            onClick={() => setOpenMonth(true)}
            // sx={{ width: 170 }}
            // variant="outlined"
            sx={{
              borderColor: "#ab8c36",
              color: "#ab8c36",
              width: 120,
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "#ab8c36",
                transform: "scale3d(1.1, 1.2, 1)",
              },
              transition: "0.1s ease-in 0.2s",
            }}
          >
            {selectedMonth.toLocaleString("default", { month: "long" })}
          </Button>
        </div>
      </div>

      {/* modalOpenMonth */}
      <Modal
        open={openMonth}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "80%",
          mt: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: "95%",
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MonthPicker
                date={selectedMonth}
                onChange={(e) => {
                  setSelectedMonth(e);
                }}
              />
            </LocalizationProvider>
            <>
              <Grid
                container
                display="flex"
                width="50%"
                justifyContent="space-between"
                alignItems="center"
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setOpenMonth(false);
                    setSelectedMonth(new Date());
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Confirm"
                  onClick={() => {
                    push({
                      ...query,
                      date: selectedMonth,
                    });
                    setOpenMonth(false);
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </>
          </Grid>
        </Box>
      </Modal>

      {/* table */}
      <div className="flex-1 max-w-6xl py-4">
        <TableContainer
          sx={{
            minHeight: "50vh",
            maxHeight: "80vh",
            maxWidth: "98%",
            margin: "0 auto",
            backgroundColor: "white",
            "&::-webkit-scrollbar": { width: 10, height: 10 },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ab8c36",
              borderRadius: 1,
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell sx={{ minWidth: 130 }}>Shipment Fee</TableCell>
                <TableCell sx={{ minWidth: 140 }}>
                  Shipment Fee After Disc TTD
                </TableCell>
                <TableCell sx={{ minWidth: 140 }}>
                  Shipment Fee After Disc TTD (RTS)
                </TableCell>
                <TableCell sx={{ minWidth: 140 }}>
                  Shipment Fee After Disc Not TTD
                </TableCell>
                <TableCell>DISCOUNT</TableCell>
                <TableCell>Shipment Fee TTD (Prev. Period)</TableCell>
                <TableCell>%Commision</TableCell>
                <TableCell>Total Commision</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listData?.length > 0 ? (
                listData.map((item, index) => (
                  <TableRow hover key={index}>
                    <TableCell>
                      {index === 8 ? (
                        <span
                          style={{ fontWeight: "bold", fontStyle: "italic" }}
                        >
                          {item.serviceName}
                        </span>
                      ) : (
                        item.serviceName
                      )}
                    </TableCell>
                    <TableCell>{convertCurrency(item.fee)}</TableCell>
                    <TableCell>{convertCurrency(item.afterDiscTTD)}</TableCell>
                    <TableCell>
                      {convertCurrency(item.afterDiscTTDRts)}
                    </TableCell>
                    <TableCell>
                      {convertCurrency(item.afterDiscNotTTD)}
                    </TableCell>
                    <TableCell>{convertCurrency(item.discount)}</TableCell>
                    <TableCell>
                      {index === 0 ? "" : convertCurrency(item.shipmentFeePrev)}
                    </TableCell>
                    <TableCell>
                      {index === 8 || index === 0 || index === 3
                        ? ""
                        : `${item.commision}%`}
                    </TableCell>
                    <TableCell>
                      {index === 0 ? "" : convertCurrency(item.totalCommision)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <div>
                  <em>data not found</em>
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
}
