import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IconButton,
  InputAdornment,
  Button,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  TextField,
  CircularProgress,
  useMediaQuery,
  Snackbar,
  Alert,
  Slide,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { handleError, capitalizeWords } from "../../helper/helpers";
import logoBIGPOS from "../../image/logo_bigpos.png";
import { RegisterEasyDrop } from "../../store/actions/scanAction";

export default function RegistrationEasyDropPage() {
  const navigate = useNavigate();

  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [showResponse, setShowResponse] = useState(false);
  const [res, setRes] = useState({ status: "", message: "" });

  const theme = useTheme();
  // const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const matchesMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setError({});
      setLoading(true);

      let create = await RegisterEasyDrop({
        shopName: capitalizeWords(shopName),
        shopAddress,
        email,
        username,
        password,
        no_telp: phoneNumber,
      });

      if (create) {
        setRes({ status: "success", message: "Registrasi Berhasil" });
        setShowResponse(true);
        setTimeout(() => {
          setLoading(false);
          setError("");
          navigate(`/login`);
        }, 3000);
      }
    } catch (err) {
      setRes({ status: "error", message: "Registrasi Gagal" });
      setShowResponse(true);
      setLoading(false);
      const message = handleError(err);
      setError(message);
    }
  };

  return (
    <Container
      sx={{ width: "100vw", height: "100vh", backgroundColor: "#333333" }}
      maxWidth={false}
    >
      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          component="img"
          sx={{
            height: "10%",
            width: "auto",
            mb: 3,
          }}
          src={logoBIGPOS}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xl: "30%", lg: "30%", md: "50%", sm: "50%", xs: "80%" },
            color: "#ffffff",
          }}
        >
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mb: matchesMobile ? 0 : 3,
            }}
          >
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Typography sx={{ color: "#ffffff", fontWeight: "bold", mr: 1 }}>
                Verifikasi Registrasi
              </Typography>
              <Tooltip
                title={
                  <div>
                    Belum mendapatkan kode registrasi ?
                    <br /> Hubungi Tim Big Delivery
                  </div>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#b08c34",
                    },
                  },
                }}
              >
                <InfoOutlined />
              </Tooltip>
            </Box>
            <TextField
              margin="normal"
              required
              label="Registration Code"
              autoFocus
              sx={{
                backgroundColor: "#ffffff",
                mb: error.registrationCode ? 0 : 2,
              }}
              variant="filled"
              disabled={loading}
              value={registrationCode}
              onChange={(e) => setRegistrationCode(e.target.value)}
              size={matchesMobile ? "small" : "medium"}
            />
            {error.registrationCode ? (
              <Typography
                sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
              >
                {error.registrationCode}
              </Typography>
            ) : null}
          </Box> */}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              mb: matchesMobile ? 0 : 3,
            }}
          >
            <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
              Informasi DOP
            </Typography>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            > */}
            <TextField
              margin="normal"
              required
              label="Nama DOP"
              sx={{
                backgroundColor: "#ffffff",
                mb: error.shopName ? 0 : 2,
              }}
              variant="filled"
              disabled={loading}
              value={shopName}
              onChange={(e) => setShopName(e.target.value)}
              size={matchesMobile ? "small" : "medium"}
            />
            {error.shopName ? (
              <Typography
                sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
              >
                {error.shopName}
              </Typography>
            ) : null}
            <TextField
              margin="normal"
              label="Alamat DOP"
              sx={{ backgroundColor: "#ffffff", mb: error.shopAddress ? 0 : 2 }}
              variant="filled"
              disabled={loading}
              value={shopAddress}
              onChange={(e) => setShopAddress(e.target.value)}
              size={matchesMobile ? "small" : "medium"}
            />
            {error.shopAddress ? (
              <Typography
                sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
              >
                {error.shopAddress}
              </Typography>
            ) : null}
            <TextField
              margin="normal"
              label="Nomor Handphone "
              sx={{ backgroundColor: "#ffffff", mb: error.no_telp ? 0 : 2 }}
              variant="filled"
              disabled={loading}
              value={phoneNumber}
              onChange={(e) => {
                if (/^[0-9]{0,14}$/.test(e.target.value))
                  setPhoneNumber(e.target.value);
              }}
              size={matchesMobile ? "small" : "medium"}
            />
            {error.no_telp ? (
              <Typography
                sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
              >
                {error.no_telp}
              </Typography>
            ) : null}
          </Box>

          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
              Akun Super User
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: matchesMobile ? "column" : "row",
              }}
            >
              <Box sx={{ width: matchesMobile ? "100%" : "50%" }}>
                <TextField
                  margin="normal"
                  required
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  sx={{
                    backgroundColor: "#ffffff",
                    width: matchesMobile ? "100%" : "90%",
                    mb: error.email ? 0 : 2,
                  }}
                  variant="filled"
                  disabled={loading}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size={matchesMobile ? "small" : "medium"}
                />
                {error.email ? (
                  <Typography
                    sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
                  >
                    {error.email}
                  </Typography>
                ) : null}
              </Box>
              <Box
                sx={{
                  width: matchesMobile ? "100%" : "50%",
                  display: "flex",
                  alignItems: matchesMobile ? "start" : "end",
                  flexDirection: "column",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  label="Username"
                  sx={{
                    backgroundColor: "#ffffff",
                    width: matchesMobile ? "100%" : "90%",
                    mb: error.username ? 0 : 2,
                    flexDirection: "end",
                  }}
                  variant="filled"
                  disabled={loading}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  size={matchesMobile ? "small" : "medium"}
                />
                {error.username ? (
                  <Typography
                    sx={{ color: "red", fontSize: matchesMobile ? 10 : 13 }}
                  >
                    {error.username}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <TextField
              margin="normal"
              required
              name="password"
              label="Password"
              type={showPassword}
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: "#ffffff",
                mb: error.password ? 0 : 2,
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="filled"
              size={matchesMobile ? "small" : "medium"}
              InputProps={{
                endAdornment: (
                  <div style={{ marginTop: "-19px" }}>
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          if (showPassword === "password") {
                            setShowPassword("text");
                          } else {
                            setShowPassword("password");
                          }
                        }}
                        edge="end"
                      >
                        {showPassword === "password" ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </div>
                ),
              }}
              disabled={loading}
            />
            {error.password ? (
              <Typography
                sx={{ color: "red", fontSize: matchesMobile ? 10 : 14 }}
              >
                {error.password}
              </Typography>
            ) : null}
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              color: "#ffffff",
              backgroundColor: "#ab8c36",
              height: "1.3cm",
              borderRadius: 0,
              "&:hover": {
                backgroundColor: "#333333",
                border: 1,
                borderColor: "#ffffff",
              },
            }}
            disabled={loading}
            onClick={(e) => handleSubmit(e)}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#fed03d" }} />
            ) : (
              "Sign Up"
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => navigate("/login")}
                component="button"
                variant="body2"
                sx={{ color: "white" }}
              >
                Sudah Ada Akun ?
              </Link>
            </Grid>
            {/* <Grid item>
                <Link href="#" variant="body2" sx={{ color: "white" }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
