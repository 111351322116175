export function convertDate(tanggal) {
  let date = new Date(tanggal);
  const jam = new Date(tanggal).toLocaleTimeString();

  const year = date.getFullYear();
  let nameOfTheMonth = date.getMonth() + 1;

  if (nameOfTheMonth === 1) nameOfTheMonth = "Januari";
  if (nameOfTheMonth === 2) nameOfTheMonth = "Februari";
  if (nameOfTheMonth === 3) nameOfTheMonth = "Maret";
  if (nameOfTheMonth === 4) nameOfTheMonth = "April";
  if (nameOfTheMonth === 5) nameOfTheMonth = "Mei";
  if (nameOfTheMonth === 6) nameOfTheMonth = "Juni";
  if (nameOfTheMonth === 7) nameOfTheMonth = "Juli";
  if (nameOfTheMonth === 8) nameOfTheMonth = "Agustus";
  if (nameOfTheMonth === 9) nameOfTheMonth = "September";
  if (nameOfTheMonth === 10) nameOfTheMonth = "Oktober";
  if (nameOfTheMonth === 11) nameOfTheMonth = "November";
  if (nameOfTheMonth === 12) nameOfTheMonth = "Desember";

  const day = date.getDate();
  let nameOfTheDay = date.getDay();

  if (nameOfTheDay === 1) nameOfTheDay = "Senin";
  if (nameOfTheDay === 2) nameOfTheDay = "Selasa";
  if (nameOfTheDay === 3) nameOfTheDay = "Rabu";
  if (nameOfTheDay === 4) nameOfTheDay = "Kamis";
  if (nameOfTheDay === 5) nameOfTheDay = "Jum'at";
  if (nameOfTheDay === 6) nameOfTheDay = "Sabtu";
  if (nameOfTheDay === 0) nameOfTheDay = "Minggu";

  return `${nameOfTheDay}, ${day} ${nameOfTheMonth} ${year}`;
}

export function convertMonth(tanggal) {
  let month = new Date(tanggal).getMonth() + 1;

  if (month === 1) month = "Januari";
  if (month === 2) month = "Februari";
  if (month === 3) month = "Maret";
  if (month === 4) month = "April";
  if (month === 5) month = "Mei";
  if (month === 6) month = "Juni";
  if (month === 7) month = "Juli";
  if (month === 8) month = "Agustus";
  if (month === 9) month = "September";
  if (month === 10) month = "Oktober";
  if (month === 11) month = "November";
  if (month === 12) month = "Desember";

  return `${month}`;
}

export function getQueryUrl(query) {
  try {
    if (/[&?=]/.test(String(query).toLowerCase())) {
      let result = {};
      query
        .split("?")[1]
        .split("&")
        .forEach((el, i) => (result[el.split("=")[0]] = el.split("=")[1]));
      return result;
    }
  } catch (e) {}
  return false;
}

export const convertDataToQuery = (data) => {
  if (data) {
    const obj = Object.keys(data);
    let query = ``;
    for (let i = 0; i < obj.length; i++) {
      if (query.length === 0) {
        query += `?${obj[i]}=${data[obj[i]]}`;
        continue;
      }
      query += `&${obj[i]}=${data[obj[i]]}`;
    }

    return query;
  }
  return "";
};

export const getOfDateNow = () => {
  const date = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formatter = new Intl.DateTimeFormat("id-ID", options);

  const formattedDate = formatter.format(date);

  return formattedDate;
};
