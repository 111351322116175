import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// material-ui
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  MobileStepper,
  useTheme,
  Typography,
} from "@mui/material";

// project imports
import MainCard from "../MainCard";

//Icon
import EtalaseCardKolList from "./EtalaseCardKolList";
import {
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
import { convertDate } from "../../helper/convertDate";

//-----------------------|| CAROUSEL EFFECT  ||-----------------------//

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function EtalaseCardFilled({
  setOpenModalListKol,
  setOpenModalInputOrEditEtalase,
  index,
  setEvent,
  setIndex,
  thisMonthEtalase,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  //? Carousel Effect

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = thisMonthEtalase?.Addons
    ? thisMonthEtalase?.Addons?.length
    : 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    // setActiveStep(step);
  };

  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12} mt={5}>
        <Typography variant="h4" mb={1}>
          Etalase {index + 1}
        </Typography>
        <MainCard
          content={false}
          sx={{
            borderRadius: 0,
            color: "#bfbfbf",
            boxShadow: 0,
            "&:hover": {
              boxShadow: "none",
              marginTop: "",
              marginBottom: "",
            },
          }}
          tabIndex={-1}
          disableRipple
        >
          <Card sx={{ width: "100%" }}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: "#bd454f", color: "#ffffff" }}
                  aria-label="recipe"
                >
                  {thisMonthEtalase.User.username[0]}
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    setIndex(index);
                    setEvent("edit");
                    setOpenModalInputOrEditEtalase(true);
                  }}
                >
                  <Edit />
                </IconButton>
              }
              title={`${thisMonthEtalase.User.username}`}
              subheader={`${convertDate(thisMonthEtalase.createdAt)}`}
            />
            <CardContent sx={{ paddingTop: 0, paddingLeft: "33px" }}>
              <Grid
                sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
                spacing={2}
              >
                {thisMonthEtalase.item_cod ? (
                  <Chip color="primary" label="Bisa COD" variant="outlined" />
                ) : null}
                {thisMonthEtalase.item_sample ? (
                  <Chip
                    color="error"
                    label={`Sampel:  ${
                      thisMonthEtalase.item_sample_quantity
                    }, Sisa: ${
                      thisMonthEtalase.item_sample_quantity -
                      thisMonthEtalase?.SisaSample?.length
                    }`}
                    variant="outlined"
                  />
                ) : null}
              </Grid>
              <Typography variant="body2" color="text.secondary">
                Nama:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                {thisMonthEtalase.item_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Kategori:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                {thisMonthEtalase.item_category}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Kelebihan Produk:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                {thisMonthEtalase.item_selling_point}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Notes:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                {thisMonthEtalase.item_notes}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Komisi:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                {thisMonthEtalase.item_commission}%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Link Produk:
              </Typography>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                <a
                  href={
                    /http/i.test(thisMonthEtalase.display_link_product)
                      ? thisMonthEtalase.display_link_product
                      : `https://${thisMonthEtalase.display_link_product}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {thisMonthEtalase.display_link_product?.length > 40
                    ? thisMonthEtalase.display_link_product.substring(0, 40) +
                      "..."
                    : thisMonthEtalase.display_link_product}
                </a>
              </Typography>
              <Grid sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Grid
                  sx={{
                    maxWidth: 250,
                    flexGrow: 1,
                    boxShadow: 1,
                    textAlign: "-webkit-center",
                  }}
                >
                  <Typography variant="h4" textAlign="center" mt={1.5}>
                    Kreator
                  </Typography>
                  {/* <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {thisMonthEtalase?.Addons?.length ? (
                      thisMonthEtalase?.Addons.map((kol, i) => (
                        <EtalaseCardKolList
                          kol={kol}
                          index={index}
                          indexKolList={i}
                          setIndex={setIndex}
                          activeStep={activeStep}
                          setOpenModalListKol={setOpenModalListKol}
                        />
                      ))
                    ) : (
                      <div>
                        <Card
                          onClick={() => {
                            setIndex(index);
                            setOpenModalListKol(true);
                          }}
                          sx={{ width: "95%", bgcolor: "#EEEEEF" }}
                        >
                          <CardHeader
                            title={
                              <Typography
                                variant="h4"
                                sx={{ textAlignLast: "center" }}
                              >
                                Belum ada KOL
                              </Typography>
                            }
                            sx={{ textAlign: "left" }}
                          />
                        </Card>
                      </div>
                    )}
                  </AutoPlaySwipeableViews> */}
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={
                          activeStep === maxSteps - 1 ||
                          !thisMonthEtalase?.Addons?.length
                        }
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={
                          activeStep === 0 || !thisMonthEtalase?.Addons?.length
                        }
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </MainCard>
      </Grid>
    </>
  );
}
