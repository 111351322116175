import {
  Preview,
  CancelPresentation,
  HourglassTop,
  CheckCircle,
  Close,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Zoom,
  styled,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  convertCurrency,
  convertDataToQuery,
  descriptionAWB,
  getQueryUrl,
} from "../../helper/custom";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { handleError } from "../../helper/helpers";
import {
  cancelDataBagging,
  createDataSeal,
  getAllDataBagging,
} from "../../store/actions/scanAction";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/drone_error.mp3";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import * as XLSX from "xlsx";
import { addLog } from "../../store/actions/actionLog";

export default function BaggingPage() {
  const [baggingList, setBaggingList] = useState([]);
  const [listAwb, setListAwb] = useState([]);
  const [openSealNumber, setOpenSealNumber] = useState("");
  const [openListResi, setOpenListResi] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState([]);
  const [error, setError] = useState("");
  const [res, setRes] = useState([]);
  const [showResponse, setShowResponse] = useState(false);

  const [errorScan, setErrorScan] = useState("");
  const [inBagging, setInBagging] = useState([]);
  const [dataCancel, setDataCancel] = useState({});
  const [openModalScanAwb, setOpenModalScanAwb] = useState(false);
  const [openModalScanBag, setOpenModalScanBag] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const inputScanAwb = useRef(null);
  const inputScanBag = useRef(null);
  const fileInputRef = useRef(null);

  useMemo(() => {
    setTimeout(() => {
      if (openModalScanAwb) {
        inputScanAwb.current.focus();
        setErrorScan("");
      }
      if (openModalScanBag) {
        inputScanBag.current.focus();
        setErrorScan("");
      }
    }, 200);
  }, [openModalScanAwb, openModalScanBag]);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const push = (payload) => {
    Navigate(`/easy-drop/bagging${convertDataToQuery(payload)}`);
  };

  const getData = async () => {
    try {
      setError("");
      setLoading(true);
      const data = await getAllDataBagging(search);
      setCount(data.count);
      setBaggingList(data.rows);
      setLoading(false);
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
      setError(message);
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const scanAWB = (e) => {
    try {
      e.preventDefault();
      setErrorScan("");
      const newAWB = inputScanAwb.current?.value;

      const checkValidAWB = inBagging.find((item) =>
        item.awb.includes(newAWB.toUpperCase().trim())
      );

      if (checkValidAWB)
        throw new Error("Data resi yang anda input sudah ada sebelumnya");

      const payload = {
        awb: newAWB.toUpperCase().trim(),
        description: descriptionAWB(newAWB.toUpperCase().trim()),
        date: moment().format("DD MMM YYYY"),
        time: moment().format("HH:mm:ss"),
      };

      setInBagging([...inBagging, payload]);

      setRes({
        status: "success",
        message: `awb ${newAWB.toUpperCase().trim()} berhasil di scan`,
      });
      setShowResponse(true);
      new Audio(correct).play();
      e.target.reset();
    } catch (error) {
      new Audio(wrong).play();
      e.target.reset();
      setErrorScan(error.message);
    }
  };

  const scanBAG = async (e) => {
    e.preventDefault();
    try {
      const newBag = inputScanBag.current?.value;

      const checkDuplicateSeal = baggingList.find((item) =>
        item.seal.includes(newBag.toUpperCase().trim())
      );

      if (checkDuplicateSeal)
        throw new Error("Data nomor seal yang anda input sudah terdaftar");

      if (!inBagging.length)
        throw new Error(
          "Sebelum submit nomor seal pastikan paket sudah dimasukkan kedalam bagging"
        );

      const inStore = {
        payload: inBagging,
        seal: newBag.toUpperCase().trim(),
      };

      const results = await createDataSeal(inStore);

      setRes({
        status: "success",
        message: `nomor seal dengan ${newBag
          .toUpperCase()
          .trim()} berhasil di scan ${
          results?.awbCount !== inBagging.length
            ? `namun yang masuk hanya resi berikut [${results.awbInSeal}]`
            : ""
        }`,
      });
      setShowResponse(true);

      getData();
      setInBagging([]);
      setOpenModalScanAwb(false);
      setOpenModalScanBag(false);
      await addLog(`BIGPOS/Scan-Bagging[${newBag.toUpperCase().trim()}]`);
      new Audio(correct).play();
      e.target.reset();
    } catch (error) {
      new Audio(wrong).play();
      e.target.reset();
      const message = await handleError(error);
      setErrorScan(message);
      setInBagging([]);
    }
  };

  const scanBulk = async (file) => {
    try {
      let newBag;
      let newAWBs = [];

      if (file) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        newBag = rows[0][0].toUpperCase().trim();
        newAWBs = rows.slice(1).map((row) => row[0].toUpperCase().trim());
      } else {
        throw new Error("File tidak ditemukan");
      }

      const checkDuplicateSeal = baggingList.find((item) =>
        item.seal.includes(newBag)
      );

      if (checkDuplicateSeal)
        throw new Error("Data nomor seal yang anda input sudah terdaftar");

      const inStore = {
        payload: newAWBs.map((awb) => ({
          awb,
          description: descriptionAWB(awb),
          date: moment().format("DD MMM YYYY"),
          time: moment().format("HH:mm:ss"),
        })),
        seal: newBag.toUpperCase().trim(),
      };

      const results = await createDataSeal(inStore);

      setRes({
        status: "success",
        message: `Nomor seal dengan ${newBag
          .toUpperCase()
          .trim()} berhasil di scan ${
          results?.awbCount !== newAWBs.length
            ? `namun yang masuk hanya resi berikut [${results.awbInSeal}]`
            : ""
        }`,
      });
      setShowResponse(true);

      // getData();
      setInBagging([]);
      setOpenModalScanAwb(false);
      setOpenModalScanBag(false);
      await addLog(`BIGPOS/Scan-Bagging[${newBag.toUpperCase().trim()}]`);
      new Audio(correct).play();
      setTimeout(() => {
        window.location.reload();
      }, 750);
    } catch (error) {
      new Audio(wrong).play();
      const message = await handleError(error);
      setErrorScan(message);
      setInBagging([]);
      setTimeout(() => {
        window.location.reload();
      }, 750);
    }
  };

  // download template excel
  const downloadTemplate = async () => {
    try {
      let rows = [
        {
          "Seal Bagging disini": "AWB/Resi disini dan seterusnya kebawah",
          "<- Masukan Nomor Seal Bagging disini":
            "<- Masukan Nomor Resi/AWB disini dan seterusnya kebawah",
        },
      ];
      let wscols = [{ width: 40 }, { width: 50 }];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "Template_ScanBagging.xlsx");
      setRes({ status: "success", message: "berhasil download template" });
      setShowResponse(true);
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      setShowResponse(true);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      scanBulk(file);
      setOpenModalUpload(false);
    }
  };

  const handleCancelSeal = async (e) => {
    e.preventDefault();
    try {
      const inStore = {
        payload: dataCancel.in_seal,
        id: dataCancel.id,
      };

      await cancelDataBagging(inStore);
      setOpenConfirmCancel(false);
      getData();
    } catch (error) {
      setRes({ status: "error", message: error.message });
    }
  };

  // function transpose(matrix) {
  //   return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
  // }

  const handleDownload = async () => {
    let headers = [];
    let rows = [];

    baggingList.forEach((e, i) => {
      headers.push(e.seal);
      e.in_seal.forEach((item, index) => {
        if (!rows[index]) {
          rows[index] = [];
        }
        rows[index][i] = item.awb;
      });
    });

    let finalOutput = [headers, ...rows];

    const ws = utils.json_to_sheet(finalOutput, { skipHeader: true });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, "seal-data.xlsx");

    setRes({
      status: "success",
      message: "berhasil meng-unduh data seal tersebut",
    });

    await addLog("BIGPOS/Download-List-Bagging");
  };

  return (
    <article className="w-full p-4 mt-20 space-y-5">
      <section className="max-w-xs">
        {/* search input */}
        <FormControl
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          }}
          variant="filled"
        >
          <TextField
            size={matchesMobile ? "small" : "medium"}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
            placeholder="Cari Nomor Seal"
            // value={searchValue}
            // onChange={(e) => {
            //   setSearchValue(e.target.value);
            // }}
          />
        </FormControl>
      </section>
      <section className="flex gap-4">
        <div className="overflow-y-auto bg-white basis-3/4 max-h-[88dvh]">
          {/* table */}
          <TableContainer sx={{ maxHeight: "100%" }}>
            <Table size="large" stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>No. Seal Bagging</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Tanggal</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell colSpan={4}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <CircularProgress size={25} sx={{ color: "#333333" }} />
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <StyledTableCell colSpan={4}>
                      <Alert
                        variant="standard"
                        severity="error"
                        sx={{ borderRadius: 0 }}
                      >
                        {error}
                      </Alert>
                    </StyledTableCell>
                  </TableRow>
                ) : baggingList.length ? (
                  baggingList.map((row, i) => (
                    <TableRow key={row.id}>
                      <StyledTableCell>{row.seal}</StyledTableCell>
                      <StyledTableCell>
                        <div className="flex justify-center max-w-[40%]">
                          {row.status ? (
                            <CheckCircle color="success" />
                          ) : (
                            <HourglassTop color="disabled" />
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{row.input_date}</StyledTableCell>

                      <StyledTableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setListAwb(row.in_seal);
                            setOpenSealNumber(row.seal);
                            setOpenListResi(true);
                          }}
                        >
                          <Preview />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setOpenConfirmCancel(true);
                            setDataCancel(row);
                          }}
                        >
                          <CancelPresentation />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : null}
              </TableBody>
            </Table>
            {baggingList.length === 0 ? (
              <Alert variant="standard" severity="error">
                No Data
              </Alert>
            ) : null}
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              height: "3rem",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25]}
              component="div"
              count={count}
              rowsPerPage={+params.limit}
              page={count <= 0 ? 0 : +params.page - 1}
              onPageChange={(_, newPage) =>
                push({ ...query, page: newPage + 1 })
              }
              onRowsPerPageChange={({ target: { value = "10" } }) =>
                push({ ...query, limit: value, page: 1 })
              }
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        </div>
        <div className="flex flex-col justify-center gap-6 min-h-[10rem] p-3 bg-white basis-1/4 max-h-52">
          {/* input */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ab8c36",
              "&:hover": {
                backgroundColor: "#333333",
                boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
              },
            }}
            onClick={() => setOpenModalScanAwb(true)}
          >
            Scan Bagging
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#ab8c36",
              "&:hover": {
                backgroundColor: "#333333",
                boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
              },
            }}
            onClick={() => setOpenModalUpload(true)}
          >
            Bulking Excel
          </Button>
          <input
            type="file"
            accept=".xlsx, .xls" // Accept only Excel files
            style={{ display: "none" }} // Hide the input element
            ref={fileInputRef} // Reference to trigger the click
            onChange={handleFileChange} // Handle file selection
          />
          <Button
            color="success"
            variant="outlined"
            type="button"
            disabled={!!baggingList.length ? false : true}
            sx={{
              "&:disabled": {
                border: "1px solid #acf9bd",
                color: "#acf9bd",
              },
            }}
            onClick={handleDownload}
          >
            Unduh Data
          </Button>
        </div>
      </section>

      {/* modal upload */}
      <Modal
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalUpload}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
                marginY: 1,
              }}
            >
              {/* <Typography variant="h4" sx={{ ml: 2 }}>
                {typeUpload === "checker"
                  ? "Upload Hasil Checker"
                  : "Upload Order Marketplace"}
              </Typography> */}
              <Button
                size="small"
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  setOpenModalUpload(false);
                }}
              >
                <Close
                  sx={{
                    color: "#bababa",
                    "&:hover": { color: "#333333" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                height: "90%",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // paddingX: 4,
                // pb: 2,
              }}
            >
              <Box
                component="label"
                sx={{
                  width: "100%",
                  height: "80%",
                  border: 2,
                  borderRadius: 2,
                  borderColor: "#bababa",
                  borderStyle: "dashed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "#bababa",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#333333",
                    borderColor: "#333333",
                  },
                }}
              >
                <input type="file" hidden onChange={handleFileChange} />
                <Typography sx={{ fontSize: 15 }}>
                  Klik Disini Untuk Mengunggah File
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => downloadTemplate()}
                  variant="outlined"
                  sx={{
                    borderColor: "#333333",
                    color: "#333333",
                    "&:hover": {
                      borderColor: "#333333",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                >
                  Download Template
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* snackbar */}
      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ borderRadius: 0, maxWidth: "50%" }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      {/* modal list resi */}
      <Modal
        open={openListResi}
        onClose={() => setOpenListResi(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openListResi}>
          <Box
            sx={{
              width: "60%",
              height: "auto",
              boxShadow: 24,
              p: 0.8,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                pb: 1.5,
                alignItems: "center",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                backgroundColor: "#333333",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
                  Seal-{openSealNumber}
                </Typography>
                <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
                  Total data {listAwb.length}
                </Typography>
                <IconButton
                  sx={{
                    color: "#ffffff",
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#ffffff20",
                    },
                  }}
                  onClick={() => setOpenListResi(false)}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{
                  minHeight: "50%",
                  width: "100%",
                  px: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "70dvh",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No. Airwaybill</TableCell>
                        <TableCell align="center">From DOP</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Service</TableCell>
                        <TableCell align="center">Receiver</TableCell>
                        <TableCell align="center">Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {listAwb.length > 0
                        ? listAwb.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell align="center">{item.awb}</TableCell>
                              <TableCell align="center">
                                {item.shopName}
                              </TableCell>
                              <TableCell align="center">
                                {item.expedition}
                              </TableCell>
                              <TableCell align="center">
                                {item.service}
                              </TableCell>
                              <TableCell align="center">
                                {item.receiver}
                              </TableCell>
                              <TableCell align="center">
                                {convertCurrency(item.cost)}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {listAwb.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal open confirm cancel bag */}
      <Modal
        open={openConfirmCancel}
        onClose={() => setOpenConfirmCancel(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openConfirmCancel}>
          <Box
            sx={{
              width: "40%",
              height: "auto",
              boxShadow: 24,
              px: 3,
              py: 4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#ffffff",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 650,
                fontSize: "1.6rem",
              }}
            >
              Apakah anda yakin ingin membatalkan nomor seal tersebut?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                mt: 1.5,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenConfirmCancel(false)}
              >
                Tidak
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleCancelSeal}
              >
                Ya
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal open scan bag*/}
      <Modal
        open={openModalScanBag}
        onClose={() => setOpenModalScanBag(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalScanBag}>
          <Box
            sx={{
              width: "60%",
              height: "auto",
              boxShadow: 24,
              p: 1.4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#333333",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#ffffff", fontWeight: "bold", fontSize: "3rem" }}
            >
              SCAN BAGGING
            </Typography>
            <Box
              component="form"
              onSubmit={scanBAG}
              sx={{
                mt: 2,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                sx={{
                  width: "50%",
                  mb: 2,
                }}
                placeholder="input your seal"
                InputProps={{
                  sx: {
                    borderRadius: 0,
                    backgroundColor: "#ffffff",
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    },
                  },
                }}
                inputRef={inputScanBag}
              />
            </Box>
            {errorScan ? (
              <Alert
                variant="standard"
                severity="error"
                sx={{ position: "absolute", top: 80, left: "37%" }}
              >
                {errorScan}
              </Alert>
            ) : null}
          </Box>
        </Zoom>
      </Modal>

      {/* modal open scan awb */}
      <Modal
        open={openModalScanAwb}
        onClose={() =>
          inBagging.length > 0
            ? setOpenModalScanBag(true)
            : setOpenModalScanAwb(false)
        }
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalScanAwb}>
          <Box
            sx={{
              width: "60%",
              height: "auto",
              boxShadow: 24,
              p: 1.4,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#333333",
              textAlign: "center",
            }}
          >
            <Typography sx={{ color: "#ffffff", fontWeight: "bold" }}>
              Masukkan resi yang ingin anda masukkan ke Bagging
            </Typography>
            <Box
              component="form"
              onSubmit={scanAWB}
              sx={{
                my: 2,
                width: "100%",
                height: "auto",
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                autoComplete="off"
                sx={{
                  width: "30%",
                }}
                placeholder="input your resi"
                InputProps={{
                  sx: {
                    borderRadius: 0,
                    backgroundColor: "#ffffff",
                    "& input": {
                      textAlign: "center",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    },
                  },
                }}
                inputRef={inputScanAwb}
              />
              <Button
                type="button"
                variant="contained"
                color="secondary"
                sx={{
                  "&:disabled": {
                    border: "1px solid #f7f8f9",
                    color: "#fff",
                  },
                  maxWidth: "10rem",
                }}
                disabled={!!inBagging.length ? false : true}
                onClick={() => setOpenModalScanBag(true)}
              >
                {`${inBagging.length} MASUKKAN KE BAGGING`}
              </Button>
            </Box>

            <TableContainer
              sx={{
                maxHeight: "50dvh",
              }}
            >
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    <TableCell align="center">No. Airwaybill</TableCell>
                    <TableCell align="center">Description</TableCell>
                    <TableCell align="center">Tanggal</TableCell>
                    <TableCell align="center">Waktu</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "white",
                  }}
                >
                  {inBagging.length > 0
                    ? inBagging.map((item, idx) => (
                        <TableRow key={idx}>
                          <TableCell align="center">{idx + 1}</TableCell>
                          <TableCell align="center">{item.awb}</TableCell>
                          <TableCell align="center">
                            {item.description}
                          </TableCell>
                          <TableCell align="center">{item.date}</TableCell>
                          <TableCell align="center">{item.time}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              {inBagging.length === 0 ? (
                <Alert
                  variant="standard"
                  severity="warning"
                  sx={{ borderRadius: 0 }}
                >
                  Tidak ada data
                </Alert>
              ) : null}
              {errorScan ? (
                <Alert
                  variant="standard"
                  severity="error"
                  sx={{ position: "absolute", top: 80, left: "37%" }}
                >
                  {errorScan}
                </Alert>
              ) : null}
            </TableContainer>
          </Box>
        </Zoom>
      </Modal>
    </article>
  );
}
