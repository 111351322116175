import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import {
  getDestinationCode,
  getPrice,
  generateResi,
} from "../../store/actions/dropperAction";
import { styled, useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import jwt_decode from "jwt-decode";

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "130ch",
  width: "70rem",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
};

// const url = "http://localhost:3000";

export default function ModalCreateResi({ open, setOpen }) {
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const [values, setValues] = useState({
    // order_id: "",
    receiver_name: "",
    receiver_email: "",
    receiver_phone: "",
    receiver_address: "",
    receiver_zip: "",
    receiver_city: "",
    amount: "",
    weight: 1,
    special_instruction: "",
    goods_desc: "",
    qty: 1,
    shipper_name: "",
    shipper_email: "",
    shipper_phone: "",
    shipper_address: "",
    shipper_zip: "",
    shipper_city: "JAKARTA",
    goods_name: "",
    services: "",
    dropper_name: "",
    pickup_name: "",
    pickup_vehicle: "",
  });
  const [registrationCode, setRegistrationCode] = useState("");
  const [date, setDate] = useState(new Date());
  const [receiverAddressCode, setReceiverAddressCode] = useState("");
  const [goodsType, setGoodsType] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [clonePrice, setClonePrice] = useState(0);

  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const DestinationCode = async () => {
    try {
      const { data } = await getDestinationCode();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetPrice = async (payload) => {
    try {
      const { data } = await getPrice(payload);
      setValue(data);
    } catch (error) {
      console.error(error);
    }
  };

  const totalBiaya = () => {
    if (values.services && goodsType) {
      const res =
        value.length > 0 &&
        value.find((x) => x.service == values.services && x.type == goodsType);
      return +res?.price;
    } else {
      return `0`;
    }
  };

  useEffect(() => {
    DestinationCode();
    setValues({
      ...values,
      dropper_name: decode.name.toUpperCase(),
    });
  }, []);

  useMemo(() => {
    let priceNeed = {
      receiverAddressCode,
      weight: values.weight,
    };
    GetPrice(priceNeed);
  }, [receiverAddressCode, values.weight]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useMemo(() => {
    if (goodsType && values.services) {
      const res =
        value.length > 0 &&
        value.find((x) => x.service == values.services && x.type == goodsType);
      setValues({ ...values, amount: totalBiaya() });
      if (values.weight === 1) setClonePrice(+res?.price); //
    }
  }, [goodsType, values.services]);

  const handleAddData = async (e) => {
    e.preventDefault();
    try {
      await generateResi({
        ...values,
        goodsType,
        receiverAddressCode,
        DropperId: decode.id,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  function formatPriceToRupiah(price) {
    const rupiahFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }).format(+price);
    return `Rp ${
      isNaN(parseFloat(rupiahFormat.split("Rp")[1]))
        ? "0"
        : rupiahFormat.split("Rp")[1]
    }`;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute inset-0 bg-white flex flex-col max-w-screen-xl m-5 lg:m-auto max-h-[93vmax] lg:max-h-[43vmax] rounded-xl overflow-auto pb-5 px-0 lg:px-5">
          <h1 className="text-center text-2xl font-bold w-full p-4 lg:text-left">
            Bikin Resi Anda
          </h1>
          <form onSubmit={handleAddData}>
            <div className="p-3 grid grid-cols-2 gap-1 lg:grid-cols-6 lg:gap-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Tanggal Pengiriman"
                  inputFormat="dd/MM/yyyy"
                  value={date}
                  onChange={(e) => setDate(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ mt: 2, width: matchMobile ? 150 : "100%" }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <div className="mt-[17px]">
                <Autocomplete
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    setValues({
                      ...values,
                      receiver_city: newInputValue.split(",")[1],
                    });
                    setReceiverAddressCode(
                      newInputValue &&
                        data.find((el) => el.name === newInputValue)?.code
                    );
                  }}
                  id="controllable-states-demo"
                  // const filteredUsers =
                  options={data.map((el) => el.name)}
                  renderInput={(params) => (
                    <TextField {...params} label="Tempat Tujuan" />
                  )}
                />
              </div>
              <TextField
                value={values.weight}
                onChange={(e) =>
                  setValues({
                    ...values,
                    weight: e.target.value,
                    amount: clonePrice * e.target.value,
                  })
                }
                label="Berat Aktual (Kg)"
                variant="outlined"
                sx={{ width: matchMobile ? "9.3rem" : "auto" }}
                disabled={loading}
                size={"medium"}
                margin="normal"
                type="number"
              />
              <FormControl
                sx={{
                  mt: matchMobile ? "7px" : "15px",
                  width: matchMobile ? "9.3rem" : "auto",
                }}
                variant="outlined"
              >
                <InputLabel>Jenis Paket</InputLabel>
                <Select
                  label="Jenis Paket"
                  value={goodsType}
                  onChange={(e) => setGoodsType(e.target.value)}
                >
                  <MenuItem value={"Paket"}>Barang</MenuItem>
                  <MenuItem value={"Document/Paket"}>Document/Paket</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ mt: "15px" }}>
                <InputLabel>Layanan</InputLabel>
                <Select
                  label="Layanan"
                  value={values.services}
                  onChange={(e) =>
                    setValues({ ...values, services: e.target.value })
                  }
                >
                  {goodsType
                    ? value.length > 0 &&
                      value
                        .filter(
                          (x) =>
                            x.type == goodsType &&
                            x.service != "CTCYES" &&
                            x.service != "JTR250" &&
                            x.service != "JTR<150" &&
                            x.service != "JTR>250" &&
                            x.service != "CTCSPS"
                        )
                        .map((el, idx) => (
                          <MenuItem key={idx} value={el?.service}>
                            {el?.service == "CTC"
                              ? "REG"
                              : el?.service == "JTR"
                              ? "GIANT"
                              : el?.service}
                          </MenuItem>
                        ))
                    : value.length > 0 &&
                      value.map((el, idx) => {
                        return (
                          <MenuItem key={idx} value={el?.service}>
                            {el?.service}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
              <h1 className="text-2xl text-center text-red-500 font-bold">
                Total Biaya <br />
                {formatPriceToRupiah(totalBiaya())}
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-6 mx-3 my-2 lg:grid-cols-4">
              <div className="border rounded-lg border-gray-400 py-2 px-3">
                <h1 className="text-xl font-semibold">Informasi Umum</h1>
                <div className="grid grid-cols-2 gap-5 py-2">
                  <TextField
                    value={moment(date).format("DD/MM/YYYY")}
                    label="Tanggal"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={receiverAddressCode}
                    label="Kode Tujuan"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value="CASH"
                    label="Metode Pembayaran"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  {/* <TextField
                    value={values.pickup_name}
                    onChange={handleChange("pickup_name")}
                    label="Sprinter Pickup"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  /> */}
                  <TextField
                    label="Jenis Paket"
                    disabled
                    variant="standard"
                    size={"small"}
                    value={goodsType}
                    margin="none"
                  />
                  <TextField
                    value={values.receiver_zip}
                    onChange={handleChange("receiver_zip")}
                    label="Kode Pos Penerima"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={values.shipper_zip}
                    onChange={handleChange("shipper_zip")}
                    label="Kode Pos Pengirim"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  {/* <FormControl
                    sx={{
                      mt: "-2px",
                      // width: matchMobile ? "9.3rem" : "auto",
                    }}
                    variant="standard"
                  >
                    <InputLabel>Pickup Vehicle</InputLabel>
                    <Select
                      label="Pickup Vehicle"
                      value={values.pickup_vehicle}
                      onChange={handleChange("pickup_vehicle")}
                    >
                      <MenuItem value={"Motor"}>Motor</MenuItem>
                      <MenuItem value={"Mobil"}>Mobil</MenuItem>
                      <MenuItem value={"Truck"}>Truck</MenuItem>
                    </Select>
                  </FormControl> */}
                  {/* <TextField
                    value={values.dropper_name}
                    label="Dropper Name"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  /> */}
                </div>
              </div>
              <div className="border rounded-lg border-gray-400 py-2 px-3">
                <h1 className="text-xl font-semibold">Informasi Tagihan</h1>
                <div className="grid grid-cols-2 gap-5 py-2">
                  <TextField
                    value={`${values.weight} Kg`}
                    label="Berat Yang Ditagih"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={"null"}
                    label="Berat Volume"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={"null"}
                    label="Standar Biaya Kirim"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={"null"}
                    label="Biaya Kirim"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={"null"}
                    label="Biaya Asuransi"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={"null"}
                    label="Biaya Lainnya"
                    variant="standard"
                    disabled
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    className="col-span-2"
                    value={`${formatPriceToRupiah(totalBiaya())}`}
                    label="Total Biaya"
                    variant="standard"
                    sx={{ width: "100%" }}
                    disabled
                    size={"small"}
                    margin="none"
                  />
                </div>
              </div>
              <div className="border rounded-lg border-gray-400 py-2 px-3">
                <h1 className="text-xl font-semibold">Detail Barang</h1>
                <div className="grid grid-cols-2 gap-5 py-2">
                  <TextField
                    value={values.goods_name}
                    onChange={handleChange("goods_name")}
                    label="Nama Barang"
                    variant="standard"
                    sx={{ mt: "-5px" }}
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    label="Nilai Barang"
                    variant="standard"
                    sx={{ mt: "-5px" }}
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    className="col-span-2"
                    value={values.qty}
                    onChange={handleChange("qty")}
                    label="Jumlah Paket"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                    type="number"
                  />
                  <TextField
                    className="col-span-2"
                    value={values.goods_desc}
                    onChange={handleChange("goods_desc")}
                    label="Keterangan"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                    placeholder="Makanan, Pecah Belah, Cairan, Paket Biasa"
                  />
                </div>
              </div>
              <div className="border rounded-lg border-gray-400 py-2 px-3">
                <h1 className="text-xl font-semibold">Informasi Penerima</h1>
                <div className="grid grid-cols-2 gap-5 py-2">
                  <TextField
                    value={values.receiver_name}
                    onChange={handleChange("receiver_name")}
                    label="Nama"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={values.receiver_phone}
                    onChange={handleChange("receiver_phone")}
                    label="No. HP"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  {/* <TextField
                    value={values.receiver_email}
                    onChange={handleChange("receiver_email")}
                    label="Email"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={values.receiver_zip}
                    onChange={handleChange("receiver_zip")}
                    label="ZIP"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  /> */}
                  <TextField
                    className="col-span-2"
                    value={values.receiver_address}
                    onChange={handleChange("receiver_address")}
                    label="Alamat"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <h1 className="text-xl font-semibold col-span-2">
                    Informasi Pengirim
                  </h1>
                  <TextField
                    value={values.shipper_name}
                    onChange={handleChange("shipper_name")}
                    label="Nama"
                    variant="standard"
                    sx={{ mt: "-6px" }}
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={values.shipper_phone}
                    onChange={handleChange("shipper_phone")}
                    label="No. HP"
                    variant="standard"
                    sx={{ mt: "-6px" }}
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  {/* <TextField
                    value={values.shipper_email}
                    onChange={handleChange("shipper_email")}
                    label="Email"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                  <TextField
                    value={values.shipper_zip}
                    onChange={handleChange("shipper_zip")}
                    label="ZIP"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  /> */}
                  <TextField
                    className="col-span-2"
                    value={values.shipper_address}
                    onChange={handleChange("shipper_address")}
                    label="Alamat"
                    variant="standard"
                    disabled={loading}
                    size={"small"}
                    margin="none"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center py-2 lg:py-5">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  backgroundColor: "#ab8c36",
                  color: "#ffffff",
                  minWidth: "220px",
                  "&:hover": {
                    backgroundColor: "#333333",
                  },
                }}
                type="submit"
              >
                Bikin Resi
              </Button>
            </div>
          </form>
          <span className="text-red-500 text-sm text-center px-3">
            *Catatan: metode pembayaran dibayarkan oleh pengirim
          </span>
        </div>
      </Modal>
    </>
  );
}
