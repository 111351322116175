import { DeleteOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Slide,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Zoom,
  tableCellClasses,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  AddAdminEasyDrop,
  allStaff,
  deleteStaff,
} from "../../store/actions/sellerAction";
import { handleError } from "../../helper/helpers";

export default function UserAdminPage() {
  const [openModalAddAdmin, setOpenModalAddAdmin] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [showPassword, setShowPassword] = useState("password");
  const [res, setRes] = useState({ status: "", message: "" });

  const [adminData, setAdminData] = useState({
    email: "",
    username: "",
    password: "",
  });

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  const addAdminEasyDrop = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let newAdmin = await AddAdminEasyDrop(adminData);
      setOpenModalAddAdmin(false);
      await new Promise((resolve) => setTimeout(resolve, 800));
      setLoading(false);
      setAdminData({ email: "", username: "", password: "" });
      setShowResponse(true);
      delete newAdmin.data.data.password;
      delete newAdmin.data.data.updatedAt;
      delete newAdmin.data.data.createdAt;
      setAdminList([...adminList, newAdmin.data.data]);
      setRes({
        status: "success",
        message: `Success! added admin ${newAdmin.data.data.username}`,
      });
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
      console.log(message);
    }
  };

  const getStaff = async () => {
    const allAdmin = await allStaff();
    const onlyAdmin = allAdmin.filter((item) => !item.isOwner);
    setAdminList(onlyAdmin);

    setLoading(false);
  };

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        pb: 3,
      }}
    >
      <Modal
        open={openModalAddAdmin}
        onClose={() => setOpenModalAddAdmin(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalAddAdmin}>
          <Box
            sx={{
              width: matchesMobile ? "95%" : "50%",
              height: "50%",
              backgroundColor: "white",
              paddingY: 5,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                height: "80%",
                paddingX: matchesMobile ? 0 : 4,
                maxWidth: "70%",
                margin: "0 auto",
              }}
            >
              <TextField
                margin="normal"
                required
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                sx={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                }}
                variant="standard"
                disabled={loading}
                value={adminData.email}
                onChange={(e) =>
                  setAdminData({ ...adminData, email: e.target.value })
                }
                size={matchesMobile ? "small" : "medium"}
              />
              <TextField
                margin="normal"
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                sx={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                }}
                variant="standard"
                disabled={loading}
                value={adminData.username}
                onChange={(e) =>
                  setAdminData({ ...adminData, username: e.target.value })
                }
                size={matchesMobile ? "small" : "medium"}
              />
              <TextField
                margin="normal"
                required
                id="password"
                label="Password"
                name="password"
                type={showPassword}
                autoComplete="password"
                sx={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                }}
                variant="standard"
                disabled={loading}
                value={adminData.password}
                onChange={(e) =>
                  setAdminData({ ...adminData, password: e.target.value })
                }
                size={matchesMobile ? "small" : "medium"}
                InputProps={{
                  endAdornment: (
                    <div style={{ marginTop: "-19px" }}>
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) => {
                            if (showPassword === "password") {
                              setShowPassword("text");
                            } else {
                              setShowPassword("password");
                            }
                          }}
                          edge="end"
                        >
                          {showPassword === "password" ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </div>
                  ),
                }}
              />
            </Box>

            <Divider orientation="horizontal" />

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                height: "10%",
                // marginY: 2,
                paddingX: 4,
                mt: 2,
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalAddAdmin(false);
                  setAdminData({ username: "", email: "", password: "" });
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                disabled={loading}
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={addAdminEasyDrop}
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Modal
        // open={openModalDelete}
        // onClose={() => setOpenModalDelete(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom
        // in={openModalDelete}
        >
          <Box
            sx={{
              // width: matchesMobile ? "auto" : "auto",
              backgroundColor: "white",
              p: 3,
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <Box>
              <Typography variant="h4">
                {`Apakah Kamu yakin menghapus User "${selectedUser.username}" ini ?`}
              </Typography>
            </Box> */}
            <Box
              sx={{
                width: "100%",
                mt: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                // onClick={(e) => {
                //   setOpenModalDelete(false);
                //   setSelectedUser({ id: "", i: "", username: "" });
                // }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                // onClick={(e) => deleteHandler(selectedUser)}
                variant="contained"
                sx={{
                  width: "10%",
                  backgroundColor: "#bd454f",
                  "&:hover": {
                    backgroundColor: "#bd454f",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Hapus
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "20%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            flexDirection: "column",
            mt: matchesMobile ? 1 : 0,
          }}
        >
          <Box
            sx={{
              height: "30%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                height: "100%",
                mb: matchesMobile ? 1 : 2,
                mr: 2,
                backgroundColor: "#ab8c36",
                boxShadow: matchesMobile
                  ? null
                  : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                "&:hover": {
                  backgroundColor: "#333333",
                  boxShadow: "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                },
              }}
              onClick={() => setOpenModalAddAdmin(true)}
            >
              Tambah Admin
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#ffffff",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Username</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>

                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <StyledTableCell colSpan={4}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <CircularProgress
                            size={25}
                            sx={{ color: "#333333" }}
                          />
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  ) : adminList.length ? (
                    adminList.map((row, i) => (
                      <TableRow key={i}>
                        <StyledTableCell>{row.username}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>Active</StyledTableCell>

                        <StyledTableCell align="center">
                          <IconButton
                            sx={{ borderRight: 1 }}
                            onClick={async () => {
                              const filterDeleteAdmin = adminList.filter(
                                (item) => item.id !== row.id
                              );
                              setLoading(true);
                              await new Promise((resolve, reject) =>
                                setTimeout(resolve, 500)
                              );
                              setAdminList(filterDeleteAdmin);
                              await deleteStaff(row.id);
                              setLoading(false);
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
              {adminList.length === 0 ? (
                <Alert variant="standard" severity="error">
                  No Data
                </Alert>
              ) : null}
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

// {sellerList.length
//   ? sellerList.map((row, i) =>
//       i === isEdit ? (
//         <TableRow
//           key={i}
//           sx={{
//             "&:last-child td, &:last-child th": {
//               border: 0,
//             },
//             backgroundColor: "#33333330",
//           }}
//         >
//           <TableCell component="th" scope="row">
//             <TextField
//               value={username}
//               label="Username"
//               variant="standard"
//               onChange={(e) => setUsername(e.target.value)}
//               error={errorFieldEdit.username ? true : false}
//               helperText={
//                 errorFieldEdit.username
//                   ? errorFieldEdit.username
//                   : ""
//               }
//             />
//           </TableCell>
//           <TableCell>
//             <TextField
//               value={email}
//               label="email"
//               variant="standard"
//               onChange={(e) => setEmail(e.target.value)}
//               error={errorFieldEdit.email ? true : false}
//               helperText={
//                 errorFieldEdit.email
//                   ? errorFieldEdit.email
//                   : ""
//               }
//             />
//           </TableCell>
//           <TableCell>
//             {userAccessStatus === "root" ? (
//               <FormControl fullWidth>
//                 <InputLabel
//                   variant="standard"
//                   htmlFor="uncontrolled-native"
//                 >
//                   status
//                 </InputLabel>
//                 <NativeSelect
//                   value={accessStatus}
//                   inputProps={{
//                     name: "Status",
//                     id: "uncontrolled-native",
//                   }}
//                   onChange={(e) =>
//                     setAccessStatus(e.target.value)
//                   }
//                 >
//                   {/* {accessStatus === "root" ? (
//                   <option value={"admin"}>admin</option>
//                 ) : null} */}
//                   <option value={"admin"}>admin</option>
//                   <option value={"checker"}>checker</option>
//                 </NativeSelect>
//               </FormControl>
//             ) : (
//               accessStatus
//             )}
//           </TableCell>

//           <TableCell align="center">
//             <IconButton
//               sx={{ color: "#000000", borderRight: 1 }}
//               onClick={(e) => {
//                 setIsEdit(-1);
//               }}
//             >
//               <CancelOutlined />
//             </IconButton>
//             <IconButton
//               sx={{ color: "#000000", borderRight: 1 }}
//               onClick={(e) => editSubmit(e)}
//             >
//               <DoneOutlined />
//             </IconButton>
//           </TableCell>
//         </TableRow>
//       ) : (
//         <TableRow key={i}>
//           <StyledTableCell>{row.username}</StyledTableCell>
//           <StyledTableCell>{row.email}</StyledTableCell>
//           <StyledTableCell>
//             {row.accessStatus}
//           </StyledTableCell>
//           {userAccessStatus !== "checker" ? (
//             <StyledTableCell align="center">
//               <IconButton
//                 sx={{ borderRight: 1 }}
//                 onClick={() => {
//                   setEditId(row.id);

//                   setOpenModalPassword(true);
//                 }}
//               >
//                 <Password />
//               </IconButton>
//               <IconButton
//                 sx={{ borderRight: 1 }}
//                 onClick={() => {
//                   setErrorFieldEdit({
//                     email: "",
//                     username: "",
//                   });
//                   setIsEdit(i);
//                   setEditId(row.id);
//                   setAccessStatus(row.accessStatus);
//                   setUsername(row.username);
//                   setEmail(row.email);
//                 }}
//               >
//                 <EditOutlined />
//               </IconButton>
//               <IconButton
//                 sx={{ borderRight: 1 }}
//                 onClick={() => {
//                   setOpenModalDelete(true);
//                   setSelectedUser({
//                     id: row.id,
//                     i,
//                     username: row.username,
//                   });
//                 }}
//               >
//                 <DeleteOutlined />
//               </IconButton>
//             </StyledTableCell>
//           ) : null}
//         </TableRow>
//       )
//     )
//   : null}
