import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Tooltip,
  Zoom,
  TablePagination,
  CircularProgress,
  Alert,
  MenuItem,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Snackbar,
  Slide,
  Select,
  Chip,
  Divider,
  Badge,
} from "@mui/material";
import correct from "../../audio/correct.mpeg";
import wrong from "../../audio/drone_error.mp3";
import { ClipLoader } from "react-spinners";
// import jwt_decode from "jwt-decode";
import { read, utils, writeFile } from "xlsx";

import {
  DeleteForever,
  Close,
  CancelOutlined,
  Search,
} from "@mui/icons-material";

import {
  ThisMonth,
  convertDataToQuery,
  getQueryUrl,
} from "../../helper/custom";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formatDate } from "../../helper/custom";
import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { handleError } from "../../helper/helpers";
import {
  getAllData,
  scanBulkData,
  uploadData,
  // PushNotificationWA,
} from "../../store/actions/scanAction";
import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { compress } from "compress-json";
import ModalLoading from "../../components/Reuse/modal/ModalLoading";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { BasicModal } from "../../components/Reuse/modal";
import { addLog } from "../../store/actions/actionLog";

function ScanPage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const push = (payload) => {
    navigate(`/easy-drop/scan${convertDataToQuery(payload)}`);
  };
  const [searchValue, setSearchValue] = useState(params.search || "");
  const [audioCorrect] = useState(new Audio(correct));
  const [audioWrong] = useState(new Audio(wrong));
  const [errorScan, setErrorScan] = useState("");
  // const selectedStatus = params.status;
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const upper = useRef(null);
  const inputScanAwb = useRef(null);
  const inputShipper = useRef(null);
  const [scanManual, setScanManual] = useState(false);
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  const [state, setState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [text, setText] = useState("");
  //for decode token
  // const token = localStorage.getItem("access_token");
  // const decode = jwt_decode(token);
  // const accessStatus = decode.accessStatus;
  const [error, setError] = useState("");
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingScanManual, setLoadingScanManual] = useState(false);
  const [dataScan, setDataScan] = useState([]);
  // const [expedition, setExpedition] = useState("JNT");
  const [services, setServices] = useState("Regular");
  const [count, setCount] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalErrorChecked, setOpenModalErrorChecked] = useState(false);
  const [errorUploadChecked, setErrorUploadChecked] = useState([]);
  const [res, setRes] = useState([]);
  const [isOpenAlertExit, setIsOpenAlertExit] = useState(false);
  const [isOpenAlertSave, setIsOpenAlertSave] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [openModalDateRange, setOpenModalDateRange] = useState(false);

  const scrollToUpper = () => {
    if (upper.current)
      upper.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
  };

  const getDuplicateAWB = (data) => {
    let hasil = false;
    if (inputScanAwb.current) {
      const result = data.filter((item) =>
        item.awb
          .toLowerCase()
          .trim()
          .includes(inputScanAwb.current.value.toLowerCase().trim())
      );
      if (result.length > 0) hasil = true;
    }
    return hasil;
  };

  useMemo(() => {
    setTimeout(() => {
      if (scanManual) {
        inputScanAwb.current.focus();
        setErrorScan("");
      }
      if (dataScan.length > 0) {
        scrollToUpper();
      }
    }, 200);
  }, [scanManual, dataScan]);

  const getData = async () => {
    try {
      setError("");
      setLoading(true);
      const data = await getAllData(search);
      setCount(data.count);
      setAllData(data.rows);
      setLoading(false);
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
      setError(message);
    }
  };

  const onStoreData = async () => {
    setLoadingScanManual(true);
    try {
      const result = await scanBulkData(dataScan);
      inputShipper.current.value = "";
      await addLog(`BIGPOS/Scan-Cashless[${dataScan.length}]`);
      if (result.duplicateAWB.length > 0) {
        setRes({
          status: "warning",
          message: `Berhasil input data dari file excel namun AWB berikut [${result.duplicateAWB
            .slice(0, 10)
            .join(", ")}] sudah terinput dalam database`,
        });
        setShowResponse(true);
      }
      setScanManual(false);
      setIsOpenAlertSave(false);
      setDataScan([]);
      setLoadingScanManual(false);
      // getData();
      setTimeout(() => {
        window.location.reload();
      }, 750);
    } catch (error) {
      console.log(error);
    }
  };

  const scanSubmit = (e) => {
    try {
      e.preventDefault();
      setErrorScan("");
      const newAWB = inputScanAwb.current?.value;

      if (!newAWB) throw new Error("Silakan masukkan nomor resi");
      if (newAWB.trim().length > 18)
        throw new Error("Nomor resi tidak boleh lebih dari 18 karakter");

      const jntCodes = [
        "JX",
        "JB",
        "JP",
        "JZ",
        "JD",
        "TKP01",
        "TKJNT",
        "TKRES",
        "TJNT",
        "JO",
        "JJ",
        "131",
      ];

      const awbPrefix2 = newAWB.trim().substring(0, 2).toUpperCase();
      const awbPrefix3 = newAWB.trim().substring(0, 3).toUpperCase();
      const awbPrefix4 = newAWB.trim().substring(0, 4).toUpperCase();
      const awbPrefix5 = newAWB.trim().substring(0, 5).toUpperCase();
      const isValidJnt =
        jntCodes.includes(awbPrefix2) ||
        jntCodes.includes(awbPrefix3) ||
        jntCodes.includes(awbPrefix4) ||
        jntCodes.includes(awbPrefix5);

      if (!isValidJnt)
        throw new Error(
          "Maaf, nomor resi yang Anda masukkan tidak berasal dari layanan pengiriman yang digunakan"
        );

      const duplicated = getDuplicateAWB(dataScan);
      if (duplicated)
        throw new Error(
          "Nomor resi yang Anda masukkan sudah discan sebelumnya"
        );

      const dataFromCurrentList = allData.find((item) =>
        item.awb.includes(newAWB.toUpperCase().trim())
      );
      if (dataFromCurrentList)
        throw new Error(
          "Nomor resi yang Anda masukkan sudah ada dalam basis data"
        );

      let number = dataScan.length + 1;
      if (number < 10) number = `00${number}`;
      else if (number < 100) number = `0${number}`;

      const newScanData = {
        order: number,
        awb: newAWB.toUpperCase().trim(),
        services,
        date: moment().format("DD MMM YYYY"),
        time: moment().format("HH:mm:ss"),
      };
      const updatedDataScan = [...dataScan, newScanData];
      setDataScan(updatedDataScan);
      setRes({
        status: "success",
        message: `AWB ${newAWB.toUpperCase().trim()} berhasil discan`,
      });
      setShowResponse(true);
      audioCorrect.play();
      e.target.reset();
    } catch (error) {
      audioWrong.play();
      e.target.reset();
      setErrorScan(error.message);
    }
  };

  const fileHandler = async (e) => {
    try {
      setText("Upload sedang berlangsung");
      setLoadingUpload(true);
      setError("");
      setErrorUploadChecked([]);
      e.preventDefault();
      const getFiles = (() => {
        try {
          return e.target.files[0];
        } catch (e) {
          return null;
        }
      })();

      const f = await getFiles.arrayBuffer();
      const wb = read(f);
      const sheet = wb.SheetNames;
      const ws = wb.Sheets[sheet[0]];
      Object.keys(ws).forEach((s) => {
        if (/c/i.test(s)) {
          if (s !== "C1") {
            if (typeof ws[s].v === "string") {
              ws[s].w = ws[s].w.split("/");

              const element = ws[s].w.splice(0, 1)[0];

              ws[s].w.splice(1, 0, element);
              ws[s].w = ws[s].w.join("/");
            }
          }
        }
      });

      const data = utils.sheet_to_json(ws, {
        raw: false,
      }); //? UBAH DATA DARI EXEL KE DALAM BENTUK JSON

      const excel = utils.sheet_to_json(ws, { header: 1 });
      let statusError = false;
      // console.log(excel[0][1].trim());
      if (
        excel[0][0].trim() !== "*Airwaybill" ||
        excel[0][1].trim() !== "Service"
      ) {
        setErrorUploadChecked([
          { status: "Format table salah harap menggunakan tamplate terbaru" },
        ]);
        statusError = true;
      } else {
        let last = false;
        let _newData = { errors: [], totalCreate: 0, totalUpdate: 0 };
        let newData = [];
        while (!last) {
          newData = data.splice(0, 10000);

          last = data.length === 0;
          let compressed = compress(newData);

          //! UPLOAD EXEL, AKAN DIKIRIM KE BACKEND DAN AKAN DICREATE DI DATABASE.S
          const _data = await uploadData(compressed);
          _newData.errors = _newData.errors.concat(_data.errors);
          _newData.totalCreate += _data.totalCreate;
          _newData.totalUpdate += _data.totalUpdate;
        }
        if (_newData.errors.length) {
          setErrorUploadChecked(_newData.errors);
          setOpenModalErrorChecked(true);
          statusError = true;
          setLoadingUpload(false);
          return;
        }
        // await PushNotificationWA({
        //   total_data: _newData.totalCreate,
        //   fill_data: newData,
        // });
      }

      setLoadingUpload(false);
      setOpenModalUpload(false);
      if (statusError) {
        setOpenModalErrorChecked(true);
      } else {
        setRes({
          status: "success",
          message:
            "berhasil upload data, silahkan periksa kembali data tersebut",
        });
        setShowResponse(true);

        setTimeout(async () => {
          // await getData();
          await addLog("BIGPOS/Upload-Scan-Cashless");
          window.location.reload();
        }, 750);
      }
    } catch (err) {
      const message = await handleError(err);
      if (Array.isArray(message)) {
        if (message.length) {
          setErrorUploadChecked(message);
        }
      } else if (message === "Tidak ada koneksi") {
        setErrorUploadChecked([{ status: message }]);
      } else {
        setErrorUploadChecked([{ status: message }]);
      }

      setLoadingUpload(false);
      setOpenModalUpload(false);
      setOpenModalErrorChecked(true);
      setRes({ status: "error", message: "gagal upload data order" });
      setShowResponse(true);
    }
    e.target.value = null;
  };

  // download template excel
  const downloadTemplate = async () => {
    try {
      let rows = [
        {
          "*Airwaybill": "",
          Service: "",
        },
      ];
      let wscols = [{ width: 20 }, { width: 20 }, { width: 20 }];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, "Upload_Data.xlsx");
      setRes({ status: "success", message: "berhasil download template" });
      setShowResponse(true);
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
      if (message.cancel) return true;
      setRes({ status: "error", message: "gagal download template" });
      setShowResponse(true);
    }
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleCetakResi = async () => {
    try {
      if (!dataScan.length)
        throw new Error("Tidak boleh cetak resi dalam keadaan kosong");

      const rows = dataScan.map((row) => {
        return {
          "*Airwaybill": row.awb,
          Service: row.services,
          PENGIRIM: inputShipper.current?.value,
          STATUS: "On Process",
          TANGGAL: `${row.date} ${row.time}`,
        };
      });
      let wscols = [
        { width: 18 },
        { width: 15 },
        { width: 13 },
        { width: 15 },
        { width: 22 },
      ];

      const ws = utils.json_to_sheet(rows);
      ws["!cols"] = wscols;
      const wb = utils.book_new();

      utils.book_append_sheet(wb, ws, "Data");
      writeFile(wb, `resi_${moment().format("DD/MM/YYYY")}.xlsx`);
      setRes({ status: "success", message: "berhasil unduh tabel list" });
      setShowResponse(true);
      await addLog("BIGPOS/Download-Table");
    } catch (error) {
      setRes({ status: "error", message: error.message });
    }
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        maxHeight: {
          lg: "100vh",
          md: "100vh",
          sm: "100vh",
          xs: "auto",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
      }}
    >
      {/* snackbar */}
      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Modal
        open={openModalDateRange}
        onClose={() => setOpenModalDateRange(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalDateRange}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => setState(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={matchesMobile ? 1 : 2}
              ranges={[state]}
              direction={matchesMobile ? "vertical" : "horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={matchesMobile ? [] : undefined}
              staticRanges={matchesMobile ? [] : undefined}
              // inputRanges={[]}
              // staticRanges={[]}
              className={
                matchesMobile
                  ? {
                      rdrStaticRanges: {
                        width: "0px",
                      },
                      rdrDateRangePickerWrapper: {
                        display: "flex !important",
                        justifyContent: "center !important",
                        alignItems: "center !important",
                        width: "100% !important",
                        position: "relative !important",
                      },
                    }
                  : null
              }
            />
            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => setOpenModalDateRange(false)}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  push({
                    ...query,
                    minDate: moment(state.startDate).format("MM-DD-YYYY"),
                    maxDate: moment(state.endDate).format("MM-DD-YYYY"),
                  });
                  setOpenModalDateRange(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>

      {/* modal error */}
      <Modal
        open={openModalErrorChecked}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalErrorChecked}>
          <Box
            sx={{
              width: "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "10%",
                width: "100%",
              }}
            >
              <Button
                size="small"
                sx={{
                  color: "#bababa",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#333333",
                  },
                }}
                onClick={() => {
                  setOpenModalErrorChecked(false);
                }}
              >
                <Close />
              </Button>
            </Box>
            <Box
              sx={{
                height: "85%",
                width: "100%",
              }}
            >
              <TableContainer
                sx={{
                  backgroundColor: "#ffffff",
                  maxHeight: "100%",
                }}
              >
                <Table
                  sx={{
                    backgroundColor: "#ffffff",
                  }}
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Keterangan</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {JSON.stringify(errorUploadChecked)} */}
                    {errorUploadChecked.length
                      ? errorUploadChecked.map((e, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {`${e.awb ? e.awb : ""} ${
                                e.status ? e.status : ""
                              }`}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal upload */}
      <Modal
        open={openModalUpload}
        onClose={() => setOpenModalUpload(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModalUpload}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "50%",
              height: "50%",
              backgroundColor: "white",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10%",
                width: "100%",
                marginY: 1,
              }}
            >
              {/* <Typography variant="h4" sx={{ ml: 2 }}>
                {typeUpload === "checker"
                  ? "Upload Hasil Checker"
                  : "Upload Order Marketplace"}
              </Typography> */}
              <Button
                size="small"
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                }}
                onClick={() => {
                  setOpenModalUpload(false);
                }}
              >
                <Close
                  sx={{
                    color: "#bababa",
                    "&:hover": { color: "#333333" },
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                height: "90%",
                width: "90%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // paddingX: 4,
                // pb: 2,
              }}
            >
              <Box
                component="label"
                sx={{
                  width: "100%",
                  height: "80%",
                  border: 2,
                  borderRadius: 2,
                  borderColor: "#bababa",
                  borderStyle: "dashed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "#bababa",
                  "&:hover": {
                    cursor: "pointer",
                    color: "#333333",
                    borderColor: "#333333",
                  },
                }}
              >
                <input type="file" hidden onChange={fileHandler} />
                <Typography sx={{ fontSize: 15 }}>
                  Klik Disini Untuk Mengunggah File
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "20%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => downloadTemplate()}
                  variant="outlined"
                  sx={{
                    borderColor: "#333333",
                    color: "#333333",
                    "&:hover": {
                      borderColor: "#333333",
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                  }}
                >
                  Download Template
                </Button>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal loading */}
      <ModalLoading loadingUpload={loadingUpload} text={text} />

      {/* modal scan list */}
      <Modal
        open={scanManual}
        // onClose={() => setScanManual(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={scanManual}>
          <Box
            sx={{
              width: "90%",
              height: "auto",
              backgroundColor: "white",
              boxShadow: 24,
              p: 0.8,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                pb: 3,
                alignItems: "center",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                backgroundColor: "#333333",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "98%",
                  height: "10%",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontWeight: "bold", mb: 0.5 }}
                >
                  Scan Manual
                </Typography>
                <IconButton
                  sx={{
                    color: "#ffffff",
                    height: "fit-content",
                    "&:hover": {
                      backgroundColor: "#ffffff20",
                    },
                  }}
                  onClick={() => {
                    if (dataScan.length > 0) {
                      setIsOpenAlertExit(true);
                      audioWrong.play();
                    } else {
                      setScanManual(false);
                    }
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: "5%",
                  py: "0.7rem",
                  width: "100%",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    width: "20%",
                    // mb: 2,
                  }}
                  placeholder="Nama Pengirim"
                  InputProps={{
                    sx: {
                      borderRadius: 0,
                      backgroundColor: "#ffffff",
                      "& input": {
                        textAlign: "left",
                        fontWeight: "bold",
                        // textTransform: "uppercase",
                      },
                    },
                  }}
                  inputRef={inputShipper}
                />

                <Tooltip title="Choose the service" placement="right">
                  <Select
                    value={services}
                    onChange={(event) => setServices(event.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      minWidth: matchesMobile ? 120 : 160,
                      borderRadius: 0,
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Choose Services</em>
                    </MenuItem>
                    <MenuItem value={"Regular"}>REG</MenuItem>
                    <MenuItem value={"Economy"}>ECO</MenuItem>
                    <MenuItem value={"Document"}>DOC</MenuItem>
                  </Select>
                </Tooltip>
              </Box>
              <Box
                // component=""
                // onSubmit={scanSubmit}
                sx={{
                  height: "50%",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "#ffffff", mb: 2 }} variant="h4">
                  Data yang di Scan hari ini
                </Typography>
                <TableContainer
                  sx={{
                    maxHeight: "40dvh",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">NO.ORDER</TableCell>
                        <TableCell align="center">NO.RESI</TableCell>
                        <TableCell align="center">Tanggal Scan</TableCell>
                        <TableCell align="center">Waktu</TableCell>
                        <TableCell align="center">Tindakan</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "white",
                      }}
                    >
                      {dataScan.length > 0
                        ? dataScan.map((item, index) => (
                            <TableRow
                              key={index}
                              ref={
                                Object.keys(item)[Object.keys(item).length - 1]
                                  ? upper
                                  : null
                              }
                            >
                              <TableCell align="center">{item.order}</TableCell>
                              <TableCell align="center">{item.awb}</TableCell>
                              <TableCell align="center">{item.date}</TableCell>
                              <TableCell align="center">{item.time}</TableCell>
                              <TableCell align="center">
                                <Tooltip title="Batalkan Resi" arrow>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      const filterResi = dataScan.filter(
                                        (el) => el.order !== item.order
                                      );
                                      setDataScan(filterResi);
                                      // localStorage.setItem(
                                      //   "dataScan",
                                      //   JSON.stringify(filterResi)
                                      // );
                                    }}
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                  {dataScan.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
                {errorScan ? (
                  <Alert
                    variant="standard"
                    severity="error"
                    sx={{ position: "absolute", top: 80 }}
                  >
                    {errorScan}
                  </Alert>
                ) : null}
              </Box>
              <Box
                component="form"
                onSubmit={scanSubmit}
                sx={{
                  my: 3,
                  height: "40%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  sx={{
                    width: "50%",
                    mb: 2,
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: 0,
                      backgroundColor: "#ffffff",
                      "& input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      },
                    },
                  }}
                  inputRef={inputScanAwb}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Button
                  type="button"
                  onClick={() => setIsOpenAlertSave(true)}
                  variant="contained"
                  disabled={dataScan.length > 0 ? false : true}
                  sx={{
                    mt: -1.5,
                    backgroundColor: "#ffffff",
                    color: "#333333",
                    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      color: "#ffffff",
                    },
                    "&:disabled": {
                      backgroundColor: "#b5b5b5",
                      color: "#dddddd",
                    },
                  }}
                >
                  Simpan Daftarkan List
                </Button>
                <Badge badgeContent={"new"} color="error">
                  <Button
                    type="button"
                    onClick={handleCetakResi}
                    variant="contained"
                    disabled={dataScan.length > 0 ? false : true}
                    sx={{
                      // mt: -1.5,
                      backgroundColor: "#ffffff",
                      color: "#333333",
                      boxShadow:
                        "0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important",
                      "&:hover": {
                        backgroundColor: "#333333",
                        color: "#ffffff",
                      },
                      "&:disabled": {
                        backgroundColor: "#b5b5b5",
                        color: "#dddddd",
                      },
                    }}
                  >
                    Unduh Tabel
                  </Button>
                </Badge>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      {/* modal confirmation exit */}
      <BasicModal
        open={isOpenAlertExit}
        onClose={setIsOpenAlertExit}
        title={<em className="text-red-500">Oops... don't forget it!</em>}
      >
        <div className="pt-4">
          <h3 className="w-full max-w-md text-xl font-semibold text-center">
            Apakah anda yakin ingin keluar tanpa menyimpan data tersebut
            terlebih dahulu?
          </h3>
          <Divider orientation="horizontal" sx={{ my: 0.7 }} />
          <div className="inline-flex justify-center w-full gap-2">
            <button
              type="button"
              onClick={() => setIsOpenAlertExit(false)}
              className="px-5 py-1 text-lg text-gray-600 border border-gray-500 rounded-lg"
            >
              Batal
            </button>
            <button
              type="button"
              onClick={() => {
                setScanManual(false);
                setIsOpenAlertExit(false);
              }}
              className="px-5 py-1 text-lg text-white bg-red-400 rounded-lg"
            >
              Ya
            </button>
          </div>
        </div>
      </BasicModal>

      {/* modal confirmation save data scan */}
      <BasicModal
        open={isOpenAlertSave}
        onClose={setIsOpenAlertSave}
        title={
          <em className="text-red-500">
            Oops... remember it! all data will be save permanent
          </em>
        }
      >
        <div className="pt-4">
          <h3 className="w-full max-w-md text-xl font-semibold text-center">
            Total data {dataScan.length}, cek kembali jika resi yang anda scan
            sudah benar?
          </h3>
          <Divider orientation="horizontal" sx={{ my: 0.7 }} />
          <div className="relative">
            {loadingScanManual && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                <ClipLoader size={35} color={"#123abc"} loading={true} />
              </div>
            )}
            <div className="inline-flex justify-center w-full gap-2">
              <button
                type="button"
                onClick={() => setIsOpenAlertExit(false)}
                className="px-5 py-1 text-lg text-gray-600 border border-gray-500 rounded-lg"
              >
                Batal
              </button>
              <button
                type="button"
                onClick={onStoreData}
                className="px-5 py-1 text-lg text-white bg-red-400 rounded-lg"
              >
                Ya
              </button>
            </div>
          </div>
        </div>
      </BasicModal>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "75%" : "100%",
            width: matchesMobile ? "100%" : "75%",
            mr: matchesMobile ? 0 : 2,
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                // alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "100%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size={matchesMobile ? "small" : "medium"}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Cari Nomor Resi"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              setScanManual(false);
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              push(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;
                        push(temp);
                        setScanManual(false);
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        push(temp);
                        setScanManual(false);
                      }
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                height: "30%",
                width: matchesMobile ? "30%" : "50%",
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: matchesMobile ? "80%" : "50%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: matchesMobile ? "8px" : "14px",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    color: "#ffffff",
                  },
                }}
                onClick={() => setOpenModalDateRange(true)}
              >
                {matchesMobile || !params.minDate
                  ? "Pilih Tanggal"
                  : `${formatDate(params.minDate)} - ${formatDate(
                      params.maxDate
                    )}`}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          No. Resi
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 80 }}>
                          Keterangan
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Layanan
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 80 }} align="center">
                          Jenis Pembayaran
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }} align="center">
                          Pengirim
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }} align="center">
                          Penerima
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Type Scan
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          Tanggal
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 150 }} align="center">
                          Status Paket
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CircularProgress
                                size={25}
                                sx={{ color: "#333333" }}
                              />
                            </Box>
                          </StyledTableCell>
                        </TableRow>
                      ) : error ? (
                        <TableRow>
                          <StyledTableCell colSpan={8}>
                            <Alert
                              variant="standard"
                              severity="error"
                              sx={{ borderRadius: 0 }}
                            >
                              {error}
                            </Alert>
                          </StyledTableCell>
                        </TableRow>
                      ) : (
                        allData.length > 0 &&
                        allData.map((item, i) => (
                          <TableRow key={i}>
                            <StyledTableCell>
                              {item.awb.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.expedition.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.service.toUpperCase()}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item?.payment_method?.toUpperCase() || "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item?.shipper?.toUpperCase() || "-"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item?.receiver?.toUpperCase() || "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Chip
                                label={item.type_scan}
                                color={
                                  item.type_scan === "uploaded"
                                    ? "default"
                                    : "info"
                                }
                                variant="outlined"
                                size="small"
                                clickable
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {moment(item.order_date).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Chip
                                size="small"
                                label={`${item.status}`}
                                color={`${
                                  item.status === "on process"
                                    ? "default"
                                    : "success"
                                }`}
                                variant="filled"
                                clickable
                              />
                            </StyledTableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  {allData.length === 0 && !loading ? (
                    <Alert
                      variant="standard"
                      severity="warning"
                      sx={{ borderRadius: 0 }}
                    >
                      Tidak ada data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  ml: matchesMobile ? 0 : 2,
                  height: "10%",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={+params.limit}
                  page={count <= 0 ? 0 : +params.page - 1}
                  onPageChange={(_, newPage) =>
                    push({ ...query, page: newPage + 1 })
                  }
                  onRowsPerPageChange={({ target: { value = "10" } }) =>
                    push({ ...query, limit: value, page: 1 })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            height: matchesMobile ? "25%" : "100%",
            width: matchesMobile ? "100%" : "25%",
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
            pt: matchesMobile ? 3 : 12.4,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#333333",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Tooltip title="Upload data yang ingin di scan">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    // height: matchesMobile ? "100%" : "30%",
                    width: "100%",
                    // mb: matchesMobile ? 1 : 2,
                    backgroundColor: "#ab8c36",
                    boxShadow: matchesMobile
                      ? null
                      : "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#333333",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                    },
                  }}
                  onClick={() => setOpenModalUpload(true)}
                >
                  Upload
                </Button>
              </Tooltip>
              <Box
                sx={{
                  width: "100%",
                  height: matchesMobile ? "50%" : "86%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ffffff50",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <Typography variant="h3" sx={{ color: "#ffffff" }}>
                            Total Scan
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {matchesMobile ? null : (
                        <>
                          {dataScan.length > 0 ? (
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", borderBottom: 0 }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{ color: "#ffffff" }}
                                >
                                  Sudah di scan {dataScan.length}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                sx={{ fontWeight: "bold", borderBottom: 0 }}
                              >
                                <Typography
                                  variant="h4"
                                  sx={{ color: "#ffffff" }}
                                >
                                  Menunggu data di scan...
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  id="basic-button"
                  aria-haspopup="true"
                  sx={{
                    backgroundColor: "#6c6d70",
                    width: "80%",
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      color: "#333333",
                    },
                  }}
                  onClick={() => setScanManual(!scanManual)}
                >
                  Scan
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ScanPage;
