import { useState, useEffect } from "react";
import {
  Container,
  Box,
  Stack,
  Divider,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CheckCircleRounded } from "@mui/icons-material";

function AgencyPage() {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const [agencyPackage, setAgencyPackage] = useState([
    {
      package: "Paket Short video",
      price: "3jt",
      description: "",
      benefits: ["40 Video Pendek"],
    },
    {
      package: "Satria",
      price: "4jt",
      description:
        "Untuk kalian yang mau cepat memulai dengan teknik yang sesuai budaya tiktok",
      benefits: ["20 Video Pendek", "4 Livestreaming"],
    },
    {
      package: "Paket Live",
      price: "3jt",
      description: "",
      benefits: ["8 Livestreaming"],
    },
  ]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingY: 2,
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          // backgroundColor: "#ffffff",
          mt: { lg: 0, md: 5, sm: 5, xs: 5 },
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            // backgroundColor: "#ffffff",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography align="center" sx={{ fontSize: 28, fontWeight: "bold" }}>
            BIG DELIVERY AGENCY
          </Typography>
          <Stack
            spacing={0}
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: 2,
              mb: 2,
              height: matchesMobile ? "100% " : "80%",
            }}
          >
            {agencyPackage.length
              ? agencyPackage.map((e) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor:
                        e.package === "Satria" ? "#ab8c36" : "#333333",
                      border: e.package === "Satria" ? 5 : 0,
                      borderColor: "#333333",
                      color: "#ffffff",
                      width: e.package === "Satria" ? 300 : 250,
                      height: e.package === "Satria" ? 450 : 400,
                      padding: 4,
                    }}
                  >
                    <Typography sx={{ height: "10%" }}>{e.package}</Typography>

                    {e.package === "Satria" ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: 30,
                            fontWeight: "bold",
                            height: "10%",
                          }}
                        >
                          {`Rp 3jt / Bulan *`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            height: "10%",
                            color: "#ffffff50",
                            textDecorationLine: "line-through",
                          }}
                        >
                          {`Rp ${e.price} / Bulan`}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        sx={{ fontSize: 30, fontWeight: "bold", height: "20%" }}
                      >
                        {`Rp ${e.price} / Bulan`}
                      </Typography>
                    )}
                    <Typography sx={{ height: "20%" }}>
                      {e.description}
                    </Typography>
                    <Divider sx={{ backgroundColor: "#ffffff" }} />
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        gap: 1,
                        height: "40%",
                        paddingY: 2,
                      }}
                    >
                      {e.benefits.length
                        ? e.benefits.map((el) => (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <CheckCircleRounded
                                sx={{
                                  fontSize: 20,
                                  color:
                                    e.package === "Satria"
                                      ? "#333333"
                                      : "#ab8c36",
                                  mr: 2,
                                }}
                              />
                              <Typography>{el}</Typography>
                            </Box>
                          ))
                        : null}
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 0,
                        backgroundColor:
                          e.package === "Satria" ? "#333333" : "#ab8c36",
                        height: "10%",
                        "&:hover": {
                          backgroundColor: "#ffffff",
                          color: "#333333",
                        },
                      }}
                    >
                      {e.package.toUpperCase()}
                    </Button>
                  </Box>
                ))
              : null}
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}

export default AgencyPage;
