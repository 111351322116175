import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  TextField,
  useMediaQuery,
  Divider,
  Button,
  IconButton,
  Snackbar,
  Slide,
  Alert,
  CircularProgress,
} from "@mui/material";
import { handleError } from "../../helper/helpers";

import { EditOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { updateProfile, getProfile } from "../../store/actions/sellerAction";
function SettingPage() {
  const url_location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  //containing data
  const [realData, setRealData] = useState({
    shopName: "",
    address: "",
    username: "",
    email: "",
    no_telp: "",
  });
  const [shopName, setShopName] = useState("");
  const [address, setAddress] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [no_telp, setNo_Telp] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [res, setRes] = useState({ status: "", message: "" });
  const [errorMessage, setErrorMessage] = useState({
    email: "",
    username: "",
    no_telp: "",
  });
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  //containing data

  const editUser = async () => {
    try {
      setLoading(true);
      setErrorMessage({ email: "", username: "" });
      const payload = {
        dataShop: {
          shopName: shopName ? shopName : "",
          address: address ? address : "",
          no_telp: no_telp ? no_telp : "",
        },
        dataUser: {
          email: email ? email : "",
          username: username ? username : "",
        },
      };
      const update = await updateProfile(payload);
      setRealData({
        shopName,
        address: address ? address : "",
        email,
        username,
        no_telp: no_telp ? no_telp : "",
      });
      setIsEdit(false);
      setRes({
        ...res,
        status: "success",
        message: "Edit Berhasil",
      });
      setLoading(false);
      setShowResponse(true);
      navigate("/setting");
    } catch (error) {
      // resetData();
      const message = handleError(error);
      if (/email/i.test(message)) {
        setErrorMessage({
          email: "Email sudah terdaftar",
          username: "",
          no_telp: "",
        });
      } else if (/username/i.test(message)) {
        setErrorMessage({
          email: "",
          username: "Username sudah terpakai",
          no_telp: "",
        });
      } else if (/no_telp/i.test(no_telp)) {
        setErrorMessage({
          email: "",
          username: "",
          no_telp: "No. Telp sudah terpakai",
        });
      } else {
        setRes({
          ...res,
          status: "error",
          message,
        });
        setShowResponse(true);
      }
      setLoading(false);
      navigate("/setting");
    }
  };

  const getProfileUser = async () => {
    try {
      const data = await getProfile();
      setGetLoading(true);
      setRealData({
        shopName: data.shopName,
        address: data.address ? data.address : "-",
        username: data.name,
        email: data.email,
        no_telp: data?.no_telp ? data?.no_telp : "-",
      });
      setShopName(data.shopName);
      setAddress(data.address ? data.address : "-");
      setUsername(data.name);
      setEmail(data.email);
      setNo_Telp(data?.no_telp ? data?.no_telp : "-");
      setGetLoading(false);
    } catch (error) {
      const message = handleError(error);
      console.log(message);
      setGetLoading(false);
    }
  };

  const resetData = () => {
    setShopName(realData.shopName);
    setAddress(realData.address);
    setUsername(realData.username);
    setEmail(realData.email);
    setNo_Telp(realData.no_telp);
  };

  useEffect(() => {
    getProfileUser();
    if (url_location.search.includes("edit=telp")) setIsEdit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        paddingY: matchesMobile ? 15 : 20,
      }}
    >
      <Snackbar
        open={showResponse}
        onClose={() => setShowResponse(false)}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ borderRadius: 0 }}
      >
        <Alert
          onClose={() => setShowResponse(false)}
          severity={res.status}
          sx={{ width: "100%" }}
        >
          {res.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
        }}
      >
        <Box
          sx={{
            height: "15%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h1" sx={{ ml: 3 }}>
            Your Profile
          </Typography>
          {isEdit ? null : (
            <IconButton
              size="large"
              sx={{ mr: 3 }}
              onClick={() => setIsEdit(true)}
            >
              <EditOutlined />
            </IconButton>
          )}
        </Box>
        {getLoading ? (
          <Box
            sx={{
              height: isEdit ? "70%" : "85%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: matchesMobile ? "column" : "row",
            }}
          >
            <CircularProgress size={25} sx={{ color: "#333333" }} />
          </Box>
        ) : (
          <Box
            sx={{
              height: isEdit ? "70%" : "85%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: matchesMobile ? "start" : "space-between",
              flexDirection: matchesMobile ? "column" : "row",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "100%" : "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingX: matchesMobile ? 0 : 2,
                alignItems: matchesMobile ? "center" : "start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: matchesMobile ? "95%" : "100%",
                  flexDirection: matchesMobile ? "column" : "row",
                  gap: matchesMobile ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "20%",
                  }}
                >
                  <Typography variant="h5">Nama Toko</Typography>
                </Box>
                <Box sx={{ width: matchesMobile ? "100%" : "80%" }}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={shopName}
                    onChange={(e) => {
                      setShopName(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 0,
                        backgroundColor: "#ffffff",
                      },
                    }}
                    sx={{ width: "100%" }}
                    disabled={
                      !isEdit || url_location.search.includes("edit=telp")
                    }
                    size={matchesMobile ? "small" : "medium"}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: matchesMobile ? "95%" : "100%",
                  flexDirection: matchesMobile ? "column" : "row",
                  gap: matchesMobile ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "20%",
                  }}
                >
                  <Typography variant="h5">Alamat Toko</Typography>
                </Box>
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "80%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 0,
                        backgroundColor: "#ffffff",
                      },
                    }}
                    sx={{ width: "100%" }}
                    disabled={
                      !isEdit || url_location.search.includes("edit=telp")
                    }
                    size={matchesMobile ? "small" : "medium"}
                  />
                </Box>
              </Box>
            </Box>
            {matchesMobile ? null : (
              <Divider orientation="vertical" flexItem={true} />
            )}
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "100%" : "50%",
                display: "flex",
                justifyContent: matchesMobile ? "start" : "center",
                flexDirection: "column",
                paddingX: matchesMobile ? 0 : 4,
                alignItems: matchesMobile ? "center" : "start",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: matchesMobile ? "95%" : "100%",
                  flexDirection: matchesMobile ? "column" : "row",
                  gap: matchesMobile ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "20%",
                  }}
                >
                  <Typography variant="h5">Email</Typography>
                </Box>
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "80%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 0,
                        backgroundColor: "#ffffff",
                        borderColor: errorMessage.email
                          ? "red !important"
                          : "black",
                        border: errorMessage.email ? 1 : 0,
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={
                      !isEdit || url_location.search.includes("edit=telp")
                    }
                    size={matchesMobile ? "small" : "medium"}
                  />
                </Box>
              </Box>
              {errorMessage.email ? (
                <Typography sx={{ color: "red", width: "100%" }} align="right">
                  {errorMessage.email}
                </Typography>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: matchesMobile ? "95%" : "100%",
                  flexDirection: matchesMobile ? "column" : "row",
                  gap: matchesMobile ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "20%",
                  }}
                >
                  <Typography variant="h5">Username</Typography>
                </Box>
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "80%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 0,
                        backgroundColor: "#ffffff",
                        borderColor: errorMessage.username
                          ? "red !important"
                          : "black",
                        border: errorMessage.username ? 1 : 0,
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={
                      !isEdit || url_location.search.includes("edit=telp")
                    }
                    size={matchesMobile ? "small" : "medium"}
                  />
                </Box>
              </Box>
              {errorMessage.username ? (
                <Typography sx={{ color: "red", width: "100%" }} align="right">
                  {errorMessage.username}
                </Typography>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: matchesMobile ? "95%" : "100%",
                  flexDirection: matchesMobile ? "column" : "row",
                  gap: matchesMobile ? 1 : 0,
                }}
              >
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "20%",
                  }}
                >
                  <Typography variant="h5">No. HP</Typography>
                </Box>
                <Box
                  sx={{
                    width: matchesMobile ? "100%" : "80%",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    focused={url_location.search.includes("edit=telp")}
                    value={no_telp}
                    onChange={(e) => {
                      if (/^[0-9]{0,14}$/.test(e.target.value))
                        setNo_Telp(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: 0,
                        backgroundColor: "#ffffff",
                        borderColor: errorMessage.no_telp
                          ? "red !important"
                          : "black",
                        border: errorMessage.no_telp ? 1 : 0,
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    disabled={!isEdit}
                    helperText={
                      url_location.search.includes("edit=telp") && isEdit
                        ? "Masukkan No. HP disini"
                        : ""
                    }
                    size={matchesMobile ? "small" : "medium"}
                  />
                </Box>
              </Box>
              {errorMessage.no_telp ? (
                <Typography sx={{ color: "red", width: "100%" }} align="right">
                  {errorMessage.no_telp}
                </Typography>
              ) : null}
            </Box>
          </Box>
        )}
        {isEdit ? (
          <Box
            sx={{
              height: "15%",
              width: "95%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                mr: 2,
                borderColor: "#ab8c36",
                color: "#ab8c36",
                width: "10%",
                "&:hover": {
                  backgroundColor: "#ffffff",
                  borderColor: "#ab8c36",
                  transform: "scale3d(1.1, 1.2, 1)",
                },
              }}
              onClick={() => {
                setErrorMessage({
                  email: "",
                  username: "",
                  no_telp: "",
                });
                resetData();
                setIsEdit(false);
                navigate("/setting");
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#333333",
                width: "10%",
                "&:hover": {
                  backgroundColor: "#333333",
                  transform: "scale3d(1.1, 1.2, 1)",
                },
              }}
              disabled={loading}
              onClick={editUser}
            >
              {loading ? (
                <CircularProgress size={25} sx={{ color: "#333333" }} />
              ) : (
                "Simpan"
              )}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Container>
  );
}

export default SettingPage;
