import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Avatar,
  CardActions,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { createRating, getContentById } from "../../../store/actions/actionKol";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "../../../helper/helpers";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../../store/actions/actionAwb";
import axios from "axios";
import { convertDate } from "../../../helper/convertDate";
import Snack from "../../../components/Snackbar/Snack";

const RatingComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { err, message, openSnack } = useSelector(
    (state) => state.UniversalReducer
  );
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [request, setRequest] = useState([]);
  const [dataVideo, setDataVideo] = useState([]);

  const handleRatingChange = (event, value) => {
    setRating(value);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Kirim rating dan komentar ke backend
    if (rating === 0) {
      dispatch(setErr(true));
      dispatch(setMessage("Berikan Rating Terlebih dahulu!"));
      dispatch(setOpenSnack(true));
    } else {
      // Tambahkan logika yang sesuai untuk mengirim rating dan komentar ke backend
      dispatch(createRating(params.kontenId, { rating, comment }))
        .then(() => {
          dispatch(setErr(false));
          dispatch(setMessage("Rating Berhasil dibuat!"));
          dispatch(setOpenSnack(true));
          setTimeout(() => {
            setRating("");
            setComment("");
            navigate("/kol/content");
          }, 1000);
        })
        .catch((err) => {
          const message = handleError(err);
          dispatch(setErr(true));
          dispatch(setMessage(message));
          dispatch(setOpenSnack(true));
        });
    }
  };

  function AvatarGender(gender, id) {
    const man1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-1.png";
    const man2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-2.png";
    const woman1 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-3.png";
    const woman2 =
      "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/avatar-4.png";
    if (gender === "pria") {
      if (id % 2 == 0) {
        return man1;
      } else {
        return man2;
      }
    } else if (gender === "wanita") {
      if (id % 2 == 0) {
        return woman1;
      } else {
        return woman2;
      }
    }
  }

  useEffect(() => {
    dispatch(getContentById(params.kontenId))
      .then(({ data }) => {
        setRequest(data.data);
        return axios.get(
          `https://www.tiktok.com/oembed?url=${data.data.url_video}`
        );
      })
      .then(({ data: _data }) => {
        setDataVideo(_data);
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      });
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snack
        open={openSnack}
        setOpen={() => dispatch(setOpenSnack(false))}
        message={message}
        status={err ? "error" : "success"}
      />
      <Grid sx={{ mt: 2 }}>
        <Card
          sx={{
            maxWidth: {
              sx: "100%",
              md: dataVideo?.thumbnail_url ? "60%" : "100%",
              lg: dataVideo?.thumbnail_url ? "60%" : "100%",
            },
            maxHeight: "100%",
          }}
        >
          <CardHeader
            avatar={<Avatar src={AvatarGender("pria", request.kol_id)} />}
            title={request.kol_name}
            subheader={convertDate(request.createdAt)}
          />
          <CardMedia
            component="img"
            height={357}
            image={
              dataVideo?.thumbnail_url
                ? dataVideo.thumbnail_url
                : "https://avatar-jualkirim.s3.ap-southeast-1.amazonaws.com/image-not-available.png"
            }
            onClick={(e) => {
              e.preventDefault();
              window.open(request.url_video, "_blank");
            }}
            alt={dataVideo?.author_name ? dataVideo?.author_name : "kreator"}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {dataVideo.title ? dataVideo.title : "-"}
              <br />
              {request.note ? request.note : "-"}
            </Typography>
            <Grid sx={{ ml: 2, mt: 2 }}>
              <Typography variant="h5">Request Detail</Typography>
              <Typography variant="body1">Request ID: {request.id}</Typography>
              <Typography variant="body1">
                URL Product:{" "}
                <a
                  href={request.url_product}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {request.url_product?.length <= 40
                    ? request.url_product
                    : request.url_product?.substring(0, 39) + "..."}
                </a>
              </Typography>
              <Typography variant="body1">KOL ID: {request.kol_id}</Typography>
              <Typography variant="body1">
                URL Video:
                <a
                  href={request.url_video}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {request.url_video?.length <= 40
                    ? request.url_video
                    : request.url?.substring(0, 39) + "..."}
                </a>
              </Typography>
              <Typography variant="body1">
                Commission: {request.commission}%
              </Typography>
            </Grid>
          </CardContent>
          <CardActions
            disableSpacing
            sx={{ textAlignLast: "center", placeContent: "center" }}
          >
            <form>
              <Box component="fieldset" borderColor="transparent">
                <Typography variant="h5">Rating</Typography>
                <Rating
                  name="rating"
                  value={request.rating ? request.rating : rating}
                  precision={1}
                  disabled={request.rating ? true : false}
                  readOnly={request.rating ? true : false}
                  onChange={handleRatingChange}
                />
              </Box>
              <Box>
                {request.rating ? (
                  <>
                    <Typography variant="h5">Komentar</Typography>
                    <Typography variant="body1">{request.comment}</Typography>
                  </>
                ) : (
                  <TextField
                    id="comment"
                    label="Komentar"
                    rows={2}
                    fullWidth
                    value={comment}
                    onChange={handleCommentChange}
                  />
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={request.rating ? true : false}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </CardActions>
        </Card>
      </Grid>
    </Container>
  );
};

export default RatingComponent;
