import React, { useState } from "react";
import {
  Box,
  Modal,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
// import { formTopup } from '../../store/actions/actionWallet';
// import { handleError } from '../../helpers/errHandler';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  // height: '50vh',
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: "10px",
};

export default function FormPengajuanTopup({ open, setOpen }) {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [amount, setAmount] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState({ success: null, failed: null });

  const handleClose = () => {
    setAmount("");
    setFile(null);
    setMessage({ success: null, failed: null });
    setOpen(false);
  };

  const fileHandler = (e) => {
    setMessage({ success: null, failed: null });
    let form = new FormData();
    form.append("image", e.target.files[0]);
    setFile(form);
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (!file) {
      setMessage({ failed: "Mohon masukan bukti transfer" });
    } else {
      setMessage({ success: null, failed: null });
      const orderNumber = Date.now(),
        payload = {
          total: +amount,
          orderNumber: orderNumber.toString(),
        };

      console.log(file);

      // dispatch(formTopup(file, payload))
      //   .then(({ data }) => {
      //     setOpenSnack(true)
      //     setOpen(false)
      //   })
      //   .catch(err => {
      //     const message = handleError(err)
      //     setMessage({ ...message, failed: message })
      //   })
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <small>Close</small>
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
        message="Berhasil mengajukan form topup"
        action={action}
      />
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleForm}>
          <Box sx={style}>
            <Typography variant="h3">Mohon upload bukti transfer</Typography>
            <Typography variant="h5">
              {message.success ? message.success : null}
            </Typography>
            <p style={{ color: "red" }}>
              {message.failed ? message.failed : null}
            </p>
            <input
              style={{ marginTop: "10px", width: "100%" }}
              type="file"
              onChange={fileHandler}
            />
            <TextField
              style={{ marginTop: 10 }}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              fullWidth
              type="number"
              size="small"
              label="Amount"
              variant="outlined"
              sx={{ width: "100%" }}
            />
            <Button
              variant="contained"
              size="medium"
              sx={{ width: "100%", mt: 2 }}
              type="submit"
              style={{ backgroundColor: "#ab8c36" }}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Modal>
    </>
  );
}
