import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Container,
  Box,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Slide,
  Alert,
  Snackbar,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import { handleError } from "../../../../helper/helpers";
import {
  CancelOutlined,
  Search,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import SimpleReportDesktopPage from "./SimpleReport";
import DetailReportDesktopPage from "./DetailReport";
import { useState, useMemo, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getQueryUrl, ThisMonth } from "../../../../helper/custom";
// import {
//   getReport,
//   getThreePlTotal,
// } from "../../../../store/actions/syncAction";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";

import jwt_decode from "jwt-decode";
// import banner1 from "../../../../image/banner1.jpeg";
// import banner2 from "../../../../image/banner2.jpeg";

function IndexDesktop() {
  // banner
  // const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  // const banners = [
  //   {
  //     label: "banner1",
  //     imgPath: banner1,
  //   },
  //   {
  //     label: "banner2",
  //     imgPath: banner2,
  //   },
  // ];
  // const [setActiveStep] = useState(0);
  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };

  //localstorage
  let status = JSON.parse(localStorage.getItem("status"));
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  let userInternal = decode.userInternalId;
  //localstorage
  const [selectedStoreSuratJalan, setSelectedStoreSuratJalan] = useState(
    // decode.userInternalId[0].id
    0
  );
  //query
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { search } = useLocation();

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //query

  //=======================

  //theme

  //theme

  //=======================
  //value
  const [searchValue, setSearchValue] = useState(params.search || "");

  const [viewDetail, setViewDetail] = useState(
    status && status.switch ? status.switch : false
  );

  const [setDataList] = useState({ data: [], count: 0 });

  const [selectedStore, setSelectedStore] = useState(
    // decode.userInternalId[0].id
    0
  );
  const [seller, setSeller] = useState(0);

  const [dataDownload, setDataDownload] = useState({
    // minDate: "",
    // maxDate: "",
    id: decode.userInternalId[0].id,
    date: "",
    threePl: "",
  });

  // const [totalDataList, setTotalDataList] = useState({
  //   total: 0,
  //   JNE: 0,
  //   JNT: 0,
  //   "Anter Aja": 0,
  //   NINJA: 0,
  //   "ID EXPRESS": 0,
  //   LION: 0,
  //   SICEPAT: 0,
  //   SHOPEE: 0,
  //   LAZADA: 0,
  //   WAHANA: 0,
  // });

  //loading
  // const [loading, setLoading] = useState(false);
  const [loading2] = useState(false);

  //loading

  // const [notif, setNotif] = useState({
  //   type: "",
  //   status: "success",
  //   message: "",
  // });

  const [res, setRes] = useState({ status: "success", message: "" });

  const [totalSuratJalan] = useState(0);

  //value

  //=======================

  //function

  // const getReportAction = async () => {
  //   try {
  //     setDataList({ data: [], count: 0 });
  //     setLoading2(true);
  //     const data = await getReport({ search, selectedStore });
  //     setDataList({ ...dataList, data: data.allData, count: data.count });
  //     setLoading2(false);
  //   } catch (error) {
  //     setLoading2(false);
  //     const message = await handleError(error);
  //     console.log(message);
  //     // setError(message);
  //   }
  // };

  // const getThreePlTotalAction = async () => {
  //   try {
  //     setLoading("totalList");
  //     const thisMonth = ThisMonth();

  //     let data;
  //     if (!params.minDate) {
  //       data = await getThreePlTotal({
  //         search:
  //           search +
  //           `&minDate=${thisMonth.startDate}&maxDate=${thisMonth.endDate}`,
  //         selectedStore,
  //       });
  //     } else {
  //       data = await getThreePlTotal({
  //         search: search,
  //         selectedStore,
  //       });
  //     }

  //     setTotalDataList(data.totalPerThreePl);
  //     setTotalSuratJalan(data.totalSuratJalan);

  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     const message = await handleError(error);
  //     console.log(message);
  //     // setError(message);
  //   }
  // };

  //function

  //=======================

  //use effect
  // useEffect(() => {
  //   getReportAction();
  //   // if (params.minDate && params.maxDate) {
  //   //   getThreePlTotalAction();
  //   // }
  // }, [query, selectedStore]);

  useEffect(() => {
    // getReportAction();
    // getThreePlTotalAction();

    if (decode.role) {
      setSeller(decode.userInternalId[0].id);
      setSelectedStore(decode?.userInternalId[seller]?.userInternalId[0]?.id);
      setSelectedStoreSuratJalan(
        decode?.userInternalId[seller]?.userInternalId[0]?.id
      );
    }
    if (!decode.role) {
      setSelectedStore(decode?.userInternalId[0].id);
      setSelectedStoreSuratJalan(decode?.userInternalId[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      sx={{
        // height: { lg: "100vh", md: "100vh", sm: "auto", xs: "auto" },
        minHeight: "100vh",
        maxHeight: {
          lg: viewDetail ? "100vh" : "auto",
          md: viewDetail ? "100vh" : "auto",
          sm: viewDetail ? "100vh" : "auto",
          xs: viewDetail ? "100vh" : "auto",
        },
        width: "100%",
        display: "flex",
        paddingY: 2,
        mt: { lg: 0, md: 0, sm: 5, xs: 5 },
      }}
    >
      <Snackbar
        open={res.message ? true : false}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ borderRadius: 0 }}
        autoHideDuration={3000}
        onClose={() =>
          setRes({
            ...res,
            message: "",
          })
        }
      >
        <Alert severity={res.status} sx={{ width: "100%" }}>
          {res.message}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          // mt: { xs: 5, sm: 5, md: 5, lg: 0 },
          // height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            height: "10%",
            alignItems: "end",
            width: "100%",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: decode.role ? null : "#123123",
              }}
            >
              {viewDetail ? (
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size="medium"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Nomor Resi / Nama Penerima"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              let temp = { ...query };
                              delete temp.search;
                              const thisMonth = ThisMonth();
                              temp.minDate = thisMonth.startDate;
                              temp.maxDate = thisMonth.endDate;
                              temp.page = 1;
                              setSearchParams(temp);
                              setSearchValue("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.search = searchValue;
                        temp.page = 1;
                        setSearchParams(temp);
                        // push({ ...query, search: searchValue });
                      }
                      if (!searchValue && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        const thisMonth = ThisMonth();
                        temp.minDate = thisMonth.startDate;
                        temp.maxDate = thisMonth.endDate;
                        setSearchParams(temp);
                      }
                    }}
                  />
                </FormControl>
              ) : (
                <>
                  {decode.users?.length ? (
                    <Box sx={{ width: "50vh" }}>
                      <FormControl
                        sx={{
                          mr: 2,
                          backgroundColor: "#ffffff",
                          width: "20vh",
                        }}
                      >
                        <InputLabel>Pilih Seller</InputLabel>
                        <Select
                          label="Pilih Seller"
                          sx={{
                            borderRadius: 0,
                            boxShadow:
                              "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                          }}
                          value={seller}
                          onChange={(e) => {
                            setSeller(e.target.value);
                            // push({ ...query, status: "proces", limit: 10, page: 1 })
                          }}
                        >
                          {userInternal.map((e, i) => (
                            <MenuItem key={i} value={e.id}>
                              {e.username}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {seller ? (
                        <FormControl
                          sx={{ backgroundColor: "#ffffff", width: "20vh" }}
                        >
                          <InputLabel>Pilih Toko</InputLabel>
                          <Select
                            label="Pilih Toko"
                            sx={{
                              borderRadius: 0,
                              boxShadow:
                                "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                            }}
                            value={selectedStore}
                            onChange={(e) => {
                              setSelectedStore(e.target.value);
                              setSelectedStoreSuratJalan(e.target.value);
                              setDataDownload({
                                ...dataDownload,
                                id: e.target.value,
                              });
                              setDataList({ data: [], count: 0 });
                              setSearchParams({ ...query, limit: 10, page: 1 });
                              // push({ ...query, status: "proces", limit: 10, page: 1 })
                            }}
                          >
                            {userInternal.map((e) => {
                              if (e.id === seller) {
                                return e.userInternalId.map((res, i) => (
                                  <MenuItem key={i} value={res.id}>
                                    {res.username}
                                  </MenuItem>
                                ));
                              }
                              return false;
                            })}
                          </Select>
                        </FormControl>
                      ) : null}
                    </Box>
                  ) : null}

                  {!decode.role ? (
                    <FormControl fullWidth sx={{ backgroundColor: "#ffffff" }}>
                      <InputLabel>Pilih Toko</InputLabel>
                      <Select
                        label="Pilih Toko"
                        sx={{
                          borderRadius: 0,
                          boxShadow:
                            "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                        }}
                        value={selectedStore}
                        onChange={(e) => {
                          setSelectedStore(e.target.value);
                          setSelectedStoreSuratJalan(e.target.value);
                          setDataDownload({
                            ...dataDownload,
                            id: e.target.value,
                          });
                          setDataList({ data: [], count: 0 });
                          setSearchParams({ ...query, limit: 10, page: 1 });
                        }}
                      >
                        {userInternal.length
                          ? userInternal.map((e, i) => (
                              <MenuItem key={i} value={e.id}>
                                {e.username}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                </>
              )}
            </Box>
            {viewDetail ? (
              <Box
                sx={{
                  width: { lg: "20%", md: "20%", sm: "30%", xs: "100%" },
                  mb: 0,
                }}
              >
                {decode.users?.length ? (
                  <>
                    <FormControl sx={{ mr: 2, backgroundColor: "#ffffff" }}>
                      <InputLabel>Pilih Seller</InputLabel>
                      <Select
                        label="Pilih Seller"
                        sx={{
                          borderRadius: 0,
                          boxShadow:
                            "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                        }}
                        value={seller}
                        onChange={(e) => {
                          setSeller(e.target.value);
                          // push({ ...query, status: "proces", limit: 10, page: 1 })
                        }}
                      >
                        {userInternal.map((e, i) => (
                          <MenuItem key={i} value={e.id}>
                            {e.username}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {seller ? (
                      <FormControl
                        sx={{ backgroundColor: "#ffffff", width: "100px" }}
                      >
                        <InputLabel>Pilih Toko</InputLabel>
                        <Select
                          label="Pilih Toko"
                          sx={{
                            borderRadius: 0,
                            boxShadow:
                              "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                          }}
                          value={selectedStore}
                          onChange={(e) => {
                            setSelectedStore(e.target.value);
                            setSelectedStoreSuratJalan(e.target.value);
                            setDataDownload({
                              ...dataDownload,
                              id: e.target.value,
                            });
                            setDataList({ data: [], count: 0 });
                            setSearchParams({ ...query, limit: 10, page: 1 });
                            // push({ ...query, status: "proces", limit: 10, page: 1 })
                          }}
                        >
                          {userInternal.map((e) => {
                            if (e.id === seller) {
                              return e.userInternalId.map((res, i) => (
                                <MenuItem key={i} value={res.id}>
                                  {res.username}
                                </MenuItem>
                              ));
                            }
                            return false;
                          })}
                        </Select>
                      </FormControl>
                    ) : null}
                  </>
                ) : null}
                {!decode.role ? (
                  <FormControl fullWidth sx={{ backgroundColor: "#ffffff" }}>
                    <InputLabel>Pilih Toko</InputLabel>
                    <Select
                      label="Pilih Toko"
                      sx={{
                        borderRadius: 0,
                        boxShadow:
                          "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      }}
                      value={selectedStore}
                      onChange={(e) => {
                        setSelectedStore(e.target.value);
                        setSelectedStoreSuratJalan(e.target.value);
                        setDataDownload({
                          ...dataDownload,
                          id: e.target.value,
                        });
                        setDataList({ data: [], count: 0 });
                        setSearchParams({ ...query, limit: 10, page: 1 });
                      }}
                    >
                      {userInternal.length
                        ? userInternal.map((e, i) => (
                            <MenuItem key={i} value={e.id}>
                              {e.username}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                ) : null}
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#ffffff",
                padding: 0.5,
                display: "flex",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Tooltip title="Tampilan Detail / Tabel">
                <IconButton
                  sx={{
                    backgroundColor: viewDetail ? "#333333" : "#ffffff",
                    color: viewDetail ? "#ffffff" : "#333333",
                    borderRadius: 0,
                    "&:hover": {
                      backgroundColor: viewDetail ? "#333333" : "#33333330",
                      color: viewDetail ? "#ffffff" : "#333333",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewDetail(!viewDetail);
                    localStorage.setItem(
                      "status",
                      JSON.stringify({ switch: !viewDetail })
                    );
                  }}
                >
                  <ViewList />
                </IconButton>
              </Tooltip>
              <Tooltip title="Tampilan Total / Module">
                <IconButton
                  sx={{
                    backgroundColor: !viewDetail ? "#333333" : "#ffffff",
                    color: !viewDetail ? "#ffffff" : "#333333",
                    borderRadius: 0,
                    "&:hover": {
                      backgroundColor: !viewDetail ? "#333333" : "#33333330",
                      color: !viewDetail ? "#ffffff" : "#333333",
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setViewDetail(!viewDetail);
                    localStorage.setItem(
                      "status",
                      JSON.stringify({ switch: !viewDetail })
                    );
                  }}
                >
                  <ViewModule />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>

        {/* pasang banner disini */}
        {/* <Box
          component="img"
          sx={{
            width: "100%",
            height: "10%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
            mb: viewDetail ? 0 : 2,
          }}
          src={banner2}
        /> */}
        {/* <Box
          sx={{
            width: "100%",
            height: "10%",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
            mb: viewDetail ? 0 : 2,
          }}
        >
          <AutoPlaySwipeableViews
            style={{ height: "100%", width: "100%", display: "flex" }}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {banners.map((step, index) => (
              <div key={step.label} style={{ height: "100%", width: "100%" }}>
                {Math.abs(activeStep - index) <= banners ? (
                  <Box
                    component="img"
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "block",
                      // backgroundSize: "cover",
                      // backgroundRepeat: "no-repeat",
                      // backgroundPosition: "center",
                      objectFit: "cover",
                    }}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        </Box> */}

        {viewDetail ? (
          <DetailReportDesktopPage
            // dataList={dataList}
            selectedStore={selectedStore}
            loading2={loading2}
            setRes={setRes}
          />
        ) : (
          <SimpleReportDesktopPage
            // totalDataList={totalDataList}
            selectedStoreSuratJalan={selectedStoreSuratJalan}
            setSelectedStoreSuratJalan={setSelectedStoreSuratJalan}
            seller={seller}
            setSeller={setSeller}
            totalSuratJalan={totalSuratJalan}
          />
        )}
      </Box>
    </Container>
  );
}
export default IndexDesktop;
