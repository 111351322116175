import { SET_SELLERS, SET_ERROR } from "../keys";

const initialState = {
  sellerList: [],
  error: "",
};

function sellerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELLERS:
      return { ...state, sellerList: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export default sellerReducer;
