import {
  Grid,
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Tooltip,
  Zoom,
  TablePagination,
  CircularProgress,
  Alert,
  TextField,
  useMediaQuery,
  FormControl,
  InputAdornment,
  Modal,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { handleError } from "../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";

import { dateFormat, timeFormat } from "../../helper/DateFormat";
import { ThisMonth, formatDate } from "../../helper/custom";

import {
  CancelOutlined,
  Search,
  EditOutlined,
  DoneOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { useState, useMemo, useEffect, useRef, Fragment } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";

import { TablePaginationActions } from "../../components/Reuse/pagination/pagination";
import { convertDataToQuery, getQueryUrl } from "../../helper/custom";

import {
  getTransactionsTiketing,
  deleteTicket,
} from "../../store/actions/syncAction";
import jwt_decode from "jwt-decode";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function ProblematicOrderPage() {
  //value
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const selectedStatus = params.status;

  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [dataDelete, setDataDelete] = useState({});
  const isAppleProduct =
    navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;
  const [valueSearch, setValueSearch] = useState(params.search || "");

  const [isEdit, setIsEdit] = useState(-1);
  const [res, setRes] = useState({ status: "success", message: "" });
  const [notif, setNotif] = useState({
    type: "",
    status: "success",
    message: "",
  });
  const [state, setState] = useState({
    startDate: params.minDate ? new Date(params.minDate) : new Date(),
    endDate: params.maxDate ? new Date(params.maxDate) : new Date(),
    key: "selection",
  });
  const [seller, setSeller] = useState(0);
  //value

  //==============

  //for navigate
  const navigate = useNavigate();
  const { search } = useLocation();

  const push = (payload) => {
    navigate(`/premium/bermasalah${convertDataToQuery(payload)}`);
  };

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //for navigate

  //=================

  //for styled
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  let userInternal = decode.userInternalId;
  const [selectedStore, setSelectedStore] = useState(
    // decode.userInternalId[0].id
    0
  );
  //========================

  //function
  const getTicketing = async () => {
    try {
      setLoading(true);
      // setCount(0);
      setCount(0);
      setDataList([]);
      const data = await getTransactionsTiketing({ search, selectedStore });
      setLoading(false);
      setCount(data.count);
      setDataList(data.data);
    } catch (error) {
      const message = await handleError(error);
      setLoading(false);
    }
  };

  // const editSubmit = async () => {
  //   try {
  //     // setCount(0);
  //     let newData = [...dataList];
  //     newData[isEdit] = dataEdit;
  //     setDataList(newData);
  //     setIsEdit(-1);
  //   } catch (error) {
  //     const message = await handleError(error);
  //     setLoading(false);
  //   }
  // };

  const confirmSubmit = async () => {
    try {
      // setCount(0);
      setNotif({ ...notif, type: "", message: "", status: "" });
      await deleteTicket({ id: dataDelete.id, verify: dataDelete.verify });
      setOpenModalConfirmation(false);
      getTicketing();
    } catch (error) {
      const message = await handleError(error);
      setNotif({ ...notif, type: "verify", message, status: "error" });
    }
  };
  //function

  useEffect(() => {
    if (decode.role) {
      setSeller(decode.userInternalId[0].id);
      setSelectedStore(decode?.userInternalId[seller]?.userInternalId[0]?.id);
    }
    if (!decode.role) {
      setSelectedStore(decode?.userInternalId[0].id);
    }
  }, []);

  useEffect(() => {
    getTicketing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedStore]);

  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        pb: 3,
        height: { lg: "100vh", md: "100vh", sm: "100vh", xs: "100vh" },
      }}
    >
      <Modal
        open={openModalConfirmation}
        onClose={() => setOpenModalConfirmation(false)}
        aria-labelledby="basic-title"
        aria-describedby="basic-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: "95%",
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 24,
            p: 2,
          }}
        >
          <Grid
            container
            display="flex"
            width="100%"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
              Apakah Anda yakin menyelesaikan Paket Bermasalah ?
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <TextField
                disabled
                id="outlined-disabled"
                label="No. Resi"
                defaultValue={dataDelete.awb}
                sx={{ width: "80%" }}
              />
              {/* {notif.type === "date" ? (
                <Grid>
                  <Alert severity={notif.status === "ok" ? "success" : "error"}>
                    {notif.message}{" "}
                  </Alert>
                </Grid>
              ) : null} */}
              <Typography sx={{ marginTop: 5 }}>
                Harap menulis "KONFIRMASI" untuk menyelesaikan masalah ini
              </Typography>
              <FormControl sx={{ minWidth: "80%" }}>
                <TextField
                  // id="outlined-multiline-static"
                  // label="Note"
                  multiline
                  placeholder="note"
                  // rows={6}
                  // defaultValue={dataTicket.note}
                  inputProps={{ maxLength: 250 }}
                  onChange={(e) => {
                    setNotif({ ...notif, type: "", message: "", status: "" });
                    setDataDelete({ ...dataDelete, verify: e.target.value });
                    // setDataTicket({ ...dataTicket, note: e.target.value });
                  }}
                />
              </FormControl>
              {notif.type === "verify" ? (
                <Grid>
                  <Typography sx={{ color: "red" }}>{notif.message}</Typography>
                </Grid>
              ) : null}
            </Box>

            <Fragment>
              <Grid
                container
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                paddingX={5}
                marginTop={5}
              >
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  title="Delete"
                  onClick={() => {
                    setDataDelete({});
                    // setNotif({
                    //   ...notif,
                    //   type: "",
                    //   status: "success",
                    //   message: "",
                    // });

                    setNotif({
                      ...notif,
                      type: "",
                      message: "",
                      status: "",
                    });
                    setOpenModalConfirmation(false);
                  }}
                >
                  cancel
                </Button>

                <Button
                  color="info"
                  variant="outlined"
                  size="small"
                  title="Cancel Delete"
                  onClick={() => confirmSubmit()}
                >
                  Confirm
                </Button>
              </Grid>
            </Fragment>
            <Grid item />
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openModal}>
          <Box
            sx={{
              width: matchesMobile ? "90%" : "auto",
              height: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              boxShadow: 24,
              p: 2,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
            }}
          >
            <DateRangePicker
              onChange={(item) => setState(item.selection)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={matchesMobile ? 1 : 2}
              ranges={[state]}
              direction={matchesMobile ? "vertical" : "horizontal"}
              rangeColors={["#333333", "#ab8c36"]}
              color="#ff1100"
              // fixedHeight={matchesMobile ? true : false}
              inputRanges={matchesMobile ? [] : undefined}
              staticRanges={matchesMobile ? [] : undefined}
              // inputRanges={[]}
              // staticRanges={[]}
              className={
                matchesMobile
                  ? {
                      rdrStaticRanges: {
                        width: "0px",
                      },
                      rdrDateRangePickerWrapper: {
                        display: "flex !important",
                        justifyContent: "center !important",
                        alignItems: "center !important",
                        width: "100% !important",
                        position: "relative !important",
                      },
                    }
                  : null
              }
            />

            <Grid
              item
              width="100%"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => {
                  setOpenModal(false);
                  let obj = query;
                  delete obj.minDate;
                  delete obj.maxDate;
                  push(obj);
                  setState({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                  });
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#d33030",
                  color: "#d33030",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#d33030",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Hapus
              </Button>
              <Button
                onClick={() => setOpenModal(false)}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={() => {
                  if (isAppleProduct) {
                    let newMinDate = new Date(
                      state.startDate
                    ).toLocaleDateString("en-US");

                    let newMaxDate = new Date(state.endDate).toLocaleDateString(
                      "en-US"
                    );

                    newMinDate = newMinDate.split("/");
                    newMaxDate = newMaxDate.split("/");

                    const tempDateMin = newMinDate[1];
                    const tempMonthMin = newMinDate[0];

                    const tempDateMax = newMaxDate[1];
                    const tempMonthMax = newMaxDate[0];
                    newMinDate[0] = tempDateMin;
                    newMinDate[1] = tempMonthMin;

                    newMaxDate[0] = tempDateMax;
                    newMaxDate[1] = tempMonthMax;
                    newMinDate = newMinDate.join("-");
                    newMaxDate = newMaxDate.join("-");

                    push({
                      ...query,
                      minDate: newMinDate,
                      maxDate: newMaxDate,
                    });
                  } else {
                    push({
                      ...query,
                      minDate: state.startDate
                        .toLocaleDateString("en-US")
                        .replaceAll("/", "-"),
                      maxDate: state.endDate
                        .toLocaleDateString("en-US")
                        .replaceAll("/", "-"),
                    });
                  }
                  // getOrderValidate();
                  setOpenModal(false);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Grid>
          </Box>
        </Zoom>
      </Modal>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: matchesMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            height: matchesMobile ? "100%" : "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "20%",
              width: "100%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: matchesMobile ? "70%" : "50%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "70%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <FormControl
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "50%",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  }}
                  variant="filled"
                >
                  <TextField
                    size="medium"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    }}
                    placeholder="Nomor Resi"
                    value={valueSearch}
                    onChange={(e) => {
                      setValueSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: params.search ? (
                        <InputAdornment position="start">
                          <IconButton
                            edge="start"
                            onClick={(e) => {
                              let temp = { ...query };
                              delete temp.search;
                              temp.page = 1;
                              push(temp);
                              setValueSearch("");
                            }}
                          >
                            <CancelOutlined />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="start">
                          <IconButton edge="start">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (valueSearch && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.minDate;
                        delete temp.maxDate;
                        temp.status = "all";
                        temp.search = valueSearch;
                        temp.page = 1;
                        push(temp);
                      }
                      if (!valueSearch && e.key === "Enter") {
                        let temp = { ...query };
                        delete temp.search;
                        push(temp);
                      }
                    }}
                  />
                </FormControl>
                <Box
                  sx={{
                    width: {
                      lg: decode.role ? "100%" : "auto",
                      md: decode.role ? "100%" : "auto",
                      sm: "40%",
                      xs: "40%",
                    },
                    backgroundColor: decode.role ? null : "#ffffff",
                  }}
                >
                  {decode.users?.length ? (
                    <>
                      <FormControl
                        sx={{
                          mr: 1.3,
                          width: "15vh",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <InputLabel>Pilih Seller</InputLabel>
                        <Select
                          label="Pilih Seller"
                          sx={{ borderRadius: 0 }}
                          value={seller}
                          onChange={(e) => {
                            setSeller(e.target.value);
                            // push({ ...query, status: "proces", limit: 10, page: 1 })
                          }}
                        >
                          {userInternal.map((e, i) => (
                            <MenuItem key={i} value={e.id}>
                              {e.username}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {seller ? (
                        <FormControl
                          sx={{ width: "15vh", backgroundColor: "#ffffff" }}
                        >
                          <InputLabel>Pilih Toko</InputLabel>
                          <Select
                            label="Pilih Toko"
                            sx={{ borderRadius: 0 }}
                            value={selectedStore}
                            onChange={(e) => {
                              setSelectedStore(e.target.value);
                              // push({ ...query, status: "proces", limit: 10, page: 1 })
                            }}
                          >
                            {userInternal.map((e) => {
                              if (e.id === seller) {
                                return e.userInternalId.map((res, i) => (
                                  <MenuItem key={i} value={res.id}>
                                    {res.username}
                                  </MenuItem>
                                ));
                              }
                            })}
                          </Select>
                        </FormControl>
                      ) : null}
                    </>
                  ) : null}

                  {!decode.role ? (
                    <FormControl fullWidth>
                      <InputLabel>Pilih Toko</InputLabel>
                      <Select
                        label="Pilih Toko"
                        sx={{
                          borderRadius: 0,
                          boxShadow:
                            "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                        }}
                        value={selectedStore}
                        onChange={(e) => {
                          setSelectedStore(e.target.value);
                          // push({ ...query, userId: e.target.value });
                        }}
                      >
                        {userInternal.length
                          ? userInternal.map((e, i) => (
                              <MenuItem key={i} value={e.id}>
                                {e.username}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  ) : null}
                </Box>
              </Box>

              <Box
                sx={{
                  height: "30%",
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    marginX: 1,
                    borderRadius: 0,
                    height: "100%",
                    width: "100%",
                    backgroundColor:
                      selectedStatus === "all" ? "#333333" : "#ab8c36",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      backgroundColor: "#333333",
                    },
                  }}
                  onClick={() =>
                    push({ ...query, status: "all", limit: 10, page: 1 })
                  }
                >
                  Semua
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    marginX: 1,
                    borderRadius: 0,
                    height: "100%",
                    width: "100%",
                    backgroundColor:
                      selectedStatus === "proces" ? "#333333" : "#ab8c36",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      backgroundColor: "#333333",
                    },
                  }}
                  onClick={() =>
                    push({ ...query, status: "proces", limit: 10, page: 1 })
                  }
                >
                  Proses
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    marginX: 1,
                    borderRadius: 0,
                    height: "100%",
                    width: "100%",
                    backgroundColor:
                      selectedStatus === "selesai" ? "#333333" : "#ab8c36",
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    "&:hover": {
                      boxShadow:
                        "2px -4px 5px 0px rgba(0, 0, 0, 0.5) !important",
                      backgroundColor: "#333333",
                    },
                  }}
                  onClick={() =>
                    push({ ...query, status: "selesai", limit: 10, page: 1 })
                  }
                >
                  Selesai
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                height: "30%",
                width: matchesMobile ? "30%" : "50%",
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: matchesMobile ? "80%" : "50%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  fontSize: matchesMobile ? "8px" : "14px",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  "&:hover": {
                    backgroundColor: "#333333",
                    color: "#ffffff",
                  },
                }}
                onClick={() => setOpenModal(true)}
              >
                {matchesMobile || !params.minDate
                  ? "Pilih Tanggal"
                  : `${formatDate(params.minDate)} - ${formatDate(
                      params.maxDate
                    )}`}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", height: "80%", backgroundColor: "#ffffff" }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                }}
              >
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                    "&::-webkit-scrollbar": { width: 10, height: 10 },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#33333350",
                      borderRadius: 1,
                    },
                  }}
                >
                  <Table size="large" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          NO.
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          TANGGAL PELAPORAN
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          AWB
                        </StyledTableCell>
                        <StyledTableCell sx={{ minWidth: 100 }}>
                          NOTE
                        </StyledTableCell>
                        <StyledTableCell align="center">STATUS</StyledTableCell>
                        <StyledTableCell align="center">ACTION</StyledTableCell>
                        {/* <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                          ACTION
                        </StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList.length
                        ? dataList.map((e, i) =>
                            i === isEdit ? (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  backgroundColor: "#33333330",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <TextField
                                    value={i + 1}
                                    label="No"
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <TextField
                                    value={new Date(
                                      e.createdAt
                                    ).toLocaleDateString("en-US")}
                                    label="Tanggal"
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    value={e.awb}
                                    label="no.awb"
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    defaultValue={e.note}
                                    label="note"
                                    variant="standard"
                                    onChange={(e) => {
                                      setDataEdit({
                                        ...dataEdit,
                                        note: e.target.value,
                                      });
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    value={
                                      /claim/i.test(e.status)
                                        ? "claim"
                                        : e.status
                                    }
                                    label="service"
                                    variant="standard"
                                    disabled
                                  />
                                </TableCell>

                                {/* <TableCell align="center">
                                  <IconButton
                                    sx={{ color: "#000000", borderRight: 1 }}
                                    onClick={(e) => {
                                      setIsEdit(-1);
                                    }}
                                  >
                                    <CancelOutlined />
                                  </IconButton>
                                  <IconButton
                                    sx={{ color: "#000000", borderRight: 1 }}
                                    onClick={(e) => editSubmit(e)}
                                  >
                                    <DoneOutlined />
                                  </IconButton>
                                </TableCell> */}
                              </TableRow>
                            ) : (
                              <TableRow
                                key={i}
                                sx={{
                                  backgroundColor:
                                    e.status === "selesai"
                                      ? "#edf7ed"
                                      : "#ffffff",
                                }}
                              >
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>
                                  {new Date(e.createdAt).toLocaleDateString(
                                    "en-US"
                                  )}
                                </TableCell>
                                <TableCell>{e.awb}</TableCell>
                                <TableCell>
                                  <Typography align="justify">
                                    {e.note}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {/claim/i.test(e.status) &&
                                  /selesai/i.test(e.status)
                                    ? "claim selesai"
                                    : /claim/i.test(e.status)
                                    ? "claim"
                                    : e.status}
                                </TableCell>
                                <TableCell align="center">
                                  {e.status === "selesai" ? null : (
                                    <IconButton
                                      sx={{
                                        borderRight: 1,
                                        backgroundColor: "#ffffff",
                                      }}
                                      onClick={() => {
                                        setOpenModalConfirmation(true);
                                        setDataDelete(e);
                                      }}
                                    >
                                      <DoneOutlined />
                                    </IconButton>
                                  )}
                                </TableCell>
                                {/* <TableCell align="center">
                                  <IconButton
                                    sx={{ borderRight: 1 }}
                                    onClick={() => {
                                      setDataEdit(e);
                                      setIsEdit(i);
                                    }}
                                  >
                                    <EditOutlined />
                                  </IconButton>
                                </TableCell> */}
                              </TableRow>
                            )
                          )
                        : null}
                    </TableBody>
                  </Table>
                  {loading ? (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={25} sx={{ color: "#333333" }} />
                    </Box>
                  ) : res.message ? (
                    <Alert
                      variant="standard"
                      severity="error"
                      sx={{ borderRadius: 0 }}
                    >
                      {res.message}
                    </Alert>
                  ) : dataList.length === 0 ? (
                    <Alert
                      variant="standard"
                      severity="error"
                      sx={{ borderRadius: 0 }}
                    >
                      No Data
                    </Alert>
                  ) : null}
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  ml: matchesMobile ? 0 : 2,
                  height: "10%",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={+params.limit}
                  page={count <= 0 ? 0 : +params.page - 1}
                  onPageChange={(_, newPage) =>
                    push({ ...query, page: newPage + 1 })
                  }
                  onRowsPerPageChange={({ target: { value = "10" } }) =>
                    push({ ...query, limit: value, page: 1 })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ProblematicOrderPage;
