import { api_gtw } from "../../apis";

export const getOrders = async (search) => {
  const { data } = await api_gtw.get(`/order/all${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const getOrdersInstant = async ({ query }) => {
  const { data } = await api_gtw.get(`/order/instant?${query}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const getOrdersDelete = async () => {
  const { data } = await api_gtw.get(`/order/delete`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const getOrdersValidate = async (search) => {
  const { data } = await api_gtw.get(`/order/validate${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const getValidateRetur = async (search) => {
  const { data } = await api_gtw.get(`/order/validate/retur/${search}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const uploadOrder = async (data, ShopId) => {
  const { data: _data } = await api_gtw.post(
    `/order/upload`,
    { data, ShopId },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return _data;
};

export const deleteOrder = async (id) => {
  const { data } = await api_gtw.patch(
    `/order/delete/${id}`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const deleteOrderPermanent = async (id) => {
  const { data } = await api_gtw.delete(`/order/delete/${id}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data;
};

export const restoreOrder = async (id) => {
  const { data } = await api_gtw.patch(
    `/order/restore/${id}`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const updateOrder = async ({ payload, id }) => {
  const { data } = await api_gtw.put(`/order/update/${id}`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const checkedOrder = async ({ noawb_order, seller }) => {
  const { data } = await api_gtw.patch(
    `/order/checked`,
    { noawb_order, seller },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const returOrder = async ({ noawb_order, seller }) => {
  const { data } = await api_gtw.patch(
    `/order/checked/retur`,
    { noawb_order, seller },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data;
};

export const getCheckedOrder = async ({ type, seller }) => {
  const { data } = await api_gtw.get(
    `/order/allChecked?type=${type === "retur" ? "retur" : "order"}&ShopId=${
      seller || 0
    }`,
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const getOrdersPrint = async (payload) => {
  const { data } = await api_gtw.post(`/order/all/print`, payload, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
  return data.data;
};

export const getDetailLottery = async (kodeUnique) => {
  const { data } = await api_gtw.get(`/order/lottery/${kodeUnique}`, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });

  return data.data;
};

export const updateStatusPackage = async ({
  awb,
  expedition,
  status,
  packageTimeCheck,
}) => {
  const { data } = await api_gtw.patch(
    `/order/update/status_package`,
    { awb, expedition, status, packageTimeCheck },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );

  return data.data;
};

// export const updateStatusPackage = async ({ dataList }) => {
//   const { data } = await api_gtw.patch(
//     `/order/update/status_package`,
//     { dataList },
//     {
//       headers: {
//         authorization: `Bearer ${localStorage.getItem("access_token")}`,
//       },
//     }
//   );

//   return data.data;
// };

export const getManifest = async ({ awb, expedition }) => {
  const { data } = await api_gtw.post(
    `/order/all/manifest`,
    { awb, expedition },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};

export const updateToRetur = async (awb) => {
  const { data } = await api_gtw.patch(
    `/order/update/toretur`,
    { awb },
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return data.data;
};
