import {
  Container,
  Typography,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  Modal,
  Zoom,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  FormControl,
  useMediaQuery,
  InputLabel,
  NativeSelect,
  Divider,
  Snackbar,
  Slide,
  Chip,
  Grid,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { getContent } from "../../../store/actions/actionKol";
import { handleError } from "../../../helper/helpers";
import {
  setErr,
  setMessage,
  setOpenSnack,
} from "../../../store/actions/actionAwb";
import { convertDate } from "../../../helper/convertDate";
import { ReceiptLong } from "@mui/icons-material";

function ViewKontenSelesai() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [type, setType] = useState("done");
  const [loading, setLoading] = useState(true);

  //----------------||DECODE TOKEN||---------------//
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  const userAccessStatus = decode.accessStatus;

  //---------------||STYLING||--------------//
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));

  //-----------||FUNCTION||-----------//
  function setCOD(proses) {
    if (proses) {
      if (proses.cod) {
        return (
          <Chip
            color="success"
            label="aktif"
            size="small"
            sx={{
              backgroundColor: "#333333",
              color: "#ffffff",
              borderRadius: 0,
            }}
          />
        );
      }
    } else {
      return (
        <Chip
          label="tidak aktif"
          size="small"
          sx={{
            backgroundColor: "#6c6d70",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
  }

  function setProses(process, status) {
    if (process === "pending") {
      return (
        <Chip
          label="Pending"
          variant="outlined"
          sx={{
            backgroundColor: "#195B66",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "accepted" && !status) {
      return (
        <Chip
          label="Menunggu Pembayaran"
          variant="outlined"
          sx={{
            backgroundColor: "#71883F",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "accepted" && status) {
      return (
        <Chip
          label="Menunggu Konten"
          variant="outlined"
          sx={{
            backgroundColor: "#3D7D54",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
    if (process === "pending-promoted") {
      return (
        <Chip
          color="primary"
          label="Konten Gratis"
          variant="outlined"
          sx={{
            backgroundColor: "#AB8C36",
            color: "#ffffff",
            borderRadius: 0,
          }}
        />
      );
    }
  }

  useEffect(() => {
    dispatch(getContent(type, 0, 1000))
      .then(({ data }) => {
        setData(data.data);
      })
      .catch((err) => {
        const message = handleError(err);
        dispatch(setErr(true));
        dispatch(setMessage(message));
        dispatch(setOpenSnack(true));
      })
      .finally(() => setLoading(false));
  }, [type]);

  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        pb: 3,
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundColor: "#ffffff",
            boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <TableContainer sx={{ maxHeight: "100%" }}>
              <Table size="large" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Tanggal Request</StyledTableCell>
                    <StyledTableCell>Kode Konten</StyledTableCell>
                    <StyledTableCell>Nama Kreator</StyledTableCell>
                    <StyledTableCell>Link Video</StyledTableCell>
                    <StyledTableCell>Rating</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell>{convertDate(row.createdAt)}</TableCell>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.kol_name}</TableCell>
                      <TableCell>
                        <a href={row.url_video} target="_blank">
                          {row.url_video?.length > 40
                            ? row.url_video.substring(0, 41) + "..."
                            : row.url_video}
                        </a>
                      </TableCell>
                      <TableCell>
                        <Button>Kasi Rating</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={25} sx={{ color: "#333333" }} />
                </Box>
              ) : data?.length === 0 ? (
                <Alert variant="standard" severity="error">
                  No Data
                </Alert>
              ) : null}
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ViewKontenSelesai;
