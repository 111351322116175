import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  Typography,
  Box,
  Button,
  Divider,
  Stack,
  FormControl,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  useMediaQuery,
  IconButton,
  Paper,
  Zoom,
  Collapse,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { handleError } from "../../../../helper/helpers";
import { styled, useTheme } from "@mui/material/styles";
import {
  DeleteOutlined,
  AddCircle,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { useState, useMemo, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getQueryUrl, formatDate, ThisMonth } from "../../../../helper/custom";
import {
  getAllSuratJalan,
  addSuratJalan,
  getThreePlTotal,
} from "../../../../store/actions/syncAction";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jwt_decode from "jwt-decode";

import { dateFormatReport } from "../../../../helper/DateFormat";

export default function SimpleReportDesktopPage({
  // totalDataList,
  selectedStoreSuratJalan,
  setSelectedStoreSuratJalan,
  totalSuratJalan,
  seller,
  setSeller,
}) {
  //localstorage
  let status = JSON.parse(localStorage.getItem("status"));
  const token = localStorage.getItem("access_token");
  const decode = jwt_decode(token);
  let userInternal = decode.userInternalId;

  //format Date
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };

  //localstorage
  const isAppleProduct =
    navigator.userAgent.match(/Macintosh|iPad|iPhone|iPod/i) !== null;
  //query
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { search } = useLocation();

  const query = useMemo(() => {
    if (!search) return null;
    return getQueryUrl(search);
  }, [search]);
  //query

  //=======================

  //theme
  const theme = useTheme();
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ffffff",
    },
  }));
  const matchesMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  //theme

  //=======================
  //value
  const [searchValue, setSearchValue] = useState(params.search || "");

  const [viewDetail, setViewDetail] = useState(
    status && status.switch ? status.switch : false
  );

  const [errorField, setErrorField] = useState([{ expedition: "", total: "" }]);
  const [dataList, setDataList] = useState({ data: [], count: 0 });
  const [dataTicket, setDataTicket] = useState({
    awb: "",
    note: "",
    idInternal: decode.userInternalId,
  });
  const [selectedStore, setSelectedStore] = useState(
    decode.userInternalId[0].id
  );

  const [dataDownload, setDataDownload] = useState({
    // minDate: "",
    // maxDate: "",
    id: decode.userInternalId[0].id,
    date: "",
    threePl: "",
  });

  const [dataSuratJalan, setDataSuratJalan] = useState({
    JNE: 0,
    JNT: 0,
    "Anter Aja": 0,
    NINJA: 0,
    "ID EXPRESS": 0,
    LION: 0,
    SICEPAT: 0,
    SHOPEE: 0,
    LAZADA: 0,
    WAHANA: 0,
  });
  const [inputSuratJalan, setInputSuratJalan] = useState([
    { expedition: "", total: "" },
  ]);
  const [inputSuratJalanDefault, setInputSuratJalanDefault] = useState([
    { expedition: "", total: "" },
  ]);

  const thisMonth = ThisMonth();
  //modal
  const [openModalDate, setOpenModalDate] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);

  const [openModalTicket, setOpenModalTicket] = useState(false);
  const [openModalTicketManual, setOpenModalTicketManual] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDatePicker2, setOpenDatePicker2] = useState(false);
  const [openModalSuratJalan, setOpenModalSuratJalan] = useState(false);
  const [openSuratJalan, setOpenSuratJalan] = useState(false);
  const [dateSuratJalan, setDateSuratJalan] = useState(false);

  //modal

  //loading
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  //loading

  const [notif, setNotif] = useState({
    type: "",
    status: "success",
    message: "",
  });

  const [res, setRes] = useState({ status: "success", message: "" });

  const [state, setState] = useState({
    startDate: params.minDate
      ? new Date(formatDate(params.minDate, true))
      : new Date(),
    endDate: params.maxDate
      ? new Date(formatDate(params.maxDate, true))
      : new Date(),
    // startDate: new Date(),
    // endDate: new Date(),
    key: "selection",
  });
  const yesterday = params.thisDate.replaceAll("-", "/");

  const [date, setDate] = useState(new Date(yesterday));
  const [date2, setDate2] = useState(new Date());
  const [totalDataList, setTotalDataList] = useState([]);
  //value

  useEffect(() => {
    // getReportAction();
    if (params.minDate && params.maxDate) {
      getThreePlTotalAction();
    }
  }, [query, selectedStore]);
  // useEffect(() => {
  //   getThreePlTotalAction();
  // }, []);
  //=======================

  //function
  const getThreePlTotalAction = async () => {
    try {
      // const totalList = await getThreePlTotal({
      //   search:
      //     search +
      //     `&minDate=${thisMonth.startDate}&maxDate=${thisMonth.endDate}`,
      //   selectedStore,
      // });
      const totalList = await getThreePlTotal({
        search: search,
        selectedStore,
      });

      setTotalDataList(totalList.totalPerThreePl);
    } catch (error) {
      const message = await handleError(error);
      console.log(message);
    }
  };

  const addSuratJalanAction = async () => {
    try {
      let temp = [];
      let status = false;
      inputSuratJalan.forEach((e, index) => {
        temp.push({ expedition: "", total: "" });
        if (!e.expedition) {
          status = true;
          temp[index].expedition = "please input field";
        } else {
          temp[index].expedition = "";
        }
        if (!e.total) {
          status = true;

          temp[index].total = "please input field";
        } else {
          temp[index].total = "";
        }

        if (e.expedition && e.total) {
          let findIndexExpedition = inputSuratJalan.findIndex((e1) => {
            return e.expedition === e1.expedition;
          });

          const findExpedition = findIndexExpedition !== index;

          if (findExpedition) {
            status = true;

            temp[index].expedition = `duplicate expedition`;
          }
        }
      });

      if (status) {
        setErrorField(temp);
        return;
      }
      const data = await addSuratJalan({
        payload: inputSuratJalan,
        date: date2,
        selectedStoreSuratJalan,
      });

      if (new Date(date2) === new Date()) {
        setDataSuratJalan(data);
      }
      setDateSuratJalan(false);
      setOpenModalSuratJalan(false);
      setInputSuratJalan(inputSuratJalanDefault);
      setDate2(new Date());

      setErrorField([{ expedition: "", total: "" }]);
    } catch (error) {
      console.log(error);
      const message = await handleError(error);
    }
  };

  const getAllSuratJalanAction = async ({ payloadDate, storeName }) => {
    try {
      setDateSuratJalan(false);

      setOpenDatePicker(false);
      const data = await getAllSuratJalan({
        date: payloadDate ? payloadDate : "",
        selectedStore: storeName,
      });

      if (data && Object.keys(data).length) {
        let newData = [];
        Object.keys(data).forEach((e) => {
          if (e !== "id" && data[e]) {
            let obj = {
              expedition: "",
              total: data[e],
            };
            if (e === "lazada") {
              obj.expedition = "totalLex";
            } else if (/ninja/i.test(e)) {
              obj.expedition = "totalNinja";
            } else if (/id/i.test(e)) {
              obj.expedition = "totalId";
            } else if (/anter/i.test(e)) {
              obj.expedition = "totalAnteraja";
            } else {
              let temp = e.charAt(0).toUpperCase() + e.slice(1);
              obj.expedition = "total" + temp;
            }

            // console.log(temp);
            newData.push(obj);
          }
        });
        setInputSuratJalan(newData);
        if (!payloadDate) {
          setDataSuratJalan(data);
          setInputSuratJalanDefault(newData);
        }
      } else {
        setInputSuratJalan([{ expedition: "", total: "" }]);
      }
      setOpenModalSuratJalan(true);
    } catch (error) {
      const message = await handleError(error);
      console.log(message);
    }
  };

  function addInput(e) {
    e.preventDefault();
    // setErrorField((current) => [
    //   ...current,
    //   { email: "", username: "", password: "", wrongFormat: "" },
    // ]);

    setInputSuratJalan((current) => [
      ...current,
      { expedition: "", total: "" },
    ]);
  }

  function suratJalanInputValue(e, i, stat) {
    e.preventDefault();
    let tempInput = [...inputSuratJalan];
    tempInput[i][stat] = e.target.value;
    setInputSuratJalan(tempInput);
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
        flexDirection: "column",
      }}
    >
      <Modal
        open={openDatePicker}
        onClose={() => {
          setDateSuratJalan(false);
          setOpenDatePicker(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Zoom in={openDatePicker}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#ffffff",
              padding: 2,
            }}
          >
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  format="dd/MM/yyyy"
                  value={dateSuratJalan ? date2 : date}
                  sx={{ "& input": { color: "red" } }}
                  onChange={(e) => {
                    if (dateSuratJalan) {
                      setDate2(e);
                    } else {
                      setDate(e);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                onClick={() => {
                  setDateSuratJalan(false);
                  setOpenDatePicker(false);
                  setDate2(new Date());
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={async () => {
                  if (dateSuratJalan) {
                    let payloadDate = date2;
                    await getAllSuratJalanAction({
                      payloadDate,
                      storeName: selectedStoreSuratJalan,
                    });
                  } else {
                    if (isAppleProduct) {
                      let newDate = new Date(date).toLocaleDateString(
                        "en-US",
                        options
                      );
                      newDate = newDate.split("/");
                      const tempDate = newDate[1];
                      const tempMonth = newDate[0];
                      newDate[1] = tempDate;
                      newDate[0] = tempMonth;
                      newDate = newDate.join("-");
                      setSearchParams({
                        ...query,
                        thisDate: newDate,
                      });
                    } else {
                      setSearchParams({
                        ...query,
                        thisDate: new Date(date)
                          .toLocaleDateString("en-US", options)
                          .replaceAll("/", "-"),
                      });
                    }
                    setDateSuratJalan(false);

                    setOpenDatePicker(false);
                  }
                  // getOrderValidate();
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Cari
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>

      <Modal
        open={openModalSuratJalan}
        onClose={() => {
          setDateSuratJalan(false);
          setOpenModalSuratJalan(false);
          setInputSuratJalan(inputSuratJalanDefault);
          setDate2(new Date());
          setDate(yesterday);
        }}
        sx={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Zoom in={openModalSuratJalan}>
          <Box
            sx={{
              width: { lg: "30%", md: "30%", sm: "50%", xs: "80%" },
              height: "50%",
              backgroundColor: "white",
              paddingY: 5,
              borderRadius: 0,
            }}
          >
            <Box
              sx={{
                height: "80%",
                paddingX: 4,
                display: "flex",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex", gap: 3 }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 0,
                    boxShadow:
                      "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                    backgroundColor: "#ffffff",
                    color: "#333333",
                    "&:hover": {
                      color: "#ffffff",
                      backgroundColor: "#333333",
                    },
                    height: "100%",
                  }}
                  onClick={(e) => {
                    setDateSuratJalan(true);
                    setOpenDatePicker(true);
                  }}
                >
                  {date2
                    ? `Tanggal : ${date2.toLocaleDateString("en-US", options)}`
                    : "Pilih Tanggal"}
                </Button>
                {decode.role ? (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                      <InputLabel>Seller</InputLabel>
                      <Select
                        value={seller}
                        label="Seller"
                        sx={{ borderRadius: 0 }}
                        onChange={(e) => {
                          setSeller(e.target.value);
                        }}
                      >
                        {userInternal.length
                          ? userInternal.map((e, i) => (
                              <MenuItem key={i} value={e.id}>
                                {e.username}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>

                    {seller ? (
                      <FormControl sx={{ m: 1, minWidth: 120, marginTop: 5 }}>
                        <InputLabel>Pilih Toko</InputLabel>
                        <Select
                          label="Pilih Toko"
                          sx={{ borderRadius: 0 }}
                          value={selectedStoreSuratJalan}
                          onChange={async (e) => {
                            setSelectedStoreSuratJalan(e.target.value);
                            setDataList({ data: [], count: 0 });
                            let payloadDate = date2;
                            await getAllSuratJalanAction({
                              payloadDate,
                              storeName: e.target.value,
                            });
                          }}
                        >
                          {userInternal.map((e) => {
                            if (e.id === seller) {
                              return e.userInternalId.map((res, i) => (
                                <MenuItem key={i} value={res.id}>
                                  {res.username}
                                </MenuItem>
                              ));
                            }
                          })}
                        </Select>
                      </FormControl>
                    ) : null}
                  </>
                ) : (
                  <FormControl fullWidth sx={{ backgroundColor: "#ffffff" }}>
                    <InputLabel>Pilih Toko</InputLabel>
                    <Select
                      label="Pilih Toko"
                      sx={{
                        borderRadius: 0,
                        boxShadow:
                          "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      }}
                      value={selectedStoreSuratJalan}
                      onChange={async (e) => {
                        setSelectedStoreSuratJalan(e.target.value);
                        setDataList({ data: [], count: 0 });
                        let payloadDate = date2;
                        await getAllSuratJalanAction({
                          payloadDate,
                          storeName: e.target.value,
                        });
                      }}
                    >
                      {userInternal.length
                        ? userInternal.map((e, i) => (
                            <MenuItem key={i} value={e.id}>
                              {e.username}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                )}
              </Box>
              <TableContainer
                sx={{
                  maxHeight: "90%",
                  "&::-webkit-scrollbar": { width: 10 },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#333333",
                    borderRadius: 1,
                  },
                }}
              >
                <Table>
                  <TableBody>
                    {inputSuratJalan.map((input, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <TableCell sx={{ width: "50%" }}>
                            <FormControl fullWidth>
                              <InputLabel>Ekspedisi</InputLabel>
                              <Select
                                value={input.expedition}
                                label="Ekspedisi"
                                onChange={(e) =>
                                  suratJalanInputValue(e, index, "expedition")
                                }
                              >
                                <MenuItem value={"totalJnt"}>JNT</MenuItem>
                                <MenuItem value={"totalJne"}>JNE</MenuItem>
                                <MenuItem value={"totalAnteraja"}>
                                  ANTER AJA
                                </MenuItem>
                                <MenuItem value={"totalNinja"}>
                                  NINJA EXPRESS
                                </MenuItem>
                                <MenuItem value={"totalId"}>
                                  ID EXPRESS
                                </MenuItem>
                                <MenuItem value={"totalLion"}>
                                  LION PARCEL
                                </MenuItem>
                                <MenuItem value={"totalSicepat"}>
                                  SICEPAT
                                </MenuItem>
                                <MenuItem value={"totalShopee"}>
                                  SHOPEE
                                </MenuItem>
                                <MenuItem value={"totalLex"}>LEX</MenuItem>
                                <MenuItem value={"totalWahana"}>
                                  WAHANA
                                </MenuItem>
                              </Select>
                              {errorField[index] &&
                              errorField[index].expedition ? (
                                <FormHelperText
                                  id="my-helper-text"
                                  sx={{ color: "red" }}
                                >
                                  {errorField[index].expedition}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </TableCell>
                          <TableCell sx={{ width: "50%" }}>
                            <TextField
                              label="total"
                              variant="standard"
                              type="number"
                              value={input.total}
                              onChange={(e) =>
                                suratJalanInputValue(e, index, "total")
                              }
                              error={
                                errorField[index] && errorField[index].total
                                  ? true
                                  : false
                              }
                              helperText={
                                errorField[index] && errorField[index].total
                                  ? errorField[index].total
                                  : ""
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                let temp = [...inputSuratJalan];
                                if (temp.length === 1) {
                                  setOpenModalSuratJalan(false);

                                  setInputSuratJalan([
                                    { expedition: "", total: "" },
                                  ]);
                                } else {
                                  temp.splice(index, 1);
                                  setInputSuratJalan(temp);
                                }
                              }}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                height: "10%",
                paddingX: 4,
              }}
            >
              <IconButton
                onClick={(e) => {
                  addInput(e);
                }}
                sx={{
                  color: "#ab8c36",
                  backgroundColor: "transparent",
                  // border: 1,
                  // borderColor: "#ab8c36",
                  fontSize: 15,
                  "&:hover": {
                    color: "#333333",
                    // borderColor: "#333333",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <AddCircle sx={{ mr: 1 }} />
                Tambah
              </IconButton>
              <Divider orientation="horizontal" />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                height: "10%",
                // marginY: 2,
                paddingX: 4,
                mt: 2,
              }}
            >
              <Button
                onClick={(e) => {
                  setOpenModalSuratJalan(false);
                  setDateSuratJalan(false);
                  setInputSuratJalan(inputSuratJalanDefault);
                  setErrorField([{ expedition: "", total: "" }]);
                }}
                style={{ marginRight: 15 }}
                variant="outlined"
                sx={{
                  borderColor: "#ab8c36",
                  color: "#ab8c36",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#ffffff",
                    borderColor: "#ab8c36",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Batal
              </Button>
              <Button
                onClick={async (e) => {
                  await addSuratJalanAction();
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  width: "10%",
                  "&:hover": {
                    backgroundColor: "#333333",
                    transform: "scale3d(1.1, 1.2, 1)",
                  },
                }}
              >
                Tambah
              </Button>
            </Box>
          </Box>
        </Zoom>
      </Modal>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mb: 5,
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "sans-serif",
              fontWeight: "bold",
              fontSize: { lg: 30, md: 30, sm: 20, xs: 20 },
            }}
          >
            {`REPORT HARI ${dateFormatReport(params.thisDate).toUpperCase()}`}
          </Typography>
        </Box>
        <Stack spacing={2} direction="row">
          {/* {loading === "totalList" ? null : (
          <Button
            variant="contained"
            onClick={() => downloadReportAction}
            sx={{
              borderRadius: 0,
              boxShadow:
                "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              backgroundColor: "#ab8c36",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
              height: "50px",
            }}
          >
            Download Report
          </Button>
        )} */}
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              backgroundColor: "#ffffff",
              color: "#333333",
              "&:hover": {
                color: "#ffffff",
                backgroundColor: "#333333",
              },
            }}
            onClick={(e) => setOpenDatePicker(true)}
          >
            {!params.thisDate
              ? "Pilih Tanggal"
              : `Tanggal : ${formatDate(params.thisDate, true)} 
              `}
          </Button>
        </Stack>
      </Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Stack
          spacing={0}
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 2,
            mb: 2,
            // paddingY: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#transparent",
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              width: "300px",
              height: "80px",
              display: "flex",
            }}
          >
            <Paper elevation={3} />
            <Box
              sx={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "5px 0px 5px 0px rgba(0, 0, 0, 0.2) !important",
                backgroundColor: "#333333",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontWeight: "bold" }}
                align="center"
              >
                TOTAL
              </Typography>
            </Box>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: 2,
                borderLeft: 0,
                borderColor: "#333333",
                backgroundColor: "#ffffff",
              }}
            >
              {loading === "totalList" ? (
                <CircularProgress size={25} sx={{ color: "#333333" }} />
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "revert",
                  }}
                >
                  {totalDataList?.total}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: "#transparent",
              boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
              width: "300px",
              height: "80px",
              display: "flex",
            }}
            // key={i}
          >
            <Paper elevation={3} />
            <Box
              sx={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "5px 0px 5px 0px rgba(0, 0, 0, 0.2) !important",
                backgroundColor: "#333333",
              }}
            >
              <Typography
                sx={{ color: "#ffffff", fontWeight: "bold" }}
                align="center"
              >
                TOTAL PAKET SURAT JALAN
              </Typography>
            </Box>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: 2,
                borderLeft: 0,
                borderColor: "#333333",
                backgroundColor: "#ffffff",
              }}
            >
              {loading === "totalList" ? (
                <CircularProgress size={25} sx={{ color: "#333333" }} />
              ) : (
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: 18,
                    fontFamily: "revert",
                  }}
                >
                  {totalSuratJalan}
                </Typography>
              )}
            </Box>
          </Box>
          {/* <Button variant="outlined">
          Masukkan Total Paket Surat Jalan
        </Button> */}
        </Stack>
      </Box>
      <Stack
        spacing={0}
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 2,
          // paddingY: 3,
        }}
      >
        {Object.keys(totalDataList).length > 0 &&
          Object.keys(totalDataList).map((e, i) =>
            e !== "total" ? (
              <Box
                sx={{
                  backgroundColor: "#transparent",
                  boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                  width: "200px",
                  height: "50px",
                  display: "flex",
                }}
                key={i}
              >
                <Box
                  sx={{
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "5px 0px 5px 0px rgba(0, 0, 0, 0.2) !important",
                    backgroundColor: "#333333",
                  }}
                >
                  <Typography
                    sx={{ color: "#ffffff", fontWeight: "bold" }}
                    align="center"
                  >
                    {e.toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: 2,
                    borderLeft: 0,
                    borderColor: "#333333",
                    backgroundColor: "#ffffff",
                  }}
                >
                  {loading === "totalList" ? (
                    <CircularProgress size={25} sx={{ color: "#333333" }} />
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: "revert",
                      }}
                    >
                      {totalDataList[e]}
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : null
          )}
      </Stack>
      <IconButton
        // variant="outlined"
        onClick={() => setOpenSuratJalan(!openSuratJalan)}
        sx={{
          marginY: 2,
          "&:hover": { backgroundColor: "transparent", color: "#333333" },
        }}
      >
        {openSuratJalan ? <ExpandLess /> : <ExpandMore />}
        <Typography sx={{ fontWeight: "bold" }}>
          Surat Jalan Hari ini
        </Typography>
      </IconButton>
      <Collapse in={openSuratJalan}>
        <Box
          sx={{
            backgroundColor: "#ffffff",
            display: "flex",
            padding: 2,
            // justifyConte: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { lg: 24, md: 24, sm: 18, xs: 18 },
              }}
            >
              {matchesMobile
                ? `SURAT JALAN TANGGAL ${new Date().toLocaleDateString(
                    "en-US",
                    options
                  )}`
                : `JUMLAH SURAT JALAN HARI ${new Date()
                    .toLocaleDateString("en-US", options)
                    .toUpperCase()}`}
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                boxShadow: "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                backgroundColor: "#ab8c36",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#333333",
                },
                height: "50px",
                width: "200px",
                fontSize: { xs: 12, sm: 12, md: 14, lg: 14 },
              }}
              onClick={async () => {
                await getAllSuratJalanAction({
                  storeName: selectedStoreSuratJalan,
                });
              }}
            >
              Buat Jumlah Surat Jalan
            </Button>
          </Box>
          <Box>
            <Stack
              spacing={0}
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 2,
                // paddingY: 3,
              }}
            >
              {Object.keys(dataSuratJalan).map((e, i) =>
                e !== "total" && e !== "id" ? (
                  <Box
                    sx={{
                      backgroundColor: "#33333320",
                      boxShadow:
                        "2px -8px 10px 0px rgba(0, 0, 0, 0.2) !important",
                      width: "200px",
                      height: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={i}
                  >
                    <Typography sx={{ color: "#333333", fontWeight: "bold" }}>
                      {e.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: "revert",
                      }}
                    >
                      {dataSuratJalan[e]}
                    </Typography>
                  </Box>
                ) : null
              )}
            </Stack>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
}
