export function handleError(err) {
  let message = err.message || "check your connections";

  if (err.response !== undefined) {
    const { data = { message: "" }, status = 500 } = err.response;

    if ([403, 401].indexOf(status) !== -1) {
      localStorage.clear();
      window.location.reload();
    }
    if (data.errors) {
      message = data.errors[0];
    } else if (data.message) {
      message = data.message;
    }

    if (message.includes("network") || message.includes("connect")) {
      message = "Sedang Maintenance Berkala Silahkan Hubungi admin WhatsApp.";
    }
  }

  return message === "Network Error"
    ? "Your network is disconnected, please try again soon."
    : message;
}
